import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SIZES } from 'helpers/enums/sizes';
import useTypedSelector from 'hooks/useTypedSelector';
import { onGetLevel } from 'redux/actions/company.actions';
import { IDashboardHeaderContainerProps } from 'types/header.types';
import DashboardHeader from 'components/main/DashboardHeader';
import { onLogout, onRedirectToBenefits } from 'redux/actions/user.actions';
import pendingBenefitsSelector from 'redux/selectors/benefits.selector';

const DashboardHeaderContainer: FunctionComponent<IDashboardHeaderContainerProps> = (props) => {
  const { scores } = props;
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    function handleResize(): void {
      if (isMenuOpened && window.innerWidth > SIZES.mediumTablet) {
        setIsMenuOpened(false);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    dispatch(onGetLevel());
  }, [dispatch]);

  const toggleMenu = () => setIsMenuOpened(!isMenuOpened);

  const company = useTypedSelector((state) => state.company.data);
  const user = useTypedSelector((state) => state.company.user);
  const level = useTypedSelector((state) => state.company.level);
  const pendingBenefits = useSelector(pendingBenefitsSelector());
  const isMenuCollapsed = useTypedSelector((state) => state.header.isMenuCollapsed);
  const companyPosition = scores.findIndex((score) => company && score.name === company.name) + 1;

  const onLogoutClick = () => {
    dispatch(onLogout());
  };
  const onRedirectToBenefitsClick = () => {
    dispatch(onRedirectToBenefits());
  };

  return (
    <DashboardHeader
      company={company}
      isMenuCollapsed={isMenuCollapsed}
      toggleMenu={toggleMenu}
      isMenuOpened={isMenuOpened}
      level={level}
      onLogoutClick={onLogoutClick}
      onRedirectToBenefitsClick={onRedirectToBenefitsClick}
      user={user}
      pendingBenefits={pendingBenefits}
      companyPosition={companyPosition}
    />
  );
};

export default DashboardHeaderContainer;
