import React, { FunctionComponent } from 'react';

import LayoutContainer from 'containers/LayoutContainer';
import ConfirmEmailAccountView from 'views/ConfirmEmailAccountView';

const ConfirmEmailAccountContainer: FunctionComponent = () => {
  return (
    <LayoutContainer isLanding>
      <ConfirmEmailAccountView />
    </LayoutContainer>
  );
};

export default ConfirmEmailAccountContainer;
