import { createMuiTheme } from '@material-ui/core';
import { COLORS } from 'helpers/enums/colors';

export const Theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        color: 'white',
        backgroundColor: COLORS.blueRibbon,
      },
      arrow: {
        color: COLORS.blueRibbon,
      },
    },
  },
});
