import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { FormGroup, ErrorBlock, CustomInput } from './styles';
import { IInputProps } from './types';

const PhoneInputMasked: FunctionComponent<IInputProps> = (props: IInputProps) => {
  const {
    input,
    isLastChild,
    meta: { touched, error },
    label,
    placeholder,
  } = props;

  const hasError = touched && !!error;

  const [value, setValue] = useState('');

  const onChange = (value2: string) => {
    setValue(value2);
    input.onChange(value2.replace(/\D/g, ''));
  };

  return (
    <FormGroup hasMarginBottom={hasError} isLastChild={isLastChild}>
      <CustomInput
        {...input}
        label={label}
        placeholder={placeholder}
        name="phone"
        margin="none"
        type="phone"
        fullWidth
        variant="outlined"
        size="small"
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
      />

      {hasError && <ErrorBlock data-testid="error-block">{error}</ErrorBlock>}
    </FormGroup>
  );
};

export default PhoneInputMasked;
