import styled from 'styled-components';

import Button from 'components/commons/Button';
import Input from 'components/inputs/Input';

export const Form = styled.form`
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  text-align: left;
  margin: 15px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const NextButton = styled(Button)`
  margin-left: auto;
`;

export const QuestionDescriptionContainer = styled.div`
  text-align: left;
  margin: 20px;
`;

export const CustomInput = styled(Input)`
  padding-bottom: 0;
`;

export const RadioButtonsContainer = styled.div``;
