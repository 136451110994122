import { FunctionComponent } from 'react';

import { ITrainingsDefaultState } from 'types/training.types';
import * as constants from 'redux/constants/training.constants';

const defaultState: ITrainingsDefaultState = {
  items: [],
  loading: false,
  showCertModal: false,
};

const trainingReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { data, type } = action;
  switch (type) {
    case constants.TRAINING_ON_GET_ALL_REQUESTED:
    case constants.TRAINING_SUBMIT_CODE_REQUESTED:
    case constants.TRAINING_ON_GET_ONE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.TRAINING_ON_GET_ALL_SUCCEEDED:
      return {
        ...state,
        items: data.trainings,
        loading: false,
      };
    case constants.TRAINING_ON_GET_ONE_SUCCEEDED:
      return {
        ...state,
        current: data.training,
        loading: false,
      };
    case constants.TRAINING_ON_GET_ALL_FAILED:
    case constants.TRAINING_SUBMIT_CODE_FAILED:
    case constants.TRAINING_MARK_AS_COMPLETE_FAILED:
    case constants.TRAINING_ON_GET_ONE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.TRAINING_SUBMIT_CODE_SUCCEEDED:
    case constants.TRAINING_MARK_AS_COMPLETE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        showCertModal: data.appliesCertRegistration,
      };
    case constants.TRAINING_ON_CLOSE_CERT_MODAL_REQUESTED:
      return {
        ...state,
        showCertModal: false,
      };
    default:
      return state;
  }
};

export default trainingReducer;
