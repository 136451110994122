import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  max-width: 1296px;

  ${({ isTablet }: { isTablet: boolean }) =>
    isTablet &&
    `
    flex-direction: column;
    `}

  @media (max-width: ${SIZES.bigTablet}px) {
    height: 100%;
  }
`;

export const NewsSection = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 0.55;
  flex-shrink: 50;
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${({ isTablet }: { isTablet: boolean }) => (isTablet ? '18px 0 0' : '18px 0 0 33px')};
`;

export const NewsContainerGrid = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 260px);
  grid-auto-rows: 350px;
  grid-gap: 15px;

  overflow-y: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${SIZES.smallDesktop}px) {
    grid-template-columns: repeat(auto-fill, 250px);
    grid-auto-rows: 290px;
    grid-gap: 50px 10px;
  }
`;

export const Title = styled.h2`
  font-size: 20px;
  font-family: 'OpenSansBold';
  color: ${COLORS.blueResolution};
  margin: 0 0 5px;
  padding: 0;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-left: 20px;
  }
`;

export const TestimonialsSection = styled.div`
  width: 100%;
  flex-grow: 0.45;
  flex-shrink: 0;
  background: ${COLORS.linkWater};
  flex-basis: ${({ isTablet }: { isTablet: boolean }) => (isTablet ? '40%' : '30%')};
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: ${({ isTablet }: { isTablet: boolean }) => (isTablet ? '18px 0 0' : '18px 0 0 33px')};
`;

export const TestimonialsContainer = styled.div`
  width: 100%;
  max-height: 100%;
  padding: 0 0 20px;

  overflow-y: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CarouselContainer = styled.div`
  padding: 0 8px;
`;
