export const USER_ON_CONFIRM_ACCOUNT_REQUESTED = 'USER_ON_CONFIRM_ACCOUNT_REQUESTED';
export const USER_ON_CONFIRM_ACCOUNT_SUCCEEDED = 'USER_ON_CONFIRM_ACCOUNT_SUCCEEDED';
export const USER_ON_CONFIRM_ACCOUNT_FAILED = 'USER_ON_CONFIRM_ACCOUNT_FAILED';

export const USER_ON_ACCEPT_ACCOUNT_REQUESTED = 'USER_ON_ACCEPT_ACCOUNT_REQUESTED';
export const USER_ON_ACCEPT_ACCOUNT_SUCCEEDED = 'USER_ON_ACCEPT_ACCOUNT_SUCCEEDED';
export const USER_ON_ACCEPT_ACCOUNT_FAILED = 'USER_ON_ACCEPT_ACCOUNT_FAILED';

export const USER_ON_FORGOT_PASSWORD_REQUESTED = 'USER_ON_FORGOT_PASSWORD_REQUESTED';
export const USER_ON_FORGOT_PASSWORD_SUCCEEDED = 'USER_ON_FORGOT_PASSWORD_SUCCEEDED';
export const USER_ON_FORGOT_PASSWORD_FAILED = 'USER_ON_FORGOT_PASSWORD_FAILED';

export const USER_ON_INITIALIZE_REQUESTED = 'USER_ON_INITIALIZE_REQUESTED';
export const USER_ON_INITIALIZE_SUCCEEDED = 'USER_ON_INITIALIZE_SUCCEEDED';
export const USER_ON_INITIALIZE_FAILED = 'USER_ON_INITIALIZE_FAILED';

export const USER_ON_LOGIN_REQUESTED = 'USER_ON_LOGIN_REQUESTED';
export const USER_ON_LOGIN_SUCCEEDED = 'USER_ON_LOGIN_SUCCEEDED';
export const USER_ON_LOGIN_FAILED = 'USER_ON_LOGIN_FAILED';

export const USER_ON_LOGOUT_REQUESTED = 'USER_ON_LOGOUT_REQUESTED';
export const USER_ON_LOGOUT_SUCCEEDED = 'USER_ON_LOGOUT_SUCCEEDED';
export const USER_ON_LOGOUT_FAILED = 'USER_ON_LOGOUT_FAILED';

export const USER_ON_REGISTER_REQUESTED = 'USER_ON_REGISTER_REQUESTED';
export const USER_ON_REGISTER_SUCCEEDED = 'USER_ON_REGISTER_SUCCEEDED';
export const USER_ON_REGISTER_FAILED = 'USER_ON_REGISTER_FAILED';

export const COMPANY_ON_REGISTER_REQUESTED = 'COMPANY_ON_REGISTER_REQUESTED';
export const COMPANY_ON_REGISTER_SUCCEEDED = 'COMPANY_ON_REGISTER_SUCCEEDED';
export const COMPANY_ON_REGISTER_FAILED = 'COMPANY_ON_REGISTER_FAILED';

export const USER_ON_VALIDATE_IDENTIFIER_REQUESTED = 'USER_ON_VALIDATE_IDENTIFIER_REQUESTED';
export const USER_ON_VALIDATE_IDENTIFIER_SUCCEEDED = 'USER_ON_VALIDATE_IDENTIFIER_SUCCEEDED';
export const USER_ON_VALIDATE_IDENTIFIER_FAILED = 'USER_ON_VALIDATE_IDENTIFIER_FAILED';

export const USER_ON_REGISTER_FORM_BACK = 'USER_ON_REGISTER_FORM_BACK';

export const USER_ON_RESET_PASSWORD_REQUESTED = 'USER_ON_RESET_PASSWORD_REQUESTED';
export const USER_ON_RESET_PASSWORD_SUCCEEDED = 'USER_ON_RESET_PASSWORD_SUCCEEDED';
export const USER_ON_RESET_PASSWORD_FAILED = 'USER_ON_RESET_PASSWORD_FAILED';

export const USER_ON_UPDATE_REQUESTED = 'USER_ON_UPDATE_REQUESTED';
export const USER_ON_UPDATE_SUCCEEDED = 'USER_ON_UPDATE_SUCCEEDED';
export const USER_ON_UPDATE_FAILED = 'USER_ON_UPDATE_FAILED';

export const USER_ON_CONTACT_REQUESTED = 'USER_ON_CONTACT_REQUESTED';
export const USER_ON_CONTACT_SUCCEEDED = 'USER_ON_CONTACT_SUCCEEDED';
export const USER_ON_CONTACT_FAILED = 'USER_ON_CONTACT_FAILED';

export const USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_REQUESTED =
  'USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_REQUESTED';
export const USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_SUCCEEDED =
  'USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_SUCCEEDED';
export const USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_FAILED =
  'USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_FAILED';

export const USER_ON_INITIALIZE_SIGN_UP_VIEW_REQUESTED =
  'USER_ON_INITIALIZE_SIGN_UP_VIEW_REQUESTED';
export const USER_ON_INITIALIZE_SIGN_UP_VIEW_SUCCEEDED =
  'USER_ON_INITIALIZE_SIGN_UP_VIEW__SUCCEEDED';

export const USER_ON_INITIALIZE_SIGN_UP_VIEW_FAILED = 'USER_ON_INITIALIZE_SIGN_UP_VIEW_FAILED';
export const USER_ON_INITIALIZE_EMPLOYEE_REQUESTED = 'USER_ON_INITIALIZE_EMPLOYEE_REQUESTED';
export const USER_ON_INITIALIZE_EMPLOYEE_SUCCEEDED = 'USER_ON_INITIALIZE_EMPLOYEE_SUCCEEDED';
export const USER_ON_INITIALIZE_EMPLOYEE_FAILED = 'USER_ON_INITIALIZE_EMPLOYEE_FAILED';

export const USER_ENABLE_REQUESTED = 'USER_ENABLE_REQUESTED';
export const USER_ENABLE_SUCCEEDED = 'USER_ENABLE_SUCCEEDED';
export const USER_ENABLE_FAILED = 'USER_ENABLE_FAILED';

export const USER_DISABLE_REQUESTED = 'USER_DISABLE_REQUESTED';
export const USER_DISABLE_SUCCEEDED = 'USER_DISABLE_SUCCEEDED';
export const USER_DISABLE_FAILED = 'USER_DISABLE_FAILED';

export const USER_CHANGE_TO_OWNER_REQUESTED = 'USER_CHANGE_TO_OWNER_REQUESTED';
export const USER_CHANGE_TO_OWNER_SUCCEEDED = 'USER_CHANGE_TO_OWNER_SUCCEEDED';
export const USER_CHANGE_TO_OWNER_FAILED = 'USER_CHANGE_TO_OWNER_FAILED';

export const USER_ON_CONFIRM_ASSISTANCE_REQUESTED = 'USER_ON_CONFIRM_ASSISTANCE_REQUESTED';
export const USER_ON_CONFIRM_ASSISTANCE_SUCCEEDED = 'USER_ON_CONFIRM_ASSISTANCE_SUCCEEDED';
export const USER_ON_CONFIRM_ASSISTANCE_FAILED = 'USER_ON_CONFIRM_ASSISTANCE_FAILED';

export const USER_ON_GET_FORMS_REQUESTED = 'USER_ON_GET_FORMS_REQUESTED';
export const USER_ON_GET_FORMS_SUCCEEDED = 'USER_ON_GET_FORMS_SUCCEEDED';
export const USER_ON_GET_FORMS_FAILED = 'USER_ON_GET_FORMS_FAILED';

export const USER_ON_COMPLETE_FORM_REQUESTED = 'USER_ON_COMPLETE_FORM_REQUESTED';
export const USER_ON_COMPLETE_FORM_SUCCEEDED = 'USER_ON_COMPLETE_FORM_SUCCEEDED';
export const USER_ON_COMPLETE_FORM_FAILED = 'USER_ON_COMPLETE_FORM_FAILED';

export const USER_ON_GET_CERTIFICATES_REQUESTED = 'USER_ON_GET_CERTIFICATES_REQUESTED';
export const USER_ON_GET_CERTIFICATES_SUCCEEDED = 'USER_ON_GET_CERTIFICATES_SUCCEEDED';
export const USER_ON_GET_CERTIFICATES_FAILED = 'USER_ON_GET_CERTIFICATES_FAILED';

export const USER_ON_CLOSE_NEW_LEVEL_MODAL = 'USER_ON_CLOSE_NEW_LEVEL_MODAL';

export const USER_ON_REDIRECT_TO_BENEFITS = 'USER_ON_REDIRECT_TO_BENEFITS';

export const USER_ON_REDIRECT_TO_PROFILE = 'USER_ON_REDIRECT_TO_PROFILE';

export const USER_ON_CLOSE_COMPLETE_PROFILE_MODAL = 'USER_ON_CLOSE_COMPLETE_PROFILE_MODAL';
