import React, { FunctionComponent, useState } from 'react';
import { FormControlLabel, styled, Switch } from '@material-ui/core';
import i18next from 'i18next';
import { COLORS } from 'helpers/enums/colors';
import { IUserStatusProps } from './types';

import './i18n';

const UserStatus: FunctionComponent<IUserStatusProps> = (props: IUserStatusProps) => {
  const { userStatus, onClick, disabled } = props;
  const [userActive, setUserActive] = useState(userStatus);

  const GreenSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: `${COLORS.greenChristi}`,
      '&:hover': {
        backgroundColor: `${COLORS.greenChristi}`,
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: `${COLORS.greenChristi}`,
    },
  }));

  return (
    <FormControlLabel
      control={
        <GreenSwitch
          checked={userActive}
          onChange={() => {
            onClick(!userActive);
            setUserActive(!userActive);
          }}
          color="primary"
          disabled={disabled}
        />
      }
      label={i18next.t(userActive ? `USER_STATUS:ACTIVE` : `USER_STATUS:INACTIVE`)}
    />
  );
};

export default UserStatus;
