import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'BENEFIT', {
  COMPANY: 'Empresa',
  ACTIVATE: 'Solicitar activación',
  ACTIVATED: 'Ya activado',
  KEEP_COLLECTING_COINS: 'Seguí juntando coins para activar este beneficio',
  MODAL_DESCRIPTION:
    'Se va a enviar un email al titular de la empresa para que te de el ok de canjear el beneficio:',
  MODAL_DESCRIPTION_OWNER:
    'Usted va a escribir un email mencionando el listado de beneficiarios para ser enviado a UniPyME, ¿desea continuar?',
  CANCEL: 'Cancelar',
  ACTIVATE_MODAL: 'Solicitud de activación',
  BENEFIT: 'Beneficio',
});
