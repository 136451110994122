import React, { FunctionComponent } from 'react';
import i18next from 'i18next';
import userIcon from './assets/user_icon.svg';
import companyIcon from './assets/company_icon.svg';
import { IChooseSignUpTypeProps } from './types';
import { RegisterButton, ButtonContainer, ButtonTitle, ButtonSubtitle, Icon } from './styles';
import './i18n';

const ChooseSignUpType: FunctionComponent<IChooseSignUpTypeProps> = (
  props: IChooseSignUpTypeProps,
) => {
  const { onClickCompany, onClickUser } = props;

  return (
    <ButtonContainer>
      <RegisterButton type="button" onClick={onClickCompany}>
        <Icon src={companyIcon} alt="company icon" />
        <ButtonTitle>{i18next.t('SIGNUP_CHOOSE_TYPE:CREATE_COMPANY')}</ButtonTitle>
        <ButtonSubtitle>{i18next.t('SIGNUP_CHOOSE_TYPE:COMPANY_INFORMATION')}</ButtonSubtitle>
      </RegisterButton>
      <RegisterButton type="button" onClick={onClickUser}>
        <Icon src={userIcon} alt="user icon" />
        <ButtonTitle>{i18next.t('SIGNUP_CHOOSE_TYPE:CREATE_USER')}</ButtonTitle>
        <ButtonSubtitle>{i18next.t('SIGNUP_CHOOSE_TYPE:USER_INFORMATION')}</ButtonSubtitle>
      </RegisterButton>
    </ButtonContainer>
  );
};

export default ChooseSignUpType;
