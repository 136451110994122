import React, { FunctionComponent } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { requiredValidation } from 'helpers/validations';
import { ISubmitCodeData } from 'views/CapsuleView/types';

import Input from 'components/inputs/Input';

import { ISubmitCodeProps } from './types';
import { Form, ButtonContainer, FormButton, Title, Subtitle, FormContainer } from './styles';
import './i18n';

const SubmitCode: FunctionComponent<
  ISubmitCodeProps & InjectedFormProps<ISubmitCodeData, ISubmitCodeProps>
> = (props) => {
  const { invalid, id, capsuleId, version, handleSubmit, onSubmit, closeModal } = props;
  const formCode = useTypedSelector((state) => state.form.trainingCode);

  const submitCode = () => {
    onSubmit({ trainingId: id, code: formCode.values.code, capsuleId, version });
    closeModal();
  };

  return (
    <FormContainer>
      <Title>{i18next.t('FORM_CODE:TITLE')}</Title>
      <Subtitle>{i18next.t('FORM_CODE:SUBTITLE')}</Subtitle>
      <Form onSubmit={handleSubmit(submitCode)} data-testid="submit-code-form">
        <Field
          component={Input}
          label={i18next.t('FORM_CODE:CODE')}
          name="code"
          validate={[requiredValidation]}
          type="string"
        />
        <ButtonContainer>
          <FormButton disabled={invalid} type="submit">
            {i18next.t('FORM_CODE:BUTTON')}
          </FormButton>
        </ButtonContainer>
      </Form>
    </FormContainer>
  );
};

export default reduxForm<ISubmitCodeData, ISubmitCodeProps>({
  form: 'trainingCode',
})(SubmitCode);
