import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { Field } from 'redux-form';

import Button from '../Button';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
`;

export const BenefitForm = styled.form`
  width: 100%;
`;

export const CollaboratorsList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const CustomButton = styled(Button)`
  background-color: ${COLORS.bluePersian};
`;

export const CustomInput = styled.input`
  font-size: 14px;
  font-family: OpenSans;
  margin: 3px 0;
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 4px;
  box-shadow: none;
  padding: 5px;
  width: 180px;
  ::placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled.span`
  color: ${COLORS.brightRed};
  font-size: 10px;
  align-self: flex-start;
`;

export const CustomField = styled(Field)`
  width: 220px;
`;

export const CustomList = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
