import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'BENEFITS_VIEW', {
  TITLE: 'Tus beneficios',
  PROGRAM: 'Programa',
  LEVEL: 'Nivel',
  NEXT_LEVEL_AT: 'Siguiente nivel a',
  POINTS: 'puntos',
  HOW_TO_GAIN_COINS: '¿Cómo sumo coins?',
  WHAT_ARE_THEY_FOR: '¿Para qué sirven?',
  ALREADY_UNIPYME: 'Felicitaciones ya eres Nivel UniPyME',
  EXPLANATION_1:
    'Cada vez que completes una capacitación y cargues el código de la misma, serán asignadas',
  EXPLANATION_2: 'a tu cuenta.',
  EXPLANATION_3: 'Cuando obtengas suficientes',
  EXPLANATION_4: ' podrás canjearlas para obtener beneficios de distintos tipos a cambio de ellas.',
  INFO_BAR_TEXT: '¡Las primeras 15 empresas con el mejor puntaje, recibirán un beneficio extra!',
  NEW: 'Nuevo',
  BENEFIT: 'Beneficio',
  SOON: 'Próximamente',
});
