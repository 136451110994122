import { FunctionComponent } from 'react';

import * as userConstants from 'redux/constants/user.constants';
import { ICountryState } from 'types/country.types';

const defaultState: ICountryState = {
  countries: [],
};

const countryReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { data, type } = action;
  switch (type) {
    case userConstants.USER_ON_INITIALIZE_SIGN_UP_VIEW_SUCCEEDED:
      return {
        ...state,
        countries: data.countries,
      };
    default:
      return state;
  }
};

export default countryReducer;
