import moment from 'moment';

import { ITraining } from 'types/training.types';

import { IForm, IQuestion } from '../types/form.types';
import { FormType } from './enums/form';
import { Sections } from './enums/sections';

export const splitFormQuestionsInPages = (questions: IQuestion[]) => {
  const pageSize = 8;
  const questionsPaginated = [];
  for (let index = 0; index < questions.length; index += pageSize) {
    const questionsSlice = questions.slice(index, index + pageSize);
    questionsPaginated.push(questionsSlice);
  }
  return questionsPaginated;
};

export const getFormByType = (
  forms: IForm[] | undefined,
  section?: Sections,
  training?: ITraining,
) =>
  forms &&
  forms
    .sort((f1, f2) => -moment(f1.dateCreated).diff(f2.dateCreated))
    .find(
      (form) =>
        (section && form.type === FormType.SECTION && form.sections.includes(section)) ||
        (training &&
          (form.type === FormType.CAPSULE || form.type === FormType.TRAINING) &&
          (form.type === FormType.CAPSULE
            ? form.capsuleId.includes(training.capsuleId)
            : form.trainingId.includes(training.id))),
    );

export const getFormQuestionsAndAnswers = (form: IForm, answers: any) =>
  form.question.map((currentQuestion) => ({
    question: currentQuestion.description,
    answer: answers[currentQuestion.id],
  }));
