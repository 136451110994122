import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import styled from 'styled-components';
import CheckSvg from 'assets/imgs/check.svg';

export const ProgramsContainer = styled.div`
  margin: 1% 25px;
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CapsuleContainer = styled.div`
  margin: 1% 25px;
  max-width: 1296px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 32px;

  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0 20px;
  }
`;

export const CapsulesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  border-radius: 6px;
  width: 75%;

  @media (max-width: ${SIZES.bigTablet}px) {
    padding-right: 0;
  }
`;

export const Title = styled.p`
  flex: 1;
  font: 20px/27px 'OpenSansBold';
  color: ${COLORS.blueResolution};
  margin: 0 0 10px;

  @media (max-width: ${SIZES.mediumTablet}px) {
    margin: 0;
  }
`;

export const CapsuleText = styled.p`
  font: 16px/24px 'Unilever';
  margin: 0 0 10px;
  cursor: pointer;
  color: ${COLORS.bluePersian};
  align-self: center;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin: 0;
  }
`;

export const TextContainer = styled.div`
  text-align: end;
  display: flex;
`;

export const ProgressContainer = styled.div`
  padding: 20px 0;
  width: 25%;
  margin-right: 40px;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 95%;
  }
`;

export const RightContainer = styled.div`
  padding-bottom: 15px;
  background: ${COLORS.grayCatskill};
  padding: 20px 20px;
  border: medium double white;
  border-radius: 10px;
  width: 100%;
`;

export const Capsules = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
`;

export const EmployeeContainer = styled.div`
  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column-reverse;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const PercentageCompleted = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #63636a;
`;

export const List = styled.ul`
  border-bottom: 1px solid gray;
  list-style-type: none;
  padding-bottom: 15px;
`;

export const Check = styled.img`
  content: url(${CheckSvg});
  width: 17px;
  margin-right: 5px;
`;

export const MoreInformation = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
  color: #63636a;
`;

export const Back = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  text-align: left;
  margin-bottom: 20px;
  ::before {
    content: '< ';
  }
  color: ${COLORS.blueResolution};
  font-size: 18px;
`;
