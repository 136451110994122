import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { CapsuleType } from './types';
import {
  Capsules,
  CapsulesContainer,
  Container,
  Text,
  Title,
  Background,
  SpecialCapsulesContainer,
  ScoringContainer,
  ScoringTitle,
  ListContainer,
  ListIcon,
  ListItemContainer,
  DescriptionText,
} from './styles';
import './i18n';
import Capsule from './components/Capsule';

const capsules = [
  CapsuleType.BUSINESS,
  CapsuleType.FINANCE,
  CapsuleType.IMPROVE,
  CapsuleType.CULTURE,
  CapsuleType.LOGISTICS,
  CapsuleType.DIGITAL,
];

const Programs: FunctionComponent = () => (
  <Background>
    <Container data-testid="programs-view" id="programs">
      <Title>{i18next.t('PROGRAMS:TITLE')}</Title>
      <DescriptionText>{i18next.t('PROGRAMS:DESCRIPTION')}</DescriptionText>
      <Capsules>
        <CapsulesContainer>
          {capsules.map((capsule) => (
            <Capsule
              key={`capsule-${capsule}`}
              title={i18next.t(`PROGRAMS:CAPSULE_TITLE_${capsule}`)}
              text={i18next.t(`PROGRAMS:CAPSULE_DESCRIPTION_${capsule}`)}
              type={capsule}
            />
          ))}
        </CapsulesContainer>
      </Capsules>
      <SpecialCapsulesContainer>
        <Capsule
          key={`capsule-${CapsuleType.SPECIAL}`}
          title={i18next.t(`PROGRAMS:CAPSULE_TITLE_${CapsuleType.SPECIAL}`)}
          text={i18next.t(`PROGRAMS:CAPSULE_DESCRIPTION_${CapsuleType.SPECIAL}`)}
          type={CapsuleType.SPECIAL}
        />
        <ScoringContainer>
          <ScoringTitle>{i18next.t(`PROGRAMS:SCORING_TITLE`)}</ScoringTitle>
          <Text>{i18next.t('PROGRAMS:SCORING_DESCRIPTION')}</Text>
          <ListContainer>
            <ListItemContainer>
              <ListIcon />
              <Text>{i18next.t('PROGRAMS:SCORING_ITEM_1')}</Text>
            </ListItemContainer>
            <ListItemContainer>
              <ListIcon />
              <Text>{i18next.t('PROGRAMS:SCORING_ITEM_2')}</Text>
            </ListItemContainer>
            <ListItemContainer>
              <ListIcon />
              <Text>{i18next.t('PROGRAMS:SCORING_ITEM_3')}</Text>
            </ListItemContainer>
          </ListContainer>
        </ScoringContainer>
      </SpecialCapsulesContainer>
    </Container>
  </Background>
);

export default Programs;
