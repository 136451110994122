import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { ICapsule } from 'types/capsules.types';

import ProgressBar from '../ProgressBar';
import { ICapsuleProgessProps } from './types';
import {
  Container,
  Capsule,
  Title,
  Text,
  CapsuleTitle,
  CapsuleTitleContainer,
  Icon,
  CapsulesContainer,
} from './styles';
import './i18n';
import CapsuleIcon from '../CapsuleIcon';

const CapsuleProgress: FunctionComponent<ICapsuleProgessProps> = (props: ICapsuleProgessProps) => {
  const { capsules, user, isColumn = false } = props;

  const returnProgressBar = (capsule: ICapsule) => {
    const { trainings } = capsule;
    const capsuleTrainings = trainings[new Date().getFullYear()];
    let countCompleted = 0;
    capsuleTrainings.forEach((training) => {
      if (training.completedTrainings.length) {
        training.completedTrainings.forEach((completedTraining) => {
          if (completedTraining.userId === user.id) {
            countCompleted += 1;
          }
        });
      }
    });
    return (
      <ProgressBar completed={countCompleted} total={capsuleTrainings.length} showPoints={false} />
    );
  };

  return (
    <Container data-testid="capsule-progress-component">
      <Title>{i18next.t('CAPSULE_PROGRESS:TITLE')}</Title>
      <Text>{i18next.t('CAPSULE_PROGRESS:TEXT')}</Text>
      <CapsulesContainer isColumn={isColumn}>
        {capsules.map((capsule) => {
          const title = capsule.title.replace('#Ucc', '');
          return (
            <Capsule>
              <CapsuleTitleContainer>
                <Icon>
                  <CapsuleIcon name={title} />
                </Icon>
                <CapsuleTitle>{title}</CapsuleTitle>
              </CapsuleTitleContainer>
              {returnProgressBar(capsule)}
            </Capsule>
          );
        })}
      </CapsulesContainer>
    </Container>
  );
};

export default CapsuleProgress;
