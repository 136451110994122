import styled, { css } from 'styled-components';

import { FOOTER_HEIGHT, HOME_BANNER_HEIGTH } from 'helpers/dimensions';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import { ReactComponent as InstagramIcon } from 'assets/imgs/icon-instagram.svg';
import { ReactComponent as FacebookIcon } from 'assets/imgs/icon-facebook.svg';
import { IFooterContainerProps } from './types';

export const FooterContainer = styled.footer<IFooterContainerProps>`
  background-color: ${COLORS.stratos};
  width: 100%;
  height: ${FOOTER_HEIGHT}px;
  padding-bottom: ${(props) => (props.isBannerVisible ? HOME_BANNER_HEIGTH : 0)}px;
  @media (max-width: ${SIZES.smallTablet}px) {
    height: auto;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0 0;
  color: ${COLORS.white};
  max-width: ${({ isLanding }: { isLanding: boolean }) => isLanding && `${SIZES.bigDesktop}px`};
  margin: ${({ isLanding }: { isLanding: boolean }) => (isLanding ? '0 auto' : '0 5%')};

  @media (max-width: ${SIZES.bigDesktop}px) {
    width: 90%;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    flex-direction: row;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${SIZES.smallTablet}px) {
    flex-direction: column;
    max-width: 200px;
  }
`;

export const Copyright = styled.p`
  display: flex;
  flex-direction: row;
  margin-top: 17px;
  font-family: OpenSans;
  font-size: 12px;
  color: ${COLORS.manatee};

  @media (max-width: ${SIZES.smallTablet}px) {
    flex-direction: column;
    justify-content: space-between;
    order: 1;
  }
`;

export const CopyText = styled.p`
  margin: 0;
  margin-right: 17px;

  @media (max-width: ${SIZES.smallTablet}px) {
    display: none;
  }
`;

export const CopyTextMobile = styled.p`
  margin: 0;
  margin-right: 17px;
  font-size: 12px;
  color: ${COLORS.manatee};
  display: none;

  @media (max-width: ${SIZES.smallTablet}px) {
    display: flex;
    margin-bottom: 30px;
  }
`;

export const LegalLink = styled.a`
  margin-left: 17px;
  margin-right: 17px;
  color: ${COLORS.manatee};

  :active {
    color: ${COLORS.manatee};
  }

  :hover {
    color: ${COLORS.linkWater};
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    padding-left: 0;
    justify-content: end;
    margin: 35px 0;

    &:first-of-type {
      margin: 0;
    }

    &:last-child {
      margin: 0 0 18px;
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;

  @media (max-width: ${SIZES.smallTablet}px) {
    flex-direction: column;
  }
`;

export const LinksContainer = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;

  a {
    color: inherit;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;

export const FooterLink = styled.li`
  padding-right: 30px;

  a {
    font-family: OpenSans;
    font-size: 15px;
  }

  :hover {
    color: ${COLORS.manatee};
  }

  &:nth-child(3) {
    padding-right: 0;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    padding-right: 0;
    padding-bottom: 30px;
  }
`;

export const SocialMediasContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SocialMediaIcon = css`
  height: 26px;
  width: 26px;
  fill: ${COLORS.white};
`;

export const SocialMediaLink = styled.a`
  margin-right: 10px;

  &:last-child {
    margin-right: 0px;
  }
`;

export const Instagram = styled(InstagramIcon)`
  ${SocialMediaIcon}
`;

export const Facebook = styled(FacebookIcon)`
  ${SocialMediaIcon}
  margin-right: 15px;
`;

export const CustomLink = styled.a`
  && {
    height: 15px;
    width: 70px;
    color: ${COLORS.white};
    padding: 9px 20px;
    margin: 0 3px 3px 0;
    background: ${COLORS.bluePersian};
    display: ${({ isDisplayed }: { isDisplayed?: boolean; isDark?: boolean }) =>
      isDisplayed ? 'block' : 'none'};

    .MuiButton-label {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      padding: 0;
      text-transform: initial;
    }

    :hover {
      color: ${COLORS.white};
    }
  }
`;

export const DivPrivacy = styled.span`
  padding: 15px;
  margin: 0;
  text-align: center;
  align-items: start;
  align-self: center;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-bottom: 100px;

  @media (max-width: ${SIZES.bigTablet}px) {
    padding: 15px 0;
    text-align: center;
  }
`;

export const ProfileDiv = styled.div`
  display: flex;
  text-align: end;
  color: ${COLORS.white};
  cursor: pointer;
  align-items: center;
`;

export const PrivacyDiv = styled.div`
  display: flex;
  text-align: end;
  cursor: pointer;
  align-items: center;

  margin-left: 17px;
  margin-right: 17px;
  color: ${COLORS.manatee};

  :active {
    color: ${COLORS.manatee};
  }

  :hover {
    color: ${COLORS.linkWater};
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-left: 0px;
  }
`;
