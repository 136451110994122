import React, { FunctionComponent, useState } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import i18next from 'i18next';

import { ILoginFormData } from 'views/LoginView/types';
import { emailValidation, requiredValidation } from 'helpers/validations';

import Input from 'components/inputs/Input';

import Modal from 'components/commons/Modal';
import Loading from 'components/commons/Loading';

import { ILoginFormProps } from './types';
import {
  Form,
  ForgotPasswordContainer,
  ForgotPasswordLink,
  ButtonContainer,
  FormButton,
  ModalText,
  ButtonsContainer,
  ModalButton,
} from './styles';
import './i18n';

const LoginForm: FunctionComponent<
  ILoginFormProps & InjectedFormProps<ILoginFormData, ILoginFormProps>
> = (props) => {
  const {
    handleSubmit,
    onSubmit,
    submitting,
    isConfirmationPopUpVisible,
    onClickClosePopup,
    onClickReSendEmail,
    loading,
  } = props;

  const [loginFormInfo, setLoginFormInfo] = useState<ILoginFormData>();

  const handleSignUpFormOnSubmit = (formData: ILoginFormData) => {
    setLoginFormInfo(formData);
    onSubmit(formData);
  };

  return (
    <>
      {loading && (
        <Modal disabled isOpen>
          <Loading />
        </Modal>
      )}
      {isConfirmationPopUpVisible && (
        <Modal disabled isOpen>
          <ModalText> {i18next.t('LOGIN_FORM:POPUP_TEXT')}</ModalText>
          <ButtonsContainer>
            <ModalButton
              data-testid="nav-link-logout"
              onClick={() => loginFormInfo && onClickReSendEmail(loginFormInfo)}
            >
              {i18next.t('LOGIN_FORM:RESEND_EMAIL_BUTTON')}
            </ModalButton>
            <ModalButton data-testid="nav-link-logout" isDark onClick={() => onClickClosePopup()}>
              {i18next.t('LOGIN_FORM:CANCEL_BUTTON')}
            </ModalButton>
          </ButtonsContainer>
        </Modal>
      )}
      <Form onSubmit={handleSubmit(handleSignUpFormOnSubmit)} data-testid="login-form">
        <Field
          component={Input}
          label={i18next.t('LOGIN_FORM:EMAIL')}
          name="email"
          validate={[requiredValidation, emailValidation]}
          type="string"
        />
        <Field
          component={Input}
          label={i18next.t('LOGIN_FORM:PASSWORD')}
          name="password"
          validate={[requiredValidation]}
          type="password"
        />
        <ForgotPasswordContainer>
          <ForgotPasswordLink to="/forgot-password">
            {i18next.t('LOGIN_FORM:FORGOT_PASSWORD')}
          </ForgotPasswordLink>
        </ForgotPasswordContainer>
        <ButtonContainer>
          <FormButton disabled={submitting} type="submit">
            {i18next.t('LOGIN_FORM:SUBMIT_BUTTON')}
          </FormButton>
        </ButtonContainer>
      </Form>
    </>
  );
};

export default reduxForm<ILoginFormData, ILoginFormProps>({
  form: 'login',
})(LoginForm);
