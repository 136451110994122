import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { redirectToTraining } from 'helpers/trainings';

import DefaultFeaturedTrainingThumbnail from './assets/defaultFeaturedTrainingThumbnail.jpg';
import { IFeaturedTrainingProps } from './types';
import {
  FeaturedTrainingContainer,
  FeaturedThumbnail,
  FeaturedTrainingContent,
  FeaturedTrainingCapsuleTitle,
  FeaturedTrainingTitle,
  FeaturedTrainingDescription,
  FeaturedTrainingButton,
  ButtonsContainer,
} from './styles';
import './i18n';

const FeaturedTraining: FunctionComponent<IFeaturedTrainingProps> = (props) => {
  const { featuredTraining, capsuleTitle, isHome } = props;
  return (
    <FeaturedTrainingContainer isHome={isHome}>
      <FeaturedThumbnail
        thumbnail={featuredTraining.thumbnail || DefaultFeaturedTrainingThumbnail}
      />
      <FeaturedTrainingContent>
        <FeaturedTrainingCapsuleTitle>{capsuleTitle}</FeaturedTrainingCapsuleTitle>
        <FeaturedTrainingTitle>{featuredTraining.title}</FeaturedTrainingTitle>
        <FeaturedTrainingDescription>{featuredTraining.description}</FeaturedTrainingDescription>
        <ButtonsContainer>
          <FeaturedTrainingButton onClick={() => redirectToTraining(featuredTraining.id, true)}>
            {i18next.t('FEATURED_TRAINING:START_TRAINING')}
          </FeaturedTrainingButton>
          <FeaturedTrainingButton onClick={() => redirectToTraining(featuredTraining.id)}>
            {i18next.t('FEATURED_TRAINING:DETAIL')}
          </FeaturedTrainingButton>
        </ButtonsContainer>
      </FeaturedTrainingContent>
    </FeaturedTrainingContainer>
  );
};

export default FeaturedTraining;
