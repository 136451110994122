import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'SIGNUP_FORM', {
  EMAIL: 'E-mail',
  EMAIL_CONFIRMATION: 'Confirmar e-mail',
  PHONE: 'Número de teléfono',
  USER_NAME: 'Nombre de usuario',
  PASSWORD: 'Contraseña',
  PASSWORD_CONFIRMATION: 'Confirmar contraseña',
  SUBMIT_BUTTON: 'Crear cuenta',
  BACK_BUTTON: 'Anterior',
  AGREE_AGE: 'Soy mayor de 18 años',
  AGREE_AND_ACCEPT: 'Acepto los',
  TERMS_AND_CONDITIONS: 'Términos y Condiciones',
  AGREE_INFO:
    'Acepto que UniPyME envíe información, promociones y encuestas a mi email y celular registrados en este sitio',
  PRIVACY_TEXT: 'Por favor, lea el aviso de',
  PRIVACY_LINK: 'privacidad',
  PRIVACY_HREF_ARGENTINA: 'https://www.unilevernotices.com/argentina/spanish/privacy-notice.html',
  PRIVACY_HREF_COLOMBIA: 'https://www.unilevernotices.com/privacy-notices/columbia-spanish.html',
  PRIVACY_TEXT_2: ' para entender cómo utilizamos sus datos personales.',
});
