import { IFormAnswer } from 'views/FormView/types';

export const mergeObjects = (
  _objValue: any,
  _srcValue: any,
  _key: any,
  object: IFormAnswer,
  source: IFormAnswer,
) => {
  if (object.questionId === source.questionId) {
    // If customizer returns undefined, merging is handled by the method instead.
    return undefined;
  }
  return null;
};
