import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { Container, Title, Subtitle } from './styles';
import './i18n';

const DidYouKnow: FunctionComponent = () => (
  <Container>
    <Title>{i18next.t('DID_YOU_KNOW:TITLE')}</Title>
    <Subtitle>{i18next.t('DID_YOU_KNOW:SUBTITLE')}</Subtitle>
  </Container>
);

export default DidYouKnow;
