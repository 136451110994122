import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useTypedSelector from 'hooks/useTypedSelector';
import {
  onCloseCertModal,
  onGetVersionTrainings,
  onSubmitTrainingCode,
} from 'redux/actions/training.action';
import { ISubmitCodeData } from 'views/CapsuleView/types';
import { onCompleteForms, onGetForms } from 'redux/actions/user.actions';
import CapsuleView from 'views/CapsuleView';
import LayoutContainer from 'containers/LayoutContainer';
import { getFormQuestionsAndAnswers } from 'helpers/forms';
import { IForm } from 'types/form.types';

const CapsuleContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const capsules = useTypedSelector((state) => state.capsules.items);
  const isMenuCollapsed = useTypedSelector((state) => state.header.isMenuCollapsed);
  const company = useTypedSelector((state) => state.company.data);
  const user = useTypedSelector((state) => state.company.user);
  const forms = useTypedSelector((state) => state.company.user?.forms);
  const { capsuleId } = useParams<{ capsuleId: string }>();
  const showCertModal = useTypedSelector((state) => state.trainings.showCertModal);
  const closeCertModal = () => dispatch(onCloseCertModal());
  const capsuleLoading = useTypedSelector((state) => state.capsules.loading);
  const trainingsLoading = useTypedSelector((state) => state.trainings.loading);

  useEffect(() => {
    if (!forms) dispatch(onGetForms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const emptyCapsule = { ...capsules[0], id: '' };
  const capsuleById = capsules.find((capsule) => capsule.id === capsuleId) || emptyCapsule;

  const trainings = capsuleById && capsuleById.trainings;

  // Minimum version possible
  let currentVersion = new Date().getFullYear();

  if (trainings) {
    Object.keys(trainings)
      .map(Number)
      .forEach((key) => {
        if (trainings[key].length && key > currentVersion) {
          currentVersion = key;
        }
      });
  }
  const completeForm = (form: IForm, values: any) => {
    const completedFormData = {
      formId: form.id,
      formAnswers: getFormQuestionsAndAnswers(form, values),
    };
    dispatch(onCompleteForms(completedFormData));
  };

  const onSubmit = (formData: ISubmitCodeData) => dispatch(onSubmitTrainingCode(formData));
  const getVersionTrainings = (version: string) =>
    dispatch(onGetVersionTrainings(capsuleId, version));

  return (
    <LayoutContainer>
      {trainings && company && user && (
        <CapsuleView
          currentCapsule={capsuleById}
          isMenuCollapsed={isMenuCollapsed}
          onSubmit={onSubmit}
          trainings={trainings}
          company={company}
          user={user}
          forms={forms}
          completeForm={completeForm}
          showCertModal={showCertModal}
          closeCertModal={closeCertModal}
          getVersionTrainings={getVersionTrainings}
          capsuleLoading={capsuleLoading}
          trainingsLoading={trainingsLoading}
          currentVersion={currentVersion}
          showAllTrainings={capsuleById.showAllTrainings}
        />
      )}
    </LayoutContainer>
  );
};

export default CapsuleContainer;
