import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'HEADER', {
  LOGIN: 'Iniciar sesión',
  LOGOUT: 'Cerrar sesión',
  SIGNUP: 'Registrarse',
  WHAT_IS: 'QUÉ ES UNIPYME',
  PROGRAMS: 'PROGRAMAS',
  NEWS: 'NOTICIAS',
  DASHBOARD: 'Dashboard',
});
