import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import manCoin from 'assets/imgs/man-coin.png';
import CoinIconSVG from 'assets/imgs/coin-icon.svg';
// import Top15IconSVG from '../../assets/imgs/top15-banner.svg';

import { IBenefitsView } from './types';
import {
  ViewContainer,
  // InfoBar,
  Title,
  CompanyInfoBody,
  ContentContainer,
  BenefitsContainer,
  TopContainer,
  InfoContainer,
  InfoContent,
  InfoTitle,
  InfoImage,
  // Top15Icon,
  // InfoBarText,
  InfoImageMobile,
  SoonContainer,
  NewBenefitTilte,
  Gift,
  NewBenefitDescription,
  BenefitContainer,
  SmallCoinIcon,
} from './styles';
import './i18n';
import Benefit from './components/Benefit';

const BenefitsView: FunctionComponent<IBenefitsView> = (props: IBenefitsView) => {
  const { company, benefits, user, activateBenefit, requestOwnerActivationBenefit } = props;

  const renderTop = () => (
    <TopContainer data-testid="top-container">
      <Title>{i18next.t('BENEFITS_VIEW:TITLE')}</Title>
      {/* <InfoBar>
        <Top15Icon src={Top15IconSVG} />
        <InfoBarText>{i18next.t('BENEFITS_VIEW:INFO_BAR_TEXT')}</InfoBarText>
      </InfoBar> */}
    </TopContainer>
  );

  const renderContent = () => (
    <ContentContainer data-testid="content-container">
      <BenefitsContainer>
        {benefits.map(
          (benefit) =>
            company &&
            user && (
              <Benefit
                key={benefit.id}
                company={company}
                benefit={benefit}
                user={user}
                activateBenefit={activateBenefit}
                requestOwnerActivationBenefit={requestOwnerActivationBenefit}
              />
            ),
        )}
        <BenefitContainer>
          <SoonContainer>
            <NewBenefitTilte>
              {i18next.t('BENEFITS_VIEW:NEW')}
              <br />
              {i18next.t('BENEFITS_VIEW:BENEFIT')}
            </NewBenefitTilte>
            <Gift />
            <NewBenefitDescription>{i18next.t('BENEFITS_VIEW:SOON')}</NewBenefitDescription>
          </SoonContainer>
        </BenefitContainer>
      </BenefitsContainer>
      <InfoContainer>
        <InfoImage src={manCoin} />
        <InfoContent>
          <InfoTitle>{i18next.t('BENEFITS_VIEW:HOW_TO_GAIN_COINS')}</InfoTitle>
          <CompanyInfoBody>
            {i18next.t('BENEFITS_VIEW:EXPLANATION_1')}
            <SmallCoinIcon src={CoinIconSVG} />
            {i18next.t('BENEFITS_VIEW:EXPLANATION_2')}
          </CompanyInfoBody>
          <InfoTitle>{i18next.t('BENEFITS_VIEW:WHAT_ARE_THEY_FOR')}</InfoTitle>
          <CompanyInfoBody>
            {i18next.t('BENEFITS_VIEW:EXPLANATION_3')}
            <SmallCoinIcon src={CoinIconSVG} />
            {i18next.t('BENEFITS_VIEW:EXPLANATION_4')}
          </CompanyInfoBody>
          <InfoImageMobile src={manCoin} />
        </InfoContent>
      </InfoContainer>
    </ContentContainer>
  );

  return (
    <ViewContainer>
      {renderTop()}
      {renderContent()}
    </ViewContainer>
  );
};

export default BenefitsView;
