import React, { FunctionComponent } from 'react';
import moment from 'moment';
import i18next from 'i18next';

import Loading from 'components/commons/Loading';

import { ICertificatesViewProps } from './types';
import './i18n';
import {
  CertsInfoContainer,
  CertsTableRow,
  Title,
  CertsTable,
  ViewContainer,
  DownloadButton,
  CertsTitleContainer,
} from './styles';

const CertificatesView: FunctionComponent<ICertificatesViewProps> = (
  props: ICertificatesViewProps,
) => {
  const { certificates } = props;

  return (
    <ViewContainer>
      <Title>{i18next.t('CERTS_VIEW:TITLE')}</Title>
      <CertsTable>
        <CertsTableRow>
          <CertsTitleContainer>{i18next.t('CERTS_VIEW:DATE_CREATED')}</CertsTitleContainer>
          <CertsTitleContainer>{i18next.t('CERTS_VIEW:CAPSULE')}</CertsTitleContainer>
          <CertsTitleContainer>{i18next.t('CERTS_VIEW:FILE')}</CertsTitleContainer>
        </CertsTableRow>
        {certificates ? (
          certificates.map((certificate) => (
            <CertsTableRow>
              <CertsInfoContainer>
                {moment(certificate.dateCompleted).format('ll')}
              </CertsInfoContainer>
              <CertsInfoContainer>{certificate.capsule.title}</CertsInfoContainer>
              <CertsInfoContainer>
                <DownloadButton href={certificate.url} target="_blank">
                  {i18next.t('CERTS_VIEW:DOWNLOAD')}
                </DownloadButton>
              </CertsInfoContainer>
            </CertsTableRow>
          ))
        ) : (
          <Loading />
        )}
      </CertsTable>
    </ViewContainer>
  );
};

export default CertificatesView;
