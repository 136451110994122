import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { HEADER_HEIGHT } from 'helpers/dimensions';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import { ReactComponent as OpenMenuSVG } from './assets/open-menu.svg';
import { ReactComponent as CloseMenuSVG } from './assets/close-menu.svg';
import { IInfoContainerProps } from './types';

export const LogoContainer = styled.div`
  position: relative;
  left: 45px;
  @media (max-width: ${SIZES.bigTablet}px) {
    left: 0;
  }
`;

export const CoinIcon = styled.img`
  width: 27px;
  margin-right: 8px;
`;

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.grayCatskill};
  z-index: 4;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 40px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    padding: 0 20px;
    margin: 0;
  }
`;

export const ProfileDiv = styled.div`
  display: flex;
  text-align: end;
  color: ${COLORS.white};
  cursor: pointer;
  align-items: center;

  @media (max-width: ${SIZES.bigTablet}px) {
    display: ${({ isActive }: { isActive?: boolean }) => isActive && 'none'};
  }
`;

export const InfoDiv = styled.div`
  display: flex;
  color: ${COLORS.white};
  align-items: flex-end;
  margin: 0 15px;

  @media (max-width: ${SIZES.bigTablet}px) {
    box-sizing: border-box;
    transition: right 0.3s ease-in-out;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
  }

  @media only screen and (max-device-height: ${SIZES.bigPhone}px) and (orientation: landscape) {
    margin: 8px 20px;
  }
`;

export const CoinsLink = styled(Link)`
  display: flex;
  margin: 0 15px;

  @media (max-width: ${SIZES.bigTablet}px) {
    box-sizing: border-box;
    transition: right 0.3s ease-in-out;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
  }

  @media only screen and (max-device-height: ${SIZES.bigPhone}px) and (orientation: landscape) {
    margin: 8px 20px;
  }
`;

export const InfoTitle = styled.h3`
  font: 20px/27px 'OpenSansBold';
  margin: 0;
  padding: 0;
  color: ${COLORS.riverBed};

  ${({ color }: { color?: string }) => css`
    color: ${color};
  `};
`;

export const Text = styled.p`
  display: inline;
  font: 14px/27px 'OpenSans';
  margin: 0 10px;
  color: ${COLORS.riverBed};
  @media (max-width: ${SIZES.bigTablet}px) {
    margin: 0;
  }
`;

export const TextCompany = styled.p`
  font: 12px/17px 'OpenSans';
  margin: 0;
  padding-bottom: 2px;
  color: ${COLORS.riverBed};
`;

export const CompanyName = styled.p`
  font-size: 12px;
  margin: 0;
  font-family: 'UnileverBold';
  padding-bottom: 2px;
`;

export const DivLi = styled.span`
  padding: 0;
  margin: 0;
  text-align: center;
  align-self: center;
  cursor: pointer;
  border-radius: 10px;

  @media (max-width: ${SIZES.bigTablet}px) {
    padding: 15px 0;
    text-align: center;
  }
`;

export const CustomButton = styled(Button)`
  && {
    height: 40px;
    width: 142px;
    color: ${COLORS.white};
    padding: 9px 20px;
    margin: 0 3px 3px 0;
    background: ${({ isDark }: { isDark?: boolean }) =>
      isDark ? `${COLORS.blueResolution}` : `${COLORS.bluePersian}`};
    display: ${({ isDisplayed }: { isDisplayed?: boolean; isDark?: boolean }) =>
      isDisplayed ? 'block' : 'none'};

    .MuiButton-label {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      padding: 0;
      text-transform: initial;
    }

    :hover {
      color: ${COLORS.white};
      background: ${COLORS.purple};
    }

    @media (max-width: ${SIZES.bigTablet}px) {
      display: block;
    }
  }
`;

export const RowDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
`;

export const TopBarRowDiv = styled(RowDiv)`
  @media (max-width: ${SIZES.bigDesktop}px) {
    max-width: 805px;
  }
`;

export const ArrowContainer = styled.div`
  width: 20px;
  height: 20px;
  background: ${COLORS.blueResolution};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

export const DropDownContent = styled.div`
  opacity: ${({ isVisible }: { isVisible: boolean; isActive?: boolean }) =>
    isVisible ? '1' : '0'};
  position: absolute;
  right: 0;
  bottom: ${({ isActive }: { isActive?: boolean }) => (isActive ? '-90px' : '-45px')};
  z-index: 1;
  transition: all 0.3s ease-in-out;
  border-radius: 30px;
`;

export const Divider = styled.div`
  border-left: 1px solid ${COLORS.grayBotticelli};
  height: 40px;
  margin: 0 10px;
  opacity: ${({ isVisible }: { isVisible?: boolean }) => (isVisible ? '1' : '0')};
`;

export const MenuOverlay = styled.div`
  @media (max-width: ${SIZES.bigTablet}px) {
    ${({ showOverlay }: { showOverlay: boolean }) =>
      showOverlay &&
      css`
        position: fixed;
        width: 100%;
        height: 100%;
        top: ${HEADER_HEIGHT}px;
        left: 0;
        background: ${COLORS.boxShadow};
      `}
  }
`;

export const MenuIconContainer = styled.div`
  display: none;

  @media (max-width: ${SIZES.bigTablet}px) {
    display: flex;
    z-index: 3;
  }
`;

export const MenuIcon = css`
  cursor: pointer;
  fill: ${COLORS.bluePersian};
`;

export const OpenMenuIcon = styled(OpenMenuSVG)`
  ${MenuIcon}
`;

export const CloseMenuIcon = styled(CloseMenuSVG)`
  ${MenuIcon}
`;

export const RouterLink = styled(Link)`
  margin: 0;
  padding: 0;
`;

export const InfoContainer = styled.div<IInfoContainerProps>`
  display: flex;
  color: ${COLORS.white};

  @media (max-width: ${SIZES.bigTablet}px) {
    align-items: center;
    position: fixed;
    top: ${({ showBenefitsBanner }) =>
      showBenefitsBanner ? SIZES.benefitsBanner + HEADER_HEIGHT : HEADER_HEIGHT}px;
    right: ${({ showSideMenu }) => (showSideMenu ? '0' : '-100%')};
    flex-direction: column;
    width: 100%;
    background-color: ${COLORS.white};
    border-top: 2px solid ${COLORS.grayMercury};
    transition: right 0.3s ease-in-out;
    padding: 15px 0;
  }
  @media (max-width: ${SIZES.mediumTablet}px) {
    ${({ showBenefitsBanner }) =>
      `
      top: ${showBenefitsBanner ? SIZES.benefitsBanner * 2 + HEADER_HEIGHT : HEADER_HEIGHT}px;
    `}
  }
`;

export const MobileDivider = styled.div`
  display: none;

  @media (max-width: ${SIZES.bigTablet}px) {
    display: block;
    align-self: center;
    border-top: 1px solid ${COLORS.grayMercury};
    width: 92%;
    margin: 6px 0 16px 0;
    opacity: ${({ isLast }: { isLast?: boolean }) => (isLast ? '1' : '0.2')};
  }
`;

export const Flag = styled.img`
  width: 32px;
  margin-left: auto;
  margin-right: 20px;
  @media (max-width: ${SIZES.bigTablet}px) {
    display: none;
  }
`;

export const BenefitsBanner = styled.div`
  width: 100%;
  background: #7baefc;
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: center;
  @media (max-width: ${SIZES.mediumTablet}px) {
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }
`;

export const PendingBenefitsText = styled(Text)`
  margin-top: 5px;
  color: ${COLORS.blackMineShaft};
`;

export const BenefitsButton = styled.div`
  margin: 4px;
  padding: 0 5px;
  border-radius: 4px;
  background-color: ${COLORS.bluePersian};
  color: white;
  cursor: pointer;
`;

export const TextBold = styled(Text)`
  font-weight: bold;
  color: ${COLORS.white};
`;
