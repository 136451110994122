import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'BENEFITS_FORM', {
  ADD_COLLABORATOR: 'Agregar Colaborador',
  SEND: 'Enviar',
  FIRST_NAME: 'Nombre',
  LAST_NAME: 'Apellido',
  ID: 'DNI',
  EMAIL: 'E-mail',
  COLLABORATOR: 'Colaborador',
  CLEAR_VALUES: 'Borrar',
});
