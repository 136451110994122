import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'TRAININGS', {
  NEXT_TRAININGS: 'Próximas Capacitaciones',
  EMPTY_ARRAY: 'No hay Capacitaciones Disponibles',
  TITLE_NO_TRAININGS: 'Todavía no hay capacitaciones disponibles en esta cápsula',
  SUBTITLE_NO_TRAININGS: 'Te informaremos en cuanto asignemos la primer capacitación.',
  START_TRAINING: 'Iniciar capacitación',
  DETAIL: 'Ver detalles',
  AVAILABLE_TRAININGS: 'Capacitaciones disponibles',
  PAST_VERSIONS: 'Ediciones anteriores',
  HOME: 'Inicio',
});
