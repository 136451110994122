import React, { FunctionComponent } from 'react';

import { ITraining } from 'types/training.types';
import i18next from 'i18next';
import { ITrainingProgressProps } from './types';
import {
  Container,
  Capsule,
  Title,
  CapsuleTitle,
  CapsuleTitleContainer,
  Icon,
  TitleContainer,
  CapsuleDescription,
  Trainings,
  Completed,
  BottomContainer,
} from './styles';
import './i18n';
import CapsuleIcon from '../CapsuleIcon';

const TrainingProgress: FunctionComponent<ITrainingProgressProps> = (
  props: ITrainingProgressProps,
) => {
  const { capsule, userId } = props;
  const capsuleTrainings = capsule.trainings[new Date().getFullYear()];

  const returnProgressBar = (training: ITraining) => {
    let completed = false;
    if (training.completedTrainings.length) {
      training.completedTrainings.forEach((completedTraining) => {
        if (completedTraining.userId === userId) completed = true;
      });
    }

    return (
      <Completed completed={completed}>
        {i18next.t(completed ? `TRAININGS_PROGRESS:COMPLETED` : `TRAININGS_PROGRESS:NOT_COMPLETED`)}
      </Completed>
    );
  };

  return (
    <Container>
      <TitleContainer>
        <Title>{capsule.title}</Title>
        <Icon>
          <CapsuleIcon name={capsule.title} />
        </Icon>
      </TitleContainer>
      <Trainings>
        {capsuleTrainings.map((training) => {
          return (
            <Capsule>
              <CapsuleTitleContainer>
                <CapsuleTitle>{training.title}</CapsuleTitle>
                <CapsuleDescription>{training.description}</CapsuleDescription>
              </CapsuleTitleContainer>
              <BottomContainer>
                <CapsuleDescription>{training.date.toString().substr(0, 10)}</CapsuleDescription>
                {returnProgressBar(training)}
              </BottomContainer>
            </Capsule>
          );
        })}
      </Trainings>
    </Container>
  );
};

export default TrainingProgress;
