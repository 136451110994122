import React, { FunctionComponent } from 'react';
import { Field, InjectedFormProps, reduxForm, reset } from 'redux-form';
import i18next from 'i18next';

import Input from 'components/inputs/Input';

import { requiredValidation, emailValidation } from 'helpers/validations';
import { USER_EMAIL, USER_FIRST_NAME, USER_LAST_NAME, USER_PHONE_NUMBER } from 'helpers/inputSizes';
import Select from 'components/inputs/Select';
import { ICountry } from 'types/country.types';
import { IContactFormProps, IContactFormData } from './types';
import {
  ContactContainer,
  CustomForm,
  ContactContent,
  ContactTitle,
  InfoSection,
  InputsSection,
  Inputs,
  MessageDiv,
  SubmitButton,
  Background,
  SelectContainer,
} from './styles';
import './i18n';

const afterSubmit = (result: any, dispatch: any) => dispatch(reset('contactForm'));

const ContactForm: FunctionComponent<
  IContactFormProps & InjectedFormProps<IContactFormData, IContactFormProps>
> = (props) => {
  const { handleSubmit, onSubmit, countries } = props;
  const countriesNames = countries.map((country: ICountry) => country.name);

  return (
    <Background>
      <ContactContainer data-testid="contact-component">
        <ContactTitle isMobile>{i18next.t('CONTACT_FORM:TITLE')}</ContactTitle>
        <ContactContent>
          <ContactTitle>{i18next.t('CONTACT_FORM:TITLE')}</ContactTitle>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <InfoSection data-testid="contact-form">
              <InputsSection>
                <Inputs>
                  <Field
                    component={Input}
                    variant="filled"
                    name="firstName"
                    label={i18next.t('CONTACT_FORM:FIRST_NAME')}
                    validate={[requiredValidation]}
                    maxLength={USER_FIRST_NAME}
                    size="small"
                    isDarkLabel
                    darkMode
                  />
                </Inputs>
                <Inputs>
                  <Field
                    component={Input}
                    variant="filled"
                    name="lastName"
                    label={i18next.t('CONTACT_FORM:LAST_NAME')}
                    validate={[requiredValidation]}
                    size="small"
                    maxLength={USER_LAST_NAME}
                    isDarkLabel
                    darkMode
                  />
                </Inputs>
              </InputsSection>
              {countries.length >= 1 && (
                <SelectContainer>
                  <Field
                    component={Select}
                    name="country"
                    validate={[requiredValidation]}
                    options={countriesNames}
                    label={i18next.t('CONTACT_FORM:SELECT_COUNTRY')}
                    variant="standard"
                    darkMode
                  />
                </SelectContainer>
              )}
              <InputsSection>
                <Inputs>
                  <Field
                    component={Input}
                    variant="filled"
                    name="phoneNumber"
                    label={i18next.t('CONTACT_FORM:PHONE_NUMBER')}
                    validate={[requiredValidation]}
                    size="small"
                    maxLength={USER_PHONE_NUMBER}
                    isDarkLabel
                    type="number"
                    darkMode
                  />
                </Inputs>
                <Inputs>
                  <Field
                    component={Input}
                    variant="filled"
                    name="email"
                    label={i18next.t('CONTACT_FORM:EMAIL')}
                    validate={[requiredValidation, emailValidation]}
                    size="small"
                    maxLength={USER_EMAIL}
                    isDarkLabel
                    darkMode
                  />
                </Inputs>
              </InputsSection>
              <MessageDiv>
                <Inputs isFullWidth>
                  <Field
                    component={Input}
                    variant="filled"
                    name="message"
                    label={i18next.t('CONTACT_FORM:MESSAGE')}
                    validate={requiredValidation}
                    size="small"
                    type="textarea"
                    isDarkLabel
                    darkMode
                  />
                </Inputs>
              </MessageDiv>
              <SubmitButton type="submit">Enviar</SubmitButton>
            </InfoSection>
          </CustomForm>
        </ContactContent>
      </ContactContainer>
    </Background>
  );
};

export default reduxForm<IContactFormData, IContactFormProps>({
  form: 'contactForm',
  onSubmitSuccess: afterSubmit,
})(ContactForm);
