import styled from 'styled-components';

import { SIZES } from 'helpers/enums/sizes';
import { COLORS } from 'helpers/enums/colors';
import { HEADER_HEIGHT } from 'helpers/dimensions';

import { ReactComponent as ArrowSVG } from 'assets/imgs/arrow-back.svg';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 1024px;
  margin: 0px auto;
  padding-top: ${HEADER_HEIGHT}px;
  padding-bottom: ${HEADER_HEIGHT}px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    width: 90%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (max-width: ${SIZES.smallDesktop}px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: ${({ isAligned }: { isAligned?: boolean }) => isAligned && 'center'};

  &:last-child {
    display: block;
    padding: 0 0 0 30px;
  }

  @media (max-width: ${SIZES.smallDesktop}px) {
    padding-right: 0px;

    &:last-child {
      display: block;
      padding: 30px 0 0 0;
    }
  }
`;

export const Title = styled.p`
  font-family: UnileverMedium;
  font-size: 40px;
  color: ${COLORS.bluePersian};
  text-align: center;
  margin: 0 0 0 10px 0;
  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 36px;
  }
`;

export const Text = styled.span`
  color: ${COLORS.riverBed};
  font-family: OpenSans;
  margin: 0;
  font-size: ${({ isLast }: { isLast?: boolean }) => (isLast ? '22px' : '18px')};

  @media (max-width: ${SIZES.smallDesktop}px) {
    font-size: 16px;
  }
`;

export const BoldTextDescription = styled.p`
  color: ${COLORS.riverBed};
  font-family: OpenSansBold;
  font-size: 20px;
  margin: 0;

  @media (max-width: ${SIZES.smallDesktop}px) {
    font-size: 16px;
  }
`;

export const BoldText = styled.span`
  font-family: OpenSansBold;
  font-size: ${({ isLast }: { isLast?: boolean }) => (isLast ? '22px' : '18px')};
  color: ${({ isLast }: { isLast?: boolean }) => (isLast ? COLORS.bluePersian : COLORS.riverBed)};

  @media (max-width: ${SIZES.smallDesktop}px) {
    font-size: 16px;
  }
`;

export const TextContent = styled.div`
  margin-bottom: 15px;
`;

export const Highlight = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: start;

  @media (max-width: ${SIZES.smallDesktop}px) {
    padding-left: 0px;
  }
`;

export const HighlightText = styled.p`
  font-family: UnileverBold;
  font-size: 20px;
  color: ${COLORS.purple};
  padding: 20px 20px 28px;
  background: ${COLORS.grayAlabaster};
  border-radius: 10px;
  margin: 0 0 78px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    font-size: 18px;
    margin: 30px 0 50px;
  }
`;

export const Subtitle = styled.p`
  font: 24px/34px 'OpenSansBold';
  color: ${COLORS.riverBed};

  @media (max-width: ${SIZES.bigTablet}px) {
    font: 20px/1 'OpenSansBold';
  }
`;

export const Image = styled.img`
  width: 90%;
  align-self: center;
`;

export const LastText = styled.span`
  color: ${COLORS.purple};
  font: 24px/30px 'UnileverMedium';

  @media (max-width: ${SIZES.smallDesktop}px) {
    font-size: 16px;
  }
`;

export const Compromise = styled.div`
  padding: 0 40px;
  text-align: center;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ListIcon = styled(ArrowSVG)`
  width: 5px;
  height: 10px;
  margin-right: 8px;
  fill: ${COLORS.bluePersian};
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  align-items: center;
`;

export const LastTextContent = styled.div`
  text-align: center;
  margin-top: 40px;
`;
