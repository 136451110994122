import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

import { ILogoProps } from './types';
import { ReactComponent as UniPyMeLogoColor } from './assets/logo-unipyme-colo.svg';
import { ReactComponent as UniPyMeLogoWhite } from './assets/logo-unipyme-w.svg';
import { ReactComponent as UnileverLogoWhite } from './assets/logo-unilever-w.svg';

export const LogoContainer = styled.div`
  writing-mode: vertical-lr;

  display: flex;
  flex-direction: column;

  a {
    color: ${({ isDarkModeOn }: ILogoProps) => (isDarkModeOn ? COLORS.white : COLORS.black)};
    font-size: 2em;
  }

  a:hover {
    color: ${COLORS.gray};
  }
`;

export const LogoImgColor = styled(UniPyMeLogoColor)`
  width: 100px;
  height: 41px;
`;

export const LogoImgWhite = styled(UniPyMeLogoWhite)`
  width: 100px;
  height: 41px;
`;

export const UnileverLogo = styled(UnileverLogoWhite)`
  width: 37px;
  height: 41px;
  .st0 {
    fill: ${COLORS.white};
  }
`;

export const Line = styled.div`
  top: 11px;
  left: 268px;
  width: 1px;
  height: 45px;
  background-color: #ffffff;
  opacity: 0.5;
  margin: 0 25px;
`;
