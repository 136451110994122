import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'SELECT_COUNTRY_SIGN_UP', {
  NEXT_BUTTON: 'Siguiente',
  BACK_BUTTON: 'Anterior',
  SELECT_COUNTRY: 'Seleccione su país',
  SELECT_STATE: 'Seleccione ubicación',
  SUBTITLE: 'Complete los datos que aparecerán a continuación.',
});
