import React, { FunctionComponent } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import i18next from 'i18next';
import {
  requiredValidation,
  identifierLengthValidation,
  identifierTypeValidation,
} from 'helpers/validations';
import Input from 'components/inputs/Input';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import { ISignUpUserFormData, ISignUpUserFormProps } from './types';
import { Form, ButtonContainer, FormButton, Theme, RowDivInput, Subtitle } from './styles';
import './i18n';

const SignUpUserForm: FunctionComponent<
  ISignUpUserFormProps & InjectedFormProps<ISignUpUserFormData, ISignUpUserFormProps>
> = (props) => {
  const { handleSubmit, onSubmit, submitting, invalid, country, onClickBack } = props;

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} data-testid="signup-form">
        <MuiThemeProvider theme={Theme}>
          <Subtitle>
            {i18next.t('SIGNUP_USER_FORM:SUBTITLE', {
              identifier: country.company.identifierLabel,
            })}
          </Subtitle>
          <Tooltip
            title={
              country.company.identifierTooltipText ? country.company.identifierTooltipText : ''
            }
            placement="top-start"
            arrow
          >
            <RowDivInput>
              <Field
                component={Input}
                label={country.company.identifierLabel}
                name="cuit"
                validate={[
                  requiredValidation,
                  identifierLengthValidation,
                  identifierTypeValidation,
                ]}
                maxLength={country.company.identifierLength}
                type="string"
              />
            </RowDivInput>
          </Tooltip>
        </MuiThemeProvider>

        <ButtonContainer>
          <FormButton type="button" onClick={onClickBack}>
            {i18next.t('SIGNUP_USER_FORM:BACK_BUTTON')}
          </FormButton>
          <FormButton disabled={submitting || invalid} type="submit">
            {i18next.t('SIGNUP_USER_FORM:NEXT_BUTTON')}
          </FormButton>
        </ButtonContainer>
      </Form>
    </>
  );
};

export default reduxForm<ISignUpUserFormData, ISignUpUserFormProps>({
  form: 'signup',
})(SignUpUserForm);
