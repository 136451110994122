import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ISignUpFormData, IValidateIdentifier } from 'views/SignUpView/types';
import {
  onRegisterUser,
  onRegisterCompany,
  onValidateIdentifier,
  onRegisterFormBack,
} from 'redux/actions/user.actions';
import { CompanySectors, companySectors } from 'helpers/enums/companySectors';
import countrySelector from 'redux/selectors/country.selector';
import LayoutContainer from 'containers/LayoutContainer';
import SignUpView from 'views/SignUpView';
import * as userActions from 'redux/actions/user.actions';
import useTypedSelector from 'hooks/useTypedSelector';
import {
  CompanyNumberOfCollaborators,
  companyNumberOfCollaborators,
} from 'helpers/enums/companyNumberOfCollaborators';
import {
  CompanyHowDidYouHearAboutUs,
  companyHowDidYouHearAboutUs,
} from 'helpers/enums/companyHowDidYouHearAboutUs';
import Modal from 'components/commons/Modal';
import Loading from 'components/commons/Loading';

const SignUpContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const countries = useSelector(countrySelector());
  const isIdentifierValid = useTypedSelector((state) => state.company.isIdentifierValid) || false;
  const onInitialize = useCallback(() => {
    dispatch(userActions.onInitializeSignUpView());
  }, [dispatch]);
  const loading = useTypedSelector((state) => state.company.loading);

  useEffect(() => {
    onInitialize();
  }, [onInitialize]);

  const onSubmit = (formData: ISignUpFormData) => {
    if (formData.creatingCompany) {
      dispatch(onRegisterCompany(formData));
    } else {
      dispatch(onRegisterUser(formData));
    }
  };

  const onFormBack = () => {
    dispatch(onRegisterFormBack());
  };

  const validateIdentifier = (formData: IValidateIdentifier) => {
    dispatch(onValidateIdentifier(formData));
  };

  const companySectorOptions = companySectors.filter(
    (sector) => sector.value !== CompanySectors.NOT_SPECIFIED,
  );

  const numberOfCollaboratorsOptions = companyNumberOfCollaborators.filter(
    (numberOfCollaborators) =>
      numberOfCollaborators.value !== CompanyNumberOfCollaborators.NOT_SPECIFIED,
  );

  const howDidYouHearAboutUsOptions = companyHowDidYouHearAboutUs.filter(
    (hearAboutUs) => hearAboutUs.value !== CompanyHowDidYouHearAboutUs.NOT_SPECIFIED,
  );

  return (
    <LayoutContainer isLanding>
      {loading && (
        <Modal disabled isOpen>
          <Loading />
        </Modal>
      )}
      <SignUpView
        companySectorOptions={companySectorOptions}
        numberOfCollaboratorsOptions={numberOfCollaboratorsOptions}
        howDidYouHearAboutUsOptions={howDidYouHearAboutUsOptions}
        onValidateIdentifier={validateIdentifier}
        onSubmit={onSubmit}
        countries={countries}
        isIdentifierValid={isIdentifierValid}
        onFormBack={onFormBack}
      />
    </LayoutContainer>
  );
};

export default SignUpContainer;
