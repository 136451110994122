import React, { FunctionComponent } from 'react';
import { Container } from './styles';
import CallbackText from './components/CallbackText';

const SurveyCallBackView: FunctionComponent = () => {
  return (
    <Container data-testid="callback-view">
      <CallbackText />
    </Container>
  );
};

export default SurveyCallBackView;
