import React, { FunctionComponent } from 'react';

import DefaultImage from 'assets/imgs/unipyme.png';

import { ICardProps } from './types';
import { Container, Link, Text } from './styles';

const Card: FunctionComponent<ICardProps> = (props: ICardProps) => {
  const { item, isGrid } = props;

  const img = item.imgUrl || item.metaImg || '';
  const title = item.title || item.metaTitle || '';

  const renderImage = (): string => {
    if (img && !img.includes('http:')) {
      return img;
    }
    return DefaultImage;
  };

  return (
    <Link data-testid={`card-link-${item.link}`} href={item.link} target="_blank">
      <Container data-testid={`card-container-${item.link}`} src={renderImage()} isGrid={isGrid}>
        <Text data-testid={`card-title-${item.link}`} isTop>
          {item.newsMedia || 'Link'}
        </Text>
        <Text data-testid={`card-text-${item.link}`} isTop={false}>
          {title}
        </Text>
      </Container>
    </Link>
  );
};

export default Card;
