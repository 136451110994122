import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { HeaderButton } from 'components/main/Header/styles';

import { Link } from 'react-router-dom';
import { IHomeViewProps } from './types';
import { HomeContainer, RowDiv, Text, FixedDiv, GradientBackground } from './styles';
import ContactForm from './components/ContactForm';
import WhatIs from './components/WhatIs';
import Programs from './components/Programs';
import Numbers from './components/Numbers';
import WhoAreWe from './components/WhoAreWe';
import Hero from './components/Hero';
import Benefits from './components/Benefits';
import Objective from './components/Objective';
import DidYouKnow from './components/DidYouKnow';
import './i18n';

const HomeView: FunctionComponent<IHomeViewProps> = (props: IHomeViewProps) => {
  const { onSubmitContact, countries, company } = props;

  return (
    <HomeContainer data-testid="home-view">
      <Hero />
      <DidYouKnow />
      <WhatIs />
      <Programs />
      <Benefits />
      <GradientBackground>
        <Objective />
        <Numbers />
      </GradientBackground>
      <WhoAreWe />
      <ContactForm onSubmit={onSubmitContact} countries={countries} />
      {!company && (
        <FixedDiv>
          <RowDiv>
            <Text>{i18next.t('HOME:JOIN')}</Text>
            <Link to="/login">
              <HeaderButton hasBackground={false}>Ingresar</HeaderButton>
            </Link>
          </RowDiv>
        </FixedDiv>
      )}
    </HomeContainer>
  );
};

export default HomeView;
