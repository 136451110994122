import Button from 'components/commons/Button';
import { COLORS } from 'helpers/enums/colors';
import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
`;

export const InputContainer = styled.div`
  margin-top: 10px;
`;

export const ButtonContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

export const FormButton = styled(Button)`
  color: ${COLORS.blueResolution};
  padding: 10px 26px;
  background: ${COLORS.blueMalibu};

  :hover {
    background: ${COLORS.purple};
  }
`;
