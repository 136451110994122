import { get, post } from 'services/api';

export async function getAllTrainings(): Promise<any> {
  const response = await get('trainings');
  return response;
}

export async function getAllTrainingsByCapsuleVersion(
  capsuleId: string,
  version: number,
): Promise<any> {
  const response = await get(`trainings?capsuleId=${capsuleId}&version=${version}`);
  return response;
}

export async function getTrainingById(trainingId: string): Promise<any> {
  const response = await get(`trainings/${trainingId}`);
  return response;
}

export async function getTrainingByIdWithCompanyData(trainingId: string): Promise<any> {
  const response = await get(`trainings/${trainingId}/company`);
  return response;
}

export async function submitCode(formData: any): Promise<any> {
  const { trainingId, code } = formData;
  const response = await post(`trainings/${trainingId}/submit-code`, { code });
  return response;
}

export async function markCompleted(trainingId: string): Promise<any> {
  const response = await post(`trainings/${trainingId}/mark-completed`);
  return response;
}
