import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'FIELDS', {
  DATE: 'Fecha',
  EMAIL: 'E-mail',
  IDENTIFIER: 'Identificador',
  PASSWORD: 'Contraseña',
  PHONE_NUMBER: 'Número de teléfono',
});

i18next.addResources(locales.ES, 'VALIDATIONS', {
  FIELD_NOT_MATCH: '{{ field }} no coincide',
  INVALID_FIELD: '{{ field }} inválido',
  INVALID_FIELD_LENGTH: '{{ field }} tiene que tener al menos {{ length }} caracteres',
  INVALID_PASSWORD_SPECIAL_CHARACTERS:
    'La contraseña requiere al menos 1 carácter en mayúscula, uno en minúscula y un número',
  REQUIRED_FIELD: 'Requerido',
  INVALID_TYPE: 'Ingrese únicamente números',
  NOT_SPECIFIED: 'No especifica',
});
