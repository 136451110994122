import { Step, StepLabel, Stepper } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'helpers/enums/colors';

export const WizardWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
`;

export const StyledStepper = styled(Stepper)`
  .MuiSvgIcon-root {
    color: gray;
  }
  .Mui-active {
    color: ${COLORS.purple} !important;
  }
  .Mui-completed {
    color: ${COLORS.purple} !important;
  }
`;

export const StepWrapper = styled(Step)``;

export const StepItem = styled(StepLabel)``;
