import * as userConstants from 'redux/constants/user.constants';
import * as trainingConstants from 'redux/constants/training.constants';
import * as benefitConstants from 'redux/constants/benefit.constants';
import { sendErrorNotification, sendSuccessNotification } from 'helpers/notifications';

const notificationMiddleware = () => (next: any) => (action: any) => {
  const { data, error, type } = action;
  switch (type) {
    case userConstants.USER_ON_CONFIRM_ACCOUNT_FAILED:
    case userConstants.USER_ON_ACCEPT_ACCOUNT_FAILED:
    case userConstants.USER_ON_FORGOT_PASSWORD_FAILED:
    case userConstants.USER_ON_LOGIN_FAILED:
    case userConstants.USER_ON_LOGOUT_FAILED:
    case userConstants.USER_ON_REGISTER_FAILED:
    case userConstants.COMPANY_ON_REGISTER_FAILED:
    case userConstants.USER_ON_VALIDATE_IDENTIFIER_FAILED:
    case userConstants.USER_ON_RESET_PASSWORD_FAILED:
    case userConstants.USER_ON_UPDATE_FAILED:
    case userConstants.USER_ON_CONTACT_FAILED:
    case trainingConstants.TRAINING_SUBMIT_CODE_FAILED:
    case trainingConstants.TRAINING_MARK_AS_COMPLETE_FAILED:
    case userConstants.USER_ON_CONFIRM_ASSISTANCE_FAILED:
    case benefitConstants.BENEFITS_ON_REQUEST_OWNER_ACTIVATION_FAILED:
    case benefitConstants.BENEFITS_ON_ACTIVATE_FAILED:
    case trainingConstants.TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_FAILED:
      sendErrorNotification(error.message);
      break;
    case userConstants.USER_ON_CONFIRM_ACCOUNT_SUCCEEDED:
    case userConstants.USER_ON_ACCEPT_ACCOUNT_SUCCEEDED:
    case userConstants.USER_ON_FORGOT_PASSWORD_SUCCEEDED:
    case userConstants.USER_ON_REGISTER_SUCCEEDED:
    case userConstants.COMPANY_ON_REGISTER_SUCCEEDED:
    case userConstants.USER_ON_RESET_PASSWORD_SUCCEEDED:
    case userConstants.USER_ON_UPDATE_SUCCEEDED:
    case userConstants.USER_ON_CONTACT_SUCCEEDED:
    case userConstants.USER_ON_VALIDATE_IDENTIFIER_SUCCEEDED:
    case userConstants.USER_ON_COMPLETE_FORM_SUCCEEDED:
    case trainingConstants.TRAINING_SUBMIT_CODE_SUCCEEDED:
    case trainingConstants.TRAINING_MARK_AS_COMPLETE_SUCCEEDED:
    case userConstants.USER_ON_CONFIRM_ASSISTANCE_SUCCEEDED:
    case benefitConstants.BENEFITS_ON_REQUEST_OWNER_ACTIVATION_SUCCEEDED:
    case benefitConstants.BENEFITS_ON_ACTIVATE_SUCCEEDED:
      sendSuccessNotification(data.message);
      break;
    default:
      break;
  }
  return next(action);
};

export default notificationMiddleware;
