import React, { FunctionComponent, useEffect } from 'react';

import LayoutContainer from 'containers/LayoutContainer';
import BenefitsView from 'views/BenefitsView';
import useTypedSelector from 'hooks/useTypedSelector';
import { ILevel } from 'types/company.types';
import { useDispatch } from 'react-redux';
import { onCompleteForms, onGetForms } from 'redux/actions/user.actions';
import { Sections } from 'helpers/enums/sections';
import { getFormQuestionsAndAnswers } from 'helpers/forms';
import { IForm } from 'types/form.types';
import { onActivateBenefit, onRequestOwnerActivationBenefit } from 'redux/actions/benefit.actions';
import Modal from 'components/commons/Modal';
import Loading from 'components/commons/Loading';

const BenefitsContainer: FunctionComponent = () => {
  const company = useTypedSelector((state) => state.company.data);
  const companyLevel = useTypedSelector((state) => state.company.level);
  const companyUser = useTypedSelector((state) => state.company.user);
  const levels = useTypedSelector((state) => state.levels.items);
  const benefits = useTypedSelector((state) => state.benefits.items);
  const loading = useTypedSelector((state) => state.benefits.loading);
  const forms = useTypedSelector((state) => state.company.user?.forms);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!forms) dispatch(onGetForms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const orderedLevels = levels.sort((a: ILevel, b: ILevel) => a.minPoints - b.minPoints);

  const completeForm = (form: IForm, values: any) => {
    const completedFormData = {
      formId: form.id,
      formAnswers: getFormQuestionsAndAnswers(form, values),
    };
    dispatch(onCompleteForms(completedFormData));
  };

  const activateBenefit = (benefitId: string, collaborators: any) =>
    dispatch(onActivateBenefit(benefitId, collaborators));

  const requestOwnerActivationBenefit = (benefitId: string) =>
    dispatch(onRequestOwnerActivationBenefit(benefitId));

  return (
    <LayoutContainer forms={forms} section={Sections.BENEFITS} completeForm={completeForm}>
      {loading && (
        <Modal disabled isOpen>
          <Loading />
        </Modal>
      )}
      {!loading && (
        <BenefitsView
          company={company || null}
          companyLevel={companyLevel}
          user={companyUser}
          levels={orderedLevels}
          benefits={benefits}
          activateBenefit={activateBenefit}
          requestOwnerActivationBenefit={requestOwnerActivationBenefit}
        />
      )}
    </LayoutContainer>
  );
};

export default BenefitsContainer;
