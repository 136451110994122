import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'PROFILE_VIEW', {
  MODIFY_PASSWORD: 'Si quieres modificar tu contraseña ponte en',
  MODIFY_PASSWORD_LINK: 'contacto con nosotros',
  TITLE: 'Perfil',
  SETTINGS: 'Configuración',
  LOG_OUT: 'Salir',
  REMOVE_ACCOUNT: 'Remover Cuenta',
  DELETE_ACCOUNT: 'Eliminar Cuenta',
  MODAL_SUBTITLE:
    '¿Está seguro de que desea eliminar su cuenta de UniPyME? Si lo hace perderá todos sus datos y deberá crearse una nueva cuenta el dia de mañana si desea volver a utilizar la plataforma.',
});
