import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import LayoutContainer from 'containers/LayoutContainer';
import useTypedSelector from 'hooks/useTypedSelector';
import CertificatesView from 'views/CertificatesView';
import {
  onCompleteForms,
  initializeCertificatesView,
  onGetForms,
} from 'redux/actions/user.actions';
import { IForm } from 'types/form.types';
import { getFormQuestionsAndAnswers } from 'helpers/forms';
import { Sections } from 'helpers/enums/sections';

const CertificatesContainer: FunctionComponent = () => {
  const certificates = useTypedSelector((state) => state.company.certificates);
  const forms = useTypedSelector((state) => state.company.user?.forms);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!forms) dispatch(onGetForms());
    dispatch(initializeCertificatesView());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const completeForm = (form: IForm, values: any) => {
    const completedFormData = {
      formId: form.id,
      formAnswers: getFormQuestionsAndAnswers(form, values),
    };
    dispatch(onCompleteForms(completedFormData));
  };

  return (
    <LayoutContainer forms={forms} completeForm={completeForm} section={Sections.CERTIFICATES}>
      <CertificatesView certificates={certificates} />
    </LayoutContainer>
  );
};

export default CertificatesContainer;
