import React, { FunctionComponent, useState } from 'react';
import i18next from 'i18next';

import LottiePlayer from 'components/commons/LottiePlayer';

import {
  Background,
  BackgroundOverlay,
  CustomReactPlayer,
  HeroWrapper,
  ModalVideo,
  PlayerContainer,
  PlayPauseDiv,
  Subtitle,
  Title,
  VideoButtonText,
  VideoButtonWrapper,
  VideoPlayButtonWrapper,
  Wrapper,
} from './styles';
import './i18n';

import VideoPlayButton from '../../animations/hero-play.json';

const Hero: FunctionComponent = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [play, setPlay] = useState<boolean>(false);

  const toggleVideo = () => {
    setOpen(() => !open);
    setPlay(false);
  };

  const playPause = () => {
    setPlay(() => !play);
  };

  return (
    <Wrapper>
      <BackgroundOverlay />
      <Background>
        <video width="100%" height="100%" autoPlay muted loop>
          <source src="hero/Unipyme-hero-landing_v02.webm" type="video/webm" />
          <source src="hero/Unipyme-hero-landing_v01.mp4" type="video/mp4" />
        </video>
      </Background>
      <HeroWrapper>
        <Title>{i18next.t('VIDEO:TITLE')}</Title>
        <Subtitle>{i18next.t('VIDEO:SUBTITLE')}</Subtitle>
        <VideoButtonWrapper>
          <VideoButtonText>{i18next.t('VIDEO:SEE_VIDEO')}</VideoButtonText>
          <VideoPlayButtonWrapper onClick={toggleVideo}>
            <LottiePlayer width={150} animationJsonPath={VideoPlayButton} autoplay loop />
          </VideoPlayButtonWrapper>
        </VideoButtonWrapper>
      </HeroWrapper>
      <ModalVideo
        isOpen={open}
        onClose={toggleVideo}
        hasInvisibleBackground
        data-testid="hero-modal-view"
      >
        <PlayerContainer>
          <PlayPauseDiv onClick={playPause} data-testid="play-pause" />
          <CustomReactPlayer
            url="https://unilever-32.wistia.com/medias/k41z7kz6by"
            playing={play}
            width="100%"
            height="90%"
          />
        </PlayerContainer>
      </ModalVideo>
    </Wrapper>
  );
};

export default Hero;
