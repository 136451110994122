import styled from 'styled-components';

import Button from 'components/commons/Button';
import { COLORS } from 'helpers/enums/colors';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const Form = styled.form`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

export const FormButton = styled(Button)`
  color: ${COLORS.white};
  padding: 10px 26px;
  background: ${COLORS.blueResolution};

  :hover:not([disabled]) {
    background: ${COLORS.bluePersian};
  }
`;

export const Title = styled.div`
  font-family: 'OpenSansBold';
  font-size: 20px;
  color: ${COLORS.blueResolution};
  width: fit-content;
`;

export const Subtitle = styled.div`
  font-family: 'OpenSansSemiBold';
  font-size: 14px;
  color: ${COLORS.riverBed};
  margin: 15px;
`;
