import * as constants from 'redux/constants/user.constants';
import * as companyConstants from 'redux/constants/company.constants';
import { clearItems } from 'helpers/storage';

const storageMiddleware = () => (next: any) => async (action: any) => {
  const { type } = action;
  switch (type) {
    case constants.USER_ON_CONFIRM_ACCOUNT_SUCCEEDED:
      clearItems();
      break;
    case companyConstants.COMPANY_ON_SUBMIT_REGISTER_FORM_SUCCEEDED:
      clearItems();
      break;
    default:
      break;
  }
  return next(action);
};

export default storageMiddleware;
