import { FunctionComponent } from 'react';

import { IProgramsDefaultState } from 'types/programs.types';
import * as constants from 'redux/constants/program.constants';

const defaultState: IProgramsDefaultState = {
  items: [],
  loading: false,
};

const programReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { data, type } = action;
  switch (type) {
    case constants.PROGRAM_ON_GET_ALL_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.PROGRAM_ON_GET_ALL_SUCCEEDED:
      return {
        ...state,
        items: data.programs,
        loading: false,
      };
    case constants.PROGRAM_ON_GET_ALL_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default programReducer;
