import React, { FunctionComponent } from 'react';

import { CapsuleNames } from 'views/HomeView/components/Programs/types';

import { ICapsuleIconProps } from './types';
import {
  ImproveIcon,
  BusinessIcon,
  FinanceIcon,
  LogisticsIcon,
  CultureIcon,
  DigitalIcon,
  AgroIcon,
} from './styles';

const CapsuleIcon: FunctionComponent<ICapsuleIconProps> = (props: ICapsuleIconProps) => {
  const { isWhite = false, isBigger = false, name } = props;
  const renderIcon = (nameCapsule: string) => {
    // eslint-disable-next-line
    const regex = /\s/g;
    const titleFormatted = nameCapsule.toLocaleLowerCase().replace(regex, '');
    switch (titleFormatted) {
      case CapsuleNames.IMPROVE:
        return <ImproveIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.BUSINESS:
        return <BusinessIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.FINANCE:
        return <FinanceIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.LOGISTICS:
        return <LogisticsIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.CULTURE:
        return <CultureIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.DIGITAL:
        return <DigitalIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.IMPROVECOL:
        return <ImproveIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.BUSINESSCOL:
        return <BusinessIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.FINANCECOL:
        return <FinanceIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.LOGISTICSCOL:
        return <LogisticsIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.I18N:
        return <LogisticsIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.CULTURECOL:
        return <CultureIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.DIGITALCOL:
        return <DigitalIcon isBigger={isBigger} isWhite={isWhite} />;
      case CapsuleNames.AGRO:
        return <AgroIcon isBigger={isBigger} isWhite={isWhite} />;
      default:
        return <ImproveIcon isBigger={isBigger} isWhite={isWhite} />;
    }
  };

  return <div data-testid="capsule-icon-component">{renderIcon(name)}</div>;
};

export default CapsuleIcon;
