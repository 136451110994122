import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'DASHBOARD_VIEW', {
  PROGRAM: 'Programa ',
  POINTS_MISSING: 'Faltan ',
  POINTS_MISSING_2: ' puntos ',
  POINTS_MISSING_3: 'para subir de nivel.',
  BENEFITS: 'Beneficios',
  BENEFITS_TEXT: 'Tenes tu lista de beneficios activa',
  PREVIOUS_TRAINING: 'Última Capacitación',
  NEXT_TRAINING: 'Próxima Capacitación',
  MAX_LEVEL_1: '¡Felicidades! ',
  MAX_LEVEL_2: 'Alcanzaste el máximo nivel',
  NO_PREVIOUS_TRAINING_TITLE: 'No hay ninguna capacitación reciente',
  NO_PREVIOUS_TRAINING_TEXT: 'Próximamente te mostraremos la última capacitación que se habilitó.',
  NO_NEXT_TRAINING_TITLE: 'No hay ninguna capacitación futura',
  NO_NEXT_TRAINING_TEXT: 'Próximamente te mostraremos la próxima capacitación que se habilitará.',
  STATS_BANNER_TITLE: 'Gracias a tu participación tu empresa cuenta con:',
  TOTAL_POINTS: 'Puntaje total',
  RANKING: 'Ranking',
  LEVEL: 'Nivel',
  POINTS_ACC: 'puntos acumulados',
  COMPANIES: ' empresas!',
  OF: 'de ',
  COINS: 'Coins',
  ACTIVATE: 'Activá un beneficio!',
  HOME: 'Inicio',
  ENTER: 'Ingresar',
  PROGRAM_CAPSULES: 'Cápsulas de este programa',
  PROGRESS_TEXT_1: 'de ',
  PROGRESS_TEXT_2: 'capacitaciones completadas',
});
