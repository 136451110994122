export const BENEFITS_ON_GET_SUCCEEDED = 'BENEFITS_ON_GET_SUCCEEDED';
export const BENEFITS_ON_GET_FAILED = 'BENEFITS_ON_GET_FAILED';

export const BENEFITS_ON_ACTIVATE_REQUESTED = 'BENEFITS_ON_ACTIVATE_REQUESTED';
export const BENEFITS_ON_ACTIVATE_SUCCEEDED = 'BENEFITS_ON_ACTIVATE_SUCCEEDED';
export const BENEFITS_ON_ACTIVATE_FAILED = 'BENEFITS_ON_ACTIVATE_FAILED';

export const BENEFITS_ON_REQUEST_OWNER_ACTIVATION_REQUESTED =
  'BENEFITS_ON_REQUEST_OWNER_ACTIVATION_REQUESTED';
export const BENEFITS_ON_REQUEST_OWNER_ACTIVATION_SUCCEEDED =
  'BENEFITS_ON_REQUEST_OWNER_ACTIVATION_SUCCEEDED';
export const BENEFITS_ON_REQUEST_OWNER_ACTIVATION_FAILED =
  'BENEFITS_ON_REQUEST_OWNER_ACTIVATION_FAILED';
