import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${SIZES.smallDesktop}px;
  width: 100%;
  margin: 0 auto 50px;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 80%;
  }
`;

export const Title = styled.h1`
  font-family: UnileverBold;
  font-size: 32px;
  color: ${COLORS.bluePersian};
  margin: 38px 0 38px 0;
`;

export const LoginFormContainer = styled.div`
  margin-top: 30px;
`;

export const SignUpContainer = styled.div`
  width: 100%;
  font-family: OpenSans;
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
`;

export const SignUpLink = styled(Link)`
  color: ${COLORS.purple};
  margin-left: 5px;
`;
