import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import Button from 'components/commons/Button';
import Input from 'components/inputs/Input';

export const Form = styled.form`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
    gap: 15px;
  }
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
  }
`;

export const RowDivInput = styled.div`
  width: 48%;

  @media (max-width: ${SIZES.bigPhone}px) {
    width: 100%;
  }
`;

export const Text = styled.p`
  font-family: OpenSans;
  font-size: 12px;
  margin: 0;
  padding-left: 5px;
  color: ${COLORS.gray};
`;

export const RequiredField = styled.span`
  margin-left: 3px;
  color: ${COLORS.purple};
  font-size: 11px;
`;

export const TermsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${({ isPrivacy }: { isPrivacy?: boolean }) => (isPrivacy ? '10px' : '0')};
`;

export const TermsAndConditionsLink = styled(Link)`
  color: ${COLORS.purple};
  font-size: 12px;
  padding-left: 4px;
  align-self: center;
  display: block;
  align-self: auto;
  margin: 0;
`;

export const CustomLink = styled.a`
  color: ${COLORS.purple};
  font-size: 12px;
  padding-left: 4px;
  align-self: center;
  display: block;
  align-self: auto;
  margin: 0;
  display: inline;
`;

export const FormButton = styled(Button)`
  padding: 10px 64px;
  background: ${COLORS.purple};

  :hover:not([disabled]) {
    background: ${COLORS.purpleHeart};
  }
`;

export const CustomInput = styled(Input)`
  .MuiFormLabel-root {
    font-family: OpenSans;
  }
`;
