import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 15px;
  background: ${COLORS.lightblueZumthor};
  padding: 20px 20px;
  border-radius: 10px;
  width: 100%;
`;

export const Capsule = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0px 4px 8px #8ea7d333;
  border-radius: 8px;
  padding: 10px;
  background: ${COLORS.white};
`;

export const Title = styled.p`
  color: ${COLORS.riverBed};
  font: 18px/24px 'OpenSansBold';
  margin: 0;
`;

export const Text = styled.p`
  font: 14px/18px 'OpenSans';
  color: ${COLORS.riverBed};
`;

export const CapsuleTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0;
`;

export const CapsuleTitle = styled.p`
  font: 14px 'OpenSansSemiBold';
  color: ${COLORS.riverBed};
  margin: 0;
  align-self: flex-end;
`;

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  padding-right: 10px;
`;

export const CapsulesContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ isColumn }: { isColumn: boolean }) =>
    isColumn ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'};
  grid-gap: 24px;

  @media (max-width: ${SIZES.mediumTablet}px) {
    grid-template-columns: repeat(1, 1fr);
  }

  overflow: auto;
  height: 85%;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #acacac;
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
