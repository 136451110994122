import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import styled from 'styled-components';

export const ProgramsContainer = styled.div`
  margin: 1% 25px;
  max-width: 1296px;
  display: flex;
  flex-direction: row;
  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 32px;

  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0 20px;
  }
`;

export const CapsulesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  border-radius: 6px;
  padding-right: 18px;

  @media (max-width: ${SIZES.bigTablet}px) {
    padding-right: 0;
  }
`;

export const Title = styled.p`
  flex: 1;
  font: 20px/27px 'OpenSansBold';
  color: ${COLORS.blueResolution};
  margin: 0 0 10px;

  @media (max-width: ${SIZES.mediumTablet}px) {
    margin: 0;
  }
`;

export const CapsuleText = styled.p`
  font: 16px/24px 'Unilever';
  margin: 0 0 10px;
  cursor: pointer;
  color: ${COLORS.bluePersian};
  align-self: center;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin: 0;
  }
`;

export const TextContainer = styled.div`
  text-align: end;
  display: flex;
`;

export const ProgressContainer = styled.div`
  padding: 20px 0;
  width: 25%;
  margin-right: 40px;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 95%;
  }
`;

export const Capsules = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
