import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import history from 'helpers/history';

import { ViewContainer, Text, CustomButton, Title } from './styles';
import './i18n';

const ConfirmRegisterView: FunctionComponent = () => {
  return (
    <ViewContainer data-testid="confirm-register-view">
      <Title>{i18next.t('CONFIRM_REGISTER:TITLE')}</Title>
      <Text>{i18next.t('CONFIRM_REGISTER:DESCRIPTION')}</Text>
      <div>
        <CustomButton onClick={() => history.push('home')}>
          {i18next.t('CONFIRM_REGISTER:BUTTON_TEXT')}
        </CustomButton>
      </div>
    </ViewContainer>
  );
};

export default ConfirmRegisterView;
