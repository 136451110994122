export enum CompanySectors {
  OTHER = 0,
  NOT_SPECIFIED = 15,
  COMMODITIES = 1,
  INGREDIENTS = 2,
  CHEMICALS = 3,
  PACKAGING = 4,
  REPLACEMENT_PARTS_AND_COMPONENTS = 5,
  EQUIPMENT_AND_MACHINERY = 6,
  MARKETING = 7,
  INNOVATION_AND_PROFESSIONAL_SERVICES = 8,
  IT_AND_TELECOMMUNICATIONS = 9,
  LOGISTICS = 10,
  MEDIA = 11,
  RECRUITMENT_AND_HUMAN_RESOURCES = 12,
  MAINTENANCE_SERVICES = 13,
  TRAVEL = 14,
  WORKPLACE = 16,
}

export const companySectors = [
  { value: CompanySectors.NOT_SPECIFIED, label: 'No especifica' },
  { value: CompanySectors.EQUIPMENT_AND_MACHINERY, label: 'Equipamiento y maquinaria' },
  { value: CompanySectors.REPLACEMENT_PARTS_AND_COMPONENTS, label: 'Repuestos y componentes' },
  { value: CompanySectors.MAINTENANCE_SERVICES, label: 'Servicios de mantenimiento' },
  {
    value: CompanySectors.INNOVATION_AND_PROFESSIONAL_SERVICES,
    label: 'Innovación y Servicios Profesionales',
  },
  { value: CompanySectors.IT_AND_TELECOMMUNICATIONS, label: 'IT y Telecomunicaciones' },
  { value: CompanySectors.LOGISTICS, label: 'Logística' },
  { value: CompanySectors.MARKETING, label: 'Marketing' },
  { value: CompanySectors.MEDIA, label: 'Medios' },
  { value: CompanySectors.RECRUITMENT_AND_HUMAN_RESOURCES, label: 'Recursos Humanos' },
  { value: CompanySectors.TRAVEL, label: 'Travel' },
  { value: CompanySectors.WORKPLACE, label: 'Workplace' },
  { value: CompanySectors.COMMODITIES, label: 'Commodities' },
  { value: CompanySectors.INGREDIENTS, label: 'Ingredientes' },
  { value: CompanySectors.CHEMICALS, label: 'Químicos' },
  { value: CompanySectors.PACKAGING, label: 'Packaging' },
];
