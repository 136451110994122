import React, { FunctionComponent } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import RadioButton from 'components/inputs/RadioButton';
import RadioGroup from 'components/inputs/RadioGroup';
import { QuestionType } from 'helpers/enums/form';
import { requiredValidation } from 'helpers/validations';

import i18next from 'i18next';
import { IQuestionOptions } from 'types/form.types';
import Button from '../../../Button';
import {
  Form,
  NextButton,
  ButtonsContainer,
  QuestionDescriptionContainer,
  CustomInput,
  DescriptionContainer,
  RadioButtonsContainer,
} from './styles';
import { IPagePopupFormProps } from './types';
import './i18n';

const PagePopupForm: FunctionComponent<
  IPagePopupFormProps & InjectedFormProps<any, IPagePopupFormProps>
> = (props) => {
  const {
    description,
    questions,
    isFirstPage,
    isLastPage,
    handleSubmit,
    submitForm,
    nextPage,
    previousPage,
    reset,
  } = props;

  const submitAndResetForm = (values: any) => {
    submitForm(values);
    reset();
  };

  const onSubmit = isLastPage ? submitAndResetForm : nextPage;

  return (
    <>
      {isFirstPage && <DescriptionContainer>{description}</DescriptionContainer>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {questions.map((question) => (
          <>
            {question.type === QuestionType.FREE_TEXT && (
              <>
                <QuestionDescriptionContainer>{question.description}</QuestionDescriptionContainer>
                <Field
                  component={CustomInput}
                  label={i18next.t('POPUP_FORM:INPUT_ANSWER')}
                  name={question.id}
                  validate={[requiredValidation]}
                  props={{
                    type: 'textarea',
                  }}
                />
              </>
            )}
            {question.type === QuestionType.OPTIONS && (
              <>
                <QuestionDescriptionContainer>{question.description}</QuestionDescriptionContainer>
                <Field
                  component={RadioGroup}
                  label="radio group"
                  name={question.id}
                  validate={[requiredValidation]}
                >
                  <RadioButtonsContainer>
                    {question?.questionOptions?.map((option: IQuestionOptions) => (
                      <RadioButton
                        label={option.description}
                        value={option.description}
                        key={option.id}
                      />
                    ))}
                  </RadioButtonsContainer>
                </Field>
              </>
            )}
          </>
        ))}
        <ButtonsContainer>
          {!isFirstPage && (
            <Button onClick={previousPage}>{i18next.t('POPUP_FORM:PREVIOUS')}</Button>
          )}
          <NextButton type="submit">
            {isLastPage ? i18next.t('POPUP_FORM:SEND') : i18next.t('POPUP_FORM:NEXT')}
          </NextButton>
        </ButtonsContainer>
      </Form>
    </>
  );
};

export default reduxForm<any, IPagePopupFormProps>({
  form: 'popup-form',
  destroyOnUnmount: false,
})(PagePopupForm);
