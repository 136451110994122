import React, { FunctionComponent, useEffect } from 'react';

import useTypedSelector from 'hooks/useTypedSelector';
import EmployeesView from 'views/EmployeesView';
import LayoutContainer from 'containers/LayoutContainer';
import {
  onChangeUserToOwner,
  onCompleteForms,
  onInitializeEmployee,
  onGetForms,
  onUserDisable,
  onUserEnable,
} from 'redux/actions/user.actions';
import { useDispatch } from 'react-redux';
import { IUser } from 'types/company.types';
import { Sections } from 'helpers/enums/sections';
import { getFormQuestionsAndAnswers } from 'helpers/forms';
import { IForm } from 'types/form.types';

const EmployeesContainer: FunctionComponent = () => {
  const company = useTypedSelector((state) => state.company.data);
  const capsules = useTypedSelector((state) => state.capsules.items);
  const companyUsers = useTypedSelector((state) => state.company.companyUsers);
  const forms = useTypedSelector((state) => state.company.user?.forms);
  const userActive = useTypedSelector((state) => state.company.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (company) {
      dispatch(onInitializeEmployee(company));
    }
    if (!forms) dispatch(onGetForms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, dispatch]);

  const onChangeStatus = (userStatus: boolean, user: IUser) => {
    if (userStatus) {
      dispatch(onUserEnable(user));
    } else {
      dispatch(onUserDisable(user));
    }
  };

  const onChangeRole = (user: IUser) => {
    dispatch(onChangeUserToOwner(user));
  };

  const completeForm = (form: IForm, values: any) => {
    const completedFormData = {
      formId: form.id,
      formAnswers: getFormQuestionsAndAnswers(form, values),
    };
    dispatch(onCompleteForms(completedFormData));
  };

  return (
    <LayoutContainer forms={forms} section={Sections.EMPLOYEES} completeForm={completeForm}>
      {!!company && !!companyUsers && !!userActive && (
        <EmployeesView
          users={companyUsers}
          capsules={capsules}
          onChangeStatus={onChangeStatus}
          onChangeRole={onChangeRole}
          userActive={userActive}
        />
      )}
    </LayoutContainer>
  );
};

export default EmployeesContainer;
