import { get, post } from 'services/api';

export async function getAllBenefits(): Promise<any> {
  const response = await get('benefits/company/pending');
  return response;
}

export async function activate(benefitId: string, collaborators: any): Promise<any> {
  const response = await post(`benefits/activate?benefitId=${benefitId}`, collaborators);
  return response;
}

export async function requestOwnerActivation(benefitId: string): Promise<any> {
  const response = await post(`benefits/request-owner-activation?benefitId=${benefitId}`);
  return response;
}
