import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import Modal from 'components/commons/Modal';

import { ArrowBackIosRounded } from '@material-ui/icons';
import { ReactComponent as icClock } from './assets/clock.svg';
import { ReactComponent as CloseModalSVG } from './assets/close-modal.svg';

export const IconClock = styled(icClock)`
  width: 22px;
  margin: 0 8px 0 0;
`;

export const LengthContainer = styled.div`
  width: 100%;
  font: 'OpenSans';
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${COLORS.riverBed};
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
`;

export const ViewContainer = styled.div`
  width: 100%;
  height: 90%;
  box-sizing: border-box;
  padding: 0 35px;
  max-width: 1296px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${SIZES.desktop}px) {
    padding: 0;
  }
`;

export const TrainingContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  gap: 30px;

  @media (max-width: ${SIZES.desktop}px) {
    flex-wrap: wrap;
    height: 100%;
    gap: 0;
  }
`;

export const DefaultText = styled.div<ITrainingDetailViewStyles>`
  color: ${COLORS.mistyQuartz};

  @media (max-width: ${SIZES.desktop}px) {
    display: none;
  }
`;
export const TrainingTitleText = styled.div`
  color: ${COLORS.riverBed};
`;

export const GrayLine = styled.div<ITrainingDetailViewStyles>`
  border: 1px solid ${COLORS.mistyQuartz};
  width: 0px;
  height: 15px;

  @media (max-width: ${SIZES.desktop}px) {
    ${(props) =>
      !props.isLast &&
      css`
        display: none;
      `}
  }
`;

export const BackButton = styled(ArrowBackIosRounded)`
  width: 7px;
  cursor: pointer;

  .MuiSvgIcon-root {
    color: ${COLORS.riverBed};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 20px 20px 0;
  font-family: 'OpenSansSemiBold';
  font-size: 16px;
  width: fit-content;
  gap: 8.5px;

  @media (max-width: ${SIZES.desktop}px) {
    margin-left: 25px;
  }
`;

export const SpeakerInfo = styled.div`
  color: white;
  border-radius: 6px;
  background: ${COLORS.riverBed};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 290px;
  padding: 30px 20px;
  box-sizing: border-box;

  @media (max-width: ${SIZES.desktop}px) {
    width: 100%;
  }
`;

export const SpeakerLabel = styled.div`
  font-family: 'OpenSans';
  font-size: 14px;
  opacity: 0.5;
  margin-top: 20px;
`;

export const SpeakerTitle = styled.div`
  font-family: 'OpenSansBold';
  font-size: 20px;
  margin: 0 0 12px 0;
  text-align: center;
`;

export const SpeakerPhotoContainer = styled.div<ITrainingDetailViewStyles>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 250px;
  height: 240px;
  border: 1px solid ${COLORS.white};
  border-radius: 4%;
  background-image: url(${(props: ITrainingDetailViewStyles) => props.speakerPhoto});
  background-size: cover;
  background-position: center;
`;

export const TrainingInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.lightblueZumthor};
  border-radius: 8px;
  padding: 30px;
  box-sizing: border-box;
  justify-content: space-around;
  flex-grow: 1;
  gap: 10px;
  height: 95%;
  @media (max-width: ${SIZES.desktop}px) {
    width: 100%;
    height: auto;
    background: transparent;
  }
`;

export const TrainingInfoText = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 75%;
  margin: 0 30px 0 0;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin: 0 0 25px;
    padding: 10px 15px;
    background: ${COLORS.white};
    border-radius: 6px;
  }
`;

export const TrainingTitle = styled.div`
  font-family: 'OpenSansBold';
  font-size: 20px;
`;

export const TrainingBody = styled.div`
  font-family: 'OpenSans';
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  width: 100%;
  white-space: pre-wrap;
  color: ${COLORS.riverBed};
`;

export const SpeakerBody = styled.div`
  font-family: 'OpenSans';
  font-size: 14px;
  text-align: left;
  width: 100%;
  white-space: pre-wrap;
  color: ${COLORS.white};
`;

export const TrainingDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-basis: 25%;
  height: fit-content;
  background: ${COLORS.grayCatskill};
  border-radius: 4px;
  padding: 25px;
  margin: 0 0 20px;

  @media (max-width: ${SIZES.bigTablet}px) {
    padding: 25px 15px;
  }
`;

export const TrainingDateInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const DateTextInfo = styled.div`
  font-family: 'OpenSansBold';
  font-size: 20px;
  margin: 10px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${SIZES.bigTablet}px) {
    font-size: 28px;
  }
`;

export const Divider = styled.div`
  border-left: 1px solid ${COLORS.grayBotticelli};
  height: 50px;
  margin: 0 20px;

  ${({ isHorizontal }: { isHorizontal?: boolean }) =>
    isHorizontal &&
    `
        border-top: 1px solid ${COLORS.white}80;
        height: 0px;
        width: 100%;
        margin: 0 0 15px 0;
    `};
`;

export const Code = styled.div`
  background: ${COLORS.blueResolution};
  font-family: 'OpenSansBold';
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  color: ${COLORS.white};
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: ${COLORS.denim};
  }

  ${({ alreadySubmited }: { alreadySubmited?: boolean }) =>
    alreadySubmited &&
    `
      background: ${COLORS.grayCatskill};
      color: ${COLORS.blueResolution};
      pointer-events: none;
      `};
`;

export const ModalVideo = styled(Modal)`
  && {
    max-width: 100%;
  }
`;
export const CustomReactPlayer = styled(ReactPlayer)`
  position: fixed;
  top: 8%;
  left: 0;
  pointer-events: none;
`;

export const PlayerContainer = styled.div`
  position: absolute;
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 50%;
  position: relative;
`;

export const VideoPreview = styled.div<ITrainingDetailViewStyles>`
  background-image: url(${(props: ITrainingDetailViewStyles) => props.videoPreview});
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlayPauseDiv = styled.div`
  width: 100%;
  height: 60%;
  position: fixed;
  z-index: 1000;
  top: 20%;
  left: 0;
  cursor: pointer;
`;

export const ModalContentContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const ModalButtonContainer = styled.div`
  margin-top: 15px;
`;

export const SurveyContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  display: none;
  z-index: 6;
  &.visible {
    display: flex;
  }
`;
export const Survey = styled.iframe`
  border-radius: 8px;
  outline: none;
  border: 2px solid black;
`;

export const JSVideo = styled.div`
  /* z-index: 1; */
  width: 90%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const JSVideoBackground = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 5;
  background-color: ${COLORS.modalBackground};
`;

export const CloseModalContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding: 20px 20px 0 0;
  z-index: 6;
`;

export const CloseModalIcon = styled(CloseModalSVG)`
  cursor: pointer;
`;

export const RightContent = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media (max-width: ${SIZES.desktop}px) {
    width: 100%;
    padding: 30px 20px;
    box-sizing: border-box;
  }
`;

export const Triangle = styled.div`
  --a: 25px;
  --border-left: calc(0.866 * var(--a));
  border-bottom: calc(var(--a) / 2) solid transparent;
  border-left: var(--border-left) solid ${COLORS.riverBed};
  border-right: 0;
  border-top: calc(var(--a) / 2) solid transparent;
  content: '';
  height: 0;
  transform: translateX(calc(0.2887 * var(--border-left) * 0.5));
  width: 0;
`;

export const PlayVideoButton = styled.div`
  align-items: center;
  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.riverBed};
  border-radius: 50%;
  display: flex;
  height: 69px;
  justify-content: center;
  width: 69px;
  cursor: pointer;
  :hover {
    background-color: ${COLORS.riverBed};
    border-color: ${COLORS.white};
    ${Triangle} {
      border-left: var(--border-left) solid ${COLORS.white};
    }
  }
`;
interface ITrainingDetailViewStyles {
  speakerPhoto?: string;
  videoPreview?: string;
  isLast?: boolean;
}
