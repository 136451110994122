import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import * as Add2Calendar from 'add2calendar';

import { onConfirmAssistance } from 'redux/actions/user.actions';
import HomeContainer from 'containers/HomeContainer';
import Modal from 'components/commons/Modal';
import ConfirmAssistanceView from 'views/ConfirmAssistanceView';
import useTypedSelector from 'hooks/useTypedSelector';
import Loading from 'components/commons/Loading';
import { ICalendarLink } from 'views/ConfirmAssistanceView/types';

const ConfirmAssistanceContainer = () => {
  const dispatch = useDispatch();
  const { userId, trainingId } = useParams<{ userId: string; trainingId: string }>();
  const loading = useTypedSelector((state) => state.company.loading);
  const training = useTypedSelector((state) => state.trainings.current);

  useEffect(() => {
    dispatch(onConfirmAssistance(userId, trainingId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  let calendarLinks: ICalendarLink[] | undefined;

  if (training) {
    const description = `${training.linkToVideo ? `${training.linkToVideo}\n` : ''}\n${
      training.description ? `${training.description}\n` : ''
    }`;

    const event = {
      title: training.title,
      description,
      location: training.linkToMeet,
      start: moment(training.date).format('YYYY-MM-DDTHH:mm:ss'),
      end: moment(training.date).add(2, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
    };
    const eventCalendarData = new Add2Calendar(event);
    calendarLinks = [
      { calendar: 'Google', link: eventCalendarData.getGoogleUrl() },
      { calendar: 'iCalendar', link: eventCalendarData.getICalUrl() },
      { calendar: 'Yahoo', link: eventCalendarData.getYahooUrl() },
      { calendar: 'Outlook', link: eventCalendarData.getOutlookUrl() },
    ];
  }

  return (
    <>
      <HomeContainer />
      {loading && (
        <Modal disabled isOpen>
          <Loading />
        </Modal>
      )}
      {!loading && calendarLinks && <ConfirmAssistanceView calendarLinks={calendarLinks} />}
    </>
  );
};

export default ConfirmAssistanceContainer;
