export enum CapsuleType {
  IMPROVE = 1,
  BUSINESS,
  FINANCE,
  LOGISTICS,
  CULTURE,
  DIGITAL,
  SPECIAL,
}

export enum CapsuleNames {
  IMPROVE = 'sustentabilidad',
  IMPROVECOL = 'sostenibilidad',
  BUSINESS = 'desarrollodenegocio',
  BUSINESSCOL = 'estrategiayposicionamiento',
  FINANCE = 'herramientasfinancieras',
  FINANCECOL = 'finanzas',
  LOGISTICS = 'logística',
  LOGISTICSCOL = 'logísticaeinternalización',
  I18N = 'internacionalización',
  CULTURE = 'culturaorganizacional',
  CULTURECOL = 'diversidadeinclusión',
  DIGITAL = 'digital',
  DIGITALCOL = 'digitalización',
  AGRO = 'agro',
}
