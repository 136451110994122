import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { Container, Title, Subtitle } from './styles';
import './i18n';

const CallbackText: FunctionComponent = () => (
  <Container>
    <Title>{i18next.t('SURVEY_CALLBACK:TITLE')}</Title>
    <Subtitle>{i18next.t('SURVEY_CALLBACK:SUBTITLE')}</Subtitle>
  </Container>
);

export default CallbackText;
