import React, { FunctionComponent } from 'react';

import { ITestimonial } from 'types/testimonials.types';

import { Container, Author, Description, QuotesIcon, Top } from './styles';

const Testimonial: FunctionComponent<ITestimonial> = (props: ITestimonial) => {
  const { author, description, isTablet } = props;

  return (
    <>
      <Container isTablet={isTablet}>
        <Top>
          <Author>{author}</Author>
          <QuotesIcon />
        </Top>
        <Description>{description}</Description>
      </Container>
    </>
  );
};

export default Testimonial;
