import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'WHOAREWE', {
  TITLE: '¿QUIÉNES SOMOS?',
  TEXT_1: 'Somos un equipo multidisciplinario que a través de ',
  TEXT_2:
    ' reafirmamos el compromiso de potenciar un ecosistema productivo eficiente, sostenible e inclusivo.',
  UNIPYME: 'UniPyME',
});
