import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import Button from 'components/commons/Button';

import { createMuiTheme } from '@material-ui/core';

export const Form = styled.form`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
`;

export const RowDivInput = styled.div`
  width: 100%;

  @media (max-width: ${SIZES.bigPhone}px) {
    width: 100%;
  }
`;

export const FormButton = styled(Button)`
  padding: 10px 64px;
  background: ${COLORS.purple};

  :hover:not([disabled]) {
    background: ${COLORS.purpleHeart};
  }
`;

export const Theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        color: 'white',
        backgroundColor: COLORS.blueRibbon,
      },
      arrow: {
        color: COLORS.blueRibbon,
      },
    },
  },
});

export const Subtitle = styled.h2`
  width: 100%;
  margin: 0 0 20px;
  font-size: 16px;
  font-family: Unilever;
  color: ${COLORS.bluePersian};
`;
