import Analytics from 'analytics';

import googleAnalytics from '@analytics/google-analytics';
import { GA_KEY } from 'configs/configs';

const analytics = Analytics({
  app: 'UniPyMe',
  plugins: [
    googleAnalytics({
      trackingId: GA_KEY,
    }),
  ],
});

export const trackEvent = (name: string, metadata: any = {}) => {
  analytics.track(name, metadata);
};

export const trackPageView = (location: string) => {
  analytics.page({
    url: location,
    title: location,
  });
};

export const reset = () => analytics.reset();

export default analytics;
