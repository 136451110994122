import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import Terms from './Terms.md';
import TermsCol from './TermsCol.md';
import { Container } from './styles';
import { ITermsAndConditionsView } from './types';

const TermsAndConditionsView: FunctionComponent<ITermsAndConditionsView> = (
  props: ITermsAndConditionsView,
) => {
  const { country } = props;
  const [postMarkdown, setPostMarkdown] = useState('');

  useEffect(() => {
    // TODO change this logic once we have implemented terms and conditions in the admin
    const terms = country === 'colombia' ? TermsCol : Terms;
    fetch(terms)
      .then((response) => response.text())
      .then((text) => {
        setPostMarkdown(text);
      });
  }, [country]);

  return (
    <Container id="terms" data-testid="terms-and-conditions-view">
      <ReactMarkdown data-testid="markdown-view" className="markdown-text">
        {postMarkdown}
      </ReactMarkdown>
    </Container>
  );
};

export default TermsAndConditionsView;
