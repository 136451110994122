import React, { FunctionComponent, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import i18next from 'i18next';

import LottiePlayer from 'components/commons/LottiePlayer';

import WhoAreWeAnimation from '../../animations/whoarewe.json';

import {
  Container,
  Background,
  Title,
  Text,
  TextContainer,
  AnimationContainer,
  Bold,
  SubtitleContainer,
} from './styles';
import './i18n';

const WhoAreWe: FunctionComponent = () => {
  const [isAnimationVisible, setAnimationVisible] = useState<boolean>(false);

  return (
    <Background>
      <Container>
        <TextContainer>
          <Title>{i18next.t('WHOAREWE:TITLE')}</Title>
          <SubtitleContainer>
            <Text>
              {i18next.t('WHOAREWE:TEXT_1')}
              <Bold>{i18next.t('WHOAREWE:UNIPYME')}</Bold>
              {i18next.t('WHOAREWE:TEXT_2')}
            </Text>
            <Text />
          </SubtitleContainer>
        </TextContainer>
        <VisibilitySensor partialVisibility offset={{ top: 10 }}>
          {({ isVisible }) => {
            if (isVisible) setAnimationVisible(true);
            return (
              <AnimationContainer>
                <LottiePlayer
                  animationJsonPath={WhoAreWeAnimation}
                  autoplay={false}
                  loop={false}
                  play={isAnimationVisible}
                  still
                />
              </AnimationContainer>
            );
          }}
        </VisibilitySensor>
      </Container>
    </Background>
  );
};

export default WhoAreWe;
