import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'FORM_INSTRUCTIONS', {
  LEGAL:
    'LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.',
  FIRST_1: 'Completar el formulario de cada cápsula es muy importante para poder desarrollar los ',
  FIRST_BOLD_1: 'entrenamientos acorde a la necesidad de todas las PyMEs.',
  FIRST_2:
    '¡Recordá que también son parte del scoring que te permitirá acceder a distintos beneficios!',
  FIRST_BUTTON: 'Comenzar',
  LAST_BOLD: 'Gracias por responder el formulario.',
  LAST: 'Confirmá que todos los datos son correctos',
  LAST_BUTTON: 'Confirmar',
});
