export enum CompanyNumberOfCollaborators {
  ONE_TO_TEN = 0,
  ELEVEN_TO_FIFTY,
  FIFTY_ONE_TO_TWO_HUNDRED,
  MORE_THAN_TWO_HUNDRED,
  NOT_SPECIFIED,
}

export const companyNumberOfCollaborators = [
  { value: CompanyNumberOfCollaborators.ONE_TO_TEN, label: 'De 1 al 10' },
  { value: CompanyNumberOfCollaborators.ELEVEN_TO_FIFTY, label: 'De 11 al 50' },
  { value: CompanyNumberOfCollaborators.FIFTY_ONE_TO_TWO_HUNDRED, label: 'De 51 al 200' },
  { value: CompanyNumberOfCollaborators.MORE_THAN_TWO_HUNDRED, label: 'Más de 200' },
  { value: CompanyNumberOfCollaborators.NOT_SPECIFIED, label: 'No especifica' },
];
