import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as userConstants from 'redux/constants/user.constants';
import { initializeCountries } from 'services/country.services';

export function* countryInitialize() {
  try {
    const data: unknown = yield call(initializeCountries);
    yield put({ type: userConstants.USER_ON_INITIALIZE_SIGN_UP_VIEW_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: userConstants.USER_ON_INITIALIZE_SIGN_UP_VIEW_FAILED, error });
  }
}

export function* watchCountries() {
  yield all([
    takeLatest(userConstants.USER_ON_INITIALIZE_SIGN_UP_VIEW_REQUESTED, countryInitialize),
  ]);
}
