import React, { FunctionComponent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';

import backgroundImage from 'assets/imgs/modal-colombia-background-image.png';

import Logo from 'components/commons/Logo';
import { SIZES } from 'helpers/enums/sizes';
import { Status } from 'helpers/enums/status';
import Modal from 'components/commons/Modal';
import { COLORS } from 'helpers/enums/colors';
import history from 'helpers/history';
import { MenuRoutes } from 'helpers/enums/links';

import { IHeaderProps } from './types';

import {
  HeaderContent,
  MenuOverlay,
  MenuContainer,
  MenuIconContainer,
  OpenMenuIcon,
  CloseMenuIcon,
  LandingNavLinks,
  DashboardNavLinks,
  DivLink,
  HeaderButton,
  HeaderContainer,
  Icon,
  IconContainer,
  TextContainer,
  LogoContainer,
  Arrow,
  ArrowIcon,
  ModalText,
  ModalButton,
  HoverBarForLinks,
  ExternalLink,
} from './styles';
import './i18n';

const Header: FunctionComponent<IHeaderProps> = (props: IHeaderProps) => {
  const {
    company,
    user,
    links,
    isLanding,
    isMenuOpened,
    isMenuCollapsed,
    toggleMenu,
    collapseMenu,
    onLogoutClick,
  } = props;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isNavHashLinkActive = (pathname: string) => {
    if (pathname === MenuRoutes.DASHBOARD) {
      return [MenuRoutes.CAPSULE, MenuRoutes.TRAININGS, MenuRoutes.DASHBOARD].some((substring) =>
        history.location.pathname.includes(substring),
      );
    }

    return pathname === history.location.pathname;
  };

  const MenuIcon = isMenuOpened ? CloseMenuIcon : OpenMenuIcon;
  const NavLinksContainer = isLanding ? LandingNavLinks : DashboardNavLinks;
  const hasTabletOrMobileSize = width <= SIZES.bigTablet;
  const renderMenuItems = () => (
    <>
      {links.map((link) => {
        if (link.external) {
          return (
            <ExternalLink
              key={`link-${link.id}`}
              isLanding={isLanding}
              data-testid={link.id}
              onClick={toggleMenu}
              href={link.to}
              target={link.target}
            >
              <HoverBarForLinks isMenuCollapsed={isMenuCollapsed} />
              <IconContainer isLanding={isLanding}>
                <Icon key={`icon-link-${link.id}`}>{link.icon}</Icon>
              </IconContainer>
              <TextContainer
                hasTabletOrMobileSize={hasTabletOrMobileSize}
                isLanding={isLanding}
                isMenuCollapsed={isMenuCollapsed}
                key={`text-link-${link.id}`}
              >
                {!hasTabletOrMobileSize && !isMenuCollapsed && !isLanding && link.text}
                {isLanding && link.text}
              </TextContainer>
            </ExternalLink>
          );
        }
        return (
          <DivLink
            key={`link-${link.id}`}
            isLanding={isLanding}
            activeClassName="active"
            data-testid={link.id}
            onClick={toggleMenu}
            smooth
            to={link.to}
            target={link.target}
            isActive={() => isNavHashLinkActive(link.to)}
          >
            <HoverBarForLinks isMenuCollapsed={isMenuCollapsed} />
            <IconContainer isLanding={isLanding}>
              <Icon key={`icon-link-${link.id}`}>{link.icon}</Icon>
            </IconContainer>
            <TextContainer
              hasTabletOrMobileSize={hasTabletOrMobileSize}
              isLanding={isLanding}
              isMenuCollapsed={isMenuCollapsed}
              key={`text-link-${link.id}`}
            >
              {!hasTabletOrMobileSize && !isMenuCollapsed && !isLanding && link.text}
              {isLanding && link.text}
            </TextContainer>
          </DivLink>
        );
      })}
    </>
  );

  const renderWithoutAccount = () => (
    <>
      {isLanding && (
        <DivLink
          isLanding={isLanding}
          data-testid="nav-link-dashboard"
          activeClassName="active"
          onClick={toggleMenu}
          smooth
          to="/dashboard"
        >
          <TextContainer hasTabletOrMobileSize={hasTabletOrMobileSize} isLanding={isLanding}>
            {i18next.t('HEADER:DASHBOARD')}
          </TextContainer>
        </DivLink>
      )}
    </>
  );

  const renderMenuArrow = () =>
    !isLanding &&
    !hasTabletOrMobileSize && (
      <Arrow data-testid="arrow-menu" isMenuCollapsed={isMenuCollapsed} onClick={collapseMenu}>
        <ArrowIcon />
      </Arrow>
    );

  const renderLogo = () => (
    <LogoContainer isLanding={isLanding}>
      {isLanding ? (
        <Logo isLanding={!company} isWhiteLogo showUnileverLogo />
      ) : (
        !isMenuCollapsed && <Logo isWhiteLogo />
      )}
    </LogoContainer>
  );

  return (
    <HeaderContainer isMenuCollapsed={isMenuCollapsed} isLanding={isLanding} data-testid="header">
      <HeaderContent isLanding={isLanding}>
        {renderLogo()}
        <MenuOverlay
          data-testid="menu-overlay"
          isLanding={isLanding}
          onClick={toggleMenu}
          showOverlay={isMenuOpened}
        />
        {renderMenuArrow()}
        <MenuContainer data-testid="menu-content">
          {isLanding && (
            <MenuIconContainer>
              <MenuIcon onClick={toggleMenu} />
            </MenuIconContainer>
          )}

          <NavLinksContainer showSideMenu={isMenuOpened}>
            {renderMenuItems()}
            {user?.status === Status.WAITING_ON_COMPANY_OWNER && (
              <Modal
                disabled
                isOpen
                backgroundColor={COLORS.modalColombiaBackground}
                backgroundImage={backgroundImage}
              >
                <ModalText>Esperando confirmación de la empresa</ModalText>
                <ModalButton data-testid="nav-link-logout" onClick={onLogoutClick}>
                  {i18next.t('DASHBOARD_HEADER:LOGOUT')}
                </ModalButton>
              </Modal>
            )}

            {!company ? (
              <>
                <li>
                  <NavLink
                    activeClassName="active"
                    data-testid="nav-link-signup"
                    onClick={toggleMenu}
                    to="/signup"
                  >
                    <HeaderButton hasBackground>{i18next.t('HEADER:SIGNUP')}</HeaderButton>
                  </NavLink>
                </li>
                <li>
                  <DivLink
                    activeClassName="active"
                    data-testid="nav-link-login"
                    onClick={toggleMenu}
                    to="/login"
                    isLanding={isLanding}
                  >
                    <HeaderButton hasBackground={false}>{i18next.t('HEADER:LOGIN')}</HeaderButton>
                  </DivLink>
                </li>
              </>
            ) : (
              <>{renderWithoutAccount()}</>
            )}
          </NavLinksContainer>
        </MenuContainer>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
