import { FunctionComponent } from 'react';

import { INotificationsDefaultState } from 'types/notifications.types';
import * as constants from 'redux/constants/notifications.constants';

const defaultState: INotificationsDefaultState = {
  items: [],
  loading: false,
};

const notificationsReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { data, type } = action;
  switch (type) {
    case constants.NOTIFICATIONS_ON_GET_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.NOTIFICATIONS_ON_GET_SUCCEEDED:
      return {
        ...state,
        items: data.notifications,
        loading: false,
      };
    case constants.NOTIFICATIONS_ON_GET_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
