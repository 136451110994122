import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'HERO', {
  TITLE_PENDING: 'La plataforma no está disponible',
  TITLE_ACTIVE: 'Bienvenido',
  MESSAGE_PENDING:
    'El ingreso de tu empresa se encuentra en estado de aprobación, una vez terminado este proceso, podrá acceder a los beneficios de UniPyME',
  MESSAGE_ACTIVE: 'En breve podrá tener acceso a los beneficios',
});
