import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const CoinIcon = styled.img`
  width: 30px;
`;

export const HorizontalStatContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const Title = styled.p`
  font-size: 15px;
`;

export const StatTitle = styled.p`
  font-family: OpenSansBold;
  font-size: 15px;
  line-height: 20px;
  color: ${COLORS.riverBed};
  margin: 0;
`;

export const StatText = styled.p`
  font-family: OpenSans;
  font-size: 11px;
  line-height: 13px;
  color: ${COLORS.riverBed};
  margin: 0;
`;

export const StatNumber = styled.p`
  font-family: OpenSansBold;
  font-size: 40px;
  line-height: 55px;
  color: ${COLORS.bluePersian};
  margin: 0;
`;

export const StatsBannerTitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-family: OpenSansBold;
  margin: 0 0 10px 0;
  text-align: center;
`;

export const Level = styled.p`
  font-family: OpenSansBold;
  font-size: 22px;
  margin: 0;
  color: ${({ color }: { color?: string }) => color};
`;

export const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 143px;
  height: 107px;
  background: ${COLORS.white};
  box-shadow: 0px 4px 8px #8ea7d333;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  padding: 10px 20px;
  @media (max-width: ${SIZES.desktop}px) {
    width: 100%;
  }
`;

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 10px;

  @media (max-width: ${SIZES.desktop}px) {
    flex-direction: column;
  }
`;

export const StatsBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  color: ${COLORS.riverBed};
  border-radius: 8px;
  background: ${COLORS.lightblueZumthor};
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: ${SIZES.desktop}px) {
    flex-direction: column;
    padding: 20px 30px;
  }
`;

export const Image = styled.img`
  width: fit-content;

  @media (max-width: ${SIZES.smallTablet}px) {
    width: 120px;
  }
`;

export const ImageContainer = styled.div`
  justify-content: flex-end;
  align-self: flex-end;
  display: flex;
  flex: 1;
  margin-left: -90px;
`;

export const ProgramText = styled.span`
  font: 18px/24px 'OpenSansBold';
  margin: 0;
`;

export const TitleBanner = styled.h2`
  font: 28px/30px 'OpenSansBold';
  margin: 0;
  padding-bottom: 5px;
`;

export const TextBanner = styled.span`
  font-size: 16px;
  font-family: ${({ isBold }: { isBold?: boolean }) => (isBold ? 'OpenSansBold' : 'OpenSans')};
  margin: 0;
`;

export const TextBannerContainer = styled.div`
  padding-top: 23px;
  padding-right: ${({ hasPaddingRight }: { hasPaddingRight?: boolean }) =>
    hasPaddingRight ? '90px' : '0'};

  &:last-child {
    padding-bottom: 23px;
  }
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 35%;

  @media (max-width: ${SIZES.desktop}px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Trainings = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${SIZES.desktop}px) {
    margin-bottom: 20px;
    justify-content: space-around;
  }

  @media (max-width: ${SIZES.desktop}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Bold = styled.b``;

export const ViewContainer = styled.div`
  width: 100%;
  height: 90%;
  box-sizing: border-box;
  padding: 0 35px;
  max-width: 1296px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${SIZES.desktop}px) {
    padding: 0;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 184px;
  margin: 0;
  padding: 0;
  gap: 20px;

  @media (max-width: ${SIZES.desktop}px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
`;

export const LeftContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: ${SIZES.desktop}px) {
    align-items: center;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0%;
  display: flex;
  gap: 30px;

  @media (max-width: ${SIZES.desktop}px) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 20px 20px 0;
  font-family: 'OpenSansSemiBold';
  font-size: 16px;
  width: fit-content;
  gap: 8.5px;

  @media (max-width: ${SIZES.desktop}px) {
    margin-left: 25px;
  }
`;

export const GrayLine = styled.div`
  border: 1px solid ${COLORS.mistyQuartz};
  width: 0px;
  height: 15px;
`;

export const CapsulesContainer = styled.div`
  background: ${COLORS.lightblueZumthor};
  width: 932px;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  gap: 20px;
  margin-bottom: 0;

  @media (max-width: ${SIZES.desktop}px) {
    border-radius: 0;
    width: 100%;
  }
`;

export const CapsulesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media (max-width: ${SIZES.desktop}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CapsulesTitle = styled.div`
  width: 100%;
  text-align: left;
  color: ${COLORS.riverBed};
  font-family: OpenSansBold;
  font-size: 20px;
`;

export const CarouselContainer = styled.div`
  width: 100%;
`;
