import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import companyReducer from 'redux/reducers/company.reducer';
import capsuleReducer from './capsule.reducer';
import newsReducer from './news.reducer';
import programReducer from './program.reducer';
import trainingReducer from './training.reducer';
import headerReducer from './header.reducer';
import testimonialsReducer from './testimonials.reducer';
import notificationsReducer from './notifications.reducer';
import levelsReducer from './levels.reducer';
import benefitReducer from './benefit.reducer';
import countryReducer from './country.reducer';
import privateRoutesReducer from './privateRoutes.reducer';

const rootReducer = combineReducers({
  form: formReducer,
  company: companyReducer,
  news: newsReducer,
  programs: programReducer,
  capsules: capsuleReducer,
  trainings: trainingReducer,
  header: headerReducer,
  testimonials: testimonialsReducer,
  notifications: notificationsReducer,
  levels: levelsReducer,
  benefits: benefitReducer,
  country: countryReducer,
  auth: privateRoutesReducer,
});

export default rootReducer;
