import React, { FunctionComponent, useState } from 'react';
import i18next from 'i18next';
import moment from 'moment';

import { codeIsSubmitted, howManyPoints } from 'helpers/codeValidations';
import { getFormByType, splitFormQuestionsInPages } from 'helpers/forms';
import { isInTheFuture } from 'helpers/dateCalculations';
import SubmitCode from 'components/commons/SubmitCode';
import PopupForm from 'components/commons/PopupForm';
import { MenuRoutes } from 'helpers/enums/links';
import Modal from 'components/commons/Modal';
import history from 'helpers/history';
import { redirectToTraining } from 'helpers/trainings';

import DefaultTrainingCardThumbnail from './assets/defaultTrainingCardThumbnail.jpg';
import { ITrainingCardProps } from './types';
import {
  FutureTrainingTitle,
  IconCalendar,
  IconClock,
  ModalButton,
  ModalContentContainer,
  SubmitCodeButton,
  TrainingContainer,
  TrainingContent,
  TrainingDateInfo,
  TrainingThumbnail,
  TrainingTitle,
  WatchTrainingButton,
  ButtonsContainer,
  TrainingDateText,
  FutureTrainingTopContainer,
  FutureTrainingBottomContainer,
  SoonText,
  TrainingDateInfoText,
  Separator,
  LengthContainer,
  TitleContainer,
} from './styles';
import './i18n';

const TrainingCard: FunctionComponent<ITrainingCardProps> = (props) => {
  const { training, onSubmit, user, forms, completeForm, showCertModal, closeCertModal, isHome } =
    props;
  const { title, date, id, completedTrainings, thumbnail, version, capsuleId } = training;

  const form = getFormByType(forms, undefined, training);
  const codeSubmitted = codeIsSubmitted(completedTrainings, user);
  const trainingDate = new Date(date);
  const now = new Date();
  const isFuture = isInTheFuture(now, trainingDate);
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const toggleForm = () => {
    setFormOpen((prev) => !prev);
  };

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const submitForm = (values: any) => {
    if (form) completeForm(form, values);
    toggleForm();
    toggleModal();
  };

  const onClickCertModalButton = () => {
    history.replace(MenuRoutes.CERTIFICATES);
    closeCertModal();
  };

  const renderCertModal = () => (
    <Modal
      isOpen={showCertModal}
      onClose={closeCertModal}
      title={i18next.t('TRAINING_CARD:CERT_MODAL_TITLE')}
    >
      <ModalContentContainer>
        {i18next.t('TRAINING_CARD:CERT_MODAL_DESCRIPTION')}
        <ModalButton onClick={onClickCertModalButton}>
          {i18next.t('TRAINING_CARD:CERT_MODAL_BUTTON')}
        </ModalButton>
      </ModalContentContainer>
    </Modal>
  );

  const renderForm = () =>
    form && (
      <PopupForm
        title={form.title}
        description={form.description}
        questionsPaginated={splitFormQuestionsInPages(form.question)}
        formOpen={formOpen}
        submitForm={submitForm}
        onCloseForm={toggleForm}
      />
    );

  const renderSubmitCodeModal = () => (
    <Modal isDark isOpen={modalOpen} onClose={toggleModal}>
      <SubmitCode
        onSubmit={onSubmit}
        id={id}
        closeModal={toggleModal}
        capsuleId={capsuleId}
        version={version}
      />
    </Modal>
  );

  return (
    <>
      {renderCertModal()}
      {renderForm()}
      {renderSubmitCodeModal()}
      <TrainingContainer isHome={isHome}>
        {!isFuture && (
          <>
            <TrainingThumbnail>
              <img src={thumbnail || DefaultTrainingCardThumbnail} alt="Thumbnail" />
            </TrainingThumbnail>
            <TrainingContent>
              <TitleContainer>
                <TrainingTitle showVideoLength={training.videoLength}>{title}</TrainingTitle>
                {training.videoLength && (
                  <LengthContainer>
                    <IconClock />
                    {training.videoLength}
                  </LengthContainer>
                )}
              </TitleContainer>
              <ButtonsContainer>
                <WatchTrainingButton onClick={() => redirectToTraining(id, true)}>
                  {i18next.t('TRAINING_CARD:WATCH_TRAINING')}
                </WatchTrainingButton>
                <SubmitCodeButton
                  onClick={form ? toggleForm : toggleModal}
                  disabled={codeSubmitted}
                >
                  {codeSubmitted
                    ? i18next.t('TRAINING_CARD:POINTS', {
                        points: howManyPoints(completedTrainings, user),
                      })
                    : i18next.t('TRAINING_CARD:SUBMIT_CODE')}
                </SubmitCodeButton>
              </ButtonsContainer>
            </TrainingContent>
          </>
        )}
        {isFuture && (
          <>
            <FutureTrainingTopContainer isHome={isHome}>
              <FutureTrainingTitle isHome={isHome}>{title}</FutureTrainingTitle>
              <TrainingDateInfo>
                <TrainingDateInfoText>
                  <IconClock />
                  {i18next.t('TRAINING_CARD:DATE')}
                  <TrainingDateText> {moment(trainingDate).format('DD/MM')}</TrainingDateText>
                </TrainingDateInfoText>
                <Separator />
                <TrainingDateInfoText>
                  <IconCalendar />
                  {i18next.t('TRAINING_CARD:HOUR')}
                  <TrainingDateText>{moment(trainingDate).format(`HH:mm [hs]`)}</TrainingDateText>
                </TrainingDateInfoText>
              </TrainingDateInfo>
              <SoonText>{i18next.t('TRAINING_CARD:SOON')}</SoonText>
            </FutureTrainingTopContainer>
            <FutureTrainingBottomContainer onClick={() => redirectToTraining(id)}>
              {i18next.t('TRAINING_CARD:DETAIL')}
            </FutureTrainingBottomContainer>
          </>
        )}
      </TrainingContainer>
    </>
  );
};

export default TrainingCard;
