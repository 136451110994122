import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import { ReactComponent as Quotes } from './assets/quotes.svg';

export const Container = styled.div`
  width: 80%;
  background: ${COLORS.grayMercury};
  margin: ${({ isTablet }: { isTablet: boolean }) => (isTablet ? '0' : '0 0 20px 0')};
  padding: 20px 15px;
  border-radius: 6px;

  @media (max-width: ${SIZES.bigTablet}px) {
    height: 160px;
  }
`;

export const Author = styled.div`
  font-size: 18px;
  font-family: 'OpenSansBold';
  color: ${COLORS.riverBed};
  text-align: start;
  margin: 8px 0 5px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-family: 'OpenSansSemiBold';
  color: ${COLORS.riverBed};
  text-align: start;
  margin: 0 0 10px;
`;

export const QuotesIcon = styled(Quotes)`
  transform: scale(0.7);
  padding: 0 0 5px 0;
  box-sizing: border-box;
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 5px;
`;
