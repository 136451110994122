import countries from 'helpers/json/countries.json';
import moment from 'moment';
import 'moment-timezone';

import { ADMIN_MAIL_ARGENTINA, ADMIN_MAIL_COLOMBIA } from 'configs/configs';

import { removeAccents } from './utils';

const getTimezoneByCountry = (country: string) => {
  switch (country.toLocaleLowerCase()) {
    case 'argentina':
      return 'America/Argentina/Buenos_Aires';
    case 'colombia':
      return 'America/Bogota';
    default:
      return 'UTC';
  }
};

export function getInformativeCountry(name: string) {
  const formattedCountries = countries.map((country) => ({
    ...country,
    name: country.name.toLocaleLowerCase().trim(),
  }));
  const formattedName = removeAccents(name.toLocaleLowerCase().trim());
  const informativeCompanyCountry = formattedCountries.find((i) => i.name === formattedName);

  return informativeCompanyCountry;
}

export const getCountryLocalDate = (date: Date, country: string): Date => {
  return moment
    .utc(moment.tz(date, getTimezoneByCountry(country)).format('YYYY-MM-DDTHH:mm:ss'))
    .toDate();
};

export const getCountryEmail = (country: string): string | undefined =>
  country === 'Argentina' ? ADMIN_MAIL_ARGENTINA : ADMIN_MAIL_COLOMBIA;
