import styled from 'styled-components';
import { List, MenuItem } from '@material-ui/core';

import { SIZES } from 'helpers/enums/sizes';

import { ReactComponent as ArrowSvg } from 'assets/imgs/arrow.svg';
import { COLORS } from 'helpers/enums/colors';

export const Arrow = styled(ArrowSvg)`
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  align-self: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0;
  height: 100%;

  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
  }

  .MenuItem.Mui-selected {
    border-radius: 8px 0 0 8px !important;
    background: ${COLORS.white};
    padding-left: 15px !important;

    :hover {
      background: ${COLORS.white};
    }
  }

  .MenuItem.MuiMenuItem-root {
    border-bottom: 1px solid ${COLORS.linkWater};
    padding-left: 0;
  }
`;

export const CustomList = styled(List)`
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background: ${COLORS.white};
    padding-left: 20px;
    border-top: none;
  }

  .MuiMenuItem-root {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .MuiListItem-gutters {
    padding-left: 10px;
    border-top: 1px solid ${COLORS.grayBotticelli};

    &:last-child {
      border-bottom: 1px solid ${COLORS.grayBotticelli};
    }
  }
`;

export const Column = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 30%;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
    padding: 0;
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  width: 100%;
  border-top: 1px solid ${COLORS.grayBotticelli};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -5px;
  cursor: pointer;
`;

export const DetailsContainer = styled.div`
  height: 100%;
  width: 70%;
  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
  }
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;
