import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/training.constants';
import * as userConstants from 'redux/constants/user.constants';
import * as companyConstants from 'redux/constants/company.constants';
import {
  getAllTrainings,
  // getTrainingById,
  submitCode,
  markCompleted,
  getAllTrainingsByCapsuleVersion,
  getTrainingByIdWithCompanyData,
} from 'services/training.service';

export function* getTrainings() {
  try {
    const data: unknown = yield call(getAllTrainings);
    yield put({ type: constants.TRAINING_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.TRAINING_ON_GET_ALL_FAILED, error });
  }
}

// export function* getTraining(action: any) {
//   try {
//     const { trainingId } = action.data?.confirmedAssistance
//       ? action.data.confirmedAssistance
//       : action;
//     const data: unknown = yield call(getTrainingById, trainingId);
//     yield put({ type: constants.TRAINING_ON_GET_ONE_SUCCEEDED, data });
//   } catch (error) {
//     yield put({ type: constants.TRAINING_ON_GET_ONE_FAILED, error });
//   }
// }

export function* getTrainingWithCompanyData(action: any) {
  try {
    const { trainingId } = action;
    const data: unknown = yield call(getTrainingByIdWithCompanyData, trainingId);
    yield put({ type: constants.TRAINING_ON_GET_ONE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.TRAINING_ON_GET_ONE_FAILED, error });
  }
}

export function* submitTrainingCode(action: any) {
  try {
    const data: unknown = yield call(submitCode, action.formData);
    const { code, capsuleId, version, trainingId } = action.formData;
    yield put({
      type: constants.TRAINING_SUBMIT_CODE_SUCCEEDED,
      data,
      code,
      capsuleId,
      version,
      trainingId,
    });
  } catch (error) {
    yield put({ type: constants.TRAINING_SUBMIT_CODE_FAILED, error });
  }
}

export function* markTrainingAsCompleted(action: any) {
  try {
    const { trainingId } = action;
    const data: unknown = yield call(markCompleted, trainingId);
    yield put({
      type: constants.TRAINING_MARK_AS_COMPLETE_SUCCEEDED,
      data,
    });
  } catch (error) {
    yield put({ type: constants.TRAINING_MARK_AS_COMPLETE_FAILED, error });
  }
}

export function* getTrainingsByCapsuleVersion(action: any) {
  try {
    const { capsuleId, version } = action;
    const data: unknown = yield call(getAllTrainingsByCapsuleVersion, capsuleId, version);
    yield put({
      type: constants.TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_SUCCEEDED,
      data,
      capsuleId,
      version,
    });
  } catch (error) {
    yield put({ type: constants.TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_FAILED, error });
  }
}

export function* watchTrainings() {
  yield all([
    takeLatest(
      [
        userConstants.USER_ON_LOGIN_SUCCEEDED,
        userConstants.USER_ON_INITIALIZE_SUCCEEDED,
        companyConstants.COMPANY_ON_SUBMIT_REGISTER_FORM_SUCCEEDED,
        constants.TRAINING_ON_GET_ALL_REQUESTED,
        constants.TRAINING_SUBMIT_CODE_SUCCEEDED,
        constants.TRAINING_MARK_AS_COMPLETE_SUCCEEDED,
      ],
      getTrainings,
    ),
    takeLatest([constants.TRAINING_SUBMIT_CODE_REQUESTED], submitTrainingCode),
    takeLatest([constants.TRAINING_MARK_AS_COMPLETE_REQUESTED], markTrainingAsCompleted),
    // takeLatest([userConstants.USER_ON_CONFIRM_ASSISTANCE_SUCCEEDED], getTraining),
    takeLatest(
      [constants.TRAINING_ON_GET_ONE_REQUESTED, constants.TRAINING_SUBMIT_CODE_SUCCEEDED],
      getTrainingWithCompanyData,
    ),
    takeLatest(
      [
        constants.TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_REQUESTED,
        constants.TRAINING_SUBMIT_CODE_SUCCEEDED,
      ],
      getTrainingsByCapsuleVersion,
    ),
  ]);
}
