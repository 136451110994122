import styled from 'styled-components';

import { HEADER_HEIGHT } from 'helpers/dimensions';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${HEADER_HEIGHT};
  justify-content: center;
  align-items: center;
`;
