import React, { ChangeEvent, FunctionComponent } from 'react';
import { InputLabel, makeStyles, MenuItem, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@mui/material';

import { COLORS } from 'helpers/enums/colors';

import { CustomSelect, FormGroup, ErrorBlock } from './styles';
import { ISelectProps } from './types';

const Select: FunctionComponent<ISelectProps> = (props: ISelectProps) => {
  const {
    input,
    options,
    meta: { touched, error },
    variant = 'outlined',
    label,
    darkMode = false,
    disabled,
  } = props;
  const { onChange, value } = input;
  const hasError = touched && !!error;

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  const useStyles = makeStyles(() => ({
    root: {
      fontFamily: 'OpenSans',
      color: darkMode ? COLORS.white : COLORS.riverBed,
      background: darkMode ? 'transparent' : COLORS.white,
    },
  }));
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <FormGroup variant={variant} size="small">
        {!value && label && (
          <InputLabel classes={{ root: classes.root }} id="demo-simple-select-outlined-label">
            {label}
          </InputLabel>
        )}
        <CustomSelect
          {...input}
          data-testid="custom-dropdown"
          onChange={(event: ChangeEvent<{ value: unknown }>) =>
            onChange(event.target.value as string)
          }
          value={value}
          renderValue={(showValue: unknown) => showValue as string}
          inputProps={{
            classes: { root: classes.root },
          }}
          error={hasError}
          darkMode={darkMode}
          disabled={disabled}
        >
          {options.map((option: string) => (
            <MenuItem classes={{ root: classes.root }} key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </CustomSelect>
        {hasError && <ErrorBlock data-testid="dropdown-help-block">{error}</ErrorBlock>}
      </FormGroup>
    </ThemeProvider>
  );
};

export default Select;
