import { COLORS } from './enums/colors';
import { ITabQuestionStatus } from './enums/form';

export const getStatusTabQuestion = (current: number, id: number): ITabQuestionStatus => {
  if (current > id) return ITabQuestionStatus.VISITED;
  if (current < id) return ITabQuestionStatus.UNVISITED;
  return ITabQuestionStatus.CURRENT;
};

export const getIconColor = (status: ITabQuestionStatus): string => {
  if (status === ITabQuestionStatus.CURRENT) {
    return COLORS.bluePersian;
  }
  if (status === ITabQuestionStatus.VISITED) {
    return COLORS.purple;
  }
  return COLORS.riverBed;
};

export const getIconStroke = (status: ITabQuestionStatus): string => {
  if (status === ITabQuestionStatus.CURRENT) {
    return '0.6px';
  }
  return '0.3px';
};
