import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import Button from 'components/commons/Button';
import Input from 'components/inputs/Input';

import { createMuiTheme } from '@material-ui/core';

export const Form = styled.form`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
    gap: 15px;
  }
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
  }
`;

export const RowDivInput = styled.div`
  width: 100%;

  @media (max-width: ${SIZES.bigPhone}px) {
    width: 100%;
  }
`;

export const FormButton = styled(Button)`
  padding: 10px 64px;
  background: ${COLORS.purple};

  :hover:not([disabled]) {
    background: ${COLORS.purpleHeart};
  }
`;

export const Theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        color: 'white',
        backgroundColor: COLORS.blueRibbon,
      },
      arrow: {
        color: COLORS.blueRibbon,
      },
    },
  },
});

export const Title = styled.h2`
  font-family: Unilever;
  font-size: 16px;
  color: ${COLORS.bluePersian};
  margin: 0 0 38px 0;
`;

export const RowDivWithPadding = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 40px;
  font-family: OpenSans;
  font-size: 16px;

  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
  }
`;

export const CustomInput = styled(Input)`
  .MuiFormLabel-root {
    font-family: OpenSans;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
    text-align: left;
  }
`;
