import { useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import QueryString from 'query-string';

const useQueryString = () => {
  return QueryString.parse(useLocation().search);
};

export const createQueryString = (object: any) => {
  return QueryString.stringify(object);
};
export default useQueryString;
