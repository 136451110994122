import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'EMPLOYEES_VIEW', {
  MY_EMPLOYEES: 'Mis colaboradores',
  NAME: 'Nombre',
  EMAIL: 'Mail',
  OWNER: 'Admin',
  USER: 'Colaborador',
  ROLE: 'Rol',
  STATUS: 'Estado',
  TRAININGS: 'Capacitaciones',
  COMPLETED: 'Completo',
  TITLE_INFORMATION: 'Gestiona tu equipo',
  IN_THIS_SECTION: 'En esta sección puedes:',
  EMPLOYEE_STATUS: 'Ver el estado de tus colaboradores',
  EMPLOYEE_TRAININGS: 'Ver el detalle de sus capacitaciones',
  ADD_OWNERS: 'Agregar a otro colaborador para ser administrador de esta cuenta',
  TEXT_INFORMATION:
    'El progreso de tu empresa depende del progreso de los colaboradores. En esta sección tienes un panorama de la actividad de tus colaboradores, sus roles y el estado de sus capacitaciones.',
  ADD_COLLABORATORS: ' Si deseas sumar colaboradores, puedes pedirles que se registren en',
  UNIPYMELATAM_HREF: 'https://unipymelatam.com',
  UNIPYMELATAM_HREF_LABEL: 'wwww.unipymelatam.com',
  MORE_INFORMATION: '+info',
  BACK: 'Atrás',
});
