import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useTypedSelector from 'hooks/useTypedSelector';
import { onCloseCertModal, onSubmitTrainingCode } from 'redux/actions/training.action';
import { ISubmitCodeData } from 'views/CapsuleView/types';
import { CompanyType } from 'helpers/programs';
import { ICapsule } from 'types/capsules.types';
import { getDashboardFeaturedTraining, getFutureTrainings } from 'helpers/trainings';
import { Sections } from 'helpers/enums/sections';

import LayoutContainer from 'containers/LayoutContainer';
import DashboardView from 'views/DashboardView';
import { onCompleteForms, onGetForms } from 'redux/actions/user.actions';
import { getFormQuestionsAndAnswers } from 'helpers/forms';
import { IForm } from 'types/form.types';
import { Status } from 'helpers/enums/status';
import Loading from 'components/commons/Loading';
import Modal from 'components/commons/Modal';

const DashboardContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const programs = useTypedSelector((state) => state.programs.items);
  const company = useTypedSelector((state) => state.company.data);
  const user = useTypedSelector((state) => state.company.user);
  const scores = useTypedSelector((state) => state.company.scores);
  const level = useTypedSelector((state) => state.company.level);
  const capsules = useTypedSelector((state) => state.capsules.items);
  const trainings = useTypedSelector((state) => state.trainings.items);
  const forms = useTypedSelector((state) => state.company.user?.forms);
  const showCertModal = useTypedSelector((state) => state.trainings.showCertModal);
  const closeCertModal = () => dispatch(onCloseCertModal());
  const capsulesLoading = useTypedSelector((state) => state.capsules.loading);
  const trainingsLoading = useTypedSelector((state) => state.trainings.loading);
  const loading = capsulesLoading || trainingsLoading;

  useEffect(() => {
    if (!forms && user?.status === Status.ACTIVE) dispatch(onGetForms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const futureTrainings = getFutureTrainings(trainings);
  const featuredTraining = getDashboardFeaturedTraining(trainings);
  const [nextTraining] = futureTrainings;

  let capsulesFiltered: ICapsule[] = [];

  const programUccelerator = programs.find((program) => program.type === CompanyType.UCCELERATOR);
  if (programUccelerator) {
    capsulesFiltered = capsules.filter((capsule) => capsule.programId === programUccelerator.id);
  }

  const completeForm = (form: IForm, values: any) => {
    const completedFormData = {
      formId: form.id,
      formAnswers: getFormQuestionsAndAnswers(form, values),
    };
    dispatch(onCompleteForms(completedFormData));
  };

  const onSubmit = (formData: ISubmitCodeData) => dispatch(onSubmitTrainingCode(formData));

  const isDataLoaded = !loading && !!company && !!capsules && !!user && !!trainings;

  return (
    <LayoutContainer forms={forms} section={Sections.DASHBOARD} completeForm={completeForm}>
      {!isDataLoaded && (
        <Modal disabled isOpen>
          <Loading />
        </Modal>
      )}
      {isDataLoaded && (
        <DashboardView
          capsules={capsulesFiltered}
          company={company}
          user={user}
          level={level}
          nextTraining={nextTraining}
          onSubmitTraining={onSubmit}
          featuredTraining={featuredTraining}
          scores={scores}
          forms={forms}
          completeForm={completeForm}
          showCertModal={showCertModal}
          closeCertModal={closeCertModal}
          trainings={trainings}
        />
      )}
    </LayoutContainer>
  );
};

export default DashboardContainer;
