import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import BusinessImage from 'assets/imgs/img-desarrollo-min.png';
import FinanceImage from 'assets/imgs/img-herram-financieras-min.png';
import ImproveImage from 'assets/imgs/img-sustentabilidad-min.png';
import LogisticsImage from 'assets/imgs/img-logistica-min.png';
import CultureImage from 'assets/imgs/img-cultura-min.png';
import DigitalImage from 'assets/imgs/img-digital-min.png';
import SpecialImage from 'assets/imgs/img-especial-min.png';

import { CapsuleType } from '../../types';
import { ICapsuleProps } from './types';
import {
  CapsuleImage,
  Container,
  Text,
  Title,
  DefaulContent,
  HoverContent,
  GradientBackground,
  HoverTitle,
  HoverContentTop,
  HoverContentInfoRow,
  ImproveIcon,
  BusinessIcon,
  CultureIcon,
  DigitalIcon,
  FinanceIcon,
  LogisticsIcon,
  Icon,
  SpecialIcon,
  HoverContentSpecialTitle,
  InfoDetailText,
  TextHover,
} from './styles';
import './i18n';
import { EditionsByCapsule, HoursByCapsule, ModulesByCapsule } from './helpers';

const Capsule: FunctionComponent<ICapsuleProps> = (props: ICapsuleProps) => {
  const { title, text, type } = props;
  const isSpecial = type === CapsuleType.SPECIAL;

  const getImageSrc = () => {
    switch (type) {
      case CapsuleType.IMPROVE:
        return ImproveImage;
      case CapsuleType.BUSINESS:
        return BusinessImage;
      case CapsuleType.FINANCE:
        return FinanceImage;
      case CapsuleType.LOGISTICS:
        return LogisticsImage;
      case CapsuleType.CULTURE:
        return CultureImage;
      case CapsuleType.DIGITAL:
        return DigitalImage;
      case CapsuleType.SPECIAL:
        return SpecialImage;
      default:
        return ImproveImage;
    }
  };

  const renderIcon = () => {
    switch (type) {
      case CapsuleType.IMPROVE:
        return <ImproveIcon />;
      case CapsuleType.BUSINESS:
        return <BusinessIcon />;
      case CapsuleType.FINANCE:
        return <FinanceIcon />;
      case CapsuleType.LOGISTICS:
        return <LogisticsIcon />;
      case CapsuleType.CULTURE:
        return <CultureIcon />;
      case CapsuleType.DIGITAL:
        return <DigitalIcon />;
      case CapsuleType.SPECIAL:
        return <SpecialIcon />;
      default:
        return <ImproveIcon />;
    }
  };

  const renderDefaultContent = () => (
    <>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </>
  );

  const renderContentOnHover = () => (
    <>
      <HoverContentTop>
        <HoverTitle>{title}</HoverTitle>
        <Icon type={type}>{renderIcon()}</Icon>
      </HoverContentTop>
      {isSpecial && (
        <HoverContentSpecialTitle>
          <HoverTitle>{i18next.t('CAPSULE:AGRO')}</HoverTitle>
        </HoverContentSpecialTitle>
      )}
      <HoverContentInfoRow isSpecial={isSpecial}>
        <TextHover isSpecial={isSpecial}>
          {isSpecial ? i18next.t('CAPSULE:DATE') : i18next.t('CAPSULE:EDITIONS')}
        </TextHover>
        <InfoDetailText>
          {isSpecial
            ? i18next.t('CAPSULE:AGRO_DATE')
            : EditionsByCapsule[type as keyof typeof EditionsByCapsule]}
        </InfoDetailText>
      </HoverContentInfoRow>
      <HoverContentInfoRow isSpecial={isSpecial}>
        <TextHover isSpecial={isSpecial}>{i18next.t('CAPSULE:MODULES')}</TextHover>
        <InfoDetailText>{ModulesByCapsule[type as keyof typeof ModulesByCapsule]}</InfoDetailText>
      </HoverContentInfoRow>
      <HoverContentInfoRow isSpecial={isSpecial}>
        <TextHover isSpecial={isSpecial}>{i18next.t('CAPSULE:HOURS')}</TextHover>
        <InfoDetailText>{HoursByCapsule[type as keyof typeof HoursByCapsule]}</InfoDetailText>
      </HoverContentInfoRow>
    </>
  );

  return (
    <Container data-testid="capsule-view">
      <CapsuleImage src={getImageSrc()} />
      <GradientBackground type={type} />
      <DefaulContent>{renderDefaultContent()}</DefaulContent>
      <HoverContent>{renderContentOnHover()}</HoverContent>
    </Container>
  );
};

export default Capsule;
