import { CompanyIdentifierType } from 'helpers/enums/companyIdentifierTypes';
import i18next from 'i18next';
import countries from 'helpers/json/countries.json';
import { MenuRoutes, userRoutesAuthorize } from 'helpers/enums/links';
import { IMenuRoutes } from 'components/main/Header/types';
import { IUser } from 'types/company.types';
import { CompanyRole } from 'views/SignUpView/types';
import { EMAIL_REGEX, PASSWORD_REGEX, PASSWORD_MINIMUM_LENGTH, CUIT_LENGTH } from './constants';

import './i18n';

export function requiredValidation(value: string): undefined | string {
  return value ? undefined : i18next.t('VALIDATIONS:REQUIRED_FIELD');
}

export function profileSelectField(value: string): undefined | string {
  return value !== i18next.t('VALIDATIONS:NOT_SPECIFIED')
    ? undefined
    : i18next.t('VALIDATIONS:REQUIRED_FIELD');
}

export function profileCompanyRole(value: number): undefined | string {
  return value !== CompanyRole.NOT_SPECIFIED ? undefined : i18next.t('VALIDATIONS:REQUIRED_FIELD');
}

export function emailValidation(value: string): undefined | string {
  return !!value && !EMAIL_REGEX.test(value)
    ? i18next.t('VALIDATIONS:INVALID_FIELD', {
        field: i18next.t('FIELDS:EMAIL').toLocaleLowerCase(),
      })
    : undefined;
}

export function emailConfirmationValidation(
  value: string,
  allValues: {
    email?: string;
    emailConfirmation?: string;
  },
): undefined | string {
  if (value !== allValues.email) {
    return i18next.t('VALIDATIONS:FIELD_NOT_MATCH', {
      field: i18next.t('FIELDS:EMAIL'),
    });
  }
  return emailValidation(value);
}

export function identifierTypeValidation(
  value: string,
  allValues: any,
  props: any,
): undefined | string {
  return !!value &&
    // eslint-disable-next-line no-restricted-globals
    isNaN(value as any) &&
    props.country.company.identifierType === CompanyIdentifierType.NUMERIC
    ? i18next.t('VALIDATIONS:INVALID_TYPE', {
        field: i18next.t('FIELDS:IDENTIFIER').toLocaleLowerCase(),
      })
    : undefined;
}

export function identifierLengthValidation(
  value: string,
  allValues: any,
  props: any,
): undefined | string {
  return !!value && value.length !== Number(props.country.company.identifierLength)
    ? i18next.t('VALIDATIONS:INVALID_FIELD_LENGTH', {
        length: props.country.company.identifierLength,
        field: i18next.t('FIELDS:IDENTIFIER').toLocaleLowerCase(),
      })
    : undefined;
}

export function cuitValidation(value: string): undefined | string {
  if (value.length !== CUIT_LENGTH) {
    return i18next.t('VALIDATIONS:INVALID_FIELD', {
      field: i18next.t('FIELDS:CUIT').toLocaleUpperCase(),
    });
  }

  const [checkDigit, ...rest] = value.split('').map(Number).reverse();

  const total = rest.reduce((acc, cur, index) => acc + cur * (2 + (index % 6)), 0);

  const mod11 = CUIT_LENGTH - (total % CUIT_LENGTH);

  if (mod11 === 0 || (mod11 !== checkDigit && checkDigit !== 0) || Number.isNaN(checkDigit)) {
    return i18next.t('VALIDATIONS:INVALID_FIELD', {
      field: i18next.t('FIELDS:CUIT').toLocaleUpperCase(),
    });
  }

  return undefined;
}

export function passwordValidation(value: string): undefined | string {
  if (!!value && value.length < PASSWORD_MINIMUM_LENGTH) {
    return i18next.t('VALIDATIONS:INVALID_FIELD_LENGTH', {
      field: i18next.t('FIELDS:PASSWORD'),
      length: PASSWORD_MINIMUM_LENGTH,
    });
  }

  return !PASSWORD_REGEX.test(value)
    ? i18next.t('VALIDATIONS:INVALID_PASSWORD_SPECIAL_CHARACTERS', {
        field: i18next.t('FIELDS:PASSWORD'),
      })
    : undefined;
}

export function passwordConfirmationValidation(
  value: string,
  allValues: {
    password?: string;
    passwordConfirmation?: string;
  },
): undefined | string {
  if (value !== allValues.password) {
    return i18next.t('VALIDATIONS:FIELD_NOT_MATCH', {
      field: i18next.t('FIELDS:PASSWORD'),
    });
  }
  return passwordValidation(value);
}

export function dateValidation(value: string): undefined | string {
  return !!value && new Date(value) >= new Date()
    ? i18next.t('VALIDATIONS:INVALID_FIELD', {
        field: i18next.t('FIELDS:DATE').toLocaleLowerCase(),
      })
    : undefined;
}

export function phoneNumberValidation(
  value: string,
  allValues: any,
  props: any,
): undefined | string {
  const country = countries.find((i) => i.name === props.country.name) || countries[0];
  return !!value &&
    (value.replace(/\D/g, '').length < country.phonePlaceholder.replace(/\D/g, '').length - 3 ||
      value.replace(/\D/g, '').length > country.phonePlaceholder.replace(/\D/g, '').length + 3)
    ? i18next.t('VALIDATIONS:INVALID_FIELD', {
        field: i18next.t('FIELDS:PHONE_NUMBER'),
      })
    : undefined;
}

export const getUserRoleMenuRoute = (user: IUser, menuRoutes: IMenuRoutes[]) => {
  return menuRoutes.filter((route) => {
    return (
      userRoutesAuthorize[user.role].includes(route.to as MenuRoutes) &&
      !(
        user.country.name === 'Colombia' &&
        (route.to === MenuRoutes.CERTIFICATES || route.to === MenuRoutes.BENEFITS)
      )
    );
  });
};
