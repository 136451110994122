import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import useTypedSelector from 'hooks/useTypedSelector';
import { onSubmitRegisterForm } from 'redux/actions/company.actions';

import LayoutContainer from 'containers/LayoutContainer';
import DashboardView from 'views/FormView';
import { IFormAnswer } from 'views/FormView/types';

const FormContainer: FunctionComponent = () => {
  const company = useTypedSelector((state) => state.company.data);
  const dispatch = useDispatch();

  const onSubmit = (formData: IFormAnswer[]) => dispatch(onSubmitRegisterForm({ form: formData }));

  return (
    <LayoutContainer isForm>
      <DashboardView company={company} onSubmit={onSubmit} />
    </LayoutContainer>
  );
};

export default FormContainer;
