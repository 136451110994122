export const COMPANY_ON_SUBMIT_REGISTER_FORM_REQUESTED =
  'COMPANY_ON_SUBMIT_REGISTER_FORM_REQUESTED';
export const COMPANY_ON_SUBMIT_REGISTER_FORM_SUCCEEDED =
  'COMPANY_ON_SUBMIT_REGISTER_FORM_SUCCEEDED';
export const COMPANY_ON_SUBMIT_REGISTER_FORM_FAILED = 'COMPANY_ON_SUBMIT_REGISTER_FORM_FAILED';

export const COMPANY_ON_GET_SCORES_REQUESTED = 'COMPANY_ON_GET_SCORES_REQUESTED';
export const COMPANY_ON_GET_SCORES_SUCCEEDED = 'COMPANY_ON_GET_SCORES_SUCCEEDED';
export const COMPANY_ON_GET_SCORES_FAILED = 'COMPANY_ON_GET_SCORES_FAILED';

export const COMPANY_ON_GET_LEVEL_REQUESTED = 'COMPANY_ON_GET_LEVEL_REQUESTED';
export const COMPANY_ON_GET_LEVEL_SUCCEEDED = 'COMPANY_ON_GET_LEVEL_SUCCEEDED';
export const COMPANY_ON_GET_LEVEL_FAILED = 'COMPANY_ON_GET_LEVEL_FAILED';
