import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import Button from 'components/commons/Button';
import { SIZES } from 'helpers/enums/sizes';

export const Form = styled.form`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
    gap: 15px;
  }
`;

export const FormButton = styled(Button)`
  padding: 10px 64px;
  background: ${COLORS.purple};

  :hover:not([disabled]) {
    background: ${COLORS.purpleHeart};
  }
`;

export const Subtitle = styled.h2`
  width: 100%;
  margin: 0 0 20px;
  font-size: 16px;
  font-family: Unilever;
  color: ${COLORS.bluePersian};
`;

export const SelectStateFieldContainer = styled.div`
  padding-top: 10px;
  min-height: 40px;
`;
