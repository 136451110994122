import { FunctionComponent } from 'react';

import { INewsDefaultState } from 'types/news.types';
import * as constants from 'redux/constants/news.constants';

const defaultState: INewsDefaultState = {
  items: [],
  loading: false,
};

const newsReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { data, type } = action;
  switch (type) {
    case constants.NEWS_ON_GET_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.NEWS_ON_GET_SUCCEEDED:
      return {
        ...state,
        items: data.news,
        loading: false,
      };
    case constants.NEWS_ON_GET_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default newsReducer;
