import React, { FunctionComponent, useState } from 'react';
import i18next from 'i18next';
import VisibilitySensor from 'react-visibility-sensor';
import { useMediaQuery } from 'react-responsive';

import { SIZES } from 'helpers/enums/sizes';
import LottiePlayer from 'components/commons/LottiePlayer';

import ObjectiveHorizontal from '../../animations/objective-horizontal.json';
import ObjectiveVertical from '../../animations/objective-vertical.json';

import { Container, Text, Title, TextContent, AnimationContainer } from './styles';
import './i18n';

const Objective: FunctionComponent = () => {
  const [isAnimationVisible, setAnimationVisible] = useState<boolean>(false);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${SIZES.smallTablet}px)`,
  });
  const isSmallDesktop = useMediaQuery({
    query: `(max-width: ${SIZES.smallTablet}px)`,
  });

  return (
    <Container data-testid="objective-view">
      <TextContent>
        <Title>{i18next.t('OBJECTIVE:TITLE')}</Title>
        <Text>{i18next.t('OBJECTIVE:TEXT_1')}</Text>
        <Text>{i18next.t('OBJECTIVE:TEXT_2')}</Text>
      </TextContent>
      <VisibilitySensor partialVisibility offset={{ top: 10 }}>
        {({ isVisible }) => {
          if (isVisible) setAnimationVisible(true);
          return (
            <AnimationContainer>
              {isDesktop && (
                <LottiePlayer
                  animationJsonPath={ObjectiveHorizontal}
                  autoplay={false}
                  loop={false}
                  play={isAnimationVisible}
                  still
                />
              )}
              {isSmallDesktop && (
                <LottiePlayer
                  animationJsonPath={ObjectiveVertical}
                  autoplay
                  loop={false}
                  play={isAnimationVisible}
                  still
                />
              )}
            </AnimationContainer>
          );
        }}
      </VisibilitySensor>
    </Container>
  );
};

export default Objective;
