export const TRAINING_ON_GET_ALL_REQUESTED = 'TRAINING_ON_GET_ALL_REQUESTED';
export const TRAINING_ON_GET_ALL_SUCCEEDED = 'TRAINING_ON_GET_ALL_SUCCEEDED';
export const TRAINING_ON_GET_ALL_FAILED = 'TRAINING_ON_GET_ALL_FAILED';

export const TRAINING_SUBMIT_CODE_REQUESTED = 'TRAINING_SUBMIT_CODE_REQUESTED';
export const TRAINING_SUBMIT_CODE_SUCCEEDED = 'TRAINING_SUBMIT_CODE_SUCCEEDED';
export const TRAINING_SUBMIT_CODE_FAILED = 'TRAINING_SUBMIT_CODE_FAILED';

export const TRAINING_MARK_AS_COMPLETE_REQUESTED = 'TRAINING_MARK_AS_COMPLETE_REQUESTED';
export const TRAINING_MARK_AS_COMPLETE_SUCCEEDED = 'TRAINING_MARK_AS_COMPLETE_SUCCEEDED';
export const TRAINING_MARK_AS_COMPLETE_FAILED = 'TRAINING_MARK_AS_COMPLETE_FAILED';

export const TRAINING_ON_GET_ONE_REQUESTED = 'TRAINING_ON_GET_ONE_REQUESTED';
export const TRAINING_ON_GET_ONE_SUCCEEDED = 'TRAINING_ON_GET_ONE_SUCCEEDED';
export const TRAINING_ON_GET_ONE_FAILED = 'TRAINING_ON_GET_ONE_FAILED';

export const TRAINING_ON_CLOSE_CERT_MODAL_REQUESTED = 'TRAINING_ON_CLOSE_CERT_MODAL_REQUESTED';

export const TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_REQUESTED =
  'TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_REQUESTED';
export const TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_SUCCEEDED =
  'TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_SUCCEEDED';
export const TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_FAILED =
  'TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_FAILED';
