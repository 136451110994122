import { FormControl, Select } from '@material-ui/core';
import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const CustomSelect = styled(Select)`
  height: fit-content;
  width: 100%;
  color: ${COLORS.riverBed};
  background: ${({ darkMode }: { darkMode?: boolean; error: boolean }) =>
    darkMode ? 'transparent' : COLORS.white};
  font-family: OpenSans;
  font-size: 14px;
  border: ${({ darkMode }: { darkMode?: boolean; error: boolean }) =>
    darkMode ? 'transparent' : `1px solid ${COLORS.grayFrench}`};

  svg {
    fill: ${({ error, darkMode }: { darkMode?: boolean; error: boolean }) => {
      if (error) {
        return 'red';
      }
      return darkMode ? COLORS.white : COLORS.bluePersian;
    }};
  }
`;

export const FormGroup = styled(FormControl)`
  flex-direction: column;
  padding-top: 10px;
  margin-bottom: 30px;
  width: 100%;

  &:last-child {
    margin-top: 20px;
  }

  &:first-child {
    margin-top: 0;
  }
`;

export const ErrorBlock = styled.div`
  width: 100%;
  position: absolute;
  bottom: -18px;
  font-size: 13px;
  font-family: OpenSans;
  line-height: 1.5;
  color: ${COLORS.red};
  text-align: left;

  @media (max-width: ${SIZES.mediumPhone}px) {
    font-size: 12px;
  }
`;
