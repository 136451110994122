import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import { IFormAnswer, IFormQuestion } from 'views/FormView/types';
import useTypedSelector from 'hooks/useTypedSelector';
import { mergeObjects } from 'helpers/mergeObjects';
import { getItem, setItem } from 'helpers/storage';

import FormInstructions from '../FormInstructions';
import FormInputs from '../FormInputs';
import { IFormQuestionProps } from './types';
import { Container, Background } from './styles';

const FormQuestion: FunctionComponent<IFormQuestionProps> = (props: IFormQuestionProps) => {
  const { form, currentTab, nextTab, onSubmit } = props;
  const formAnswers = useTypedSelector((state) => state.form.questions);
  const [currentGroup, setCurrentGroup] = useState<IFormQuestion[]>(form[currentTab].questions);
  const localAnswers = getItem('answers') || '{}';
  const isBeginForm = currentTab === 0;
  const isEndForm = currentTab === form[form.length - 1].id;
  const nextTabIndex = currentTab + 1 > form.length - 1 ? form.length : currentTab + 1;
  const answers = useRef<{ questionId: number; answer: any }[]>([]);

  useEffect(() => {
    setCurrentGroup(() => form[currentTab].questions);
  }, [currentTab, form]);

  useEffect(() => {
    if (formAnswers?.values) setItem('answers', JSON.stringify(formAnswers?.values));
  }, [formAnswers?.values]);

  const formatAnswers = (): {
    questionId: number;
    answer: any;
  }[] => {
    const questions = Object.keys(formAnswers.values);
    const repeatedIds: IFormAnswer[] = [];
    const formatedAnswers = questions
      .filter((key) => {
        // Get question id -> id#question#option
        const [id, , option] = key.split('#');
        if (!option) {
          return true;
        }
        const intId = parseInt(id, 10);
        repeatedIds.push({ questionId: intId, answer: { [option]: formAnswers.values[key] } });
        return false;
      })
      .map((key) => {
        // Get question id -> id#question#option
        const [id, ,] = key.split('#');
        const intId = parseInt(id, 10);
        return { questionId: intId, answer: formAnswers.values[key] };
      });
    let mergedObject: IFormAnswer;
    repeatedIds.forEach((item, index) => {
      mergedObject = _.mergeWith(mergedObject, item, mergeObjects);
      if (mergedObject.questionId) formatedAnswers.push({ ...mergedObject });
      else if (index === repeatedIds.length - 1) {
        const exist = formatedAnswers.find((ans) => ans.questionId === item.questionId);
        if (!exist) formatedAnswers.push({ ...item });
      } else mergedObject = item;
    });
    return formatedAnswers;
  };

  const submitForm = () => {
    onSubmit(answers.current);
  };

  const saveAnswers = () => {
    if (nextTabIndex === form.length - 1) answers.current = formatAnswers();
    nextTab();
  };

  return (
    <Background>
      <Container data-testid="form-question-view">
        {isBeginForm || isEndForm ? (
          <FormInstructions
            isFirstInstruction={isBeginForm}
            onClick={isBeginForm ? nextTab : submitForm}
            nextTabName={form[nextTabIndex]?.title}
          />
        ) : (
          <FormInputs
            questions={currentGroup}
            nextTab={saveAnswers}
            nextTabName={form[nextTabIndex].title}
            initialValues={JSON.parse(localAnswers)}
          />
        )}
      </Container>
    </Background>
  );
};

export default FormQuestion;
