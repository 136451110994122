import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import CapsuleIcon from 'components/commons/CapsuleIcon';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import { ICapsuleProps } from './types';
import {
  CapsuleContainer,
  Title,
  Text,
  RowDiv,
  PointsContainer,
  TextDescription,
  TextContainer,
  Icon,
  ColumnDiv,
  Theme,
  CapsuleButton,
} from './styles';
import './i18n';

const Capsule: FunctionComponent<ICapsuleProps> = (props: ICapsuleProps) => {
  const {
    capsule: { primaryColor, trainings, title, description },
    onClick,
    userId,
  } = props;
  let countCompleted = 0;
  const capsuleTrainings = trainings[new Date().getFullYear()];

  capsuleTrainings.forEach((training) => {
    if (training.completedTrainings.length) {
      training.completedTrainings.forEach((completedTraining) => {
        if (completedTraining.userId === userId) countCompleted += 1;
      });
    }
  });

  const newTitle = title.replace('#Ucc', '');

  return (
    <CapsuleContainer color={primaryColor}>
      <CapsuleButton type="button" onClick={onClick}>
        <RowDiv>
          <MuiThemeProvider theme={Theme}>
            <Tooltip title={newTitle} placement="top-start" arrow>
              <Title>{newTitle}</Title>
            </Tooltip>
          </MuiThemeProvider>
          <Icon>
            <CapsuleIcon isWhite name={newTitle} />
          </Icon>
        </RowDiv>
        <TextDescription>{description}</TextDescription>
        <PointsContainer>
          <RowDiv>
            <ColumnDiv>
              <TextContainer>
                <Text isBold>{countCompleted}</Text>
                <Text>
                  {i18next.t('PROGRAMS_VIEW_CAPSULE:CANT_TRAININGS')} {capsuleTrainings.length}
                </Text>
              </TextContainer>
            </ColumnDiv>
          </RowDiv>
        </PointsContainer>
      </CapsuleButton>
    </CapsuleContainer>
  );
};

export default Capsule;
