import React, { FunctionComponent, useState } from 'react';

import { CompanyStatus } from 'helpers/enums/status';
import { getItem, setItem } from 'helpers/storage';

import FormTab from './components/FormTab';
import FormQuestion from './components/FormQuestion';
import { IFormViewProps } from './types';
import { FormContainer } from './styles';
import form from './form_v2.json';
import Hero from './components/Hero';

const FormView: FunctionComponent<IFormViewProps> = (props: IFormViewProps) => {
  const { company, onSubmit } = props;
  const localCurrentTab = getItem('currentTab') || '0';
  const [currentTab, setCurrentTab] = useState<number>(parseInt(localCurrentTab, 10));

  const nextTab = () => {
    setItem('currentTab', (currentTab + 1).toString());
    setCurrentTab((prev) => prev + 1);
  };

  const renderComponent = (): JSX.Element | null => {
    if (company) {
      if (company.status === CompanyStatus.COMPLETE_FORM) {
        return (
          <>
            <FormTab tabs={form} currentTab={currentTab} />
            <FormQuestion
              form={form}
              currentTab={currentTab}
              nextTab={nextTab}
              onSubmit={onSubmit}
            />
          </>
        );
      }
      return <Hero data-testid="hero-view" status={company.status} />;
    }
    return <FormContainer data-testid="form-view" />;
  };

  return renderComponent();
};

export default FormView;
