import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IHeaderContainerProps } from 'types/header.types';
import useTypedSelector from 'hooks/useTypedSelector';
import { onMenuCollapsed } from 'redux/actions/header.actions';
import { SIZES } from 'helpers/enums/sizes';
import { DashboardLinks, LandingLinks } from 'helpers/enums/links';

import Header from 'components/main/Header';
import { onLogout } from 'redux/actions/user.actions';
import { getUserRoleMenuRoute } from 'helpers/validations';
import pendingBenefitsSelector from 'redux/selectors/benefits.selector';

const HeaderContainer: FunctionComponent<IHeaderContainerProps> = (
  props: IHeaderContainerProps,
) => {
  const { isForm, isLanding } = props;
  const dispatch = useDispatch();
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const company = useTypedSelector((state) => state.company.data);
  const isMenuCollapsed = useTypedSelector((state) => state.header.isMenuCollapsed);
  const user = useTypedSelector((state) => state.company.user);
  const pendingBenefits = useSelector(pendingBenefitsSelector());

  useEffect(() => {
    function handleResize(): void {
      if (isMenuOpened && window.innerWidth > SIZES.mediumTablet) {
        setIsMenuOpened(false);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const onCollapseClick = () => {
    dispatch(onMenuCollapsed());
  };

  const onLogoutClick = () => {
    setIsMenuOpened(false);
    dispatch(onLogout());
  };

  const toggleMenu = () => setIsMenuOpened(!isMenuOpened);

  return (
    <>
      {!isForm && (
        <Header
          isMenuOpened={isMenuOpened}
          isMenuCollapsed={isMenuCollapsed}
          toggleMenu={toggleMenu}
          collapseMenu={onCollapseClick}
          company={company}
          user={user}
          isLanding={isLanding}
          links={!isLanding && user ? getUserRoleMenuRoute(user, DashboardLinks) : LandingLinks}
          onLogoutClick={onLogoutClick}
          pendingBenefits={pendingBenefits}
        />
      )}
    </>
  );
};

export default HeaderContainer;
