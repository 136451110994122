import React, { ChangeEvent, FunctionComponent } from 'react';

import { IRadioGroupProps } from './types';
import { FormGroup, ErrorBlock, CustomRadioGroup, useStyles } from './styles';

const RadioGroup: FunctionComponent<IRadioGroupProps> = (props: IRadioGroupProps) => {
  const {
    input,
    meta: { touched, error },
    className = '',
    ...otherProps
  } = props;
  const { onChange, value } = input;
  const hasError = touched && !!error;
  const classes = useStyles();

  return (
    <FormGroup data-testid="custom-radiogroup">
      <CustomRadioGroup
        className={className}
        classes={{ root: classes.root }}
        data-testid="material-radiogroup"
        {...input}
        {...otherProps}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        value={value}
      />
      {hasError && <ErrorBlock data-testid="error-block">{error}</ErrorBlock>}
    </FormGroup>
  );
};

export default RadioGroup;
