import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import imageWhatIs from 'assets/imgs/img-what-is.png';

import {
  Container,
  Text,
  Info,
  Title,
  TextContent,
  TextContainer,
  Image,
  ListIcon,
  ListContainer,
  ListItemContainer,
  BoldText,
  LastTextContent,
} from './styles';
import './i18n';

const WhatIs: FunctionComponent = () => (
  <Container data-testid="whatis-view" id="what-is">
    <Title>{i18next.t('WHATIS:TITLE')}</Title>
    <TextContainer>
      <Info isAligned>
        <Image src={imageWhatIs} />
      </Info>
      <Info>
        <TextContent>
          <Text>{i18next.t('WHATIS:FIRST_TEXT_1')}</Text>
          <BoldText>{i18next.t('WHATIS:UNILEVER')}</BoldText>
          <Text>{i18next.t('WHATIS:FIRST_TEXT_2')}</Text>
        </TextContent>
        <TextContent>
          <Text>{i18next.t('WHATIS:SECOND_TEXT')}</Text>
        </TextContent>
        <ListContainer>
          <ListItemContainer>
            <ListIcon />
            <Text>{i18next.t('WHATIS:FIRST_ITEM')}</Text>
          </ListItemContainer>
          <ListItemContainer>
            <ListIcon />
            <Text>{i18next.t('WHATIS:SECOND_ITEM')}</Text>
          </ListItemContainer>
          <ListItemContainer>
            <ListIcon />
            <Text>{i18next.t('WHATIS:THIRD_ITEM')}</Text>
          </ListItemContainer>
        </ListContainer>
        <LastTextContent>
          <BoldText isLast>{i18next.t('WHATIS:UNIPYME')}</BoldText>
          <Text isLast>{i18next.t('WHATIS:THIRD_TEXT_1')}</Text>
          <BoldText isLast>{i18next.t('WHATIS:UNILEVER')}</BoldText>
          <Text isLast>{i18next.t('WHATIS:THIRD_TEXT_2')}</Text>
          <BoldText isLast>{i18next.t('WHATIS:PYMES')}</BoldText>
          <Text isLast>{i18next.t('WHATIS:THIRD_TEXT_3')}</Text>
        </LastTextContent>
      </Info>
    </TextContainer>
  </Container>
);

export default WhatIs;
