import * as constants from 'redux/constants/training.constants';

export function onSubmitTrainingCode(formData: any): { type: string; formData: any } {
  return {
    type: constants.TRAINING_SUBMIT_CODE_REQUESTED,
    formData,
  };
}

export function onMarkTrainingComplete(trainingId: string): { type: string; trainingId: string } {
  return {
    type: constants.TRAINING_MARK_AS_COMPLETE_REQUESTED,
    trainingId,
  };
}

export function onCloseCertModal(): { type: string } {
  return {
    type: constants.TRAINING_ON_CLOSE_CERT_MODAL_REQUESTED,
  };
}

export function onGetVersionTrainings(
  capsuleId: string,
  version: string,
): { type: string; capsuleId: string; version: string } {
  return {
    type: constants.TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_REQUESTED,
    capsuleId,
    version,
  };
}

export function onGetOneTraining(trainingId: string): { type: string; trainingId: string } {
  return {
    type: constants.TRAINING_ON_GET_ONE_REQUESTED,
    trainingId,
  };
}
