import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

import Button from 'components/commons/Button';

export const ErrorContainer = styled.div`
  width: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const ErrorBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.h3`
  color: ${COLORS.riverBed};
  font: 20px/1.3 'OpenSansBold';
  text-align: center;
`;

export const StartButton = styled(Button)`
  background: ${COLORS.blueResolution};
  font: 14px/1 'OpenSansBold';
  padding: 15px 35px;
`;

export const Link = styled.a`
  margin: 0;
  padding: 0;
`;

export const Image = styled.img`
  width: 35%;
  min-width: 250px;
`;
