import styled, { css } from 'styled-components';
import Modal from 'react-modal';

import { SIZES } from 'helpers/enums/sizes';
import { COLORS } from 'helpers/enums/colors';
import { ReactComponent as PlayPauseSVG } from 'assets/imgs/play-pause.svg';

import { ReactComponent as CloseModalSVG } from './assets/close-modal.svg';

export const ModalContainer = styled(Modal)`
  && {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    outline: none;
    max-width: 500px;

    @media (max-width: ${SIZES.bigPhone}px) {
      max-width: 100%;
      margin: 0 20px;
    }
  }
`;

export const ModalContent = styled.div`
  background-color: ${({ hasInvisibleBackground }: { hasInvisibleBackground?: boolean }) =>
    hasInvisibleBackground ? 'none' : COLORS.white};
  border-radius: 6px;
  max-height: 90vh;
  overflow: scroll;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

export const CloseModalContainer = styled.div`
  ${({ hasInvisibleBackground }: { hasInvisibleBackground: boolean }) =>
    hasInvisibleBackground
      ? css`
          position: fixed;
          right: 0;
          top: 0;
          padding: 20px 20px 0 0;
        `
      : css`
          position: absolute;
          right: 0;
          padding-right: 8px;
          padding-top: 8px;
        `};
`;

export const CloseModalIcon = styled(CloseModalSVG)`
  cursor: pointer;
  ${({ isDark }: { isDark?: boolean }) =>
    isDark &&
    `
      g {
        fill: ${COLORS.riverBed};
      }
      `};
`;

export const ChildrenContainer = styled.div`
  padding: ${({ hasPadding }: { hasPadding: boolean }) => (hasPadding ? '0 30px 30px' : '0 30px')};
  z-index: 1000;

  @media (max-width: ${SIZES.mediumPhone}px) {
    padding: 15px;
  }
`;

export const TitleContainer = styled.div`
  background: ${COLORS.purple};
  color: white;
  font: 32px/26px 'UnileverBold';
  padding: 31px 26px;
`;

export const PlayPause = styled(PlayPauseSVG)`
  width: 15%;
  height: 15%;
  cursor: pointer;

  @media (max-width: ${SIZES.mediumPhone}px) {
    width: 25%;
    height: 25%;
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
  color: white;
`;

export const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: ${({ hasInvisibleBackground }: { hasInvisibleBackground: boolean }) =>
    hasInvisibleBackground ? '1' : '-1'};
  background: transparent;
`;
