import { FunctionComponent } from 'react';

import { ITestimonialsDefaultState } from 'types/testimonials.types';
import * as constants from 'redux/constants/testimonials.constants';

const defaultState: ITestimonialsDefaultState = {
  items: [],
};

const testimonialsReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { data, type } = action;
  switch (type) {
    case constants.TESTIMONIALS_ON_GET_REQUESTED:
      return {
        ...state,
      };
    case constants.TESTIMONIALS_ON_GET_SUCCEEDED:
      return {
        ...state,
        items: data.testimonials,
      };
    case constants.TESTIMONIALS_ON_GET_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default testimonialsReducer;
