import * as userConstants from 'redux/constants/user.constants';
import history from 'helpers/history';
import { Role } from 'helpers/enums/roles';
import { MenuRoutes } from 'helpers/enums/links';
import { WWW_URL } from 'configs/configs';

const redirectMiddleware = (state: any) => (next: any) => (action: any) => {
  const { type } = action;
  switch (type) {
    case userConstants.USER_ON_CONFIRM_ACCOUNT_SUCCEEDED:
      setTimeout(() => history.push('/dashboard'), 0);
      break;
    case userConstants.USER_ON_LOGIN_SUCCEEDED:
      // eslint-disable-next-line no-case-declarations
      const path = action.from ? action.from : '/dashboard';
      setTimeout(() => history.push(path), 0);
      break;
    case userConstants.USER_ON_FORGOT_PASSWORD_SUCCEEDED:
    case userConstants.USER_ON_RESET_PASSWORD_SUCCEEDED:
      history.push('/login');
      break;
    case userConstants.USER_ON_LOGOUT_SUCCEEDED:
      window.location.replace(`${WWW_URL}`);
      break;
    case userConstants.USER_ON_CONFIRM_ACCOUNT_FAILED:
    case userConstants.USER_ON_ACCEPT_ACCOUNT_FAILED:
    case userConstants.USER_ON_ACCEPT_ACCOUNT_SUCCEEDED:
    case userConstants.USER_ON_CONFIRM_ASSISTANCE_FAILED:
      history.push(`/`);
      break;
    case userConstants.USER_ON_REGISTER_SUCCEEDED:
      history.push('/confirm-signup');
      break;
    case userConstants.COMPANY_ON_REGISTER_SUCCEEDED:
      history.push('/confirm-signup');
      break;
    case userConstants.USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_SUCCEEDED:
      history.push('/confirm-email-account');
      break;
    case userConstants.USER_ON_INITIALIZE_EMPLOYEE_REQUESTED:
      // eslint-disable-next-line no-case-declarations
      const userActive = state.getState().company.user;
      if (userActive?.role === Role.COMPANY_USER) {
        history.push('/dashboard');
      }
      break;
    case userConstants.USER_ON_REDIRECT_TO_BENEFITS:
      history.push(MenuRoutes.BENEFITS);
      break;
    case userConstants.USER_ON_REDIRECT_TO_PROFILE:
      history.push('/perfil');
      break;
    default:
      break;
  }
  return next(action);
};

export default redirectMiddleware;
