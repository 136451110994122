import React, { FunctionComponent } from 'react';
import { HashLink } from 'react-router-hash-link';
import i18next from 'i18next';

import { IFormInstructionsProps } from './types';
import { Container, TextContainer, Text, Bold, Button, Legal } from './styles';
import './i18n';

const FormInstructions: FunctionComponent<IFormInstructionsProps> = (
  props: IFormInstructionsProps,
) => {
  const { isFirstInstruction, onClick, nextTabName } = props;

  const scroll = (el: HTMLElement) => el.scrollIntoView(false);

  return (
    <Container data-testid="form-instruction-view">
      {isFirstInstruction ? (
        <>
          <TextContainer>
            <Text>
              {i18next.t('FORM_INSTRUCTIONS:FIRST_1')}
              <Bold>{i18next.t('FORM_INSTRUCTIONS:FIRST_BOLD_1')}</Bold>
            </Text>
          </TextContainer>
          <TextContainer>
            <Bold>{i18next.t('FORM_INSTRUCTIONS:FIRST_2')}</Bold>
          </TextContainer>
        </>
      ) : (
        <>
          <TextContainer>
            <Bold>{i18next.t('FORM_INSTRUCTIONS:LAST_BOLD')}</Bold>
          </TextContainer>
          <TextContainer>
            <Text>{i18next.t('FORM_INSTRUCTIONS:LAST')}</Text>
          </TextContainer>
        </>
      )}
      <HashLink to={`#${nextTabName || ''}`} scroll={scroll} data-testid="link-form-instructions">
        <Button onClick={onClick} data-testid="button-form-instructions">
          {i18next.t(`FORM_INSTRUCTIONS:${isFirstInstruction ? 'FIRST_BUTTON' : 'LAST_BUTTON'}`)}
        </Button>
      </HashLink>
      <Legal>{i18next.t('FORM_INSTRUCTIONS:LEGAL')}</Legal>
    </Container>
  );
};

export default FormInstructions;
