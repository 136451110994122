import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import i18next from 'i18next';

import history from 'helpers/history';
import {
  isInTheFuture,
  isLessThanOneHourAndHalfAfter,
  isLessThanTenMinBefore,
} from 'helpers/dateCalculations';
import Modal from 'components/commons/Modal';
import SubmitCode from 'components/commons/SubmitCode';
import { MenuRoutes } from 'helpers/enums/links';
import PopupForm from 'components/commons/PopupForm';
import { getFormByType, splitFormQuestionsInPages } from 'helpers/forms';
import Button from 'components/commons/Button';
import { isFutureLiveTraining } from 'helpers/trainings';
import { SIZES } from 'helpers/enums/sizes';

import defaultTrainingDetailVideoPreview from './assets/defaultTrainingDetailVideoPreview.jpg';
import { ITrainingDetailViewProps } from './types';
import {
  TrainingContainer,
  TrainingInfo,
  SpeakerInfo,
  TrainingTitle,
  TrainingBody,
  Divider,
  SpeakerLabel,
  SpeakerTitle,
  Code,
  ModalVideo,
  CustomReactPlayer,
  PlayerContainer,
  PlayPauseDiv,
  ModalContentContainer,
  ModalButtonContainer,
  SurveyContainer,
  JSVideoBackground,
  CloseModalContainer,
  CloseModalIcon,
  Survey,
  SpeakerPhotoContainer,
  RightContent,
  ButtonContainer,
  BackButton,
  SpeakerBody,
  VideoPreview,
  PlayVideoButton,
  Triangle,
  PreviewContainer,
  DefaultText,
  TrainingTitleText,
  GrayLine,
  LengthContainer,
  IconClock,
  ViewContainer,
} from './styles';
import './i18n';

const TrainingDetailView: FunctionComponent<ITrainingDetailViewProps> = (
  props: ITrainingDetailViewProps,
) => {
  const {
    training,
    onSubmit,
    onMarkAsCompleted,
    codeSubmitted,
    points,
    forms,
    completeForm,
    showCertModal,
    closeCertModal,
    openWithVideo,
    currentCapsuleName,
  } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const isMobile = windowWidth <= SIZES.desktop;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [modalVideoOpen, setModalVideoOpen] = useState<boolean>(false);
  const [play, setPlay] = useState<boolean>(false);
  const [openJSVideo, setOpenJSVideo] = useState<boolean>(false);

  const form = getFormByType(forms, undefined, training);

  const parseDate = new Date(training.date);
  const now = new Date();
  const isHorizontal = true;

  const isLiveTraining = !!training.linkToMeet;
  const showButtonToMeet =
    isLessThanTenMinBefore(now, parseDate) || isLessThanOneHourAndHalfAfter(now, parseDate);
  const isFuture = isFutureLiveTraining(now, parseDate);
  const isSoon = isLiveTraining ? isFuture && !showButtonToMeet : isInTheFuture(now, parseDate);
  const showPreview =
    ReactPlayer.canPlay(training.linkToVideo) || training.linkToVideo.includes('.js');

  const toggleVideo = () => {
    setModalVideoOpen((prev) => !prev);
    setPlay(false);
  };

  const playPause = () => {
    setPlay((prev) => !prev);
  };

  const onClose = () => {
    setModalOpen(() => false);
  };

  const toggleForm = () => {
    setFormOpen((prev) => !prev);
  };

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const toggleJSVideo = () => {
    setOpenJSVideo((prev) => !prev);
  };

  const onCloseModalVideo = () => {
    setModalVideoOpen(false);
  };

  const submitForm = (values: any) => {
    if (form) completeForm(form, values);
    toggleForm();
    toggleModal();
  };

  const onClickCertModalButton = () => {
    history.replace(MenuRoutes.CERTIFICATES);
    closeCertModal();
  };

  const loadJSEmbed = (src: string) => {
    const existingWistiaPlayerScript = document.getElementById('wistia-script');
    if (!existingWistiaPlayerScript) {
      const wistiaPlayerScript = document.createElement('script');
      wistiaPlayerScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaPlayerScript.id = 'wistia-script';
      document.body.appendChild(wistiaPlayerScript);
      const wistiaVideoScript = document.createElement('script');
      wistiaVideoScript.src = src;
      wistiaVideoScript.id = 'external-script';
      document.body.appendChild(wistiaVideoScript);
    }
  };

  useEffect(() => {
    if (openJSVideo) {
      loadJSEmbed(training.linkToVideo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openJSVideo]);

  useEffect(() => {
    if (openWithVideo) {
      if (training.linkToVideo.includes('.js')) {
        toggleJSVideo();
      } else if (ReactPlayer.canPlay(training.linkToVideo)) {
        toggleVideo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openWithVideo]);

  // Listener to automatically give viewer its coins
  useEffect(() => {
    document.addEventListener('hasReachedWatchedCompletionThreshold', onMarkAsCompleted);

    return () => {
      document.removeEventListener('hasReachedWatchedCompletionThreshold', onMarkAsCompleted);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickJSVideoCloseButton = () => {
    let currentLocation = history.location.pathname;
    currentLocation = currentLocation.replace('/open', '');
    history.replace(currentLocation);
    window.location.reload();
  };

  const renderVideoModals = () => (
    <>
      {openJSVideo && (
        <>
          <JSVideoBackground />
          <CloseModalContainer>
            <CloseModalIcon data-testid="modal-close-button" onClick={onClickJSVideoCloseButton} />
          </CloseModalContainer>
          <div
            className="wistia_embed"
            id="wistia_embed"
            style={{
              height: 'calc(90vw * 9/18)',
              width: '90vw',
              transform: 'translate(-50%, -50%)',
              position: 'fixed',
              top: '50%',
              zIndex: 6,
              left: '50%',
            }}
          />
          <SurveyContainer id="survey-container">
            <Survey frameBorder="0" title="survey" id="survey" />
          </SurveyContainer>
        </>
      )}
      <ModalVideo
        isOpen={modalVideoOpen}
        onClose={onCloseModalVideo}
        hasInvisibleBackground
        data-testid="hero-modal-view"
      >
        <PlayerContainer>
          <PlayPauseDiv onClick={playPause} />
          <CustomReactPlayer
            url={training.linkToVideo}
            playing={play}
            width="100%"
            height="85%"
            config={{
              youtube: {
                playerVars: { rel: 0 },
              },
            }}
          />
        </PlayerContainer>
      </ModalVideo>
    </>
  );

  const renderCertModal = () => (
    <Modal
      isOpen={showCertModal && !openJSVideo}
      onClose={closeCertModal}
      title={i18next.t('TRAINING_DETAIL_VIEW:CERT_MODAL_TITLE')}
    >
      <ModalContentContainer>
        {i18next.t('TRAINING_DETAIL_VIEW:CERT_MODAL_DESCRIPTION')}
        <ModalButtonContainer>
          <Button onClick={onClickCertModalButton}>
            {i18next.t('TRAINING_DETAIL_VIEW:CERT_MODAL_BUTTON')}
          </Button>
        </ModalButtonContainer>
      </ModalContentContainer>
    </Modal>
  );

  const renderForm = () =>
    form && (
      <PopupForm
        title={form.title}
        description={form.description}
        questionsPaginated={splitFormQuestionsInPages(form.question)}
        formOpen={formOpen}
        submitForm={submitForm}
        onCloseForm={toggleForm}
      />
    );

  const renderSubmitCodeModal = () => (
    <Modal isDark isOpen={modalOpen} onClose={onClose}>
      <SubmitCode
        onSubmit={onSubmit}
        id={training.id}
        closeModal={onClose}
        capsuleId={training.capsuleId}
        version={training.version}
      />
    </Modal>
  );

  return (
    <>
      {renderCertModal()}
      {renderForm()}
      {renderSubmitCodeModal()}
      {renderVideoModals()}
      <ViewContainer data-testid="training-detail-view">
        <ButtonContainer data-testid="back-button">
          <BackButton onClick={() => history.push(`${MenuRoutes.CAPSULE}/${training.capsuleId}`)} />
          <GrayLine />
          <DefaultText>{i18next.t('TRAINING_DETAIL_VIEW:HOME')}</DefaultText>
          <GrayLine />
          <DefaultText>{currentCapsuleName}</DefaultText>
          <GrayLine isLast />
          <TrainingTitleText>{isMobile ? currentCapsuleName : training.title}</TrainingTitleText>
        </ButtonContainer>
        <TrainingContainer>
          <TrainingInfo>
            <TrainingTitle> {training.title} </TrainingTitle>
            {training.videoLength && (
              <LengthContainer>
                <IconClock />
                {training.videoLength}
              </LengthContainer>
            )}
            {showPreview && (
              <PreviewContainer>
                <VideoPreview
                  videoPreview={training.videoPreview || defaultTrainingDetailVideoPreview}
                >
                  <PlayVideoButton
                    onClick={training.linkToVideo.includes('.js') ? toggleJSVideo : toggleVideo}
                  >
                    <Triangle />
                  </PlayVideoButton>
                </VideoPreview>
              </PreviewContainer>
            )}
            {!showPreview && (
              <TrainingBody>
                <b>{i18next.t('TRAINING_DETAIL_VIEW:NO_VIDEO')}</b>
              </TrainingBody>
            )}
            <TrainingBody>
              {training.description.length > 0
                ? training.description
                : i18next.t('TRAINING_DETAIL_VIEW:NO_TRAINING_DESCRIPTION')}
            </TrainingBody>
          </TrainingInfo>
          <RightContent>
            <SpeakerInfo>
              <SpeakerPhotoContainer speakerPhoto={training.speakerPhoto} />
              <SpeakerLabel>{i18next.t('TRAINING_DETAIL_VIEW:SPEECH_FROM')}</SpeakerLabel>
              <SpeakerTitle>{training.speaker}</SpeakerTitle>
              <Divider isHorizontal={isHorizontal} />
              <SpeakerBody>
                {training.speakerDescription.length > 0
                  ? training.speakerDescription
                  : i18next.t('TRAINING_DETAIL_VIEW:NO_SPEAKER_DESCRIPTION')}
              </SpeakerBody>
            </SpeakerInfo>
            {codeSubmitted ? (
              <Code alreadySubmited>
                {i18next.t('TRAINING_DETAIL_VIEW:POINTS', {
                  points,
                })}
              </Code>
            ) : (
              !isSoon && (
                <Code
                  alreadySubmited={codeSubmitted}
                  onClick={form ? toggleForm : toggleModal}
                  data-testid="toggle-button"
                >
                  {i18next.t('TRAINING_DETAIL_VIEW:CODE')}
                </Code>
              )
            )}
          </RightContent>
        </TrainingContainer>
      </ViewContainer>
    </>
  );
};

export default TrainingDetailView;
