import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'CONTACT_FORM', {
  TITLE: 'CONTACTO',
  FIRST_NAME: 'Nombre*',
  LAST_NAME: 'Apellido*',
  PHONE_NUMBER: 'Teléfono*',
  EMAIL: 'E-mail*',
  MESSAGE: 'Mensaje',
  SELECT_COUNTRY: 'País',
});
