import React, { FunctionComponent, useState } from 'react';
import i18next from 'i18next';

import BenefitsForm from 'components/commons/BenefitsForm';
import Modal from 'components/commons/Modal';
import { Role } from 'helpers/enums/roles';

import { IBenefitProps } from './types';
import {
  ActivateModalButton,
  AvailableBenefitContainer,
  BenefitContainer,
  SmallGrayText,
  BenefitDesciptionContainer,
  BenefitImage,
  ImageContainer,
  Container,
  CoinIcon,
  CoinPrice,
  CoinPriceContainer,
  CongratulationsGift,
  ModalContent,
  NotEnoughCoinsContainer,
  NotEnoughCoinsText,
  ImageContainerModal,
  BenefitImageContainer,
  ButtonsContainer,
  CancelButton,
  CongratulationsDescriptionContainer,
  ActivateButton,
  Title,
  Subtitle,
  ModalDescription,
} from './styles';
import './i18n';

const Benefit: FunctionComponent<IBenefitProps> = (props) => {
  const { benefit, company, user, activateBenefit, requestOwnerActivationBenefit } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [openRequestBenefitForm, setOpenRequestBenefitForm] = useState<boolean>(false);

  const toggleModal = () => setModalOpen((prev) => !prev);
  const toggleRequestModal = () => setOpenRequestBenefitForm((prev) => !prev);

  const companyHasEnoughCoins = company.coins >= benefit.coinPrice;

  const onRequestBenefitSubmit = (e: any) => {
    toggleRequestModal();
    toggleModal();
    activateBenefit(benefit.id, !e.collaborators ? [] : e.collaborators);
  };

  const onRequestOwnerActivation = () => {
    toggleModal();
    requestOwnerActivationBenefit(benefit.id);
  };

  const renderModals = () => (
    <>
      <Modal isDark isOpen={modalOpen} onClose={toggleModal}>
        <ModalContent>
          <CongratulationsGift />
          <CongratulationsDescriptionContainer>
            <ModalDescription>
              {user.role === Role.COMPANY_OWNER
                ? i18next.t('BENEFIT:MODAL_DESCRIPTION_OWNER')
                : i18next.t('BENEFIT:MODAL_DESCRIPTION')}
            </ModalDescription>
          </CongratulationsDescriptionContainer>
          <BenefitImageContainer>
            <ImageContainerModal>
              <BenefitImage src={benefit.image} />
            </ImageContainerModal>
          </BenefitImageContainer>
          <ButtonsContainer>
            <CancelButton onClick={toggleModal}>{i18next.t('BENEFIT:CANCEL')}</CancelButton>
            <ActivateButton
              onClick={
                user.role === Role.COMPANY_OWNER ? toggleRequestModal : onRequestOwnerActivation
              }
            >
              {i18next.t('BENEFIT:ACTIVATE')}
            </ActivateButton>
          </ButtonsContainer>
        </ModalContent>
      </Modal>

      <Modal isDark isOpen={openRequestBenefitForm} onClose={toggleRequestModal}>
        <Container>
          <Title>{i18next.t('BENEFIT:ACTIVATE_MODAL')}</Title>
          <Subtitle>{`${i18next.t('BENEFIT:BENEFIT')}: ${benefit.title}`}</Subtitle>
          <Subtitle>{`${i18next.t('BENEFIT:COMPANY')}: ${company.name}`}</Subtitle>
          <BenefitsForm onSubmit={onRequestBenefitSubmit} />
        </Container>
      </Modal>
    </>
  );

  return (
    <BenefitContainer>
      {renderModals()}
      <AvailableBenefitContainer>
        <ImageContainer>
          <BenefitImage src={benefit.image} />
        </ImageContainer>
        <BenefitDesciptionContainer>
          <SmallGrayText>{benefit.description}</SmallGrayText>
        </BenefitDesciptionContainer>
        <CoinPriceContainer>
          <CoinIcon />
          <CoinPrice>{benefit.coinPrice}</CoinPrice>
        </CoinPriceContainer>
        {companyHasEnoughCoins || !benefit.pending ? (
          <ActivateModalButton onClick={toggleModal} disabled={!benefit.pending}>
            {benefit.pending ? i18next.t('BENEFIT:ACTIVATE') : i18next.t('BENEFIT:ACTIVATED')}
          </ActivateModalButton>
        ) : (
          <NotEnoughCoinsContainer>
            <NotEnoughCoinsText>{i18next.t('BENEFIT:KEEP_COLLECTING_COINS')}</NotEnoughCoinsText>
          </NotEnoughCoinsContainer>
        )}
      </AvailableBenefitContainer>
    </BenefitContainer>
  );
};

export default Benefit;
