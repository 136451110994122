import { trackPageView, trackEvent } from 'helpers/analytics';
import FacebookPixel from 'react-facebook-pixel';

import * as appConstants from 'redux/constants/app.constants';
import * as trainingConstants from 'redux/constants/training.constants';
import * as userConstants from 'redux/constants/user.constants';

const analyticsMiddleware = () => (next: any) => (action: any) => {
  const { type } = action;
  switch (type) {
    case appConstants.APP_ON_ROUTE_CHANGE:
      if (action.location !== '/confirm-signup') {
        FacebookPixel.pageView();
        trackPageView(action.location);
      }
      break;
    case userConstants.USER_ON_REGISTER_SUCCEEDED:
    case userConstants.COMPANY_ON_REGISTER_SUCCEEDED:
      trackEvent('registration-complete', {});
      FacebookPixel.track('Complete registration');
      break;
    case trainingConstants.TRAINING_SUBMIT_CODE_SUCCEEDED:
      FacebookPixel.track('Submitted code', { code: action.code });
      break;
    case trainingConstants.TRAINING_MARK_AS_COMPLETE_SUCCEEDED:
      FacebookPixel.track('Completed training', { trainingId: action.data.trainingId });
      break;
    default:
      break;
  }
  return next(action);
};

export default analyticsMiddleware;
