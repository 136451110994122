import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import Button from 'components/commons/Button';

export const ProfileContainer = styled.div`
  width: 100%;
  margin: 0 50px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 90%;
    height: 100%;
    margin: 0 20px;
  }
`;

export const Title = styled.p`
  font: 18px/24px 'OpenSansBold';
  color: ${COLORS.blueResolution};
  margin: ${({ hasLessMargin }: { hasLessMargin?: boolean }) =>
    hasLessMargin ? '10px 0 15px' : '20px 0 25px'};
`;

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const SettingsContainer = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TextContainer = styled.div`
  border-bottom: 1px solid ${COLORS.grayMercury};
  padding-bottom: 15px;
`;

export const Text = styled.span`
  font: 16px/20px 'OpenSans';
  color: ${COLORS.riverBed};
  margin: 0;
  padding-right: 6px;
`;

export const TextLink = styled.a`
  font: 16px/20px 'OpenSansBold';
  color: ${COLORS.bluePersian};
  margin: 0;
  cursor: pointer;
  padding-right: ${({ hasSeparator }: { hasSeparator?: boolean }) => hasSeparator && '6px'};
  margin-right: ${({ hasSeparator }: { hasSeparator?: boolean }) => hasSeparator && '6px'};

  border-right: ${({ hasSeparator }: { hasSeparator?: boolean }) =>
    hasSeparator && `2px solid ${COLORS.bluePersian}`};
`;

export const ModalTitle = styled.div`
  font-family: 'OpenSansBold';
  font-size: 20px;
  color: ${COLORS.blueResolution};
  width: fit-content;
`;

export const ModalSubtitle = styled.div`
  font-family: 'OpenSansSemiBold';
  font-size: 14px;
  color: ${COLORS.riverBed};
  margin: 15px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const ModalButton = styled(Button)`
  color: ${COLORS.white};
  padding: 10px 26px;
  background: ${COLORS.blueResolution};

  :hover:not([disabled]) {
    background: ${COLORS.bluePersian};
  }
`;
