import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import Button from 'components/commons/Button';
import { ReactComponent as CoinIconSVG } from 'assets/imgs/coin-icon.svg';
import { ReactComponent as CongratulationsGiftSVG } from 'assets/imgs/congratulations-gift.svg';

export const BenefitContainer = styled.div`
  width: 263px;
  height: 345px;
  background: ${COLORS.white};
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 90%;
    margin: 15px;
    height: 315px;
  }
`;

export const BenefitImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.lightblueZumthor};
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 75px;
`;

export const ImageContainerModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 157px;
  height: 60px;
`;

export const BenefitImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const BenefitDesciptionContainer = styled.div`
  margin: 0;
  padding: 0;
  height: 120px;
  width: 227px;
  margin-bottom: 5px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
  }
`;

export const CongratulationsDescriptionContainer = styled(BenefitDesciptionContainer)`
  width: 358px;
  height: auto;
  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
  }
`;

export const SmallGrayText = styled.p`
  font: 14px/20px 'OpenSans';
  color: ${COLORS.riverBed};
  overflow-wrap: break-word;
  margin: 0;
  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 12px;
  }
`;

export const ModalDescription = styled.p`
  font: 14px/20px 'OpenSans';
  color: ${COLORS.riverBed};
  margin: 0;
  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 12px;
  }
`;

export const AvailableBenefitContainer = styled.div`
  width: 89%;
  height: 89%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const CoinPriceContainer = styled.div`
  width: 100%;
  height: 50px;
  background: ${COLORS.lightblueZumthor};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  gap: 8px;
`;

export const CoinPrice = styled.p`
  font: 36px 'OpenSansBold';
  color: ${COLORS.riverBed};
`;

export const CoinIcon = styled(CoinIconSVG)`
  width: 32px;
`;

export const ActivateModalButton = styled(Button)`
  width: 100%;
  font: 16px 'OpenSansBold';
  color: white;
  background-color: ${COLORS.bluePersian};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ModalContent = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
`;

const ModalButton = styled(Button)`
  width: 170px;
  height: 40px;
  font: 16px 'OpenSansBold';
  color: white;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
  }
`;

export const CancelButton = styled(ModalButton)`
  background-color: ${COLORS.blueFrenchSky};
`;

export const ActivateButton = styled(ModalButton)`
  background-color: ${COLORS.bluePersian};
`;
export const NotEnoughCoinsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 50px;
`;

export const NotEnoughCoinsText = styled.p`
  font: 14px 'OpenSans';
  color: ${COLORS.blackMineShaft};
  text-align: center;
  margin: 0;
`;

export const CongratulationsGift = styled(CongratulationsGiftSVG)`
  width: 333px;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-width: 300px;

  @media (max-width: ${SIZES.mediumPhone}px) {
    padding: 0;
  }
`;

export const Title = styled.div`
  font-family: 'OpenSansBold';
  font-size: 20px;
  color: ${COLORS.blueResolution};
  width: fit-content;
  margin-bottom: 15px;
`;

export const Subtitle = styled.div`
  font-family: 'OpenSansSemiBold';
  font-size: 14px;
  color: ${COLORS.riverBed};
  margin: 0 15px 15px 15px;
`;
