import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { ICapsulesDefaultState } from 'types/capsules.types';

import { ICompanyDefaultState } from 'types/company.types';
import { IHeaderContainerDefaultState } from 'types/header.types';
import { INewsDefaultState } from 'types/news.types';
import { IProgramsDefaultState } from 'types/programs.types';
import { ITrainingsDefaultState } from 'types/training.types';
import { ITestimonialsDefaultState } from 'types/testimonials.types';
import { INotificationsDefaultState } from 'types/notifications.types';
import { ILevelPropsDefaultState } from 'views/BenefitsView/types';
import { IBenefitDefaultState } from 'types/benefit.types';
import { IAuthProps } from 'types/auth.types';

interface IRootState {
  company: ICompanyDefaultState;
  form: any;
  news: INewsDefaultState;
  programs: IProgramsDefaultState;
  capsules: ICapsulesDefaultState;
  trainings: ITrainingsDefaultState;
  header: IHeaderContainerDefaultState;
  testimonials: ITestimonialsDefaultState;
  notifications: INotificationsDefaultState;
  levels: ILevelPropsDefaultState;
  benefits: IBenefitDefaultState;
  auth: IAuthProps;
}

const useTypedSelector: TypedUseSelectorHook<IRootState> = useSelector;

export default useTypedSelector;
