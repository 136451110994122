import styled, { css } from 'styled-components';
import { NavHashLink } from 'react-router-hash-link';

import { HEADER_HEIGHT } from 'helpers/dimensions';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import Button from 'components/commons/Button';

import { ReactComponent as menuArrowIcon } from 'assets/imgs/arrow.svg';
import { ReactComponent as FacebookSVG } from 'assets/imgs/icon-facebook.svg';
import { ReactComponent as InstagramSVG } from 'assets/imgs/icon-instagram.svg';
import { ReactComponent as OpenMenuSVG } from './assets/open-menu.svg';
import { ReactComponent as CloseMenuSVG } from './assets/close-menu.svg';
import { ITextContainerProps } from './types';

export const LandingContainer = css`
  position: fixed;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  background: rgba(31, 54, 199, 0.8);
  z-index: 4;
  border-bottom: 1px solid ${COLORS.white};
`;

export const DashboardContainer = css`
  background-color: ${COLORS.linkWater};
  font-size: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0 3px 6px ${COLORS.riverBed}29;
  position: fixed;
  z-index: 5;
  background: transparent linear-gradient(180deg, #1f36c7 0%, #7705bc 100%) 0% 0% no-repeat
    padding-box;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  ${({
    isLanding,
  }: {
    isLanding: boolean;
    showBenefitsBanner?: boolean;
    isMenuCollapsed?: boolean;
  }) =>
    isLanding
      ? css`
          ${LandingContainer}
        `
      : css`
          ${DashboardContainer}
        `};
`;

export const LandingLink = css`
  padding: 0 10px;
  display: inline-block;
  text-align: center;
  align-self: center;
  cursor: pointer;

  &:last-child {
    padding: 0;
    margin: 0;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    padding-bottom: 30px;
    text-align: center;

    &:first-child {
      padding-top: 15px;
    }
  }
`;

export const IconContainer = styled.div`
  display: ${({ isLanding }: { isLanding?: boolean }) => (isLanding ? 'none' : 'flex')};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: ${SIZES.bigTablet}px) {
    ${({ isLanding }: { isLanding: boolean }) =>
      !isLanding &&
      css`
        border-radius: 0;
        width: 33px;
        height: 50px;
      `};
  }
`;

export const TextContainer = styled.div`
  font-family: ${({ isLanding }: ITextContainerProps) => (isLanding ? 'Unilever' : 'OpenSans')};
  font-size: 14px;
  margin: ${({ isMenuCollapsed }: ITextContainerProps) => (isMenuCollapsed ? '0' : '0 10px')};
  color: ${({ isLanding }: ITextContainerProps) => {
    if (isLanding) {
      return COLORS.white;
    }
    return COLORS.white;
  }};

  .active {
    font-family: 'Unilever';
  }

  ${({ isLanding }: ITextContainerProps) =>
    isLanding &&
    css`
      :hover {
        text-decoration: underline;
      }
    `}
`;

export const HoverBarForLinks = styled.div`
  background-color: ${COLORS.blueResolution};
  position: absolute;
  z-index: -1;
  height: 45px;
  left: 0px;
  border-radius: 0px 6px 6px 0px;
  display: none;
  width: ${({ isMenuCollapsed }: { isMenuCollapsed: boolean }) =>
    isMenuCollapsed ? '55px' : '165px'};
`;

export const DashboardLink = css`
  padding: 12px 10px;
  text-align: start;
  cursor: pointer;
  color: ${COLORS.riverBed};
  display: flex;
  align-items: center;
  max-width: 150px;
  -webkit-tap-highlight-color: transparent;

  &:last-child {
    margin-top: auto;
    fill: ${COLORS.blueResolution};

    ${IconContainer} {
      background: transparent;
    }

    @media (max-width: ${SIZES.bigTablet}px) {
      display: none;
    }
  }

  @media (min-width: ${SIZES.bigTablet}px) {
    &:hover {
      ${HoverBarForLinks} {
        display: block;
      }
    }
  }

  &:focus,
  &:focus-within,
  &:active,
  &:hover {
    @media (max-width: ${SIZES.bigTablet}px) {
      ${IconContainer} {
        background: transparent;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
    }
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    padding: 0;
    text-align: center;

    &:first-child {
      padding: 0;
      margin-left: 20px;
    }
  }
`;

export const collapsedDashboardLinks = css`
  padding: 20px 0 0;
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
  color: ${COLORS.riverBed};
  display: flex;
  align-items: center;
`;

export const ExternalLink = styled.a`
  ${({ isLanding }: { isLanding: boolean }) =>
    isLanding
      ? css`
          ${LandingLink}
        `
      : css`
          ${DashboardLink}
        `};
`;

export const DivLink = styled(NavHashLink)`
  ${({ isLanding }: { isLanding: boolean }) =>
    isLanding
      ? css`
          ${LandingLink}
        `
      : css`
          ${DashboardLink}
        `};
`;

export const LandingContent = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: ${SIZES.bigDesktop}px;
  margin: 0 auto;

  @media (max-width: ${SIZES.bigDesktop}px) {
    width: 90%;
  }

  @media (max-width: ${SIZES.smallDesktop}px) {
    padding: 0 25px;
  }
`;

export const DashboardContent = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: row;
    position: fixed;
    bottom: 0;
  }
`;

export const HeaderContent = styled.div`
  ${({ isLanding }: { isLanding: boolean }) =>
    isLanding
      ? css`
          ${LandingContent}
        `
      : css`
          ${DashboardContent}
        `};
`;

export const LandingNavLinks = styled.ul`
  display: flex;
  list-style-type: none;

  li,
  li a,
  li span {
    color: ${COLORS.blueRibbon};
    font-size: 12px;
  }

  a.active {
    color: ${COLORS.blueRibbon};
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    position: fixed;
    top: 0;
    right: ${({ showSideMenu }: { showSideMenu?: boolean }) => (showSideMenu ? '0' : '-300px')};
    flex-direction: column;
    width: 300px;
    height: 100vh;
    margin: 0;
    padding: 5vh 0 0;
    background: linear-gradient(180deg, ${COLORS.purpleHeart}, ${COLORS.bluePersian});
    transition: all 0.3s ease-in-out;

    li {
      padding: 15px 0;
      text-align: center;
    }
  }

  @media only screen and (min-device-width: ${SIZES.mediumPhone}px) and (max-device-width: ${SIZES.smallTablet}px) and (orientation: landscape) {
    padding: 30px 0;
  }
`;

export const DashboardNavLinks = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
  flex: 1;
  margin-top: 55px;

  a {
    font-size: 14px;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    background: ${({ showSideMenu }: { showSideMenu?: boolean }) =>
      showSideMenu
        ? COLORS.white
        : 'transparent linear-gradient(180deg, #1F36C7 0%, #7705BC 100%) 0% 0% no-repeat padding-box;'};
    width: 100%;
    position: fixed;
    flex-direction: row;
    bottom: 0;
    margin: 0;
    justify-content: space-around;
  }

  .active {
    color: ${COLORS.bluePersian};
    g {
      .st0 {
        fill: ${COLORS.white};
      }
    }
    @media (max-width: ${SIZES.bigTablet}px) {
      ${IconContainer} {
        background: ${COLORS.blueResolution};
      }
    }
    @media (min-width: ${SIZES.bigTablet}px) {
      ${HoverBarForLinks} {
        display: block;
      }
    }
  }
`;

export const MenuOverlay = styled.div`
  @media (max-width: ${SIZES.bigTablet}px) {
    ${({ showOverlay, isLanding }: { showOverlay: boolean; isLanding: boolean }) =>
      showOverlay &&
      isLanding &&
      css`
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: ${COLORS.boxShadow};
      `}
  }
`;

export const MenuContainer = styled.div`
  display: contents;
`;

export const MenuIconContainer = styled.div`
  display: none;

  @media (max-width: ${SIZES.bigTablet}px) {
    display: flex;
    z-index: 3;
  }
`;

export const MenuIcon = css`
  cursor: pointer;
`;

export const OpenMenuIcon = styled(OpenMenuSVG)`
  ${MenuIcon}
  g {
    fill: ${COLORS.white};
  }
`;

export const CloseMenuIcon = styled(CloseMenuSVG)`
  ${MenuIcon}
  g {
    fill: ${COLORS.bluePersian};
  }
  @media (max-width: ${SIZES.bigTablet}px) {
    g {
      fill: ${COLORS.white};
    }
  }
`;

export const HeaderButton = styled(Button)`
  && {
    height: 40px;
    width: 135px;
    color: ${COLORS.white};
    font-family: 'Unilever';
    font-weight: lighter;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;

    .MuiButton-label {
      font-size: 14px;
      line-height: 18px;
      padding: 0px;
      text-transform: initial;
    }

    ${({ hasBackground }: { hasBackground: boolean }) =>
      hasBackground
        ? css`
            padding: 5px 6px;
            justify-content: center;
            background: ${COLORS.bluePersian};
            :hover:not([disabled]) {
              background: ${COLORS.white};
              color: ${COLORS.bluePersian};
            }
          `
        : css`
            padding: 9px 20px;
            background: ${COLORS.white};
            border: 1px solid ${COLORS.white};
            color: ${COLORS.blueResolution};
            margin-left: 18px;
            @media (max-width: ${SIZES.bigTablet}px) {
              margin-left: 0;
            }
            :hover:not([disabled]) {
              background: ${COLORS.purple};
              color: ${COLORS.white};
            }
          `};
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .st0 {
    fill: ${COLORS.white};
  }
  @media (max-width: ${SIZES.bigTablet}px) {
    margin: 0;

    #Insights {
      width: 28px;
      height: 28px;
    }
  }
`;

export const LogoContainer = styled.div`
  margin: 0;
  position: absolute;
  ${({ isLanding }: { isLanding: boolean }) =>
    !isLanding &&
    css`
      top: 15px;
      left: 33px;
    `}

  @media (max-width: ${SIZES.bigTablet}px) {
    display: none;
  }
`;

const arrowButton = css`
  position: relative;
  width: 20px;
  top: 70px;
  left: 50%;
  cursor: pointer;
  z-index: 5;

  g {
    .bg {
      fill: ${COLORS.bluePersian};
    }
    .arrow {
      fill: ${COLORS.linkWater};
    }
    .stroke {
      fill: ${COLORS.bluePersian};
    }
  }
`;

export const ArrowIcon = styled(menuArrowIcon)`
  cursor: pointer;
`;

export const Arrow = styled.div`
  ${({ isMenuCollapsed }: { isMenuCollapsed: boolean }) =>
    isMenuCollapsed
      ? css`
          ${arrowButton}
        `
      : css`
          ${arrowButton}
          transform: rotate(180deg);
        `};
`;

export const SocialMediaIcon = css`
  cursor: pointer;
  height: 35px;
`;

export const FacebookIcon = styled(FacebookSVG)`
  ${SocialMediaIcon};
  padding-right: 15px;
  padding-top: 3px;
`;

export const InstagramIcon = styled(InstagramSVG)`
  ${SocialMediaIcon};
  padding-top: 3px;

  @media (max-width: ${SIZES.bigTablet}px) {
    padding-right: 20px;
  }
`;

export const LinksContainer = styled.div`
  padding-left: 20px;

  @media (max-width: ${SIZES.bigTablet}px) {
    display: flex;
    justify-content: center;
  }
`;

export const ModalText = styled.h1`
  margin-top: 20px;
  color: ${COLORS.blueRibbon};
`;

export const ModalButton = styled(Button)`
  && {
    height: 40px;
    width: 142px;
    color: ${COLORS.white};
    padding: 9px 20px;
    margin: 0 3px 25px 0;
    background: ${({ isDark }: { isDark?: boolean }) =>
      isDark ? `${COLORS.blueResolution}` : `${COLORS.bluePersian}`};

    .MuiButton-label {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      padding: 0;
      text-transform: initial;
    }

    :hover {
      color: ${COLORS.white};
      background: ${COLORS.purple};
    }
  }
`;
