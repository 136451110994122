import { get, post, put } from 'services/api';

export async function confirmAccount(token: string): Promise<any> {
  const response = await put(`users/confirm-account?token=${token}`);
  return response;
}

export async function acceptAccount(token: string): Promise<any> {
  const response = await put(`users/accept-account?token=${token}`);
  return response;
}

export async function forgotPassword(formData: any): Promise<any> {
  const response = await post('users/forgot-password', formData);
  return response;
}

export async function initialize(): Promise<any> {
  const response = await get('users/profile');
  return response;
}

export async function login(formData: any): Promise<any> {
  const response = await post('auth/login', formData);
  return response;
}

export async function allUsersByCompany(formData: any): Promise<any> {
  const response = await get('users/all-users-by-company', formData);
  return response;
}

export async function enableUser(formData: any): Promise<any> {
  const response = await put('users/enable-user', formData);
  return response;
}

export async function disableUser(formData: any): Promise<any> {
  const response = await put('users/disable-user', formData);
  return response;
}

export async function changeUserToOwner(formData: any): Promise<any> {
  const response = await put('users/change-user-to-owner', formData);
  return response;
}

export async function logout(): Promise<any> {
  const response = await get('auth/logout');
  return response;
}

export async function register(formData: any): Promise<any> {
  const response = await post('auth/register', formData);
  return response;
}

export async function registerUser(formData: any): Promise<any> {
  const response = await post('auth/register-user', formData);
  return response;
}

export async function validateIdentifier(formData: any): Promise<any> {
  const response = await post('auth/validate-identifier', formData);
  return response;
}

export async function emailAccountConfirmation(formData: any): Promise<any> {
  const response = await post('auth/email-account-confirmation', formData);
  return response;
}

export async function resetPassword(formData: any): Promise<any> {
  const response = await put('users/reset-password', formData);
  return response;
}

export async function update(formData: any): Promise<any> {
  const response = await put('users/update', formData);
  return response;
}

export async function contact(formData: any): Promise<any> {
  const response = await post('users/contact', formData);
  return response;
}

export async function confirmAssistance(userId: string, trainingId: string): Promise<any> {
  const response = await post(`assistance/confirm?userId=${userId}&trainingId=${trainingId}`);
  return response;
}

export async function getFormsByUserId(): Promise<any> {
  const response = await get('forms');
  return response;
}

export async function completeForm(formData: any): Promise<any> {
  const response = await post('forms/complete', formData);
  return response;
}
