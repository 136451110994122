import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/user.constants';
import { getAllCertificates } from 'services/certificates.service';

export function* getCertificates() {
  try {
    const data: unknown = yield call(getAllCertificates);
    yield put({ type: constants.USER_ON_GET_CERTIFICATES_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_GET_CERTIFICATES_FAILED, error });
  }
}

export function* watchCertificates() {
  yield all([takeLatest([constants.USER_ON_GET_CERTIFICATES_REQUESTED], getCertificates)]);
}
