import React, { FunctionComponent, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import i18next from 'i18next';

import {
  Container,
  Title,
  Content,
  StatContainer,
  StatDescription,
  CustomCountUp,
  UnipymeLogoWhite,
  TitleContainer,
} from './styles';
import './i18n';

const Numbers: FunctionComponent = () => {
  const [isCountUpVisible, setCountUpVisible] = useState<boolean>(false);
  const renderStat = (statNumber: number, statDescription: string): JSX.Element => {
    return (
      <StatContainer>
        <CustomCountUp duration={3} end={isCountUpVisible ? statNumber : 0} prefix="+" />
        <StatDescription>{statDescription}</StatDescription>
      </StatContainer>
    );
  };

  return (
    <Container data-testid="numbers-view">
      <TitleContainer>
        <UnipymeLogoWhite />
        <Title>{i18next.t('NUMBERS:TITLE')}</Title>
      </TitleContainer>
      <Content>
        <VisibilitySensor partialVisibility offset={{ top: 10 }}>
          {({ isVisible }) => {
            if (isVisible) setCountUpVisible(true);
            return (
              <>
                {renderStat(3500, i18next.t('NUMBERS:REGISTERED_PYMES'))}
                {renderStat(60, i18next.t('NUMBERS:TRAININGS_DONE'))}
                {renderStat(55, i18next.t('NUMBERS:STRATEGIC_PARTNERS'))}
              </>
            );
          }}
        </VisibilitySensor>
      </Content>
    </Container>
  );
};

export default Numbers;
