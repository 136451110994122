import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import CountUp from 'react-countup';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import { HEADER_HEIGHT } from 'helpers/dimensions';

import MapImage from 'assets/imgs/mapa-texture.svg';
import { ReactComponent as UnipymeLogoWhiteSVG } from './assets/logo-unipyme-w.svg';

export const UnipymeLogoWhite = styled(UnipymeLogoWhiteSVG)`
  height: 65px;
  width: 175px;
  margin-bottom: 7px;
  margin-right: 12px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;

  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CustomCountUp = styled(CountUp)`
  font-family: UnileverBold;
  font-size: 60px;
  color: ${COLORS.white};
  margin: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1240px;
  margin: 0 auto;
  padding-top: ${HEADER_HEIGHT}px;
  padding-bottom: ${HEADER_HEIGHT}px;
  width: 100%;
  background-image: url(${MapImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  align-items: center;

  @media (max-width: ${SIZES.smallDesktop}px) {
    width: 90%;
    padding-top: 105px;
  }
`;

export const Title = styled.p`
  font-family: UnileverMedium;
  font-size: 40px;
  line-height: 65px;
  color: ${COLORS.white};
  margin-top: 0px;
  margin-bottom: 27px;
  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 36px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 70%;

  @media (max-width: ${SIZES.smallDesktop}px) {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const StatContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
`;

export const StatTitle = styled.h1`
  font-family: UnileverBold;
  font-size: 32px;
  color: ${COLORS.white};
  margin: 0;

  @media (max-width: ${SIZES.smallDesktop}px) {
    font-size: 14px;
  }
`;

export const StatDescription = styled.p`
  font-size: 18px;
  text-align: center;
  color: ${COLORS.white};
  margin: 10px 0 0 0;
  font-family: OpenSans;
`;
