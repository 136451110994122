import { get, put } from 'services/api';

export async function submitForm(formData: any): Promise<any> {
  const response = await put('companies/submit-form', formData);
  return response;
}

export async function getPoints(): Promise<any> {
  const response = await get('companies/getPoints');
  return response;
}

export async function getLevel(): Promise<any> {
  const response = await get('levels/company');
  return response;
}
