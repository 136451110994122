import { MIN_AFTER_TO_SHOW_MEET_BUTTON, MIN_BEFORE_TO_SHOW_MEET_BUTTON } from 'configs/configs';
import moment from 'moment';

export const isLessThanTenMinBefore = (now: Date, date: Date): boolean => {
  return (
    // Check that the difference in miliseconds is lower or equal than 10 minutes (1 min = 60000 mseconds)
    (date.getTime() - now.getTime()) / 60000 <= MIN_BEFORE_TO_SHOW_MEET_BUTTON &&
    date.getTime() - now.getTime() > 0
  );
};

export const isLessThanOneHourAndHalfAfter = (now: Date, date: Date): boolean => {
  return (
    // Check that the difference in miliseconds is lower or equal than 90 minutes (1 min = 60000 mseconds)
    (now.getTime() - date.getTime()) / 60000 <= MIN_AFTER_TO_SHOW_MEET_BUTTON &&
    now.getTime() - date.getTime() > 0
  );
};

export const isMoreThanOneHourAndHalfAfter = (now: Date, date: Date): boolean => {
  return (
    // Check that the difference in miliseconds is more than 90 minutes (1 min = 60000 mseconds)
    (now.getTime() - date.getTime()) / 60000 > MIN_AFTER_TO_SHOW_MEET_BUTTON
  );
};

export const isInTheFuture = (now: Date, date: Date): boolean => {
  return (
    // Check that the difference in miliseconds is more than 0 minutes (1 min = 60000 mseconds)
    (date.getTime() - now.getTime()) / 60000 > 0
  );
};

export const isToday = (now: Date, date: Date): boolean => {
  const startOfDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  return (
    // Check that the difference in miliseconds is more than 0 minutes and
    // Check that the now is Between 00.00.00 and date
    (date.getTime() - now.getTime()) / 60000 > 0 && moment(now).isBetween(startOfDate, date)
  );
};
