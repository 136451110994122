import styled, { css } from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const FeaturedTrainingContainer = styled.div<IFeaturedTrainingStyles>`
  height: 286px;
  background: ${COLORS.riverBed};
  border-radius: 8px;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  width: 932px;
  @media (max-width: ${SIZES.desktop}px) {
    width: 90%;
    flex-direction: column;
    height: auto;
    margin-bottom: 20px;
  }
`;

export const FeaturedThumbnail = styled.div<IFeaturedTrainingStyles>`
  width: 446px;
  height: 246px;
  border: 2px solid ${COLORS.white};
  border-radius: 6px;
  background-image: url(${(props: IFeaturedTrainingStyles) => props.thumbnail});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: ${SIZES.desktop}px) {
    width: 100%;
  }
  @media (max-width: ${SIZES.mediumTablet}px) {
    height: 162px;
  }
`;

export const FeaturedTrainingContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 42px;
  width: 360px;

  @media (max-width: ${SIZES.desktop}px) {
    padding: 0;
    width: 100%;
    margin-top: 5px;
  }
`;

export const FeaturedTrainingCapsuleTitle = styled.div`
  font-family: OpenSansBold;
  font-size: 12px;
  line-height: 20px;
  word-wrap: break-word;
  color: ${COLORS.white};
`;

export const ScrollBar = css`
  overflow: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #acacac;
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const FeaturedTrainingTitle = styled.div`
  font-family: OpenSansBold;
  font-size: 20px;
  line-height: 22px;
  color: ${COLORS.white};
`;

export const FeaturedTrainingDescription = styled.div`
  font-family: OpenSans;
  font-size: 13px;
  line-height: 19px;
  margin: 10px 0;
  color: ${COLORS.white};
  padding-right: 5px;
  ${ScrollBar}
`;

export const FeaturedTrainingButton = styled.div`
  width: 174px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 19px;
  font-family: OpenSansBold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: ${COLORS.white};
  color: ${COLORS.fuchsiaBlue};
  justify-self: flex-end;
  cursor: pointer;

  :hover {
    background-color: ${COLORS.lavenderHaze};
    color: ${COLORS.white};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  gap: 10px;
  @media (max-width: ${SIZES.desktop}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface IFeaturedTrainingStyles {
  isHome?: boolean;
  thumbnail?: string;
}
