export enum Status {
  PENDING = 1,
  ACTIVE,
  INACTIVE,
  WAITING_ON_COMPANY_OWNER,
}

export enum CompanyStatus {
  COMPLETE_FORM = 1,
  PENDING,
  ACTIVE,
  INACTIVE,
}
