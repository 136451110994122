import { createSelector } from 'reselect';

const getBenefits = (state: any) => state.benefits.items;
const getCompany = (state: any) => state.company;

const pendingBenefitsSelector = () =>
  createSelector([getBenefits, getCompany], (benefits) =>
    benefits.filter((benefit: any) => benefit.pending),
  );

export default pendingBenefitsSelector;
