import * as constants from 'redux/constants/benefit.constants';

export function onActivateBenefit(
  benefitId: string,
  collaborators: any,
): { type: string; benefitId: string; collaborators: any } {
  return { type: constants.BENEFITS_ON_ACTIVATE_REQUESTED, benefitId, collaborators };
}

export function onRequestOwnerActivationBenefit(benefitId: string): {
  type: string;
  benefitId: string;
} {
  return {
    type: constants.BENEFITS_ON_REQUEST_OWNER_ACTIVATION_REQUESTED,
    benefitId,
  };
}
