import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import { createMuiTheme } from '@material-ui/core';

export const CapsuleContainer = styled.div`
  border: 1px solid ${COLORS.grayMercury};
  margin: 2px 2px;
  border-radius: 8px;
  background: ${({ color }: { color: string }) => color};
  color: white;
  padding: 12px 20px;
  max-width: 27%;
  min-height: 257px;
  max-height: 660px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${SIZES.desktop}px) {
    max-width: 100%;
    margin: 0 0 30px;
    min-height: 0;
    display: block;
  }
`;

export const Title = styled.p`
  margin: 0;
  padding-bottom: 15px;
  font: 18px/22px 'OpenSansBold';
  height: 52px;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  :hover {
    cursor: help;
  }
  @media (max-width: ${SIZES.mediumTablet}px) {
    padding: 0;
  }
`;

export const Text = styled.p`
  margin: 0;
  font: ${({ isBold }: { isBold?: boolean }) =>
    isBold ? `14px/20px 'OpenSansBold'` : `14px/20px 'OpenSans'`};
  padding: 0 5px 0 0;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
`;

export const Points = styled.p`
  justify-self: flex-end;
  margin: 0;
  font: 20px/20px 'OpenSansBold';
`;

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`;

export const TextDescription = styled.p`
  margin: 0;
  font: 14px/20px 'OpenSans';
  padding: 0 5px 30px 0;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.36);
`;

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  padding-left: 10px;
`;

export const ButtonContainer = styled(Link)`
  display: flex;
  align-self: center;
  cursor: pointer;
  align-items: center;
  background: ${COLORS.blueCatalina};
  color: ${COLORS.white};
  border-radius: 4px;
  padding: 12px;
  font: 14px/20px 'OpenSansBold';

  @media (max-width: ${SIZES.mediumTablet}px) {
    padding: 15px 12px;
  }
`;

export const Theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        color: 'white',
        backgroundColor: COLORS.blueRibbon,
      },
      arrow: {
        color: COLORS.blueRibbon,
      },
    },
  },
});
