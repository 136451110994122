import styled from 'styled-components';

import Input from 'components/inputs/Input';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import { CustomButton } from 'components/commons/Button/styles';

export const Form = styled.form`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
`;

export const Button = styled(CustomButton)`
  background-color: ${COLORS.purple};
  padding: 12px 68px;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
  margin: 20px;

  @media (max-width: ${SIZES.mediumTablet}px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 35%;

  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 100%;
  }
`;

export const Question = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  width: 65%;
  font-family: OpenSans;
  font-size: 16px;
  padding-top: ${({ isRadio }: { isRadio: boolean }) => (isRadio ? '6px' : '8px')};

  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 100%;
    padding-top: 0;
  }
`;

export const CustomInput = styled(Input)`
  padding: 0;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;
