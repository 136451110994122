import styled from 'styled-components';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const CheckBoxStyled = styled(Checkbox)`
  && {
    padding: 0 12px 0 12px;

    .MuiFormControlLabel-root {
      margin-right: 0px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
  && {
    margin-right: 0;
  }
`;

export const ErrorBlock = styled.div`
  width: 100%;
  position: absolute;
  font-size: 13px;
  line-height: 1.5;
  color: ${COLORS.red};
  text-align: left;
  padding-bottom: 50px;
  padding-left: 3px;

  @media (max-width: ${SIZES.mediumPhone}px) {
    font-size: 12px;
  }
`;
