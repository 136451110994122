import React, { FunctionComponent } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import i18next from 'i18next';
import { ISignUpFormData } from 'views/SignUpView/types';
import {
  requiredValidation,
  emailValidation,
  passwordConfirmationValidation,
  passwordValidation,
  phoneNumberValidation,
  emailConfirmationValidation,
} from 'helpers/validations';
import countries from 'helpers/json/countries.json';
import CustomCheckbox from 'components/inputs/Checkbox';
import PhoneInputMasked from 'components/inputs/PhoneInput';
import { ISignUpFormProps } from './types';
import {
  Form,
  ButtonContainer,
  RowDivInput,
  RowDiv,
  Text,
  TermsDiv,
  TermsAndConditionsLink,
  FormButton,
  RequiredField,
  CustomLink,
  CustomInput,
} from './styles';
import './i18n';

const SignUpForm: FunctionComponent<
  ISignUpFormProps & InjectedFormProps<ISignUpFormData, ISignUpFormProps>
> = (props) => {
  const { handleSubmit, onSubmit, submitting, invalid, country, onClickBack } = props;

  const countryJson = countries.find((i) => i.name === props?.country?.name);
  const getPhonePlaceholder = (placeholder: string) => {
    return placeholder.replace(new RegExp('[0-9]', 'g'), 'X');
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} data-testid="signup-form">
        <RowDiv>
          <Field
            component={CustomInput}
            label={i18next.t('SIGNUP_FORM:USER_NAME')}
            name="userName"
            validate={[requiredValidation]}
          />
        </RowDiv>
        <Field
          component={CustomInput}
          label={i18next.t('SIGNUP_FORM:EMAIL')}
          name="email"
          validate={[requiredValidation, emailValidation]}
          type="email"
        />
        <Field
          component={CustomInput}
          label={i18next.t('SIGNUP_FORM:EMAIL_CONFIRMATION')}
          name="emailConfirmation"
          validate={[requiredValidation, emailConfirmationValidation]}
          type="email"
        />
        <RowDiv>
          <Field
            component={PhoneInputMasked}
            placeholder={getPhonePlaceholder(countryJson ? countryJson?.phonePlaceholder : '')}
            label={i18next.t('SIGNUP_FORM:PHONE')}
            name="phone"
            validate={
              country.isPhoneMandatory === true
                ? [requiredValidation, phoneNumberValidation]
                : [phoneNumberValidation]
            }
            type="phone"
          />
        </RowDiv>
        <RowDiv>
          <RowDivInput>
            <Field
              component={CustomInput}
              label={i18next.t('SIGNUP_FORM:PASSWORD')}
              name="password"
              validate={[requiredValidation, passwordValidation]}
              type="password"
            />
          </RowDivInput>
          <RowDivInput>
            <Field
              component={CustomInput}
              label={i18next.t('SIGNUP_FORM:PASSWORD_CONFIRMATION')}
              name="passwordConfirmation"
              validate={[requiredValidation, passwordConfirmationValidation]}
              type="password"
            />
          </RowDivInput>
        </RowDiv>
        <TermsDiv>
          <Field
            name="aboveAge"
            component={CustomCheckbox}
            isSmaller
            validate={[requiredValidation]}
          />
          <Text>{i18next.t('SIGNUP_FORM:AGREE_AGE')}</Text>
          <RequiredField>*</RequiredField>
        </TermsDiv>
        <TermsDiv>
          <Field
            name="termsOfUse"
            component={CustomCheckbox}
            isSmaller
            validate={[requiredValidation]}
          />
          <Text>{i18next.t('SIGNUP_FORM:AGREE_AND_ACCEPT')}</Text>
          <TermsAndConditionsLink
            to={`/terminos-y-condiciones?country=${country.name.toLowerCase()}`}
            target="_blank"
          >
            {i18next.t('SIGNUP_FORM:TERMS_AND_CONDITIONS')}
          </TermsAndConditionsLink>
          <RequiredField>*</RequiredField>
        </TermsDiv>
        <TermsDiv>
          <Field name="promotions" component={CustomCheckbox} isSmaller />
          <Text>{i18next.t('SIGNUP_FORM:AGREE_INFO')}</Text>
        </TermsDiv>
        <TermsDiv isPrivacy>
          <Text>
            {i18next.t('SIGNUP_FORM:PRIVACY_TEXT')}
            <CustomLink
              target="_blank"
              href={i18next.t(`SIGNUP_FORM:PRIVACY_HREF_${country.name.toLocaleUpperCase()}`)}
            >
              {i18next.t('SIGNUP_FORM:PRIVACY_LINK')}
            </CustomLink>
            {i18next.t('SIGNUP_FORM:PRIVACY_TEXT_2')}
          </Text>
        </TermsDiv>
        <ButtonContainer>
          <FormButton type="button" onClick={onClickBack}>
            {i18next.t('SIGNUP_FORM:BACK_BUTTON')}
          </FormButton>
          <FormButton disabled={submitting || invalid} type="submit">
            {i18next.t('SIGNUP_FORM:SUBMIT_BUTTON')}
          </FormButton>
        </ButtonContainer>
      </Form>
    </>
  );
};

export default reduxForm<ISignUpFormData, ISignUpFormProps>({
  form: 'signup',
})(SignUpForm);
