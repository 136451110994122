import { CapsuleType } from 'views/HomeView/components/Programs/types';
import { COLORS } from './enums/colors';

export const getIconBackgroundColor = (type: CapsuleType): string => {
  switch (type) {
    case CapsuleType.IMPROVE:
      return COLORS.blueCurious;
    case CapsuleType.BUSINESS:
      return COLORS.eminence;
    case CapsuleType.FINANCE:
      return COLORS.blueTory;
    case CapsuleType.LOGISTICS:
      return COLORS.purpleDaisyBush;
    case CapsuleType.CULTURE:
      return COLORS.hibiscus;
    case CapsuleType.DIGITAL:
      return COLORS.greenSurfie;
    case CapsuleType.SPECIAL:
      return COLORS.grayMid;
    default:
      return COLORS.pictonBlue;
  }
};

export const getGradientBackground = (type: CapsuleType): string => {
  let brighterColor;
  let darkerColor;
  switch (type) {
    case CapsuleType.BUSINESS:
      brighterColor = COLORS.fuchsiaBlue;
      darkerColor = COLORS.eminence;
      break;
    case CapsuleType.FINANCE:
      brighterColor = COLORS.denim;
      darkerColor = COLORS.blueTory;
      break;
    case CapsuleType.IMPROVE:
      brighterColor = COLORS.pictonBlue;
      darkerColor = COLORS.blueCurious;
      break;
    case CapsuleType.LOGISTICS:
      brighterColor = COLORS.purpleHeart;
      darkerColor = COLORS.purpleDaisyBush;
      break;
    case CapsuleType.CULTURE:
      brighterColor = COLORS.ceriseRed;
      darkerColor = COLORS.hibiscus;
      break;
    case CapsuleType.DIGITAL:
      brighterColor = COLORS.persianGreen;
      darkerColor = COLORS.greenSurfie;
      break;
    case CapsuleType.SPECIAL:
      brighterColor = COLORS.grayDignity;
      darkerColor = COLORS.grayMid;
      break;
    default:
      return COLORS.pictonBlue;
  }
  const backgroundStyle = `transparent linear-gradient(180deg, ${brighterColor} 0%, ${darkerColor} 100%) 0% 0% no-repeat padding-box;`;
  return backgroundStyle;
};

export enum CompanyTypeName {
  UCCELERATOR = 'Uccelerator',
}

export enum CompanyType {
  UCCELERATOR = 1,
}
