import styled from 'styled-components';

import { SIZES } from 'helpers/enums/sizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: 0 auto 30px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    margin: 20px;
  }
`;
