import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import { HOME_BANNER_HEIGTH } from 'helpers/dimensions';

export const HomeContainer = styled.div`
  background-color: ${COLORS.white};
  width: 100%;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  gap: 5px;
`;

export const LoginButton = styled.a`
  text-align: center;
  background: ${COLORS.white};
  padding: 6px 20px 10px;
  color: ${COLORS.blueDodger};
  border-radius: 21px;
  margin-left: 15px;
  font-family: 'Unilever';
`;

export const Text = styled.span`
  font-family: OpenSans;
  font-size: 22px;
  color: ${COLORS.white};
  margin-right: 15px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    font-size: 18px;
  }
`;

export const FixedDiv = styled.div`
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 14, 77, 0.8);
  width: 100vw;
  justify-content: center;
  height: ${HOME_BANNER_HEIGTH}px;
  z-index: 1;
`;

export const GradientBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent linear-gradient(180deg, var(--unnamed-color-1f36c7) 0%, #6c24b6 100%) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #1f36c7 0%, #6c24b6 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1;
  width: 100%;
`;
