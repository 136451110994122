import styled from 'styled-components';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import ReactPlayer from 'react-player';
import Modal from 'components/commons/Modal';

export const Wrapper = styled.div`
  position: relative;
  width: 932px;
  display: flex;
  justify-content: center;

  @media (max-width: ${SIZES.desktop}px) {
    width: 90%;
  }
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 14, 77, 0.8);
  height: calc(50vh);
  max-height: 350px;
  border-radius: 8px;
  width: 932px;

  @media (max-width: ${SIZES.desktop}px) {
    width: 90%;
  }
`;

export const Background = styled.section`
  width: 932px;
  height: calc(50vh);
  max-height: 350px;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: ${SIZES.desktop}px) {
    width: 90%;
  }

  video {
    object-fit: cover;
    object-position: center;
  }
`;

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  box-sizing: border-box;
  width: 932px;
  align-items: center;

  @media (max-width: ${SIZES.desktop}px) {
    width: 90%;
    left: 5%;
  }
`;

export const Title = styled.h2`
  max-width: 70%;
  font-family: 'UnileverBold';
  font-size: 30px;
  margin: 0;
  color: ${COLORS.linkWater};
  text-align: center;
  @media (max-width: ${SIZES.desktop}px) {
    font-size: 22px;
  }
`;

export const Subtitle = styled(Title)`
  font-size: 22px;
  margin-top: 10px;
  text-align: center;

  @media (max-width: ${SIZES.desktop}px) {
    font-size: 14px;
  }
`;

export const VideoButtonWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`;

export const VideoPlayButtonWrapper = styled.div`
  width: 150px;
  height: 75px;
  position: absolute;
  left: 70px;
  top: 3px;
  cursor: pointer;
  @media (max-width: ${SIZES.smallTablet}px) {
    left: 40px;
    top: -4px;
  }
`;

export const VideoButtonText = styled.p`
  font-family: 'Unilever';
  font-size: 22px;
  color: ${COLORS.linkWater};
  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 18px;
  }
`;

export const CustomReactPlayer = styled(ReactPlayer)`
  position: fixed;
  z-index: 1;
  top: 8%;
  left: 0;
  pointer-events: none;
`;

export const ModalVideo = styled(Modal)`
  && {
    max-width: 100%;
  }
`;

export const PlayerContainer = styled.div`
  position: absolute;
`;

export const PlayPauseDiv = styled.div`
  width: 100%;
  height: 90%;
  position: fixed;
  z-index: 1000;
  top: 7%;
  left: 0;
  cursor: pointer;
`;

export const PlayPauseButtonWrapper = styled.div`
  position: absolute;
  z-index: 100;
  height: 200px;
  width: 200px;
  top: calc(50% - 85px);
  left: calc(50% - 100px);
  cursor: pointer;
  @media (max-width: ${SIZES.smallTablet}px) {
    height: 100px;
    width: 100px;
    top: calc(50% - 15px);
    left: calc(50% - 50px);
  }
`;
