import React, { useState, useEffect, FunctionComponent } from 'react';
import { SIZES } from 'helpers/enums/sizes';

import { itemsListProps } from './types';
import {
  Container,
  Column,
  CustomList,
  CustomMenuItem,
  DetailsContainer,
  RowDiv,
  Arrow,
} from './styles';

const ItemsListWithShowSection: FunctionComponent<itemsListProps> = (props: itemsListProps) => {
  const {
    items,
    renderItem,
    renderItemSelected,
    selected,
    setSelected,
    renderGoToListText,
    emptyListText,
  } = props;

  const widthOfTablet = window.innerWidth < SIZES.bigTablet;
  const [isTablet, setIsTablet] = useState<boolean>(widthOfTablet);
  const [listView, setListView] = useState(true);

  useEffect(() => {
    function handleResize(): void {
      if (window.innerWidth > SIZES.bigTablet) {
        setIsTablet(false);
      } else {
        setIsTablet(true);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const onMenuItemClick = (item: any) => {
    setSelected(item);
    setListView(false);
  };

  const renderGoToList = () => (
    <RowDiv onClick={() => setListView(true)} data-testid="go-to-list-button">
      <Arrow />
      {renderGoToListText()}
    </RowDiv>
  );

  return (
    <div data-testid="items-list-with-show-section-component">
      {items.length ? (
        <Container>
          {(!isTablet || listView) && (
            <Column>
              <CustomList data-testid="custom-list">
                {items.map((item) => (
                  <CustomMenuItem
                    key={item.id}
                    button
                    onClick={() => onMenuItemClick(item)}
                    selected={!!selected && selected.id === item.id}
                    data-testid={`custom-menu-item-${item.id}`}
                  >
                    {renderItem(item)}
                  </CustomMenuItem>
                ))}
              </CustomList>
            </Column>
          )}
          {(!isTablet || !listView) && (
            <DetailsContainer>
              {isTablet && renderGoToList()}
              {selected && renderItemSelected(selected)}
            </DetailsContainer>
          )}
        </Container>
      ) : (
        <h1>{emptyListText}</h1>
      )}
    </div>
  );
};

export default ItemsListWithShowSection;
