import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'SIGNUP_CHOOSE_TYPE', {
  CREATE_COMPANY: 'Crear Empresa',
  CREATE_USER: 'Crear Colaborador',
  NEXT_BUTTON: 'Siguiente',
  USER_INFORMATION:
    '¡Si quieres registrarte como usuario de una empresa ya existente, haz click aquí!',
  COMPANY_INFORMATION: '¡Si quieres registrar una empresa , haz click aquí!',
});
