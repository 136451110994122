import React, { FunctionComponent, useState } from 'react';
import i18next from 'i18next';
import { IUser } from 'types/company.types';
import TrainingProgress from 'components/commons/TrainingsProgress';
import { ICapsule } from 'types/capsules.types';
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { tableCellClasses } from '@mui/material';
import { COLORS } from 'helpers/enums/colors';
import { Role } from 'helpers/enums/roles';
import Capsule from './components/Capsule';

import { IEmployeesViewProps } from './types';
import {
  ProgramsContainer,
  TabsContainer,
  Title,
  ProgressContainer,
  Capsules,
  CapsulesContainer,
  EmployeeContainer,
  RightContainer,
  CapsuleContainer,
  PercentageCompleted,
  List,
  MoreInformation,
  Check,
  Back,
} from './styles';
import './i18n';
import UserStatus from './components/UserStatus';
import UserRole from './components/UserRole';

const EmployeesView: FunctionComponent<IEmployeesViewProps> = (props: IEmployeesViewProps) => {
  const { users, capsules, onChangeStatus, onChangeRole, userActive } = props;
  const [userSelected, setUserSelected] = useState('');
  const [capsuleSelected, setCapsuleSelected] = useState<ICapsule>();

  const getPercentage = (userId: string) => {
    let countCompleted = 0;
    let totalTrainings = 0;
    capsules.map((capsule) => {
      const { trainings } = capsule;
      const capsuleTrainings = trainings[new Date().getFullYear()];

      capsuleTrainings.forEach((training) => {
        if (training.completedTrainings.length) {
          training.completedTrainings.forEach((completedTraining) => {
            if (completedTraining.userId === userId) {
              countCompleted += 1;
            }
          });
        }
        totalTrainings += 1;
      });
      return capsule;
    });
    const percentage = (countCompleted * 100) / totalTrainings;
    return <div>{percentage.toFixed(2)}%</div>;
  };

  const onChange = (userStatus: boolean, user: IUser) => {
    onChangeStatus(userStatus, user);
  };

  const onChangeRoleToOwner = (user: IUser) => {
    onChangeRole(user);
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'transparent',
      color: `${COLORS.blueNavy}`,
      fontWeight: 'bold',
      width: '120px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: '#63636a',
    },
  }));
  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
      backgroundColor: `${COLORS.grayCatskill}`,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const renderUserInfoTable = (user: IUser) => {
    return (
      <StyledTableRow>
        <StyledTableCell>{user.name}</StyledTableCell>
        <StyledTableCell>{user.email}</StyledTableCell>
        <StyledTableCell>
          <UserStatus
            userStatus={user.status === 2}
            onClick={(userStatus) => {
              onChange(userStatus, user);
            }}
            disabled={user.id === userActive.id}
          />
        </StyledTableCell>
        <StyledTableCell>
          <UserRole
            isOwner={user.role === Role.COMPANY_OWNER}
            onClick={() => {
              onChangeRoleToOwner(user);
            }}
          />
        </StyledTableCell>
        <StyledTableCell>
          <PercentageCompleted>
            {getPercentage(user.id)} {i18next.t('EMPLOYEES_VIEW:COMPLETED')}
          </PercentageCompleted>
        </StyledTableCell>
        <StyledTableCell>
          <MoreInformation
            type="button"
            onClick={() => {
              setUserSelected(user.id);
            }}
          >
            {i18next.t('EMPLOYEES_VIEW:MORE_INFORMATION')}
          </MoreInformation>
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  const renderUsersTable = () => {
    return (
      <div>
        <TabsContainer>
          <Title>{i18next.t('EMPLOYEES_VIEW:MY_EMPLOYEES')}</Title>
        </TabsContainer>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>{i18next.t('EMPLOYEES_VIEW:NAME')}</StyledTableCell>
                <StyledTableCell>{i18next.t('EMPLOYEES_VIEW:EMAIL')}</StyledTableCell>
                <StyledTableCell>{i18next.t('EMPLOYEES_VIEW:STATUS')}</StyledTableCell>
                <StyledTableCell>{i18next.t('EMPLOYEES_VIEW:ROLE')}</StyledTableCell>
                <StyledTableCell>{i18next.t('EMPLOYEES_VIEW:TRAININGS')}</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                return renderUserInfoTable(user);
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const renderCapsules = (userId: string) => {
    return (
      <CapsulesContainer>
        {capsules.map((capsule) => (
          <Capsule
            capsule={capsule}
            key={capsule.id}
            userId={userId}
            onClick={() => setCapsuleSelected(capsule)}
          />
        ))}
      </CapsulesContainer>
    );
  };

  return (
    <>
      {userSelected === '' ? (
        <ProgramsContainer>
          <Capsules>
            <EmployeeContainer>
              {renderUsersTable()}
              <ProgressContainer>
                <RightContainer>
                  <Title>{i18next.t('EMPLOYEES_VIEW:TITLE_INFORMATION')}</Title>
                  <p>{i18next.t('EMPLOYEES_VIEW:IN_THIS_SECTION')}</p>
                  <List>
                    <li>
                      <Check />
                      {i18next.t('EMPLOYEES_VIEW:EMPLOYEE_STATUS')}
                    </li>
                    <li>
                      <Check />
                      {i18next.t('EMPLOYEES_VIEW:EMPLOYEE_TRAININGS')}
                    </li>
                    <li>
                      <Check />
                      {i18next.t('EMPLOYEES_VIEW:ADD_OWNERS')}
                    </li>
                  </List>
                  <p>
                    {i18next.t('EMPLOYEES_VIEW:TEXT_INFORMATION')}
                    {i18next.t('EMPLOYEES_VIEW:ADD_COLLABORATORS')}{' '}
                    <a href={i18next.t('EMPLOYEES_VIEW:UNIPYMELATAM_HREF')}>
                      {i18next.t('EMPLOYEES_VIEW:UNIPYMELATAM_HREF_LABEL')}
                    </a>
                  </p>
                </RightContainer>
              </ProgressContainer>
            </EmployeeContainer>
          </Capsules>
        </ProgramsContainer>
      ) : (
        <CapsuleContainer>
          <Back
            type="button"
            onClick={() => {
              setUserSelected('');
            }}
          >
            {i18next.t('EMPLOYEES_VIEW:BACK')}
          </Back>
          <Capsules>
            <EmployeeContainer>
              {renderCapsules(userSelected)}
              <ProgressContainer>
                {capsuleSelected && (
                  <TrainingProgress capsule={capsuleSelected} userId={userSelected} />
                )}
              </ProgressContainer>
            </EmployeeContainer>
          </Capsules>
        </CapsuleContainer>
      )}
    </>
  );
};

export default EmployeesView;
