export enum SIGN_UP_STEPS {
  STEP_CHOOSE_TYPE,
  STEP_COUNTRY,
  STEP_FORM,
  STEP_USER_INFORMATION,
}

export enum SIGN_UP {
  STEP_COMPANY_FORM,
  STEP_USER_FORM,
}
