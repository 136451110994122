import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/news.constants';
import * as appConstants from 'redux/constants/app.constants';
import { getAllNews } from 'services/news.service';

export function* getNews() {
  try {
    const data: unknown = yield call(getAllNews);
    yield put({ type: constants.NEWS_ON_GET_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.NEWS_ON_GET_FAILED, error });
  }
}

export function* watchNews() {
  yield all([
    takeLatest(
      [appConstants.APP_ON_INITIALIZE_REQUESTED, constants.NEWS_ON_GET_REQUESTED],
      getNews,
    ),
  ]);
}
