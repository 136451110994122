import { FunctionComponent } from 'react';

import { IBenefitDefaultState } from 'types/benefit.types';
import * as constants from 'redux/constants/benefit.constants';

const defaultState: IBenefitDefaultState = {
  items: [],
  loading: false,
};

const benefitReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { data, type } = action;
  switch (type) {
    case constants.BENEFITS_ON_REQUEST_OWNER_ACTIVATION_REQUESTED:
    case constants.BENEFITS_ON_ACTIVATE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.BENEFITS_ON_GET_SUCCEEDED:
      return {
        ...state,
        items: data.benefits,
      };
    case constants.BENEFITS_ON_REQUEST_OWNER_ACTIVATION_SUCCEEDED:
    case constants.BENEFITS_ON_ACTIVATE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        items: state.items.map((benefit: any) => ({
          ...benefit,
          pending: benefit.id === action.benefitId ? false : benefit.pending,
        })),
      };
      break;
    case constants.BENEFITS_ON_GET_FAILED:
    default:
      return state;
  }
};

export default benefitReducer;
