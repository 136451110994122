import styled from 'styled-components';

import { CustomButton } from 'components/commons/Button/styles';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;

export const TextContainer = styled.div`
  margin: 10px;
  font-size: 18px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    font-size: 16px;
  }
`;

export const Text = styled.span`
  font-family: OpenSans;
`;

export const Bold = styled.span`
  font-family: OpenSansBold;
`;

export const Button = styled(CustomButton)`
  background-color: ${COLORS.purple};
  padding: 12px 68px;
  margin-top: 42px;
`;

export const Legal = styled.div`
  margin-top: 100px;
  font-family: OpenSans;
  font-size: 12px;
  background: ${COLORS.white};
  padding: 15px;
  text-align: start;
`;
