import React, { FunctionComponent, useState } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import i18next from 'i18next';

import { requiredValidation } from 'helpers/validations';
import countryStates from 'helpers/json/country-states.json';

import { ICountryStateData } from 'types/locality.types';
import { ICountry } from 'types/country.types';
import Select from 'components/inputs/Select';
import { ISelectCountrySignUpFormProps, ISelectCountrySignUpFormData } from './types';
import { Form, ButtonContainer, FormButton, Subtitle, SelectStateFieldContainer } from './styles';
import './i18n';

const SelectCountrySignUpForm: FunctionComponent<
  ISelectCountrySignUpFormProps &
    InjectedFormProps<ISelectCountrySignUpFormData, ISelectCountrySignUpFormProps>
> = (props) => {
  const { isCompanyForm, handleSubmit, onSubmit, countries, onClickBack, reset } = props;
  const [selectedCountryStates, setSelectedCountryStates] = useState<string[]>([]);

  const countriesNames = countries.map((country: ICountry) => country.name);
  const onChange = (country: any) => {
    const currentCountryStateData = countryStates.find(
      (state: ICountryStateData) => state.country === country,
    );
    if (currentCountryStateData) {
      setSelectedCountryStates(currentCountryStateData.data);
    }
    reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Subtitle> {i18next.t('SELECT_COUNTRY_SIGN_UP:SUBTITLE')}</Subtitle>
      {countries.length >= 1 && (
        <Field
          component={Select}
          name="country"
          validate={[requiredValidation]}
          options={countriesNames}
          label={i18next.t('SELECT_COUNTRY_SIGN_UP:SELECT_COUNTRY')}
          onChange={onChange}
        />
      )}
      {isCompanyForm && (
        <SelectStateFieldContainer>
          {selectedCountryStates.length >= 1 && (
            <Field
              component={Select}
              name="state"
              validate={[requiredValidation]}
              options={selectedCountryStates}
              label={i18next.t('SELECT_COUNTRY_SIGN_UP:SELECT_STATE')}
            />
          )}
        </SelectStateFieldContainer>
      )}
      <ButtonContainer>
        <FormButton type="button" onClick={onClickBack}>
          {i18next.t('SELECT_COUNTRY_SIGN_UP:BACK_BUTTON')}
        </FormButton>
        <FormButton type="submit">{i18next.t('SELECT_COUNTRY_SIGN_UP:NEXT_BUTTON')}</FormButton>
      </ButtonContainer>
    </Form>
  );
};

export default reduxForm<ISelectCountrySignUpFormData, ISelectCountrySignUpFormProps>({
  form: 'selectCountry',
})(SelectCountrySignUpForm);
