import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.p`
  font-family: OpenSansBold;
  font-size: 40px;
  color: ${COLORS.white};
  margin: 0 0 18px 0;
  align-self: center;
  width: 95%;
  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 36px;
  }
`;

export const Subtitle = styled.p`
  font-family: OpenSans;
  font-size: 18px;
  color: ${COLORS.white};
  width: 90%;
  align-self: center;
  margin: 0;
`;
