import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useTypedSelector from 'hooks/useTypedSelector';

import LayoutContainer from 'containers/LayoutContainer';
import ProgramsView from 'views/ProgramsView';
import { CompanyType } from 'helpers/programs';
import { onCompleteForms, onGetForms } from 'redux/actions/user.actions';
import { Sections } from 'helpers/enums/sections';
import { getFormQuestionsAndAnswers } from 'helpers/forms';
import { IForm } from 'types/form.types';

const ProgramsContainer: FunctionComponent = () => {
  const programs = useTypedSelector((state) => state.programs.items);
  const capsules = useTypedSelector((state) => state.capsules.items);
  const company = useTypedSelector((state) => state.company.data);
  const user = useTypedSelector((state) => state.company.user);
  const forms = useTypedSelector((state) => state.company.user?.forms);
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState({ title: '', id: '', type: 0 });

  const programUccelerator = programs.find((program) => program.type === CompanyType.UCCELERATOR);

  const currentCapsules = capsules
    .filter((capsule) => capsule.programId === activeTab.id)
    .sort(
      (a: any, b: any) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime(),
    );

  const setUcceleratorTab = () => {
    if (programUccelerator) {
      setActiveTab({
        title: programUccelerator.title,
        id: programUccelerator.id,
        type: programUccelerator.type,
      });
    }
  };

  useEffect(() => {
    if (programs.length) {
      if (!!company && company.type === CompanyType.UCCELERATOR) {
        setUcceleratorTab();
      }
    }
    if (!forms) dispatch(onGetForms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs, company, dispatch]);

  const changeTab = () => {
    if (activeTab.type === CompanyType.UCCELERATOR) {
      setUcceleratorTab();
    }
  };

  const completeForm = (form: IForm, values: any) => {
    const completedFormData = {
      formId: form.id,
      formAnswers: getFormQuestionsAndAnswers(form, values),
    };
    dispatch(onCompleteForms(completedFormData));
  };

  return (
    <LayoutContainer forms={forms} section={Sections.PROGRAMS} completeForm={completeForm}>
      {!!programs && !!company && !!user && (
        <ProgramsView
          user={user}
          changeTab={changeTab}
          capsules={currentCapsules}
          activeTab={activeTab}
          programs={programs}
          forms={forms}
        />
      )}
    </LayoutContainer>
  );
};

export default ProgramsContainer;
