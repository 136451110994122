import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const Background = styled.div`
  background: ${COLORS.whiteGhost};
`;

export const Container = styled.div`
  display: flex;
  max-width: 1024px;
  margin: 0px auto;
  padding: 80px 0;
  position: relative;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${SIZES.smallDesktop}px) {
    width: 80%;
  }

  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 90%;
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 100%;
  }
`;

export const Title = styled.p`
  font-family: UnileverMedium;
  font-size: 40px;
  color: ${COLORS.bluePersian};
  @media (max-width: ${SIZES.mediumTablet}px) {
    text-align: center;
  }
  @media (max-width: ${SIZES.smallDesktop}px) {
    font-size: 36px;
  }
`;

export const Text = styled.p`
  font-family: OpenSans;
  font-size: 22px;
  color: ${COLORS.riverBed};

  @media (max-width: ${SIZES.mediumTablet}px) {
    text-align: center;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 20px;
  }
`;

export const Bold = styled.b`
  font-family: OpenSansBold;
`;

export const SubtitleContainer = styled.div``;

export const AnimationContainer = styled.div`
  position: relative;
  width: 60%;
  height: 350px;

  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 100%;
    height: 300px;
  }
  @media (max-width: ${SIZES.smallTablet}px) {
    height: 200px;
  }
`;
