import LogoBenefits from 'assets/imgs/logo-cdb.svg';
import LogoArgos from 'assets/imgs/logo-argos.svg';
import LogoLenovo from 'assets/imgs/logo-lenovo.svg';
import LogoCompraCierta from 'assets/imgs/logo-compra-cierta.svg';
import LogoWytoc from 'assets/imgs/logo-wytoc.svg';

export const partnerLogos = {
  LOGO_BENEFITS: LogoBenefits,
  LOGO_LENOVO: LogoLenovo,
  LOGO_COMPRA_CIERTA: LogoCompraCierta,
  LOGO_WYTOC: LogoWytoc,
  LOGO_ARGOS: LogoArgos,
};
