import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { HashLink } from 'react-router-hash-link';
import i18next from 'i18next';

import { requiredValidation } from 'helpers/validations';
import { localitiesFilter, provincesFilter } from 'helpers/address';
import useTypedSelector from 'hooks/useTypedSelector';
import { IFormQuestion, IFormAnswer } from 'views/FormView/types';

import Select from 'components/inputs/Select';
import RadioButton from 'components/inputs/RadioButton';
import RadioGroup from 'components/inputs/RadioGroup';

import { IFormInputProps } from './types';
import localities from './localidades.json';
import provinces from './provincias.json';
import {
  Form,
  Button,
  ButtonContainer,
  InputContainer,
  Question,
  QuestionContainer,
} from './styles';
import './i18n';

const localitiesFiltered = localitiesFilter(localities.localidades);

const provincesFiltered = provincesFilter(provinces.provincias);

const FormInput: FunctionComponent<
  IFormInputProps & InjectedFormProps<IFormAnswer[], IFormInputProps>
> = (props) => {
  const formAnswers = useTypedSelector((state) => state.form.questions);
  const [fiscalLocalitiesOptions, setFiscalOptions] = useState<string[]>([]);
  const [exploitLocalitiesOptions, setExploitOptions] = useState<string[]>([]);
  const { handleSubmit, questions, nextTab, nextTabName } = props;
  const prevFiscalProvince = useRef<string>('');
  const prevExploitProvince = useRef<string>('');

  useEffect(() => {
    const fiscalProvince: string = formAnswers?.values?.['4#Domicilio Fiscal#Provincia'];
    const exploitProvince: string =
      formAnswers?.values?.['5#Domicilio Explotación (Fábrica Principal)#Provincia'];
    if (fiscalProvince) {
      if (fiscalProvince !== prevFiscalProvince.current) {
        delete formAnswers.values['4#Domicilio Fiscal#Localidad'];
        if (!formAnswers.syncErrors) formAnswers.syncErrors = {};
        formAnswers.syncErrors['4#Domicilio Fiscal#Localidad'] = i18next.t(
          'VALIDATIONS:REQUIRED_FIELD',
        );
      }
      // Delete duplicates
      setFiscalOptions(() => [...new Set(localitiesFiltered[fiscalProvince])]);
      prevFiscalProvince.current = fiscalProvince;
    }
    if (exploitProvince) {
      if (exploitProvince !== prevExploitProvince.current) {
        delete formAnswers.values['5#Domicilio Explotación (Fábrica Principal)#Localidad'];
        if (!formAnswers.syncErrors) formAnswers.syncErrors = {};
        formAnswers.syncErrors['5#Domicilio Explotación (Fábrica Principal)#Localidad'] = i18next.t(
          'VALIDATIONS:REQUIRED_FIELD',
        );
      }
      // Delete duplicates
      setExploitOptions(() => [...new Set(localitiesFiltered[exploitProvince])]);
      prevExploitProvince.current = exploitProvince;
    }
  }, [formAnswers]);

  const scroll = (el: HTMLElement) => el.scrollIntoView(false);

  const renderQuestion = (question: IFormQuestion): JSX.Element | null => {
    const { id, title, required, options, type, subQuestion } = question;
    let input: JSX.Element | JSX.Element[] | null = null;

    if (type === 'selects') {
      if (options) {
        input = options.map((option) => {
          const name = `${id}#${title}#${option}`;
          if (option === 'Provincia') {
            return (
              <Field
                key={`question-id-${id}-${option}`}
                component={Select}
                name={name}
                label={option}
                options={provincesFiltered}
                validate={required ? [requiredValidation] : []}
              />
            );
          }
          return (
            <Field
              key={`question-id-${id}-${option}`}
              component={Select}
              name={name}
              label={option}
              options={
                title.includes('Fiscal') ? fiscalLocalitiesOptions : exploitLocalitiesOptions
              }
              validate={required ? [requiredValidation] : []}
            />
          );
        });
      }
    }

    if (type === 'select') {
      input = (
        <Field
          key={`question-id-${id}-${title}`}
          component={Select}
          name={`${id}#${title}#${subQuestion ? title : ''}`}
          validate={required ? [requiredValidation] : []}
          options={options}
        />
      );
    }

    if (type === 'boolean') {
      input = (
        <Field
          component={RadioGroup}
          validate={required ? [requiredValidation] : []}
          name={`${id}#${title}#${subQuestion ? title : ''}`}
        >
          {[i18next.t('FORM_INPUTS:YES'), i18next.t('FORM_INPUTS:NO')].map((label: string) => {
            return <RadioButton label={label} value={label} key={`${id}-${label}`} />;
          })}
        </Field>
      );
    }

    return (
      <QuestionContainer>
        <Question isRadio={type === 'boolean'}>{title}</Question>
        <InputContainer>{input}</InputContainer>
      </QuestionContainer>
    );
  };

  return (
    <Form data-testid="form-input-view">
      {questions.map((question: IFormQuestion) => renderQuestion(question))}
      <ButtonContainer>
        <HashLink to={`#${nextTabName}`} scroll={scroll}>
          <Button onClick={handleSubmit(nextTab)} data-testid="form-button-submit">
            {i18next.t('FORM_INPUTS:NEXT')}
          </Button>
        </HashLink>
      </ButtonContainer>
    </Form>
  );
};

export default reduxForm<IFormAnswer[], IFormInputProps>({
  form: 'questions',
})(FormInput);
