import React, { useState, ChangeEvent, FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { COLORS } from 'helpers/enums/colors';

import { IInputProps } from './types';
import { FormGroup, CustomInput, ErrorBlock } from './styles';

const Input: FunctionComponent<IInputProps> = (props: IInputProps) => {
  const {
    disabled = false,
    input,
    isLastChild,
    placeholder = '',
    maxLength = 255,
    meta: { touched, error },
    rows = 3,
    rowsMax = 3,
    size = 'medium',
    type = 'text',
    variant = 'outlined',
    className = '',
    label = '',
    darkMode = false,
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { onChange, value } = input;
  const isPasswordInput = type === 'password';
  const isTextAreaInput = type === 'textarea';
  const hasError = touched && !!error;

  const useStyles = makeStyles(() => ({
    input: {
      fontSize: '14px',
      color: darkMode ? COLORS.white : COLORS.riverBed,
      fontFamily: 'OpenSans',
      background: COLORS.white,
      backgroundColor: darkMode ? 'transparent' : 'auto',
      '&::placeholder': {
        textAlign: 'start',
        fontSize: '14px',
        color: COLORS.riverBed,
        fontFamily: 'OpenSans',
        '@media (max-width:768px)': { fontSize: '16px !important' },
      },
      '@media (max-width:768px)': { fontSize: '16px !important' },
    },
    cssLabel: {
      textAlign: 'center',
      '@media (max-width:768px)': { fontSize: '16px !important' },
    },
  }));
  const classes = useStyles();

  const getInputType = () => {
    let inputType = type;
    if (isPasswordInput) {
      inputType = showPassword ? 'text' : type;
    }
    return inputType;
  };

  return (
    <FormGroup
      hasMarginBottom={hasError && isPasswordInput}
      className={className}
      isLastChild={isLastChild}
    >
      <CustomInput
        {...input}
        disabled={disabled}
        error={hasError}
        inputProps={{ 'data-testid': `input-${placeholder}`, maxLength }}
        placeholder={placeholder}
        multiline={isTextAreaInput}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        rows={rows}
        rowsMax={rowsMax}
        size={size}
        type={getInputType()}
        value={value}
        label={label}
        variant={variant}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          endAdornment: isPasswordInput && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                data-testid="toggle-password-button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          classes: { input: classes.input },
        }}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
          },
        }}
        darkMode={darkMode}
      />
      {hasError && <ErrorBlock data-testid="error-block">{error}</ErrorBlock>}
    </FormGroup>
  );
};

export default Input;
