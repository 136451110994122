import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'SIGNUP_COMPANY_FORM', {
  COMPANY_NAME: 'Nombre de la empresa',
  NEXT_BUTTON: 'Siguiente',
  BACK_BUTTON: 'Anterior',
  TITLE: 'Datos de tu empresa',
  COMPANY_SPECIALTY: 'Describa la especialidad de su empresa',
  IS_PROVIDER_OR_CLIENT: '¿Es Proveedor o Cliente/distribuidor?',
  PROVIDER: 'Proveedor',
  CLIENT: 'Cliente/Distribuidor',
  OTHER: 'Otros',
  SELECT_NUMBER_OF_COLLABORATORS: 'Cantidad de colaboradores ',
  COMPANY_SPECIALTY_PLACEHOLDER: 'Ej. Agencia especializada en marketing digital',
  HOW_DID_YOU_HEAR_ABOUT_US: '¿Cómo conocieron UniPyME?',
  SELECT_SECTOR: '¿Cuál es el sector de su pyme?',
  SELECT_SUB_SECTOR: 'Elija rubro',
});
