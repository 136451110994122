import React, { FunctionComponent, useState } from 'react';
import i18next from 'i18next';
import { useLocation } from 'react-router-dom';

import { ArrowDropDown } from '@material-ui/icons';
import { Status } from 'helpers/enums/status';
import { getInformativeCountry } from 'helpers/countries';
import Logo from 'components/commons/Logo';
import { MenuRoutes } from 'helpers/enums/links';
import CoinIconSVG from 'assets/imgs/coin-icon.svg';

import { IDashboardHeaderProps } from './types';
import {
  Container,
  Content,
  ProfileDiv,
  Text,
  CompanyName,
  DivLi,
  CustomButton,
  RowDiv,
  DropDownContent,
  ArrowContainer,
  InfoDiv,
  InfoTitle,
  Divider,
  TextCompany,
  MenuOverlay,
  MenuIconContainer,
  OpenMenuIcon,
  CloseMenuIcon,
  RouterLink,
  MobileDivider,
  InfoContainer,
  Flag,
  BenefitsButton,
  TopBarRowDiv,
  BenefitsBanner,
  PendingBenefitsText,
  TextBold,
  LogoContainer,
  CoinIcon,
  CoinsLink,
} from './styles';
import './i18n';

const DashboardHeader: FunctionComponent<IDashboardHeaderProps> = (
  props: IDashboardHeaderProps,
) => {
  const {
    company,
    isMenuOpened,
    isMenuCollapsed,
    toggleMenu,
    onLogoutClick,
    user,
    pendingBenefits,
    onRedirectToBenefitsClick,
    companyPosition,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  const MenuIcon = isMenuOpened ? CloseMenuIcon : OpenMenuIcon;
  const isActive = user?.status === Status.ACTIVE;
  const showBenefitsBanner =
    !!pendingBenefits.length &&
    user?.country.name !== 'Colombia' &&
    pathname !== MenuRoutes.BENEFITS;

  const renderDropDown = () => (
    <DropDownContent isVisible={isOpen} isActive={isActive}>
      {isActive && (
        <DivLi data-testid="nav-link-logout">
          <RouterLink to="/perfil">
            <CustomButton isDisplayed={isOpen}>
              {i18next.t('DASHBOARD_HEADER:SEE_PROFILE')}
            </CustomButton>
          </RouterLink>
        </DivLi>
      )}
      <DivLi data-testid="nav-link-logout" onClick={onLogoutClick}>
        <CustomButton isDisplayed={isOpen}>{i18next.t('DASHBOARD_HEADER:LOGOUT')}</CustomButton>
      </DivLi>
    </DropDownContent>
  );
  const companyInformativeCountry = user && getInformativeCountry(user.country.name);
  const renderCompanyInfo = () => (
    <InfoContainer showSideMenu={isMenuOpened} showBenefitsBanner={showBenefitsBanner}>
      <InfoDiv>
        <Text>{i18next.t('DASHBOARD_HEADER:RANKING')}</Text>
        <InfoTitle> {`#${companyPosition}`} </InfoTitle>
      </InfoDiv>
      <InfoDiv>
        <Text>{i18next.t('DASHBOARD_HEADER:TOTAL_POINTS')}</Text>
        <InfoTitle> {company?.points} </InfoTitle>
      </InfoDiv>
      <CoinsLink to={MenuRoutes.BENEFITS}>
        <CoinIcon src={CoinIconSVG} />
        <InfoTitle> {company?.coins} </InfoTitle>
      </CoinsLink>

      <MobileDivider isLast />
      <RowDiv>
        <RouterLink to="/perfil">
          <CustomButton isDark>{i18next.t('DASHBOARD_HEADER:SEE_PROFILE')}</CustomButton>
        </RouterLink>
        <DivLi data-testid="nav-link-logout" onClick={onLogoutClick}>
          <CustomButton isDark>{i18next.t('DASHBOARD_HEADER:LOGOUT')}</CustomButton>
        </DivLi>
      </RowDiv>
    </InfoContainer>
  );

  return (
    <>
      <Container data-testid="dashboard-header">
        <Content>
          {isMenuCollapsed && (
            <LogoContainer>
              <Logo />
            </LogoContainer>
          )}
          <MenuOverlay data-testid="menu-overlay" onClick={toggleMenu} showOverlay={isMenuOpened} />
          <TopBarRowDiv>
            {isActive && (
              <>
                <MenuIconContainer>
                  <MenuIcon onClick={toggleMenu} />
                </MenuIconContainer>

                {renderCompanyInfo()}
              </>
            )}
            <Flag src={companyInformativeCountry?.image} alt={companyInformativeCountry?.name} />
            <ProfileDiv
              isActive={isActive}
              onClick={() => setIsOpen(!isOpen)}
              data-testid="open-menu"
            >
              <Divider isVisible={isActive} />
              <TextCompany>
                {i18next.t('DASHBOARD_HEADER:HI')}
                <CompanyName>{user?.name}</CompanyName>
              </TextCompany>
              <ArrowContainer>
                <ArrowDropDown />
              </ArrowContainer>
              {renderDropDown()}
            </ProfileDiv>
          </TopBarRowDiv>
        </Content>
        {showBenefitsBanner && (
          <BenefitsBanner>
            <PendingBenefitsText>
              {pendingBenefits.length > 1
                ? i18next.t('DASHBOARD_HEADER:MANY_PENDING_BENEFITS', {
                    pendingBenefits: pendingBenefits.length,
                  })
                : i18next.t('DASHBOARD_HEADER:ONE_PENDING_BENEFIT')}
            </PendingBenefitsText>
            <BenefitsButton onClick={onRedirectToBenefitsClick}>
              <TextBold>{i18next.t('DASHBOARD_HEADER:GO_TO_BENEFITS')}</TextBold>
            </BenefitsButton>
          </BenefitsBanner>
        )}
      </Container>
    </>
  );
};

export default DashboardHeader;
