import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'PROFILE_FORM', {
  TEXT: 'Ingrese los mails de empleados que tomaran las capacitaciones',
  MAILS: 'Ingrese las direcciones de mail separadas con una coma (,)',
  NAME: 'Nombre de la empresa',
  CUIT: 'CUIT',
  EMAIL: 'Email',
  PHONE: 'Teléfono',
  SPECIALTY: 'Describa la especialidad de su empresa',
  SECTOR: '¿Cuál es el sector de su pyme?',
  SUB_SECTOR: 'Rubro',
  SUB_SECTOR_OTHER: 'Rubro: Otros',
  COLLABORATORS: 'Cantidad de colaboradores ',
  HOW_DID_YOU_HEAR_ABOUT_US: '¿Cómo conocieron UniPyME?',
  IS_PROVIDER_OR_CLIENT: '¿Es Proveedor o Cliente/distribuidor?',
  SUBSCRIBED_TO_REMINDER: 'Suscripto a la casilla de mails',
  ACCEPT_PROMOTIONS: 'Suscripto a información, promociones y encuestas',
  PROVIDER: 'Proveedor',
  CLIENT: 'Cliente/Distribuidor',
  SELECT_STATE: 'Seleccione ubicación',
});
