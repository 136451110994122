import * as constants from 'redux/constants/header.constants';

export function onMenuCollapsed(): { type: string } {
  return {
    type: constants.MENU_COLLAPSED_REQUESTED,
  };
}

export function onClosePopup(): { type: string } {
  return {
    type: constants.LAYOUT_CONFIRMATION_POPUP_CLOSED,
  };
}
