import React, { FunctionComponent, useEffect, useState } from 'react';
import i18next from 'i18next';

import { SIGN_UP_STEPS, SIGN_UP } from 'helpers/signUpSteps';
import SignUpForm from 'views/SignUpView/components/SignUpForm';
import SignUpCompanyForm from 'views/SignUpView/components/SignUpCompanyForm';
import { ICountry } from 'types/country.types';
import { companySubSectors } from 'helpers/enums/companySubSector';

import SelectCountrySignUpForm from './components/SelectCountrySignUpForm';
import SignUpUserForm from './components/SignUpUserForm';
import { ISignUpFormData, ISignUpViewProps } from './types';
import {
  SignUpContainer,
  SignUpContent,
  Title,
  SignUpFormContainer,
  LoginContainer,
  LoginLink,
  Text,
  TextContainer,
  HelpText,
  MailLink,
} from './styles';
import './i18n';
import { ISelectCountrySignUpFormData } from './components/SelectCountrySignUpForm/types';
import { ISignUpCompanyFormData } from './components/SignUpCompanyForm/types';
import { ISignUpUserFormData } from './components/SignUpUserForm/types';
import StepWizard from './components/StepWizard';
import ChooseSignUpType from './components/ChooseSignUpType';

const SignUpView: FunctionComponent<ISignUpViewProps> = (props: ISignUpViewProps) => {
  const {
    onValidateIdentifier,
    onSubmit,
    countries,
    isIdentifierValid,
    onFormBack,
    companySectorOptions,
    numberOfCollaboratorsOptions,
    howDidYouHearAboutUsOptions,
  } = props;

  const [step, setStep] = useState(SIGN_UP_STEPS.STEP_CHOOSE_TYPE);
  const [type, setType] = useState(SIGN_UP.STEP_COMPANY_FORM);
  const [countrySelected, setCountrySelected] = useState<ICountry>();
  const [stateSelected, setStateSelected] = useState<string>('');
  const [companyData, setCompanyData] = useState<ISignUpCompanyFormData>();
  const [activeStep, setActiveStep] = useState<number>(-1);

  const handleOnSubmitStepCountry = (formData: ISelectCountrySignUpFormData) => {
    setActiveStep(activeStep + 1);
    setCountrySelected(countries.find((i) => i.name === formData.country));
    setStateSelected(formData.state);
    setStep(SIGN_UP_STEPS.STEP_FORM);
  };

  useEffect(() => {
    if (isIdentifierValid) {
      setActiveStep(activeStep + 1);
      setStep(SIGN_UP_STEPS.STEP_USER_INFORMATION);
    }
  }, [isIdentifierValid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStep(SIGN_UP_STEPS.STEP_CHOOSE_TYPE);
    setType(SIGN_UP.STEP_COMPANY_FORM);
    setCountrySelected(undefined);
    setStateSelected('');
    setCompanyData(undefined);
    setActiveStep(-1);
  }, []);

  const handleOnSubmitCompanySignUp = async (formData: ISignUpCompanyFormData) => {
    setCompanyData(formData);
    const validateIdentifier = {
      cuit: formData.cuit,
      countryId: countrySelected?.id,
      creatingCompany: type === SIGN_UP.STEP_COMPANY_FORM,
    };

    onValidateIdentifier(validateIdentifier);
  };

  const handleOnSubmitUserSignUp = async (formData: ISignUpUserFormData) => {
    setCompanyData(formData);

    const validateIdentifier = {
      cuit: formData.cuit,
      countryId: countrySelected?.id,
      creatingCompany: type === SIGN_UP.STEP_COMPANY_FORM,
    };
    onValidateIdentifier(validateIdentifier);
  };

  const handleOnSubmitSignUp = (formData: ISignUpFormData) => {
    const signUpData = formData;
    signUpData.creatingCompany = type === SIGN_UP.STEP_COMPANY_FORM;
    if (companyData) {
      signUpData.companyName = companyData.companyName;
      signUpData.cuit = companyData.cuit;
      signUpData.collaborators = numberOfCollaboratorsOptions.find(
        (i) => i.label === companyData.collaborators,
      )?.value;
      signUpData.companyRole = companyData.companyRole;
      signUpData.specialty = companyData.specialty;
      signUpData.sector = companySectorOptions.find((i) => i.label === companyData.sector)?.value;
      signUpData.hearAboutUs = howDidYouHearAboutUsOptions.find(
        (i) => i.label === companyData.hearAboutUs,
      )?.value;
      signUpData.subSector = companySubSectors.find(
        (companySubSector) => companySubSector.label === companyData.subSector,
      )?.value;
      signUpData.subSectorOtherText = companyData.subSectorOtherText;
    }

    if (countrySelected) {
      signUpData.countryId = countrySelected.id;
      signUpData.state = stateSelected;
      onSubmit(formData);
    }
  };

  const onSelectStepCountry = () => {
    setActiveStep(activeStep + 1);
    setStep(SIGN_UP_STEPS.STEP_COUNTRY);
  };
  const onClickCompany = () => {
    setStep(SIGN_UP_STEPS.STEP_COUNTRY);
    setType(SIGN_UP.STEP_COMPANY_FORM);
    setActiveStep(activeStep + 1);
  };

  const onClickUser = () => {
    setStep(SIGN_UP_STEPS.STEP_COUNTRY);
    setType(SIGN_UP.STEP_USER_FORM);
    setActiveStep(activeStep + 1);
  };

  const onClickBack = () => {
    if (step === SIGN_UP_STEPS.STEP_USER_INFORMATION) {
      onFormBack();
    }
    if (activeStep !== -1 && step !== 0) {
      setActiveStep(activeStep - 1);
      setStep(step - 1);
    }
  };

  return (
    <SignUpContainer data-testid="signup-view">
      <SignUpContent>
        <Title>{i18next.t('SIGNUP_VIEW:SIGNUP')}</Title>
        {step !== SIGN_UP_STEPS.STEP_CHOOSE_TYPE && (
          <StepWizard steps={['', '', '']} activeStep={activeStep} />
        )}
        {step === SIGN_UP_STEPS.STEP_CHOOSE_TYPE && (
          <ChooseSignUpType onClickCompany={onClickCompany} onClickUser={onClickUser} />
        )}
        {step === SIGN_UP_STEPS.STEP_COUNTRY && (
          <SignUpFormContainer>
            <SelectCountrySignUpForm
              onSubmit={handleOnSubmitStepCountry}
              onClickBack={onClickBack}
              countries={countries}
              isCompanyForm={type === SIGN_UP.STEP_COMPANY_FORM}
            />
          </SignUpFormContainer>
        )}
        {step === SIGN_UP_STEPS.STEP_FORM && type === SIGN_UP.STEP_COMPANY_FORM && (
          <SignUpFormContainer>
            <SignUpCompanyForm
              onClickBack={onClickBack}
              onSubmit={handleOnSubmitCompanySignUp}
              country={countrySelected || countries[0]}
              onSelectStepCountry={onSelectStepCountry}
              companySectorOptions={companySectorOptions}
              numberOfCollaboratorsOptions={numberOfCollaboratorsOptions}
              howDidYouHearAboutUsOptions={howDidYouHearAboutUsOptions}
            />
          </SignUpFormContainer>
        )}
        {step === SIGN_UP_STEPS.STEP_FORM && type === SIGN_UP.STEP_USER_FORM && (
          <SignUpFormContainer>
            <SignUpUserForm
              onClickBack={onClickBack}
              onSubmit={handleOnSubmitUserSignUp}
              country={countrySelected || countries[0]}
              onSelectStepCountry={onSelectStepCountry}
            />
          </SignUpFormContainer>
        )}
        {step === SIGN_UP_STEPS.STEP_USER_INFORMATION && (
          <SignUpFormContainer>
            <SignUpForm
              onClickBack={onClickBack}
              onSubmit={handleOnSubmitSignUp}
              country={countrySelected || countries[0]}
              onSelectStepCountry={onSelectStepCountry}
            />
          </SignUpFormContainer>
        )}

        <LoginContainer>
          {i18next.t('SIGNUP_VIEW:ALREADY_HAVE_ACCOUNT')}
          <LoginLink to="/login">{i18next.t('SIGNUP_VIEW:LOGIN')}</LoginLink>
          {step === SIGN_UP_STEPS.STEP_FORM && countrySelected && (
            <HelpText>
              {i18next.t('SIGNUP_VIEW:HELP_TEXT')}

              <MailLink
                href={`mailto:${i18next.t(
                  `SIGNUP_VIEW:MAIL_${countrySelected.name.toLocaleUpperCase()}`,
                )}`}
              >
                {i18next.t(`SIGNUP_VIEW:MAIL_${countrySelected.name.toLocaleUpperCase()}`)}
              </MailLink>
            </HelpText>
          )}
        </LoginContainer>
      </SignUpContent>
      <TextContainer>
        <Text>{i18next.t('SIGNUP_VIEW:INFO_SHARED')}</Text>
      </TextContainer>
    </SignUpContainer>
  );
};

export default SignUpView;
