import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'SIGNUP_VIEW', {
  ALREADY_HAVE_ACCOUNT: '¿Ya tenés cuenta?',
  LOGIN: 'Iniciar sesión',
  SIGNUP: 'REGISTRARSE',
  COUNTRIES: 'Países',
  INFO_SHARED:
    'LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.',
  HELP_TEXT: 'Para obtener ayuda por favor escribe a ',
  MAIL_ARGENTINA: 'unipyme.argentina@unilever.com',
  MAIL_COLOMBIA: 'unipyme.colombia@unilever.com',
});
