import React, { FunctionComponent, useEffect } from 'react';
import ReactModal from 'react-modal';

import { COLORS } from 'helpers/enums/colors';

import { IModalProps } from './types';
import {
  ModalContainer,
  ModalContent,
  CloseModalContainer,
  CloseModalIcon,
  ChildrenContainer,
  TitleContainer,
  ModalBackground,
} from './styles';

const Modal: FunctionComponent<IModalProps> = (props: IModalProps) => {
  const {
    children,
    disabled = false,
    hasInvisibleBackground = false,
    closeWithBackground = true,
    isOpen,
    onClose = () => {},
    title,
    className = '',
    isDark = false,
    backgroundColor,
    backgroundImage,
  } = props;

  ReactModal.defaultStyles.overlay = {
    ...ReactModal.defaultStyles.overlay,
    backgroundColor: backgroundColor || COLORS.modalBackground,
    zIndex: 10,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };
  useEffect(() => {
    // 27: Escape key
    const handleKeyUp = (event: KeyboardEvent) => event.keyCode === 27 && isOpen && onClose();
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  return (
    <ModalContainer ariaHideApp={false} isOpen={isOpen} className={className}>
      <ModalContent data-testid="modal" hasInvisibleBackground={hasInvisibleBackground}>
        {!disabled && (
          <CloseModalContainer hasInvisibleBackground={hasInvisibleBackground}>
            <CloseModalIcon data-testid="modal-close-button" isDark={isDark} onClick={onClose} />
          </CloseModalContainer>
        )}
        {!!title && <TitleContainer id="modal-title">{title}</TitleContainer>}
        <ChildrenContainer hasPadding={!!title}>{children}</ChildrenContainer>
        <ModalBackground
          onClick={closeWithBackground ? onClose : () => {}}
          hasInvisibleBackground={hasInvisibleBackground}
        />
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
