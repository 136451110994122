/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent, useState } from 'react';
import i18next from 'i18next';

import Logo from 'components/commons/Logo';
import { LandingLinks, LegalLinks } from 'helpers/enums/links';
import { SOCIAL_MEDIAS } from 'helpers/enums/socialMedias';

import { IFooter } from './types';
import {
  FooterContainer,
  FooterContent,
  LeftContainer,
  Copyright,
  CopyText,
  CopyTextMobile,
  RightContainer,
  LinksContainer,
  FooterLink,
  LegalLink,
  CustomLink,
  DivPrivacy,
  ProfileDiv,
  PrivacyDiv,
  SocialMediasContainer,
  Instagram,
  Facebook,
} from './styles';
import './i18n';

const Footer: FunctionComponent<IFooter> = (props: IFooter) => {
  const { isLanding, isHome, countries, isLogged } = props;
  const [isOpen, setIsOpen] = useState(false);

  const renderDropDown = () => (
    <DivPrivacy data-testid="nav-link-logout">
      {countries.map(
        (country) =>
          i18next.t(`FOOTER:PRIVACY_HREF_${country.name.toLocaleUpperCase()}`).includes('http') && (
            <CustomLink
              href={i18next.t(`FOOTER:PRIVACY_HREF_${country.name.toLocaleUpperCase()}`)}
              target="_blank"
              isDisplayed={isOpen}
            >
              {country.name}
            </CustomLink>
          ),
      )}
    </DivPrivacy>
  );

  return (
    <FooterContainer data-testid="footer-container" isBannerVisible={!isLogged && isHome}>
      <FooterContent isLanding={isLanding}>
        <LeftContainer>
          <Logo isDarkModeOn isLanding isWhiteLogo showUnileverLogo />
          <LinksContainer data-testid="footer-links-container">
            {LandingLinks.map((link) => (
              <FooterLink key={link.id}>
                <a href={link.to}>{link.text}</a>
              </FooterLink>
            ))}
            <CopyTextMobile>&copy; Unilever {new Date().getFullYear()}</CopyTextMobile>
          </LinksContainer>
        </LeftContainer>
        <RightContainer>
          <Copyright>
            <CopyText>&copy; Unilever {new Date().getFullYear()}</CopyText>

            <ProfileDiv
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              data-testid="open-menu"
            >
              <PrivacyDiv>{i18next.t(`FOOTER:PRIVACY_TEXT`)}</PrivacyDiv>
              {renderDropDown()}
            </ProfileDiv>
            {LegalLinks.map((link) => (
              <LegalLink href={link.href} key={link.id} target="_blank">
                {link.text}
              </LegalLink>
            ))}
          </Copyright>
          <SocialMediasContainer>
            <a target="_blank" rel="noopener noreferrer" href={SOCIAL_MEDIAS.facebook.link}>
              <Facebook />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={SOCIAL_MEDIAS.instagram.link}>
              <Instagram />
            </a>
          </SocialMediasContainer>
        </RightContainer>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
