import { ICompany } from 'types/company.types';

import { CompanyRole } from 'views/SignUpView/types';
import { CompanySectors } from './enums/companySectors';
import { CompanyNumberOfCollaborators } from './enums/companyNumberOfCollaborators';
import { CompanyHowDidYouHearAboutUs } from './enums/companyHowDidYouHearAboutUs';

export const isCompanyDataCompleted = (company: ICompany) =>
  company.state !== '' &&
  company.specialty !== '' &&
  company.sector !== CompanySectors.NOT_SPECIFIED &&
  company.collaborators !== CompanyNumberOfCollaborators.NOT_SPECIFIED &&
  company.companyRole !== CompanyRole.NOT_SPECIFIED &&
  company.hearAboutUs !== CompanyHowDidYouHearAboutUs.NOT_SPECIFIED;
