import React, { FunctionComponent } from 'react';

import { SIZES } from 'helpers/enums/sizes';

import Arrow from './components/Arrow';

import { ICarouselProps } from './types';
import { StyledCarousel, Container } from './styles';

const responsive = (itemsPerSlide: number, isHome: boolean | undefined) => ({
  desktop: {
    breakpoint: { max: 3000, min: SIZES.desktop },
    items: itemsPerSlide,
    paritialVisibilityGutter: 0,
  },
  smallDesktop: {
    breakpoint: { max: SIZES.desktop, min: SIZES.smallDesktop },
    items: 3,
    paritialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: SIZES.smallDesktop, min: 800 },
    items: isHome ? 2 : 3,
    paritialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 800, min: 650 },
    items: 2,
  },
  mobileSmall: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
  },
});

const Carousel: FunctionComponent<ICarouselProps> = (props: ICarouselProps) => {
  const {
    autoPlay,
    items,
    renderItem,
    infinite = false,
    hasMarginBottom,
    itemsPerSlide = 4,
    isHome,
  } = props;

  return (
    <Container data-testid="styled-carousel" hasMarginBottom={hasMarginBottom}>
      <StyledCarousel
        swipeable
        draggable
        showDots={false}
        responsive={responsive(itemsPerSlide, isHome)}
        ssr={false} // means not to render carousel on server-side.
        infinite={infinite}
        autoPlay={autoPlay}
        autoPlaySpeed={3000}
        partialVisible
        keyBoardControl
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px"
        customRightArrow={<Arrow />}
        customLeftArrow={<Arrow reverse />}
      >
        {items.map(renderItem)}
      </StyledCarousel>
    </Container>
  );
};

export default Carousel;
