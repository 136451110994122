import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'TRAINING_DETAIL_VIEW', {
  TRAININGS: 'Capacitaciones',
  DATE: 'Fecha',
  HOUR: 'Hora',
  SPEECH_FROM: 'Capacitación dictada por',
  NO_SPEAKER_DESCRIPTION: 'No hay descripción del Orador',
  NO_TRAINING_DESCRIPTION: 'No hay descripción de la Capacitación',
  NO_VIDEO: 'No hay video de la Capacitación',
  START_TRAINING: 'Iniciar Capacitación',
  POINTS: 'Sumó {{points}} puntos',
  CODE: 'Cargar Código',
  SOON: 'Próximamente',
  VIDEO: 'Ver Capacitación',
  FINISHED: 'Evento finalizado',
  CERT_MODAL_TITLE: '¡Felicidades!',
  CERT_MODAL_DESCRIPTION:
    'Completaste todas las capacitaciones pertenecientes a esta cápsula, en breve se genererará un certificado al que podrás acceder en la sección Certificados.',
  CERT_MODAL_BUTTON: 'Ir a Certificados',
  HOME: 'Inicio',
});
