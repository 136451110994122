import styled from 'styled-components';
import { COLORS } from 'helpers/enums/colors';
import Button from 'components/commons/Button';

export const RegisterButton = styled(Button)`
  && {
    width: 40%;
    color: ${COLORS.white};
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    background: ${COLORS.white};
    border: 1px solid ${COLORS.blueResolution};
    color: ${COLORS.blueResolution};

    :hover:not([disabled]) {
      background: ${COLORS.blueResolution};
      color: ${COLORS.white};
      && .ButtonSubtitle {
        color: white;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
`;

export const ButtonTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin: auto;
  padding: 10px 0;
`;

export const ButtonSubtitle = styled.div`
  font-size: 12px;

  text-transform: initial;
  color: #707070;
  font-weight: 100;
`;

export const Icon = styled.img`
  margin: auto;
`;
