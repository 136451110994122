import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { CompanyStatus } from 'helpers/enums/status';

import { IHeroProps } from './types';
import { Container, Title, Message } from './styles';
import './i18n';

const Hero: FunctionComponent<IHeroProps> = (props: IHeroProps) => {
  const { status } = props;

  return (
    <Container data-testid="hero-view">
      <Title>{status === CompanyStatus.ACTIVE && i18next.t('HERO:TITLE_ACTIVE')}</Title>
      <Message>
        {status === CompanyStatus.ACTIVE
          ? i18next.t('HERO:MESSAGE_ACTIVE')
          : i18next.t('HERO:MESSAGE_PENDING')}
      </Message>
    </Container>
  );
};

export default Hero;
