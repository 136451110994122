import { ICountry } from 'types/country.types';

export interface ISignUpFormData {
  companyName?: string;
  userName: string;
  cuit: string;
  email: string;
  emailConfirmation: string;
  password: string;
  passwordConfirmation: string;
  countryId: string;
  state: string;
  phone?: string;
  promotions: boolean;
  creatingCompany: boolean;
  companyRole?: CompanyRole;
  specialty?: string;
  sector?: number;
  collaborators?: number;
  hearAboutUs?: number;
  subSector?: number;
  subSectorOtherText?: string;
}

export interface IValidateIdentifier {
  cuit: string;
  countryId: string | undefined;
  creatingCompany: boolean;
}

export interface ISectorOptions {
  value: number;
  label: string;
}

export interface ISubSectorOptions {
  [key: number]: Array<{
    value: number;
    label: string;
  }>;
}

export interface INumberOfCOllaboratorsOptions {
  value: number;
  label: string;
}

export interface IHowDidYouHearAboutUsOptions {
  value: number;
  label: string;
}

export interface ISignUpViewProps {
  onValidateIdentifier: (formData: IValidateIdentifier) => void;
  onSubmit: (formData: ISignUpFormData) => void;
  countries: ICountry[];
  isIdentifierValid: boolean;
  onFormBack: () => void;
  numberOfCollaboratorsOptions: INumberOfCOllaboratorsOptions[];
  howDidYouHearAboutUsOptions: IHowDidYouHearAboutUsOptions[];
  companySectorOptions: ISectorOptions[];
  // companySubSectorOptions: ISubSectorOptions;
}

export enum CompanyRole {
  NOT_SPECIFIED = 0,
  CLIENT,
  PROVIDER,
  OTHER,
}
