import styled from 'styled-components';

import Button from 'components/commons/Button';
import { COLORS } from 'helpers/enums/colors';

export const Form = styled.form`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

export const FormButton = styled(Button)`
  color: ${COLORS.blueResolution};
  padding: 10px 26px;
  background: ${COLORS.blueMalibu};

  :hover:not([disabled]) {
    background: ${COLORS.purple};
  }
`;
