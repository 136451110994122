export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const APP_DOMAIN = process.env.REACT_APP_APP_DOMAIN;

export const WWW_URL = `https://${APP_DOMAIN}`;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const HERO_VIDEO_URL = process.env.REACT_APP_YOUTUBE_VIDEO_HERO_URL;

export const GOOGLE_CAPTCHA = process.env.REACT_APP_GOOGLE_CAPTCHA;

export const MIN_BEFORE_TO_SHOW_MEET_BUTTON = parseInt(
  process.env.REACT_APP_MIN_BEFORE_TO_SHOW_MEET_BUTTON || '10',
  10,
);
export const MIN_AFTER_TO_SHOW_MEET_BUTTON = parseInt(
  process.env.REACT_APP_MIN_AFTER_TO_SHOW_MEET_BUTTON || '90',
  10,
);

export const ADMIN_MAIL_ARGENTINA = process.env.REACT_APP_ADMIN_MAIL_ARGENTINA;
export const ADMIN_MAIL_COLOMBIA = process.env.REACT_APP_ADMIN_MAIL_COLOMBIA;

export const GA_KEY = process.env.REACT_APP_GA_KEY;

export const FB_PIXEL = process.env.REACT_APP_FB_PIXEL || '';
