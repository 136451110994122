import styled from 'styled-components';

import { SIZES } from 'helpers/enums/sizes';
import Button from 'components/commons/Button';
import { COLORS } from 'helpers/enums/colors';

export const InputsLeft = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 80px 0 0;
  border-right: 1px solid ${COLORS.grayMercury};

  @media (max-width: ${SIZES.bigTablet}px) {
    border-right: none;
    padding: 0;
  }
`;

export const InputsRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 80px;

  @media (max-width: ${SIZES.bigTablet}px) {
    padding: 40px 0 0;
  }
`;

export const Form = styled.form`
  width: 100%;
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const SelectDiv = styled(InputDiv)`
  padding-bottom: 40px;
`;

export const RadioGroupDiv = styled(InputDiv)`
  padding-bottom: 40px;
`;

export const CheckboxDiv = styled(InputDiv)`
  padding-bottom: 15px;
`;

export const ButtonContainer = styled.div`
  margin: 30px 0;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const FormButton = styled(Button)`
  padding: 10px 34px;
  background: ${COLORS.blueResolution};

  :hover:not([disabled]) {
    background: ${COLORS.bluePersian};
  }
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column;
  }
`;

export const Text = styled.p`
  font: 14px/19px 'OpenSansBold';
  color: ${COLORS.riverBed};
  margin: 0 0 14px;
`;

export const MailsText = styled.p`
  font: 13px/22px 'OpenSans';
  margin: 0;
  color: ${COLORS.riverBed};
`;

export const RadioButtonsContainer = styled.div`
  margin-top: 10px;
`;

export const FieldLabel = styled.div`
  display: flex;
  align-self: flex-start;
  width: 100%;
  font: 13px/22px;
  font-family: 'OpenSans';
  font-weight: 500;
  margin: 0;
  padding: 0;
`;
