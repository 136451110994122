import React, { FunctionComponent, useState } from 'react';
import { FormControlLabel, MuiThemeProvider, styled, Switch, Tooltip } from '@material-ui/core';
import i18next from 'i18next';
import { COLORS } from 'helpers/enums/colors';
import { Theme } from './styles';
import { IUserRoleProps } from './types';

import './i18n';

const UserRole: FunctionComponent<IUserRoleProps> = (props: IUserRoleProps) => {
  const { isOwner, onClick } = props;
  const [role, setUserRole] = useState(isOwner);

  const GreenSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: `${COLORS.greenChristi}`,
      '&:hover': {
        backgroundColor: `${COLORS.greenChristi}`,
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: `${COLORS.greenChristi}`,
    },
  }));

  return (
    <MuiThemeProvider theme={Theme}>
      <Tooltip
        title={
          role
            ? ''
            : 'Al tildar este campo, este usuario tendrá permisos para administrar esta sección'
        }
        placement="top-start"
        arrow
      >
        <FormControlLabel
          control={
            <GreenSwitch
              checked={role}
              onChange={() => {
                onClick(role);
                setUserRole(!role);
              }}
              disabled={role}
              color="primary"
            />
          }
          label={i18next.t(role ? `EMPLOYEES_VIEW:OWNER` : `EMPLOYEES_VIEW:USER`)}
        />
      </Tooltip>
    </MuiThemeProvider>
  );
};

export default UserRole;
