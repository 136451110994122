import React, { FunctionComponent } from 'react';

import useTypedSelector from 'hooks/useTypedSelector';
import { CompanyStatus } from 'helpers/enums/status';
import FormContainer from './FormContainer';
import DashboardContainer from './DashboardContainer';

const UserLoggedContainer: FunctionComponent = () => {
  const company = useTypedSelector((state) => state.company.data);

  return (
    <>
      {/* To enable form: Delete COMPLETE_FORM */}
      {company?.status === CompanyStatus.ACTIVE ||
      company?.status === CompanyStatus.COMPLETE_FORM ? (
        <DashboardContainer />
      ) : (
        <FormContainer />
      )}
    </>
  );
};

export default UserLoggedContainer;
