import React, { FunctionComponent } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import i18next from 'i18next';

import { IForgotPasswordFormData } from 'views/ForgotPasswordView/types';
import { requiredValidation, emailValidation } from 'helpers/validations';

import Input from 'components/inputs/Input';
import Captcha from 'components/commons/Captcha';

import { IForgotPasswordFormProps } from './types';
import { Form, ButtonContainer, FormButton } from './styles';
import './i18n';

const ForgotPasswordForm: FunctionComponent<
  IForgotPasswordFormProps & InjectedFormProps<IForgotPasswordFormData, IForgotPasswordFormProps>
> = (props) => {
  const { handleSubmit, onSubmit, invalid } = props;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="forgot-password-form">
      <Field
        component={Input}
        label={i18next.t('FORGOT_PASSWORD_FORM:EMAIL')}
        name="email"
        validate={[requiredValidation, emailValidation]}
        type="string"
      />
      <Field name="captcha" component={Captcha} validate={[requiredValidation]} />
      <ButtonContainer>
        <FormButton disabled={invalid} type="submit">
          {i18next.t('FORGOT_PASSWORD_FORM:SUBMIT_BUTTON')}
        </FormButton>
      </ButtonContainer>
    </Form>
  );
};

export default reduxForm<IForgotPasswordFormData, IForgotPasswordFormProps>({
  form: 'forgotPassword',
})(ForgotPasswordForm);
