import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'LOGIN_FORM', {
  EMAIL: 'Email',
  FORGOT_PASSWORD: '¿Olvidó su contraseña?',
  PASSWORD: 'Contraseña',
  SUBMIT_BUTTON: 'Iniciar sesión',
  POPUP_TEXT: 'La cuenta está pendiente de activación ',
  RESEND_EMAIL_BUTTON: 'Reenviar mail de confirmación',
  CANCEL_BUTTON: 'Cancelar',
});
