import styled, { css } from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

import { ReactComponent as Improve } from 'assets/imgs/improve.svg';
import { ReactComponent as Business } from 'assets/imgs/business.svg';
import { ReactComponent as Finance } from 'assets/imgs/finance.svg';
import { ReactComponent as Logistics } from 'assets/imgs/logistics.svg';
import { ReactComponent as Culture } from 'assets/imgs/culture.svg';
import { ReactComponent as Digital } from 'assets/imgs/digital.svg';
import { ReactComponent as Agro } from 'assets/imgs/agro.svg';

export const CapsuleIcon = css`
  width: 30px;
`;

export const BiggerCapsuleIcon = css`
  width: 400px;
`;

export const AgroIcon = styled(Agro)`
  ${({ isBigger }: { isBigger?: boolean }) => (isBigger ? BiggerCapsuleIcon : CapsuleIcon)};

  fill: ${({ isWhite }: { isWhite?: boolean; isBigger?: boolean }) =>
    isWhite ? `${COLORS.white}` : `${COLORS.salemGreen}`};
`;

export const ImproveIcon = styled(Improve)`
  ${({ isBigger }: { isBigger?: boolean }) => (isBigger ? BiggerCapsuleIcon : CapsuleIcon)};

  fill: ${({ isWhite }: { isWhite?: boolean; isBigger?: boolean }) =>
    isWhite ? `${COLORS.white}` : `${COLORS.bluePiction}`};
`;

export const BusinessIcon = styled(Business)`
  ${({ isBigger }: { isBigger?: boolean }) => (isBigger ? BiggerCapsuleIcon : CapsuleIcon)};

  fill: ${({ isWhite }: { isWhite?: boolean; isBigger?: boolean }) =>
    isWhite ? `${COLORS.white}` : `${COLORS.purpleAffair}`};
`;

export const FinanceIcon = styled(Finance)`
  ${({ isBigger }: { isBigger?: boolean }) => (isBigger ? BiggerCapsuleIcon : CapsuleIcon)};

  fill: ${({ isWhite }: { isWhite?: boolean; isBigger?: boolean }) =>
    isWhite ? `${COLORS.white}` : `${COLORS.blueSanMarino}`};
`;

export const LogisticsIcon = styled(Logistics)`
  ${({ isBigger }: { isBigger?: boolean }) => (isBigger ? BiggerCapsuleIcon : CapsuleIcon)};

  fill: ${({ isWhite }: { isWhite?: boolean; isBigger?: boolean }) =>
    isWhite ? `${COLORS.white}` : `${COLORS.purpleGigas}`};
`;

export const CultureIcon = styled(Culture)`
  ${({ isBigger }: { isBigger?: boolean }) => (isBigger ? BiggerCapsuleIcon : CapsuleIcon)};

  fill: ${({ isWhite }: { isWhite?: boolean; isBigger?: boolean }) =>
    isWhite ? `${COLORS.white}` : `${COLORS.pinkRazzmatazz}`};
`;

export const DigitalIcon = styled(Digital)`
  ${({ isBigger }: { isBigger?: boolean }) => (isBigger ? BiggerCapsuleIcon : CapsuleIcon)};

  fill: ${({ isWhite }: { isWhite?: boolean; isBigger?: boolean }) =>
    isWhite ? `${COLORS.white}` : `${COLORS.greenOcean}`};
`;
