import { IUser } from 'types/company.types';
import { ICompletedTraining } from 'types/training.types';

export const codeIsSubmitted = (completedTrainings: ICompletedTraining[], user: IUser) => {
  return completedTrainings.some((training) => training.userId === user.id);
};

export const howManyPoints = (completedTrainings: ICompletedTraining[], user: IUser) => {
  const foundTraining = completedTrainings.find((training) => training.userId === user.id, null);
  return foundTraining?.points || 0;
};
