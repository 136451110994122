import styled from 'styled-components';
import { RadioGroup, makeStyles } from '@material-ui/core';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const useStyles = makeStyles(() => ({
  root: {
    flexDirection: 'row',
  },
}));

export const FormGroup = styled.div`
  width: 100%;
  position: relative;
`;

export const CustomRadioGroup = styled(RadioGroup)`
  flex-direction: row;
`;

export const ErrorBlock = styled.div`
  width: 100%;
  position: absolute;
  font-size: 13px;
  line-height: 1.5;
  color: ${COLORS.red};
  text-align: left;

  @media (max-width: ${SIZES.mediumPhone}px) {
    font-size: 12px;
  }
`;
