import React, { FunctionComponent, useEffect, useState } from 'react';
import i18next from 'i18next';

import Carousel from 'components/commons/Carousel';
import CoinIconSVG from 'assets/imgs/coin-icon.svg';
import TrainingCard from 'views/CapsuleView/components/TrainingCard';
import { ICapsule } from 'types/capsules.types';
import { SIZES } from 'helpers/enums/sizes';

import FeaturedTraining from 'components/commons/FeaturedTraining';
import Hero from 'views/HomeView/components/Hero';
import CapsuleCard from './components/CapsuleCard';
import { IDashboardViewProps } from './types';
import {
  StatsBanner,
  StatContainer,
  StatsContainer,
  StatsBannerTitle,
  StatTitle,
  StatText,
  StatNumber,
  Bold,
  CapsulesContainer,
  CoinIcon,
  HorizontalStatContainer,
  ViewContainer,
  RightContainer,
  LeftContainer,
  ContentContainer,
  ButtonContainer,
  CapsulesGrid,
  CapsulesTitle,
  CarouselContainer,
  GrayLine,
} from './styles';
import './i18n';

const DashboardView: FunctionComponent<IDashboardViewProps> = (props: IDashboardViewProps) => {
  const {
    company,
    nextTraining,
    scores,
    onSubmitTraining,
    featuredTraining,
    user,
    forms,
    completeForm,
    showCertModal,
    closeCertModal,
    capsules,
    trainings,
  } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const isMobile = windowWidth <= SIZES.desktop;

  const featuredTrainingCapsuleTitle =
    capsules.find((c) => c.id === featuredTraining?.capsuleId)?.title || '';

  const renderNextTraining = () =>
    !!nextTraining && (
      <TrainingCard
        onSubmit={onSubmitTraining}
        training={nextTraining}
        user={user}
        forms={forms}
        completeForm={completeForm}
        showCertModal={showCertModal}
        closeCertModal={closeCertModal}
        isHome
      />
    );

  const renderCapsuleCard = (capsule: ICapsule): JSX.Element => (
    <CapsuleCard
      title={capsule.title}
      description={capsule.description}
      partnerLogo={capsule.partnerLogo}
      capsuleId={capsule.id}
      thumbnail={capsule.thumbnail}
      trainings={trainings.filter((t) => t.capsuleId === capsule.id)}
      userId={user.id}
    />
  );

  const renderCapsules = (): JSX.Element => {
    return (
      <CapsulesContainer>
        <CapsulesTitle>{i18next.t('DASHBOARD_VIEW:PROGRAM_CAPSULES')}</CapsulesTitle>
        {isMobile ? (
          <CarouselContainer>
            <Carousel
              autoPlay={false}
              items={capsules}
              renderItem={renderCapsuleCard}
              infinite={false}
              isHome
            />
          </CarouselContainer>
        ) : (
          <CapsulesGrid>
            {capsules
              .sort((a, b) => a.orderInHome - b.orderInHome)
              .map((capsule) => renderCapsuleCard(capsule))}
          </CapsulesGrid>
        )}
      </CapsulesContainer>
    );
  };

  const companyPosition = scores.findIndex((score) => score.name === company.name) + 1;

  return (
    <ViewContainer>
      <ButtonContainer>
        <GrayLine />
        {i18next.t('DASHBOARD_VIEW:HOME')}
      </ButtonContainer>
      <ContentContainer>
        <LeftContainer>
          {featuredTraining ? (
            <FeaturedTraining
              isHome
              featuredTraining={featuredTraining}
              capsuleTitle={featuredTrainingCapsuleTitle}
            />
          ) : (
            <Hero />
          )}

          {renderCapsules()}
        </LeftContainer>
        <RightContainer>
          {renderNextTraining()}
          <StatsBanner>
            <StatsBannerTitle>{i18next.t('DASHBOARD_VIEW:STATS_BANNER_TITLE')}</StatsBannerTitle>
            <StatsContainer>
              <StatContainer>
                <StatTitle>{i18next.t('DASHBOARD_VIEW:TOTAL_POINTS')}</StatTitle>
                <StatNumber>{company.points}</StatNumber>
                <StatText>{i18next.t('DASHBOARD_VIEW:POINTS_ACC')}</StatText>
              </StatContainer>
              <StatContainer>
                <StatTitle>{i18next.t('DASHBOARD_VIEW:RANKING')}</StatTitle>
                <StatNumber>{companyPosition}</StatNumber>
                <StatText>
                  {i18next.t('DASHBOARD_VIEW:OF')}
                  <Bold>{scores.length}</Bold>
                  {i18next.t('DASHBOARD_VIEW:COMPANIES')}
                </StatText>
              </StatContainer>
              <StatContainer>
                <StatTitle>{i18next.t('DASHBOARD_VIEW:COINS')}</StatTitle>
                <HorizontalStatContainer>
                  <StatNumber>{company.coins}</StatNumber>
                  <CoinIcon src={CoinIconSVG} />
                </HorizontalStatContainer>
                <StatText>{i18next.t('DASHBOARD_VIEW:ACTIVATE')}</StatText>
              </StatContainer>
            </StatsContainer>
          </StatsBanner>
        </RightContainer>
      </ContentContainer>
    </ViewContainer>
  );
};

export default DashboardView;
