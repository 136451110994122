import styled from 'styled-components';

import Hero from 'assets/imgs/brands.png';
import { COLORS } from 'helpers/enums/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${Hero});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled.h1`
  font-family: UnileverBold;
  color: ${COLORS.bluePersian};
`;

export const Message = styled.div`
  color: ${COLORS.bluePersian};
  max-width: 720px;
  margin: 0 5%;
  text-align: center;
`;
