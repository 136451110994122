import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

export const Container = styled.div`
  height: 10px;
  background: ${COLORS.white};
  border: 0.1px solid ${COLORS.grayCatskill};
  border-radius: 5px;
  display: flex;
  flex: 1;
  margin-right: 5px;
`;

export const FillContainer = styled.div`
  max-width: 100%;
  width: ${({ progress }: { progress?: number }) => `${progress}%`};
  background: ${COLORS.blueDenim};
  transition: width 1s ease-in-out;
  border-radius: 10px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: ${({ fromBenefits }: { fromBenefits: boolean }) => fromBenefits && '7px 0'};
`;

export const PercentageContainer = styled.div`
  text-align: end;
  width: 10%;
`;

export const Percentage = styled.p`
  font-size: 14px;
  margin: 0;
  justify-self: flex-end;
  font-family: 'OpenSans';
`;

export const BarContainer = styled.div`
  width: 90%;
`;

export const BenefitText = styled.p`
  justify-self: flex-end;
  color: ${COLORS.riverBed};
  font: 14px/1 'OpenSansBold';
  margin: 0;
`;
