import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import Carousel from 'components/commons/Carousel';
import Card from 'components/commons/NewsCard/index';

import { ITestimonial } from 'types/testimonials.types';
import { INews } from 'types/news.types';

import { INewsAndTestimonialsProps } from './types';
import Testimonial from './components/Testimonial';
import {
  Container,
  NewsSection,
  NewsContainerGrid,
  TestimonialsSection,
  TestimonialsContainer,
  Title,
  CarouselContainer,
} from './styles';
import './i18n';

const NewsAndTestimonialsView: FunctionComponent<INewsAndTestimonialsProps> = (
  props: INewsAndTestimonialsProps,
) => {
  const { news, testimonials, isTablet } = props;

  const testimonialModel = (item: ITestimonial): JSX.Element => {
    return <Testimonial author={item.author} description={item.description} isTablet={isTablet} />;
  };
  const newsModel = (item: INews): JSX.Element => {
    return <Card item={item} isGrid={false} />;
  };

  const renderTestimonialsList = () =>
    testimonials.map((testimonial) => (
      <Testimonial
        author={testimonial.author}
        description={testimonial.description}
        isTablet={isTablet}
      />
    ));

  const renderTestimonialsCarousel = () => (
    <CarouselContainer>
      <Carousel
        autoPlay={false}
        items={testimonials}
        renderItem={testimonialModel}
        infinite={false}
      />
    </CarouselContainer>
  );

  const renderNewsGrid = () => (
    <NewsContainerGrid>
      {news.map((notice) => (
        <Card item={notice} isGrid />
      ))}
    </NewsContainerGrid>
  );

  const renderNewsCarousel = () => (
    <CarouselContainer>
      <Carousel autoPlay={false} items={news} renderItem={newsModel} infinite={false} />
    </CarouselContainer>
  );

  return (
    <>
      <Container data-testid="view-container" isTablet={isTablet}>
        <NewsSection isTablet={isTablet}>
          <Title>{i18next.t('NEWS_AND_TESTIMONIALS_VIEW:NEWS')}</Title>
          {isTablet ? renderNewsCarousel() : renderNewsGrid()}
        </NewsSection>
        <TestimonialsSection isTablet={isTablet}>
          <Title>{i18next.t('NEWS_AND_TESTIMONIALS_VIEW:TESTIMONIALS')}</Title>
          <TestimonialsContainer>
            {isTablet ? renderTestimonialsCarousel() : renderTestimonialsList()}
          </TestimonialsContainer>
        </TestimonialsSection>
      </Container>
    </>
  );
};

export default NewsAndTestimonialsView;
