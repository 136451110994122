import axios from 'axios';
import history from 'helpers/history';

import { API_BASE_URL } from 'configs/configs';
// import { ENVIRONMENTS } from 'helpers/enums/environments';

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

// if (process.env.NODE_ENV === ENVIRONMENTS.staging) {
//   axios.defaults.withCredentials = true;
// }

console.log('NODE_ENV: ', process.env.NODE_ENV);
axios.defaults.withCredentials = true;

function errorResponse(error: any) {
  const { response } = error;
  let message = error;
  if (response) {
    const { data } = response;
    message = data.message;
  }
  if (message === 'Unauthorized') {
    return history.push('/login');
  }
  return new Error(message);
}

export async function get(url: string, headers = {}): Promise<any> {
  try {
    const options = { ...headers };
    const { data } = await api.get(url, options);
    return data;
  } catch (error) {
    throw errorResponse(error);
  }
}

export async function post(url: string, params = {}, headers = {}): Promise<any> {
  try {
    const options = { ...headers };
    const { data } = await api.post(url, params, options);
    return data;
  } catch (error) {
    throw errorResponse(error);
  }
}

export async function put(url: string, params = {}, headers = {}): Promise<any> {
  try {
    const options = { ...headers };
    const { data } = await api.put(url, params, options);
    return data;
  } catch (error) {
    throw errorResponse(error);
  }
}
