import { FunctionComponent } from 'react';

import { ICompanyDefaultState } from 'types/company.types';
import * as companyConstants from 'redux/constants/company.constants';
import * as trainingConstants from 'redux/constants/training.constants';
import * as constants from 'redux/constants/user.constants';
import * as headerConstants from 'redux/constants/header.constants';
import * as benefitConstants from 'redux/constants/benefit.constants';
import { IForm } from 'types/form.types';

const defaultState: ICompanyDefaultState = {
  data: null,
  scores: [],
  loading: false,
  level: null,
  user: null,
  isIdentifierValid: false,
  companyUsers: [],
  certificates: null,
  newLevelReached: false,
  showCompleteProfileModal: true,
};

const companyReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { data, type } = action;
  switch (type) {
    case constants.USER_ON_LOGIN_REQUESTED:
    case constants.USER_ON_REGISTER_REQUESTED:
    case companyConstants.COMPANY_ON_SUBMIT_REGISTER_FORM_REQUESTED:
    case constants.USER_ON_UPDATE_REQUESTED:
    case constants.USER_ON_CONTACT_REQUESTED:
    case constants.USER_ON_FORGOT_PASSWORD_REQUESTED:
    case companyConstants.COMPANY_ON_GET_SCORES_REQUESTED:
    case companyConstants.COMPANY_ON_GET_LEVEL_REQUESTED:
    case constants.USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_REQUESTED:
    case constants.USER_ON_VALIDATE_IDENTIFIER_REQUESTED:
    case constants.COMPANY_ON_REGISTER_REQUESTED:
    case constants.USER_ON_CONFIRM_ASSISTANCE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_SUCCEEDED:
    case constants.USER_ON_CONFIRM_ACCOUNT_SUCCEEDED:
    case constants.USER_ON_ACCEPT_ACCOUNT_SUCCEEDED:
    case constants.USER_ON_INITIALIZE_SUCCEEDED:
    case constants.USER_ON_LOGIN_SUCCEEDED:
    case constants.USER_ON_REGISTER_SUCCEEDED:
    case companyConstants.COMPANY_ON_SUBMIT_REGISTER_FORM_SUCCEEDED:
    case constants.USER_ON_UPDATE_SUCCEEDED:
      return {
        ...state,
        data: data.company,
        user: data.user,
        loading: false,
      };
    case companyConstants.COMPANY_ON_GET_LEVEL_SUCCEEDED:
      return {
        ...state,
        level: data.level,
        loading: false,
      };
    case companyConstants.COMPANY_ON_GET_SCORES_SUCCEEDED:
      return {
        ...state,
        scores: data.scores,
        loading: false,
      };
    case trainingConstants.TRAINING_SUBMIT_CODE_SUCCEEDED:
    case trainingConstants.TRAINING_MARK_AS_COMPLETE_SUCCEEDED:
    case benefitConstants.BENEFITS_ON_ACTIVATE_SUCCEEDED:
    case constants.USER_ON_COMPLETE_FORM_SUCCEEDED:
      return {
        ...state,
        newLevelReached: data.newLevelReached || false,
        level: data.newLevel,
        data: {
          ...state.data,
          points: parseInt(state.data.points, 10) + data.points,
          coins: data.coins ? parseInt(state.data.coins, 10) + data.coins : state.data.coins,
        },
      };
    case constants.USER_ON_CLOSE_NEW_LEVEL_MODAL:
      return {
        ...state,
        newLevelReached: false,
      };
    case constants.USER_ON_LOGIN_FAILED:
    case constants.USER_ON_REGISTER_FAILED:
    case companyConstants.COMPANY_ON_SUBMIT_REGISTER_FORM_FAILED:
    case constants.USER_ON_UPDATE_FAILED:
    case constants.USER_ON_CONTACT_SUCCEEDED:
    case constants.USER_ON_CONTACT_FAILED:
    case constants.USER_ON_FORGOT_PASSWORD_SUCCEEDED:
    case constants.USER_ON_FORGOT_PASSWORD_FAILED:
    case companyConstants.COMPANY_ON_GET_SCORES_FAILED:
    case companyConstants.COMPANY_ON_GET_LEVEL_FAILED:
    case headerConstants.LAYOUT_CONFIRMATION_POPUP_OPENED:
    case constants.COMPANY_ON_REGISTER_SUCCEEDED:
    case constants.COMPANY_ON_REGISTER_FAILED:
    case constants.USER_ON_CONFIRM_ASSISTANCE_SUCCEEDED:
    case constants.USER_ON_CONFIRM_ASSISTANCE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.USER_ON_VALIDATE_IDENTIFIER_SUCCEEDED:
      return {
        ...state,
        isIdentifierValid: true,
        loading: false,
      };
    case constants.USER_ON_VALIDATE_IDENTIFIER_FAILED:
    case constants.USER_ON_REGISTER_FORM_BACK:
      return {
        ...state,
        isIdentifierValid: false,
        loading: false,
      };
    case constants.USER_ON_LOGOUT_SUCCEEDED:
      return defaultState;
    case constants.USER_ON_INITIALIZE_SIGN_UP_VIEW_REQUESTED:
      return {
        ...state,
        isIdentifierValid: false,
      };
    case constants.USER_ON_INITIALIZE_EMPLOYEE_SUCCEEDED:
      return {
        ...state,
        companyUsers: data.users,
      };
    case constants.USER_ON_GET_FORMS_SUCCEEDED:
      return {
        ...state,
        user: {
          ...state.user,
          forms: data.forms,
        },
      };
    case constants.USER_ON_COMPLETE_FORM_REQUESTED:
      return {
        ...state,
        user: {
          ...state.user,
          forms: state.user.forms.filter((form: IForm) => form.id !== action.formData.formId),
        },
      };
    case constants.USER_ON_GET_CERTIFICATES_SUCCEEDED:
      return {
        ...state,
        certificates: data.certificates,
      };
    case constants.USER_ON_REDIRECT_TO_PROFILE:
    case constants.USER_ON_CLOSE_COMPLETE_PROFILE_MODAL:
      return {
        ...state,
        showCompleteProfileModal: false,
      };
    case constants.USER_ON_CONFIRM_ACCOUNT_FAILED:
    case constants.USER_ON_INITIALIZE_FAILED:
    case constants.USER_ON_LOGOUT_FAILED:
    default:
      return state;
  }
};

export default companyReducer;
