import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import LayoutContainer from 'containers/LayoutContainer';
import NotificationsView from 'views/NotificationsView';

import useTypedSelector from 'hooks/useTypedSelector';
import { onCompleteForms, onGetForms } from 'redux/actions/user.actions';
import { Sections } from 'helpers/enums/sections';
import { getFormQuestionsAndAnswers } from 'helpers/forms';
import { IForm } from 'types/form.types';

const NotificationsContainer: FunctionComponent = () => {
  const notifications = useTypedSelector((state) => state.notifications.items);
  const forms = useTypedSelector((state) => state.company.user?.forms);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!forms) dispatch(onGetForms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const completeForm = (form: IForm, values: any) => {
    const completedFormData = {
      formId: form.id,
      formAnswers: getFormQuestionsAndAnswers(form, values),
    };
    dispatch(onCompleteForms(completedFormData));
  };

  return (
    <LayoutContainer forms={forms} section={Sections.NOTIFICATIONS} completeForm={completeForm}>
      <NotificationsView notifications={notifications} />
    </LayoutContainer>
  );
};

export default NotificationsContainer;
