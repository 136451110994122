import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as benefitConstants from 'redux/constants/benefit.constants';
import * as appConstants from 'redux/constants/app.constants';
import * as constants from 'redux/constants/user.constants';
import * as headerConstants from 'redux/constants/header.constants';
import {
  acceptAccount,
  allUsersByCompany,
  changeUserToOwner,
  completeForm,
  confirmAccount,
  confirmAssistance,
  contact,
  disableUser,
  emailAccountConfirmation,
  enableUser,
  forgotPassword,
  getFormsByUserId,
  initialize,
  login,
  logout,
  register,
  registerUser,
  resetPassword,
  update,
  validateIdentifier,
} from 'services/user.services';

export function* userConfirmAccount(action: any) {
  try {
    const data: unknown = yield call(confirmAccount, action.token);
    yield put({ type: constants.USER_ON_CONFIRM_ACCOUNT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_CONFIRM_ACCOUNT_FAILED, error });
  }
}

export function* userAcceptAccount(action: any) {
  try {
    const data: unknown = yield call(acceptAccount, action.token);
    yield put({ type: constants.USER_ON_ACCEPT_ACCOUNT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_ACCEPT_ACCOUNT_FAILED, error });
  }
}

export function* userForgotPassword(action: any) {
  try {
    const data: unknown = yield call(forgotPassword, action.formData);
    yield put({ type: constants.USER_ON_FORGOT_PASSWORD_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_FORGOT_PASSWORD_FAILED, error });
  }
}

export function* userInitialize() {
  try {
    const data: unknown = yield call(initialize);
    yield put({ type: constants.USER_ON_INITIALIZE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_INITIALIZE_FAILED, error });
  }
}

export function* userLogin(action: any) {
  try {
    const data: unknown = yield call(login, action.formData);
    yield put({ type: constants.USER_ON_LOGIN_SUCCEEDED, data, from: action.from });
  } catch (error: any) {
    yield put({
      type: error.message.includes('activa')
        ? headerConstants.LAYOUT_CONFIRMATION_POPUP_OPENED
        : constants.USER_ON_LOGIN_FAILED,
      error,
    });
  }
}

export function* initializeEmployee(action: any) {
  try {
    const data: unknown = yield call(allUsersByCompany, action.company);
    yield put({ type: constants.USER_ON_INITIALIZE_EMPLOYEE_SUCCEEDED, data });
  } catch (error: any) {
    yield put({
      type: constants.USER_ON_INITIALIZE_EMPLOYEE_FAILED,
      error,
    });
  }
}

export function* userOnEnable(action: any) {
  try {
    const data: unknown = yield call(enableUser, action.user);
    yield put({ type: constants.USER_ENABLE_SUCCEEDED, data });
  } catch (error: any) {
    yield put({
      type: constants.USER_ENABLE_FAILED,
      error,
    });
  }
}

export function* userOnDisable(action: any) {
  try {
    const data: unknown = yield call(disableUser, action.user);
    yield put({ type: constants.USER_DISABLE_SUCCEEDED, data });
  } catch (error: any) {
    yield put({
      type: constants.USER_DISABLE_FAILED,
      error,
    });
  }
}

export function* userOnChangeToOwner(action: any) {
  try {
    const data: unknown = yield call(changeUserToOwner, action.user);
    yield put({ type: constants.USER_CHANGE_TO_OWNER_SUCCEEDED, data });
  } catch (error: any) {
    yield put({
      type: constants.USER_CHANGE_TO_OWNER_FAILED,
      error,
    });
  }
}

export function* userLogout() {
  try {
    yield call(logout);
    yield put({ type: constants.USER_ON_LOGOUT_SUCCEEDED });
  } catch (error) {
    yield put({ type: constants.USER_ON_LOGOUT_FAILED, error });
  }
}

export function* userRegister(action: any) {
  try {
    const data: unknown = yield call(registerUser, action.formData);
    yield put({ type: constants.USER_ON_REGISTER_SUCCEEDED, data });
  } catch (error: any) {
    yield put({
      type: constants.USER_ON_REGISTER_FAILED,
      error,
    });
  }
}

export function* companyRegister(action: any) {
  try {
    const data: unknown = yield call(register, action.formData);
    yield put({ type: constants.COMPANY_ON_REGISTER_SUCCEEDED, data });
  } catch (error: any) {
    yield put({
      type: constants.COMPANY_ON_REGISTER_FAILED,
      error,
    });
  }
}

export function* userValidateIdentifier(action: any) {
  try {
    const data: unknown = yield call(validateIdentifier, action.formData);
    yield put({ type: constants.USER_ON_VALIDATE_IDENTIFIER_SUCCEEDED, data });
  } catch (error: any) {
    yield put({
      type: constants.USER_ON_VALIDATE_IDENTIFIER_FAILED,
      error,
    });
  }
}

export function* userEmailAccountConfirmation(action: any) {
  try {
    const data: unknown = yield call(emailAccountConfirmation, action.formData);
    yield put({ type: constants.USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_SUCCEEDED, data });
  } catch (error: any) {
    yield put({
      type: constants.USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_FAILED,
      error,
    });
  }
}

export function* userResetPassword(action: any) {
  try {
    const data: unknown = yield call(resetPassword, action.formData);
    yield put({ type: constants.USER_ON_RESET_PASSWORD_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_RESET_PASSWORD_FAILED, error });
  }
}

export function* userUpdate(action: any) {
  try {
    const data: unknown = yield call(update, action.formData);
    yield put({ type: constants.USER_ON_UPDATE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_UPDATE_FAILED, error });
  }
}

export function* userContact(action: any) {
  try {
    const data: unknown = yield call(contact, action.formData);
    yield put({ type: constants.USER_ON_CONTACT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_CONTACT_FAILED, error });
  }
}

export function* userConfirmAssistance(action: any) {
  try {
    const { userId, trainingId } = action;
    const data: unknown = yield call(confirmAssistance, userId, trainingId);

    yield put({ type: constants.USER_ON_CONFIRM_ASSISTANCE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_CONFIRM_ASSISTANCE_FAILED, error });
  }
}
export function* userOnGetForms() {
  try {
    const data: unknown = yield call(getFormsByUserId);
    yield put({ type: constants.USER_ON_GET_FORMS_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_GET_FORMS_FAILED, error });
  }
}

export function* userOnCompleteForms(action: any) {
  try {
    const data: unknown = yield call(completeForm, action.formData);
    yield put({ type: constants.USER_ON_COMPLETE_FORM_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_COMPLETE_FORM_FAILED, error });
  }
}

export function* watchUsers() {
  yield all([
    takeLatest(constants.USER_ON_CONFIRM_ACCOUNT_REQUESTED, userConfirmAccount),
    takeLatest(constants.USER_ON_ACCEPT_ACCOUNT_REQUESTED, userAcceptAccount),
    takeLatest(constants.USER_ON_FORGOT_PASSWORD_REQUESTED, userForgotPassword),
    takeLatest(
      [
        appConstants.APP_ON_INITIALIZE_REQUESTED,
        constants.USER_ON_CONFIRM_ACCOUNT_SUCCEEDED,
        benefitConstants.BENEFITS_ON_ACTIVATE_SUCCEEDED,
      ],
      userInitialize,
    ),
    takeLatest(constants.USER_ON_LOGIN_REQUESTED, userLogin),
    takeLatest(constants.USER_ON_INITIALIZE_EMPLOYEE_REQUESTED, initializeEmployee),
    takeLatest(constants.USER_ON_LOGOUT_REQUESTED, userLogout),
    takeLatest(constants.USER_ON_REGISTER_REQUESTED, userRegister),
    takeLatest(constants.COMPANY_ON_REGISTER_REQUESTED, companyRegister),
    takeLatest(constants.USER_ON_VALIDATE_IDENTIFIER_REQUESTED, userValidateIdentifier),
    takeLatest(
      constants.USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_REQUESTED,
      userEmailAccountConfirmation,
    ),
    takeLatest(constants.USER_ON_RESET_PASSWORD_REQUESTED, userResetPassword),
    takeLatest(constants.USER_ON_UPDATE_REQUESTED, userUpdate),
    takeLatest(constants.USER_ON_CONTACT_REQUESTED, userContact),
    takeLatest(constants.USER_ENABLE_REQUESTED, userOnEnable),
    takeLatest(constants.USER_DISABLE_REQUESTED, userOnDisable),
    takeLatest(constants.USER_CHANGE_TO_OWNER_REQUESTED, userOnChangeToOwner),
    takeLatest(constants.USER_ON_CONFIRM_ASSISTANCE_REQUESTED, userConfirmAssistance),
    takeLatest(constants.USER_ON_GET_FORMS_REQUESTED, userOnGetForms),
    takeLatest(constants.USER_ON_COMPLETE_FORM_REQUESTED, userOnCompleteForms),
  ]);
}
