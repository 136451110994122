export const COLORS = {
  black: '#000000',
  white: '#FFFFFF',
  whiteCatskill: '#EFF2F7',
  gray: '#727272',
  grayAlabaster: '#F7F7F7',
  grayMercury: '#E5E5E5',
  grayCatskill: '#E7EAF3',
  grayBotticelli: '#C9D3E3',
  graySantas: '#9C9BB4',
  grayMid: '#61626C',
  grayDignity: '#9A9CA8',
  grayFrench: '#C4C4CC',
  silver: '#BABABA',
  riverBed: '#474F63',
  boxShadow: 'rgba(0, 0, 0, 0.20)',
  modalBackground: 'rgba(71,79,99,0.75)',
  modalColombiaBackground: 'rgba(71,79,99,0.9)',
  greenChristi: '#7DB00E',
  greenOcean: '#48A191',
  greenSurfie: '#0F7467',
  red: '#FF0000',
  brightRed: '#A70000',
  purple: '#7706BC',
  purpleIndigo: '#7705BC',
  royalPurple: '#7A3AA5',
  pigmentIndigo: '#58048B',
  blueCatalina: '#08287C',
  blueDodger: '#00b4ff',
  blueNavy: '#065EEF',
  blueMalibu: '#77DDF5',
  blueRibbon: '#005EEF',
  blueResolution: '#001F82',
  bluePersian: '#1F36C7',
  bluePiction: '#3BAAE0',
  blueSanMarino: '#3B65AE',
  blueTory: '#134DB9',
  blueCurious: '#268DD5',
  blueDenim: '#1B63E6',
  blueDayflower: '#36A2D8',
  zircon: '#F2F7FF',
  linkWater: '#F8FAFD',
  pictonBlue: '#4AB0F7',
  fuchsiaBlue: '#8F4FBB',
  denim: '#1A62E7',
  purpleGigas: '#63398A',
  purpleHeart: '#6C24B6',
  purpleAffair: '#8C509F',
  purpleDaisyBush: '#531D8B',
  purpleMedium: '#9074DC',
  ceriseRed: '#E33679',
  persianGreen: '#02A592',
  rainbowSkyPurple: '#AD79D1',
  salemGreen: '#09833D',
  stratos: '#000E4D',
  manatee: '#7F86A6',
  pinkRazzmatazz: '#EC187B',
  bronzePYME: '#BB744F',
  silverPYME: '#909090',
  goldenPYME: '#BB964F',
  platinumPYME: '#4F91BB',
  blueFrenchSky: '#7BAEFC',
  blackMineShaft: '#1F1F1F',
  eminence: '#6A3A8B',
  hibiscus: '#B92B62',
  lightblueZumthor: '#E6F0FF',
  lightGray: '#ABABAA',
  whiteGhost: '#F5F8FD',
  slateMistGray: '#636465',
  pearlEssenceGray: '#EAEAEA',
  doveGray: '#BEBEBE',
  charcoalGray: '#525252',
  grayLavenderMist: '#9BA0B9',
  mistyQuartz: '#AFB1B4',
  lavenderHaze: '#AD78D0',
};
