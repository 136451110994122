import { all } from 'redux-saga/effects';

import { watchUsers } from 'redux/sagas/user.sagas';
import { watchCapsules } from './capsule.sagas';
import { watchCompanies } from './company.sagas';
import { watchNews } from './news.sagas';
import { watchPrograms } from './program.sagas';
import { watchTrainings } from './training.sagas';
import { watchTestimonials } from './testimonials.sagas';
import { watchNotifications } from './notifications.sagas';
import { watchLevels } from './levels.sagas';
import { watchBenefits } from './benefit.sagas';
import { watchCountries } from './country.sagas';
import { watchCertificates } from './certificates.sagas';

export default function* rootSaga() {
  yield all([
    watchUsers(),
    watchCompanies(),
    watchNews(),
    watchPrograms(),
    watchCapsules(),
    watchTrainings(),
    watchTestimonials(),
    watchNotifications(),
    watchLevels(),
    watchBenefits(),
    watchCountries(),
    watchCertificates(),
  ]);
}
