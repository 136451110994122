import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/capsule.constants';
import * as userConstants from 'redux/constants/user.constants';
import * as trainingConstants from 'redux/constants/training.constants';
import * as companyConstants from 'redux/constants/company.constants';
import { getAllCapsules } from 'services/capsule.service';

export function* getCapsules() {
  try {
    const data: unknown = yield call(getAllCapsules);
    yield put({ type: constants.CAPSULE_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.CAPSULE_ON_GET_ALL_FAILED, error });
  }
}

export function* watchCapsules() {
  yield all([
    takeLatest(
      [
        userConstants.USER_ON_LOGIN_SUCCEEDED,
        userConstants.USER_ON_CONFIRM_ACCOUNT_SUCCEEDED,
        userConstants.USER_ON_INITIALIZE_SUCCEEDED,
        trainingConstants.TRAINING_SUBMIT_CODE_SUCCEEDED,
        trainingConstants.TRAINING_MARK_AS_COMPLETE_SUCCEEDED,
        companyConstants.COMPANY_ON_SUBMIT_REGISTER_FORM_SUCCEEDED,
      ],
      getCapsules,
    ),
  ]);
}
