import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'WHATIS', {
  TITLE: '¿QUÉ ES UNIPYME?',
  UNIPYME: 'UniPyME',
  UNILEVER: 'Unilever',
  PYMES: 'PyMEs',
  FIRST_TEXT_1: 'Es un programa gratuito de formación y de desarrollo estratégico creado por ',
  FIRST_TEXT_2: ' para potenciar a la industria nacional.',
  SECOND_TEXT: 'Cada una de las cápsulas de capacitaciones cuentan con:',
  THIRD_TEXT_1: ' refleja el compromiso de ',
  THIRD_TEXT_2: ' para preparar a las ',
  THIRD_TEXT_3: ' para el futuro.',
  FIRST_ITEM: 'Contenido simple',
  SECOND_ITEM: 'Herramientas de aplicación inmediata',
  THIRD_ITEM: 'Beneficios para las PyMEs',
});
