import { FunctionComponent } from 'react';

import { ICapsule, ICapsulesDefaultState } from 'types/capsules.types';
import * as constants from 'redux/constants/capsule.constants';
import * as trainingConstants from 'redux/constants/training.constants';

const defaultState: ICapsulesDefaultState = {
  items: [],
  loading: false,
};

const capsuleReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { data, type } = action;
  switch (type) {
    case constants.CAPSULE_ON_GET_ALL_REQUESTED:
    case trainingConstants.TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case trainingConstants.TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_SUCCEEDED:
      return {
        ...state,
        items: state.items.map((capsule: ICapsule) =>
          capsule.id !== data.capsuleId
            ? capsule
            : {
                ...capsule,
                trainings: {
                  ...capsule.trainings,
                  [data.version]: data.trainings,
                },
              },
        ),
        loading: false,
      };
    case constants.CAPSULE_ON_GET_ALL_SUCCEEDED:
      return {
        ...state,
        items: data.capsules,
        loading: false,
      };
    case trainingConstants.TRAINING_ON_GET_ALL_BY_CAPSULE_VERSION_FAILED:
    case constants.CAPSULE_ON_GET_ALL_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default capsuleReducer;
