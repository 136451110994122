export enum CompanyHowDidYouHearAboutUs {
  NOT_SPECIFIED = 0,
  WEB_SEARCH,
  THIRD_PARTY_REFERENCE,
  SOCIAL_NETWORKS,
  ONLINE_PUBLICITY,
  EXTERNAL_LINKS,
  OTHER,
}

export const companyHowDidYouHearAboutUs = [
  { value: CompanyHowDidYouHearAboutUs.NOT_SPECIFIED, label: 'No especifica' },
  { value: CompanyHowDidYouHearAboutUs.WEB_SEARCH, label: 'Búsqueda web' },
  { value: CompanyHowDidYouHearAboutUs.THIRD_PARTY_REFERENCE, label: 'Referencia de terceros' },
  { value: CompanyHowDidYouHearAboutUs.SOCIAL_NETWORKS, label: 'Redes sociales' },
  { value: CompanyHowDidYouHearAboutUs.ONLINE_PUBLICITY, label: 'Publicidad en línea' },
  { value: CompanyHowDidYouHearAboutUs.EXTERNAL_LINKS, label: 'Enlaces externos' },
  { value: CompanyHowDidYouHearAboutUs.OTHER, label: 'Otros' },
];
