import { getCookie } from 'helpers/cookies';
import { FunctionComponent } from 'react';

import * as constants from 'redux/constants/user.constants';

const defaultState = async () => ({
  items: [],
  loading: false,
  accessPrivateRoutes: await getCookie(),
});

const privateRoutesReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { type } = action;
  switch (type) {
    case constants.USER_ON_LOGIN_SUCCEEDED:
    case constants.USER_ON_UPDATE_SUCCEEDED:
    case constants.USER_ON_INITIALIZE_SUCCEEDED:
      return { ...state, accessPrivateRoutes: true };
    default:
      return state;
  }
};

export default privateRoutesReducer;
