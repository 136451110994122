import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/company.constants';
import * as trainingConstants from 'redux/constants/training.constants';
import * as appConstants from 'redux/constants/app.constants';
import { getLevel, getPoints, submitForm } from 'services/company.services';

export function* userSubmitRegisterForm(action: any) {
  try {
    const data: unknown = yield call(submitForm, action.formData);
    yield put({ type: constants.COMPANY_ON_SUBMIT_REGISTER_FORM_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.COMPANY_ON_SUBMIT_REGISTER_FORM_FAILED, error });
  }
}

export function* getCompaniesWithScores() {
  try {
    const data: unknown = yield call(getPoints);
    yield put({ type: constants.COMPANY_ON_GET_SCORES_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.COMPANY_ON_GET_SCORES_FAILED, error });
  }
}

export function* getCompanyLevel() {
  try {
    const data: unknown = yield call(getLevel);
    yield put({ type: constants.COMPANY_ON_GET_LEVEL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.COMPANY_ON_GET_LEVEL_FAILED, error });
  }
}

export function* watchCompanies() {
  yield all([
    takeLatest(constants.COMPANY_ON_SUBMIT_REGISTER_FORM_REQUESTED, userSubmitRegisterForm),
    takeLatest(
      [
        constants.COMPANY_ON_GET_SCORES_REQUESTED,
        trainingConstants.TRAINING_SUBMIT_CODE_SUCCEEDED,
        trainingConstants.TRAINING_MARK_AS_COMPLETE_SUCCEEDED,
        appConstants.APP_ON_INITIALIZE_REQUESTED,
      ],
      getCompaniesWithScores,
    ),
    takeLatest(
      [
        constants.COMPANY_ON_GET_LEVEL_REQUESTED,
        trainingConstants.TRAINING_SUBMIT_CODE_SUCCEEDED,
        trainingConstants.TRAINING_MARK_AS_COMPLETE_SUCCEEDED,
      ],
      getCompanyLevel,
    ),
  ]);
}
