import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import CapsuleProgress from 'components/commons/CapsulesProgress';

import { CompanyType, CompanyTypeName } from 'helpers/programs';
import Loading from 'components/commons/Loading';
import { IProgramsViewProps } from './types';
import {
  ProgramsContainer,
  TabsContainer,
  Title,
  CapsuleText,
  CapsulesContainer,
  TextContainer,
  ProgressContainer,
  Capsules,
} from './styles';
import './i18n';
import Capsule from './components/Capsule';

const ProgramsView: FunctionComponent<IProgramsViewProps> = (props: IProgramsViewProps) => {
  const { activeTab, changeTab, capsules, programs, user } = props;

  return (
    <ProgramsContainer data-testid="programs-view">
      {capsules.length ? (
        <>
          <Capsules>
            <TabsContainer>
              <Title>{i18next.t('PROGRAMS_VIEW:PROGRAM_CAPSULES')}</Title>
              {programs.length > 1 && (
                <TextContainer onClick={changeTab}>
                  <CapsuleText>
                    {i18next.t('PROGRAMS_VIEW:SEE_PROGRAM')}
                    {activeTab.type === CompanyType.UCCELERATOR &&
                      ` ${CompanyTypeName.UCCELERATOR}`}
                  </CapsuleText>
                </TextContainer>
              )}
            </TabsContainer>
            <CapsulesContainer>
              {capsules.map((capsule) => (
                <Capsule capsule={capsule} key={capsule.id} user={user} />
              ))}
            </CapsulesContainer>
          </Capsules>
          <ProgressContainer>
            <CapsuleProgress capsules={capsules} user={user} isColumn />
          </ProgressContainer>
        </>
      ) : (
        <Loading />
      )}
    </ProgramsContainer>
  );
};

export default ProgramsView;
