import { FunctionComponent } from 'react';

import { IHeaderContainerDefaultState } from 'types/header.types';
import * as constants from 'redux/constants/header.constants';
import * as userConstants from 'redux/constants/user.constants';

const defaultState: IHeaderContainerDefaultState = {
  isMenuCollapsed: false,
  isConfirmationPopUpVisible: false,
};

const headerReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { type } = action;
  switch (type) {
    case constants.MENU_COLLAPSED_REQUESTED:
      return {
        ...state,
        isMenuCollapsed: !state.isMenuCollapsed,
      };
    case constants.LAYOUT_CONFIRMATION_POPUP_OPENED:
      return {
        ...state,
        isConfirmationPopUpVisible: true,
      };
    case userConstants.USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_REQUESTED:
    case constants.LAYOUT_CONFIRMATION_POPUP_CLOSED:
      return {
        ...state,
        isConfirmationPopUpVisible: false,
      };
    default:
      return state;
  }
};

export default headerReducer;
