import React, { FunctionComponent, useState } from 'react';
import i18next from 'i18next';

import Modal from 'components/commons/Modal';

import { CalendarLinksContainer, DescriptionContainer, IconCalendar, CalendarLink } from './styles';
import { ICalendarLink, IConfirmAssistanceViewProps } from './types';
import './i18n';

const ConfirmAssistanceView: FunctionComponent<IConfirmAssistanceViewProps> = (
  props: IConfirmAssistanceViewProps,
) => {
  const { calendarLinks } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Modal
      disabled
      isOpen={isOpen}
      title={i18next.t('CONFIRM_ASSISTANCE_VIEW:TITLE')}
      onClose={toggleModal}
    >
      <DescriptionContainer>
        {i18next.t('CONFIRM_ASSISTANCE_VIEW:DESCRIPTION')}
      </DescriptionContainer>
      <CalendarLinksContainer>
        {calendarLinks.map((calendarLink: ICalendarLink) => (
          <CalendarLink>
            <IconCalendar />{' '}
            <a href={calendarLink.link} rel="noopener noreferrer" target="_blank">
              {calendarLink.calendar}
            </a>
          </CalendarLink>
        ))}
      </CalendarLinksContainer>
    </Modal>
  );
};

export default ConfirmAssistanceView;
