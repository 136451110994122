import styled from 'styled-components';

export const ModalContentContainer = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const ModalButtonContainer = styled.div`
  margin-top: 15px;
`;
