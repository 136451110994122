import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { ILoginFormData } from 'views/LoginView/types';
import { onLogin, onResendEmailAccountConfirmation } from 'redux/actions/user.actions';
import useTypedSelector from 'hooks/useTypedSelector';

import LayoutContainer from 'containers/LayoutContainer';
import LoginView from 'views/LoginView';
import Modal from 'components/commons/Modal';
import Loading from 'components/commons/Loading';
import { onClosePopup } from 'redux/actions/header.actions';
import { useLocation } from 'react-router-dom';
import { ILocationState } from 'types/location.types';

const LoginContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const loading = useTypedSelector((state) => state.company.loading);
  const location = useLocation<ILocationState>();
  const from = location.state?.from;

  const onSubmit = (formData: ILoginFormData) => dispatch(onLogin(formData, from));

  const onClickClosePopup = () => {
    dispatch(onClosePopup());
  };

  const onClickReSendEmail = (formData: any) => {
    dispatch(onResendEmailAccountConfirmation(formData));
  };

  const isConfirmationPopUpVisible = useTypedSelector(
    (state) => state.header.isConfirmationPopUpVisible,
  );

  return (
    <>
      {loading && (
        <Modal disabled isOpen>
          <Loading />
        </Modal>
      )}
      <LayoutContainer isLanding>
        <LoginView
          onSubmit={onSubmit}
          isConfirmationPopUpVisible={isConfirmationPopUpVisible}
          onClickClosePopup={onClickClosePopup}
          onClickReSendEmail={onClickReSendEmail}
          loading={loading}
        />
      </LayoutContainer>
    </>
  );
};

export default LoginContainer;
