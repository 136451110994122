import React, { FunctionComponent } from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';

import { IRadioButtonProps } from './types';
import { useStyles } from './styles';

const RadioGroup: FunctionComponent<IRadioButtonProps> = (props: IRadioButtonProps) => {
  const { label, value, className = '', checked, onChange, disabled } = props;
  const classes = useStyles();

  return (
    <FormControlLabel
      className={className}
      classes={{ root: classes.root, label: classes.label }}
      control={<Radio classes={{ root: classes.radio, checked: classes.checked }} value={value} />}
      label={label}
      name={label}
      data-testid="custom-radiobutton"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default RadioGroup;
