import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'DASHBOARD_HEADER', {
  PROGRAM: 'Programa',
  LEVEL: 'Nivel',
  TOTAL_POINTS: 'Puntaje Total',
  HI: 'Hola,',
  LOGOUT: 'Cerrar sesión',
  SEE_PROFILE: 'Ver Perfil',
  ONE_PENDING_BENEFIT: '¡Tenés un beneficio pendiente de activación!',
  MANY_PENDING_BENEFITS: '¡Tenés {{ pendingBenefits }} beneficios pendientes de activación!',
  GO_TO_BENEFITS: 'Ir a beneficios',
  TOTAL_COINS: 'Monedas Totales',
  RANKING: 'Ranking',
});
