import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

export const Background = styled.div`
  background: ${COLORS.linkWater};
  width: 100%;
  display: flex;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  overflow: hidden;
`;

export const Text = styled.span`
  font-family: ${({ isBold }: { isBold: boolean }) => (isBold ? 'OpenSansBold' : 'OpenSans')};
`;
