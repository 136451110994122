import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { HEADER_HEIGHT } from 'helpers/dimensions';
import { SIZES } from 'helpers/enums/sizes';

export const AnimationContainer = styled.div`
  position: absolute;
  width: 55%;
  height: 500px;
  top: 195px;
  @media (max-width: ${SIZES.smallDesktop}px) {
    top: 140px;
    width: 80%;
  }
  @media (max-width: ${SIZES.smallTablet}px) {
    top: 300px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  padding-top: ${HEADER_HEIGHT}px;
  padding-bottom: ${HEADER_HEIGHT + 400}px;
  align-items: center;
  position: relative;
  @media (max-width: ${SIZES.smallDesktop}px) {
    padding-bottom: ${HEADER_HEIGHT + 200}px;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    padding-bottom: ${HEADER_HEIGHT + 425}px;
  }
`;

export const TextContent = styled.div`
  width: 100%;
  text-align: center;
`;

export const Title = styled.p`
  font-family: UnileverMedium;
  font-size: 40px;
  color: ${COLORS.white};
  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 36px;
  }
`;

export const Text = styled.div`
  font-family: OpenSans;
  font-size: 18px;
  color: ${COLORS.white};

  @media (max-width: ${SIZES.smallDesktop}px) {
    font-size: 16px;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1026;
  height: 1026;
`;

export const Copy = styled.div`
  color: ${COLORS.riverBed};
  text-align: center;
  font-size: 15px;
`;

export const Image = styled.img`
  @media (max-width: ${SIZES.mediumTablet}px) {
    display: none;
  }
`;

export const MobileImage = styled.img`
  display: none;

  @media (max-width: ${SIZES.mediumTablet}px) {
    display: block;
  }
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LoginButton = styled.a`
  text-align: center;
  background: ${COLORS.purple};
  padding: 10px 60px;
  color: ${COLORS.white};
  border-radius: 6px;
  margin-left: 15px;
`;
