import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/program.constants';
import * as userConstants from 'redux/constants/user.constants';
import * as companyConstants from 'redux/constants/company.constants';
import { getAllPrograms } from 'services/program.services';

export function* getPrograms() {
  try {
    const data: unknown = yield call(getAllPrograms);
    yield put({ type: constants.PROGRAM_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PROGRAM_ON_GET_ALL_FAILED, error });
  }
}

export function* watchPrograms() {
  yield all([
    takeLatest(
      [
        userConstants.USER_ON_LOGIN_SUCCEEDED,
        userConstants.USER_ON_INITIALIZE_SUCCEEDED,
        companyConstants.COMPANY_ON_SUBMIT_REGISTER_FORM_SUCCEEDED,
      ],
      getPrograms,
    ),
  ]);
}
