import { makeStyles } from '@material-ui/core';

import { COLORS } from 'helpers/enums/colors';

export const useStyles = makeStyles(() => ({
  checked: {},
  radio: {
    color: COLORS.blueResolution,
    height: '17px',
    width: '17px',
    '&$checked': {
      color: COLORS.blueResolution,
    },
  },
  root: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginLeft: '0',
  },
  label: {
    padding: '5px',
    fontFamily: 'OpenSans',
    color: COLORS.riverBed,
    textAlign: 'left',
  },
}));
