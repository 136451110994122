import { COLORS } from 'helpers/enums/colors';
import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 0px !important;
  max-height: 510px;
  min-height: 50px;
  background: ${COLORS.white};
  padding: 20px 0 20px 20px;
  border-radius: 10px;
  width: 100%;
`;

export const Trainings = styled.div`
  max-height: 510px;
  padding-right: 19px;
  overflow: auto;
  background: ${COLORS.white};
  border-radius: 10px;
  width: 94%;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #acacac;
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const Capsule = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 23px;
  background-color: ${COLORS.grayCatskill};
  border-radius: 10px;
  padding: 10px 13px;
  border: medium double white;
`;

export const Title = styled.p`
  color: ${COLORS.blueResolution};
  font: 18px/24px 'OpenSansBold';
  margin: 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font: 14px/18px 'OpenSans';
  color: ${COLORS.riverBed};
`;

export const CapsuleTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

export const CapsuleTitle = styled.p`
  font: 14px 'OpenSansSemiBold';
  color: ${COLORS.riverBed};
  margin: 0;
`;

export const CapsuleDescription = styled.p`
  color: ${COLORS.riverBed};
  margin: 0;
`;

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  padding-right: 10px;
`;

export const Completed = styled.p`
  color: ${({ completed }: { completed?: boolean }) =>
    completed ? `${COLORS.greenChristi}` : `${COLORS.red}`};

  margin: 0;
  margin-left: 20px;
`;

export const BottomContainer = styled.div`
  display: flex;
`;
