import styled, { css } from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { ITabQuestionStatus } from 'helpers/enums/form';
import { getIconColor, getIconStroke } from 'helpers/statusTabQuestion';
import { ReactComponent as Inicio } from 'assets/imgs/inicio.svg';
import { ReactComponent as General } from 'assets/imgs/general.svg';
import { ReactComponent as Sustentabilidad } from 'assets/imgs/sustentabilidad.svg';
import { ReactComponent as Negocio } from 'assets/imgs/negocio.svg';
import { ReactComponent as Financiera } from 'assets/imgs/financiera.svg';
import { ReactComponent as Logistica } from 'assets/imgs/logistica.svg';
import { ReactComponent as Personas } from 'assets/imgs/personas.svg';
import { ReactComponent as Digital } from 'assets/imgs/digital-tab.svg';

import { ITabQuestionProps } from '../../types';

export const Background = styled.div`
  background: ${COLORS.white};
  width: 100%;
  box-shadow: 0px 1px 2px ${COLORS.boxShadow};
  z-index: 2;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
`;

export const TitleContainer = styled.div`
  color: ${COLORS.bluePersian};
`;

export const Title = styled.h1`
  font-family: UnileverBold;
  padding-left: 5px;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  overflow-x: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const TabTitle = styled.h6`
  margin-top: 0px;
  margin-bottom: -4px;
  -webkit-margin-before: 4px;
  padding-left: 5px;
  font-size: 16px;
  color: ${({ status: { status } }: { status: { status: ITabQuestionStatus; title: string } }) => {
    if (status === ITabQuestionStatus.CURRENT) {
      return COLORS.bluePersian;
    }
    if (status === ITabQuestionStatus.VISITED) {
      return COLORS.purple;
    }
    return COLORS.riverBed;
  }};
  font-family: ${({
    status: { status },
  }: {
    status: { status: ITabQuestionStatus; title: string };
  }) => {
    if (status === ITabQuestionStatus.VISITED || status === ITabQuestionStatus.UNVISITED) {
      return 'OpenSans';
    }
    return 'OpenSansBold';
  }};
  font-weight: normal;
  min-width: ${({ status: { title } }: { status: { status: ITabQuestionStatus; title: string } }) =>
    title.split(' ').length > 1 ? '88px' : 'auto'};
`;

export const Tab = styled.div<ITabQuestionProps>`
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: end;
  align-self: flex-end;
  border-bottom: ${({ status }: { status: ITabQuestionStatus }) => {
    if (status === ITabQuestionStatus.CURRENT) {
      return '4px solid';
    }
    return '0px';
  }};
  border-color: ${COLORS.bluePersian};
`;

export const TabIcon = css`
  min-width: 20px;
  height: 20px;
  align-self: flex-end;
  stroke-width: ${({ status }: { status: ITabQuestionStatus }) => getIconStroke(status)};
  stroke: ${({ status }: { status: ITabQuestionStatus }) => getIconColor(status)};
`;

export const HomeIcon = styled(Inicio)`
  ${TabIcon}
`;

export const GeneralIcon = styled(General)`
  ${TabIcon}
`;

export const SustainabilityIcon = styled(Sustentabilidad)`
  ${TabIcon}
`;

export const BusinessIcon = styled(Negocio)`
  ${TabIcon}
`;

export const FinanceIcon = styled(Financiera)`
  ${TabIcon}
`;

export const LogisticsIcon = styled(Logistica)`
  ${TabIcon}
`;

export const PersonsIcon = styled(Personas)`
  ${TabIcon}
`;

export const DigitalIcon = styled(Digital)`
  ${TabIcon}
`;
