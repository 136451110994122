import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { IFormGroup } from 'views/FormView/types';
import { getStatusTabQuestion } from 'helpers/statusTabQuestion';

import { IFormTabProps } from './types';
import {
  BUSINESS,
  DIGITAL,
  FINANCE,
  GENERAL,
  LOGISTICS,
  PERSONS,
  SUSTAINABILITY,
} from './constants';
import {
  Background,
  BusinessIcon,
  Container,
  DigitalIcon,
  FinanceIcon,
  GeneralIcon,
  HomeIcon,
  LogisticsIcon,
  PersonsIcon,
  SustainabilityIcon,
  Tab,
  TabContainer,
  TabTitle,
  Title,
  TitleContainer,
} from './styles';
import './i18n';

const renderIcon = (tab: string, status: number) => {
  switch (tab) {
    case GENERAL:
      return <GeneralIcon status={status} />;
    case SUSTAINABILITY:
      return <SustainabilityIcon status={status} />;
    case BUSINESS:
      return <BusinessIcon status={status} />;
    case FINANCE:
      return <FinanceIcon status={status} />;
    case LOGISTICS:
      return <LogisticsIcon status={status} />;
    case PERSONS:
      return <PersonsIcon status={status} />;
    case DIGITAL:
      return <DigitalIcon status={status} />;
    default:
      return <HomeIcon status={status} />;
  }
};

const FormTab: FunctionComponent<IFormTabProps> = (props: IFormTabProps) => {
  const { tabs, currentTab } = props;
  const finalTab = tabs[tabs.length - 1].id;

  const renderTabs = () => {
    return tabs.map((item: IFormGroup) => {
      const status = getStatusTabQuestion(currentTab, item.id);
      return (
        item.id !== finalTab && (
          <Tab status={status} key={`tab-${item.id}`} id={item.title}>
            {renderIcon(item.title, status)}
            <TabTitle status={{ status, title: item.title }}>{item.title}</TabTitle>
          </Tab>
        )
      );
    });
  };

  return (
    <Background>
      <Container data-testid="form-tab-view">
        <TitleContainer>
          <Title>{i18next.t('FORM_TAB:TITLE')}</Title>
        </TitleContainer>
        <TabContainer>{renderTabs()}</TabContainer>
      </Container>
    </Background>
  );
};

export default FormTab;
