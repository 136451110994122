import React from 'react';
import i18next from 'i18next';
import { Field, FieldArray, reduxForm } from 'redux-form';

import { emailValidation, requiredValidation } from 'helpers/validations';

import './i18n';
import {
  BenefitForm,
  ButtonsWrapper,
  CollaboratorsList,
  CustomButton,
  CustomInput,
  ErrorText,
  FieldContainer,
  CustomList,
} from './styles';

const renderField = ({ input, label, type, meta: { touched, error } }: any) => (
  <FieldContainer>
    <CustomInput {...input} type={type} placeholder={label} />
    {touched && error && <ErrorText>{error}</ErrorText>}
  </FieldContainer>
);

const renderCollaborators = ({ fields }: any) => (
  <CollaboratorsList>
    <li>
      <CustomButton onClick={() => fields.push({})}>
        {i18next.t('BENEFITS_FORM:ADD_COLLABORATOR')}
      </CustomButton>
    </li>
    {fields.map((member: any, index: any) => (
      <CustomList key={`collaborator-${index + 1}`}>
        <h4>{`${i18next.t('BENEFITS_FORM:COLLABORATOR')} #${index + 1}`}</h4>
        <Field
          name={`${member}firstName`}
          type="text"
          component={renderField}
          label={i18next.t('BENEFITS_FORM:FIRST_NAME')}
          validate={[requiredValidation]}
        />
        <Field
          name={`${member}lastName`}
          type="text"
          component={renderField}
          label={i18next.t('BENEFITS_FORM:LAST_NAME')}
          validate={[requiredValidation]}
        />
        <Field
          name={`${member}id`}
          type="number"
          component={renderField}
          label={i18next.t('BENEFITS_FORM:ID')}
          validate={[requiredValidation]}
        />
        <Field
          name={`${member}email`}
          type="text"
          component={renderField}
          label={i18next.t('BENEFITS_FORM:EMAIL')}
          validate={[requiredValidation, emailValidation]}
        />
      </CustomList>
    ))}
  </CollaboratorsList>
);

const BenefitsForm = (props: any) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  return (
    <BenefitForm onSubmit={handleSubmit}>
      <FieldArray name="collaborators" component={renderCollaborators} />
      <ButtonsWrapper>
        <CustomButton type="submit" disabled={submitting}>
          {i18next.t('BENEFITS_FORM:SEND')}
        </CustomButton>
        <CustomButton disabled={pristine || submitting} onClick={reset}>
          {i18next.t('BENEFITS_FORM:CLEAR_VALUES')}
        </CustomButton>
      </ButtonsWrapper>
    </BenefitForm>
  );
};

export default reduxForm({
  form: 'benefitsForm',
})(BenefitsForm);
