import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { FormGroup } from '@material-ui/core';

import { COLORS } from 'helpers/enums/colors';

import { ICheckboxProps } from './types';
import { CheckBoxStyled, CustomFormControlLabel } from './styles';

const CustomCheckbox: FunctionComponent<ICheckboxProps> = (props: ICheckboxProps) => {
  const { input, label } = props;

  const useStyles = makeStyles(() => ({
    root: {
      '&$checked': {
        color: COLORS.purple,
      },
    },
    checked: {},
  }));
  const classes = useStyles();

  return (
    <FormGroup>
      <CustomFormControlLabel
        control={
          <CheckBoxStyled
            {...input}
            classes={{ root: classes.root, checked: classes.checked }}
            checked={!!input.value}
            data-testid="custom-checkbox"
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default CustomCheckbox;
