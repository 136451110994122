import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/levels.constants';
import * as appConstants from 'redux/constants/app.constants';
import * as companyConstants from 'redux/constants/company.constants';
import { getAllLevels } from 'services/levels.services';

export function* getLevels() {
  try {
    const data: unknown = yield call(getAllLevels);
    yield put({ type: constants.LEVELS_ON_GET_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.LEVELS_ON_GET_FAILED, error });
  }
}

export function* watchLevels() {
  yield all([
    takeLatest(
      [
        appConstants.APP_ON_INITIALIZE_REQUESTED,
        constants.LEVELS_ON_GET_REQUESTED,
        companyConstants.COMPANY_ON_SUBMIT_REGISTER_FORM_SUCCEEDED,
      ],
      getLevels,
    ),
  ]);
}
