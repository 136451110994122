import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const DefaulContent = styled.div`
  position: absolute;
  opacity: 1;
  transition: 0.3s;
  top: 182px;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 20px;
  pointer-events: none;

  :hover {
    opacity: 0;
  }
`;

export const HoverContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${COLORS.white};
  opacity: 0;
  transition: 0.3s;
  z-index: 1;
  position: absolute;
  top: 0px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
`;

export const WhiteBackground = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 182px;
  height: 198px;
  background: ${COLORS.white};
  transition: 0.3s;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${COLORS.riverBed};
`;

export const Title = styled.p`
  font-family: OpenSansBold;
  font-size: 18px;
  color: ${(props) => props.color};
  text-align: left;
  word-wrap: break-word;
  max-width: 100%;
`;

export const Text = styled.p`
  font-size: 14px;
  font-family: OpenSans;
  margin: 0;
  color: ${COLORS.riverBed};
  text-align: left;
  word-wrap: break-word;
`;

export const CapsuleThumbnail = styled.div`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 198px;
  position: relative;
  transition: 0.3s;
  opacity: 1;
  overflow: hidden;

  img {
    display: block;
    height: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const PartnerLogo = styled.img`
  max-height: 44px;
  max-width: 72px;
`;

export const Container = styled.div`
  width: 280px;
  height: 367px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  @media (max-width: ${SIZES.smallTablet}px) {
    margin-left: 0;
    margin-right: 0;
  }

  :hover {
    ${HoverContent} {
      opacity: 1;
    }
    ${DefaulContent} {
      opacity: 0;
    }
    ${WhiteBackground} {
      top: 0px;
      height: 100%;
    }
    ${CapsuleThumbnail} {
      opacity: 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const RedirectToCapsuleButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 107px;
  height: 40px;
  background-color: ${COLORS.stratos};
  color: ${COLORS.white};
  font-family: OpenSansBold;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;

  :hover {
    background-color: ${COLORS.grayLavenderMist};
  }
`;
