import { FunctionComponent } from 'react';

import { ILevelPropsDefaultState } from 'views/BenefitsView/types';
import * as constants from 'redux/constants/levels.constants';

const defaultState: ILevelPropsDefaultState = {
  items: [],
};

const levelsReducer: FunctionComponent<any> = (state = defaultState, action: any) => {
  const { data, type } = action;
  switch (type) {
    case constants.LEVELS_ON_GET_SUCCEEDED:
      return {
        ...state,
        items: data.levels,
      };
    case constants.LEVELS_ON_GET_FAILED:
    default:
      return state;
  }
};

export default levelsReducer;
