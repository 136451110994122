import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import { ReactComponent as GiftSVG } from 'assets/imgs/nuevo-beneficio-regalo.svg';

export const SmallCoinIcon = styled.img`
  width: 12px;
  margin: 0 5px;
  padding: 0;
`;
export const NewBenefitDescription = styled.p`
  font: 20px 'OpenSansBold';
  color: ${COLORS.purpleIndigo};
  margin: 0;
`;

export const NewBenefitTilte = styled.p`
  font: 26px 'OpenSansBold';
  color: ${COLORS.purpleIndigo};
  margin: 0;
`;

export const Gift = styled(GiftSVG)`
  width: 180px;
  margin: 0;
`;

export const SoonContainer = styled.div`
  background: ${COLORS.lightblueZumthor};
  width: 96%;
  height: 96%;
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const BenefitContainer = styled.div`
  width: 263px;
  height: 345px;
  background: ${COLORS.white};
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 90%;
    margin: 15px;
    height: 315px;
  }
`;

export const ViewContainer = styled.div`
  width: 100%;
  height: 90%;
  box-sizing: border-box;
  padding: 0 35px 0;
  max-width: 1296px;

  @media (max-width: ${SIZES.bigTablet}px) {
    padding: 0;
  }
`;

export const TopContainer = styled.div`
  width: 100%;

  @media (max-width: ${SIZES.bigTablet}px) {
    height: fit-content;
    padding: 0 20px;
    box-sizing: border-box;
  }
`;

export const Title = styled.h2`
  font: 20px/1 'OpenSansBold';
  color: ${COLORS.blueResolution};
`;

export const InfoBar = styled.div`
  position: relative;
  z-index: 0;
  width: 75%;
  color: ${COLORS.white};
  background: ${COLORS.blueFrenchSky};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;

  @media (max-width: ${SIZES.bigTablet}px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
  }
`;

export const CompanyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 8% 0 0;

  ${({ gridArea, isUnipyme }: { gridArea: string; isUnipyme?: boolean }) =>
    gridArea === 'c' &&
    `
      margin: 0 0 0 10%;
      width: ${isUnipyme ? '35%' : '20%'}
    `};

  @media (max-width: ${SIZES.bigTablet}px) {
    margin: 0;
    width: 100%;
    grid-area: ${({ gridArea }: { gridArea: string }) => gridArea};
    align-items: center;

    ${({ gridArea, isUnipyme }: { gridArea: string; isUnipyme?: boolean }) =>
      gridArea === 'c'
        ? `
          border-top: 1px solid ${COLORS.grayBotticelli};
          padding: 5px 0;
          align-items: ${!isUnipyme && 'start'};
          `
        : gridArea === 'a' &&
          `
          border-right: 1px solid ${COLORS.grayBotticelli};
    `};
  }
`;

export const CompanyInfoTitle = styled.h3`
  margin: 5px 0;
  font: 20px/1 'OpenSansBold';
  color: ${({ color }: { color?: string }) => color};

  @media (max-width: ${SIZES.bigTablet}px) {
    text-align: ${({ isLast }: { isLast?: boolean; color?: string }) => !isLast && 'center'};
    font: 18px/1 'OpenSansBold';
    align-self: ${({ isLast }: { isLast?: boolean; color?: string }) => isLast && 'start'};
  }
`;

export const CompanyInfoBody = styled.p`
  font: 14px/1.2 'OpenSans';
  margin: 5px 0;

  @media (max-width: ${SIZES.bigTablet}px) {
    font: ${({ isLast }: { isLast?: boolean }) =>
      isLast ? '14px/1 OpenSansSemiBold' : '16px/1 OpenSans'};
    align-self: ${({ isLast }: { isLast?: boolean }) => isLast && 'start'};
    max-width: 80%;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    max-width: 65%;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }
`;

export const LevelContent = styled.div`
  width: 99%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    margin: 20px;
    overflow-y: auto;
    -ms-overflow-style: auto;
    ::-webkit-scrollbar {
      display: auto;
    }
  }
`;

export const BenefitsContainer = styled.div`
  width: 78%;
  padding: 40px 0 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  justify-items: center;
  overflow: auto;
  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    order: 1;
    padding: 0;
    align-items: center;
  }
`;

export const InfoContainer = styled.div`
  color: ${COLORS.white};
  font: 'OpenSans';
  width: 20%;
  box-sizing: border-box;
  margin: 0 0 0 auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;

  /* top: -120px;

  @media (max-width: ${SIZES.bigDesktop}px) {
    top: -80px;
  } */

  @media (max-width: ${SIZES.bigTablet}px) {
    position: initial;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
`;

export const InfoContent = styled.div`
  background: ${COLORS.riverBed};
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: ${SIZES.bigTablet}px) {
    border-radius: 0;
    padding-bottom: 60px;
    margin: 20px;
    border-radius: 8px;
    position: relative;
  }
`;

export const InfoTitle = styled.h3`
  font: 18px/1 'OpenSansBold';
`;

export const InfoImage = styled.img`
  width: 100%;

  @media (max-width: ${SIZES.bigTablet}px) {
    display: none;
  }
`;

export const InfoImageMobile = styled.img`
  display: none;

  @media (max-width: ${SIZES.bigTablet}px) {
    display: block;
    position: absolute;
    width: 280px;
    right: -55px;
    top: 45%;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    top: 55%;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    top: 65%;
    right: -65px;
  }
`;

export const Top15Icon = styled.img`
  width: 65px;
  position: absolute;
  top: -12px;
  left: 15px;

  @media (max-width: ${SIZES.bigTablet}px) {
    position: inherit;
    top: 0;
    left: 0;
    width: 65px;
    margin: 10px;
  }
`;

export const InfoBarText = styled.p`
  font: 16px 'OpenSansBold';
  color: white;
  margin-left: 16%;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-left: inherit;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 14px;
  }
`;
