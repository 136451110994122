import styled, { css } from 'styled-components';

import { SIZES } from 'helpers/enums/sizes';
import Button from 'components/commons/Button';
import { COLORS } from 'helpers/enums/colors';

export const SelectContainer = styled.div`
  margin-bottom: 30px;
`;

export const ContactTitle = styled.h1`
  margin: 0;
  width: 45%;
  font-size: 40px;
  color: ${COLORS.white};

  ${({ isMobile }: { isMobile?: boolean }) =>
    isMobile &&
    css`
      display: none;
    `}

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
    margin-bottom: 30px;

    display: ${({ isMobile }: { isMobile?: boolean }) => (isMobile ? 'flex' : 'none')};
  }
`;

export const ContactContainer = styled.div`
  width: 80%;
  max-width: ${SIZES.bigDesktop}px;
  margin: 90px 0;

  @media (max-width: ${SIZES.smallDesktop}px) {
    width: 90%;
  }
`;

export const Background = styled.div`
  display: flex;
  justify-content: center;
  background: transparent linear-gradient(0deg, var(--unnamed-color-1f36c7) 0%, #6c24b6 100%) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(0deg, #1f36c7 0%, #6c24b6 100%) 0% 0% no-repeat
    padding-box;
`;

export const CustomForm = styled.form`
  width: 100%;
  margin-top: 5px;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-top: 0;
  }

  @media (max-width: ${SIZES.bigPhone}px) {
    padding-left: 5px;
  }
`;

export const FirstSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Inputs = styled.div`
  width: ${({ isFullWidth }: { isFullWidth?: boolean }) => (isFullWidth ? '100%' : '40%')};
  @media (max-width: ${SIZES.bigPhone}px) {
    width: 100%;
  }
`;

export const ContactContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${SIZES.smallDesktop}px) {
    margin-right: 15px;
  }

  @media (max-width: ${SIZES.mediumTablet}px) {
    margin-left: 0;
  }
`;

export const InputsSection = styled.div`
  display: flex;
  flex-direction: 'row';
  justify-content: space-between;

  @media (max-width: ${SIZES.mediumTablet}px) {
    margin-top: 0;
  }

  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const MessageDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SubmitButton = styled(Button)`
  width: fit-content;
  color: ${COLORS.white};
  background: ${COLORS.purpleMedium};
  padding: 12px 88px;
  margin-top: 34px;
  align-self: flex-end;
  height: 48px;
  font-family: Unilever;

  @media (max-width: ${SIZES.bigPhone}px) {
    align-self: center;
  }

  :hover {
    color: ${COLORS.white};
    background: ${COLORS.blueRibbon};
  }
`;
