import { ICapsule } from 'types/capsules.types';
import { CapsuleType } from '../../types';
import { ICapsuleByYear } from './types';

const HOURS_BY_TRAINING = 2;

export const EditionsByCapsule = {
  [CapsuleType.BUSINESS]: 2,
  [CapsuleType.FINANCE]: 2,
  [CapsuleType.IMPROVE]: 2,
  [CapsuleType.CULTURE]: 1,
  [CapsuleType.LOGISTICS]: 2,
  [CapsuleType.DIGITAL]: 2,
  [CapsuleType.SPECIAL]: 1,
};

export const ModulesByCapsule = {
  [CapsuleType.BUSINESS]: 10,
  [CapsuleType.FINANCE]: 13,
  [CapsuleType.IMPROVE]: 9,
  [CapsuleType.CULTURE]: 7,
  [CapsuleType.LOGISTICS]: 8,
  [CapsuleType.DIGITAL]: 5,
  [CapsuleType.SPECIAL]: 3,
};

export const HoursByCapsule = {
  [CapsuleType.BUSINESS]: ModulesByCapsule[CapsuleType.BUSINESS] * HOURS_BY_TRAINING,
  [CapsuleType.FINANCE]: ModulesByCapsule[CapsuleType.FINANCE] * HOURS_BY_TRAINING,
  [CapsuleType.IMPROVE]: ModulesByCapsule[CapsuleType.IMPROVE] * HOURS_BY_TRAINING,
  [CapsuleType.CULTURE]: ModulesByCapsule[CapsuleType.CULTURE] * HOURS_BY_TRAINING,
  [CapsuleType.LOGISTICS]: ModulesByCapsule[CapsuleType.LOGISTICS] * HOURS_BY_TRAINING,
  [CapsuleType.DIGITAL]: ModulesByCapsule[CapsuleType.DIGITAL] * HOURS_BY_TRAINING,
  [CapsuleType.SPECIAL]: ModulesByCapsule[CapsuleType.SPECIAL] * HOURS_BY_TRAINING,
};

export const getCapsulesByYear = (capsules: ICapsule[]) => {
  const capsulesByYear: ICapsuleByYear = {};

  capsules.forEach((capsule) => {
    if (!capsulesByYear[capsule.year]) {
      capsulesByYear[capsule.year] = [];
    }
    capsulesByYear[capsule.year].push(capsule);
  });

  return capsulesByYear;
};
