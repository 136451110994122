import styled, { css } from 'styled-components';
import { TextField } from '@material-ui/core';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const FormGroup = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: ${({ isLastChild }: { isLastChild?: boolean; hasMarginBottom: boolean }) =>
    isLastChild ? '0' : '40px'};
  margin-bottom: ${({ hasMarginBottom }: { hasMarginBottom: boolean; isLastChild?: boolean }) =>
    hasMarginBottom ? '18px' : '0'};
`;

export const CustomInput = styled(TextField)`
  width: 100%;
  height: fit-content;
  margin-bottom: 0px !important;

  label {
    display: flex;
    font-size: 14px;
    span {
      display: none;
    }
  }

  .MuiInputBase-input {
    font-size: 14px;
    font-family: OpenSans;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill::first-line,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px white inset !important;
      background-clip: content-box !important;
      font-size: 16px;
    }
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .MuiOutlinedInput-input {
    padding: 15px 14px;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid ${COLORS.grayMercury};
  }

  .MuiInputBase-multiline.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
    padding: 0;
  }

  ${({ darkMode }: { darkMode: boolean }) =>
    darkMode
      ? css`
          .MuiFilledInput-underline {
            border-bottom: 1px solid ${COLORS.white};
          }
          .MuiFilledInput-underline.Mui-error {
            border-bottom: 1px solid ${COLORS.red};
          }

          .MuiFilledInput-root {
            background-color: rgba(0, 0, 0, 0);
          }
          .MuiFormLabel-root.Mui-focused {
            color: ${COLORS.white};
          }

          .MuiInputLabel-filled {
            color: ${COLORS.white};
          }
        `
      : css`
          .MuiOutlinedInput-notchedOutline {
            border-color: ${COLORS.grayFrench};
          }
        `}
`;

export const ErrorBlock = styled.div`
  width: 100%;
  position: absolute;
  font-family: OpenSans;
  font-size: 13px;
  line-height: 1.5;
  color: ${COLORS.red};
  text-align: left;

  @media (max-width: ${SIZES.mediumPhone}px) {
    font-size: 12px;
  }
`;
