export enum ITabQuestionStatus {
  VISITED = 1,
  CURRENT,
  UNVISITED,
}

export enum QuestionType {
  OPTIONS,
  FREE_TEXT,
}

export enum FormType {
  SECTION,
  CAPSULE,
  TRAINING,
}

export enum FormStatus {
  DRAFT,
  PUBLISHED,
}
