import * as constants from 'redux/constants/company.constants';

export function onSubmitRegisterForm(formData: any): { type: string; formData: any } {
  return {
    type: constants.COMPANY_ON_SUBMIT_REGISTER_FORM_REQUESTED,
    formData,
  };
}

export function onGetScores(): { type: string } {
  return {
    type: constants.COMPANY_ON_GET_SCORES_REQUESTED,
  };
}

export function onGetLevel(): { type: string } {
  return {
    type: constants.COMPANY_ON_GET_LEVEL_REQUESTED,
  };
}
