import Button from 'components/commons/Button';
import { COLORS } from 'helpers/enums/colors';
import styled from 'styled-components';

export const Text = styled.p`
  font-size: 26px;
  margin: 0;
  text-align: center;
`;

export const ViewContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const CustomButton = styled(Button)`
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 16px;
  background: ${COLORS.purple};
  margin-bottom: 10px;
`;

export const Title = styled.h1`
  font-family: UnileverBold;
  padding-left: 5px;
  color: ${COLORS.bluePersian};
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
`;
