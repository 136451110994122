import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

import { ReactComponent as icCalendar } from 'assets/imgs/icon-calendar.svg';

export const IconCalendar = styled(icCalendar)`
  width: 14px;
  margin: 0 5px;
`;

export const ConfirmAssistanceContainer = styled.div`
  background-color: ${COLORS.white};
`;

export const CalendarLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TitleContainer = styled.div`
  background: ${COLORS.purple};
  color: white;
  font: 20px 'UnileverBold';
  padding: 10px;
`;

export const DescriptionContainer = styled.div`
  text-align: left;
  margin: 15px 0;
`;

export const CalendarLink = styled.div``;
