import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'FORM_CODE', {
  BUTTON: 'Cargar Código',
  CODE: 'Código',
  TITLE: 'Ingresa tu código',
  SUBTITLE:
    'Si finalizaste la capacitación ingresa el código aquí para sumar los puntos correspondientes',
});
