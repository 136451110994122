import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import DefaultCapsuleThumbnail from './assets/defaultCapsuleThumbnail.jpg';
import {
  Container,
  Text,
  Title,
  DefaulContent,
  HoverContent,
  WhiteBackground,
  TitleContainer,
  PartnerLogo,
  RedirectToCapsuleButton,
  ButtonContainer,
  CapsuleThumbnail,
} from './styles';
import { ICapsuleCardProps } from './types';
import '../../i18n';

const CapsuleCard: FunctionComponent<ICapsuleCardProps> = (props) => {
  const { title, description, partnerLogo, thumbnail, capsuleId, userId, trainings } = props;

  const progress = () => {
    let countCompleted = 0;
    trainings.forEach((training) => {
      if (training.completedTrainings.length) {
        training.completedTrainings.forEach((completedTraining) => {
          if (completedTraining.userId === userId) {
            countCompleted += 1;
          }
        });
      }
    });
    return (
      <Text>
        {countCompleted} {i18next.t('DASHBOARD_VIEW:PROGRESS_TEXT_1')} {trainings.length}{' '}
        {i18next.t('DASHBOARD_VIEW:PROGRESS_TEXT_2')}
      </Text>
    );
  };

  const renderTitle = () => (
    <TitleContainer>
      <Title>{title}</Title>
      <PartnerLogo src={partnerLogo} />
    </TitleContainer>
  );

  const renderDefaultContent = () => (
    <DefaulContent>
      {renderTitle()}
      <Text>{description}</Text>
    </DefaulContent>
  );

  const renderContentOnHover = () => (
    <HoverContent>
      {renderTitle()}
      {progress()}
      <ButtonContainer>
        <RedirectToCapsuleButton to={`/capsulas/${capsuleId}`}>
          {i18next.t('DASHBOARD_VIEW:ENTER')}
        </RedirectToCapsuleButton>
      </ButtonContainer>
    </HoverContent>
  );

  return (
    <Container>
      <CapsuleThumbnail>
        <img alt="Capsule thumbnail" src={thumbnail || DefaultCapsuleThumbnail} />
      </CapsuleThumbnail>
      <WhiteBackground />
      {renderDefaultContent()}
      {renderContentOnHover()}
    </Container>
  );
};

export default CapsuleCard;
