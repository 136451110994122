import React, { FunctionComponent } from 'react';

import ArrowSvg from 'assets/imgs/arrow.svg';

import { IArrowProps } from './types';
import { StyledArrow } from './styles';

const Arrow: FunctionComponent<IArrowProps> = ({ reverse = false, onClick }: IArrowProps) => (
  <StyledArrow reverse={reverse} data-testid="arrow" src={ArrowSvg} onClick={onClick} />
);

export default Arrow;
