import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/benefit.constants';
import * as appConstants from 'redux/constants/app.constants';
import * as companyConstants from 'redux/constants/company.constants';
import * as userConstants from 'redux/constants/user.constants';
import { activate, getAllBenefits, requestOwnerActivation } from 'services/benefit.services';

export function* getBenefits() {
  try {
    const data: unknown = yield call(getAllBenefits);
    yield put({ type: constants.BENEFITS_ON_GET_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.BENEFITS_ON_GET_FAILED, error });
  }
}

export function* activateBenefit(action: any) {
  try {
    const data: unknown = yield call(activate, action.benefitId, action.collaborators);
    yield put({
      type: constants.BENEFITS_ON_ACTIVATE_SUCCEEDED,
      data,
      benefitId: action.benefitId,
    });
  } catch (error) {
    yield put({ type: constants.BENEFITS_ON_ACTIVATE_FAILED, error });
  }
}

export function* requestOwnerActivationBenefit(action: any) {
  try {
    const { benefitId } = action;
    const data: unknown = yield call(requestOwnerActivation, benefitId);
    yield put({
      type: constants.BENEFITS_ON_REQUEST_OWNER_ACTIVATION_SUCCEEDED,
      data,
      benefitId,
    });
  } catch (error) {
    yield put({ type: constants.BENEFITS_ON_REQUEST_OWNER_ACTIVATION_FAILED, error });
  }
}

export function* watchBenefits() {
  yield all([
    takeLatest(
      [
        userConstants.USER_ON_LOGIN_SUCCEEDED,
        appConstants.APP_ON_INITIALIZE_REQUESTED,
        companyConstants.COMPANY_ON_SUBMIT_REGISTER_FORM_SUCCEEDED,
      ],
      getBenefits,
    ),
    takeLatest(constants.BENEFITS_ON_ACTIVATE_REQUESTED, activateBenefit),
    takeLatest(
      constants.BENEFITS_ON_REQUEST_OWNER_ACTIVATION_REQUESTED,
      requestOwnerActivationBenefit,
    ),
  ]);
}
