import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import Carousel from 'components/commons/Carousel';
import { partnerLogos } from 'helpers/partnerLogos';

import { Background, Container, PartnerLogo, Title } from './styles';
import './i18n';

const Benefits: FunctionComponent = () => {
  const renderPartnerLogo = (path: string): JSX.Element => {
    return <PartnerLogo src={path} />;
  };

  return (
    <Background>
      <Container id="benefits">
        <Title>{i18next.t('BENEFITS:TITLE')}</Title>
        <Carousel
          autoPlay={false}
          items={Object.values(partnerLogos)}
          renderItem={renderPartnerLogo}
          infinite
          itemsPerSlide={3}
        />
      </Container>
    </Background>
  );
};

export default Benefits;
