import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { HEADER_HEIGHT } from 'helpers/dimensions';
import { SIZES } from 'helpers/enums/sizes';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 1024px;
  margin: 0px auto;
  padding-top: ${HEADER_HEIGHT + 10}px;
  padding-bottom: ${HEADER_HEIGHT + 10}px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    width: 90%;
  }

  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 90%;
  }
`;

export const Title = styled.p`
  font-family: UnileverMedium;
  font-size: 40px;
  color: ${COLORS.bluePersian};
  text-align: center;
  margin-bottom: 80px;
  margin-top: 0;
  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 36px;
  }
`;

export const PartnerLogo = styled.img`
  width: 225px;
  height: 105px;
`;

export const Background = styled.div`
  background-color: ${COLORS.linkWater};
`;
