import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const NotificationsContainer = styled.div`
  padding: 10px 0 0 40px;
  width: 100%;
  max-width: 1296px;
  @media (max-width: ${SIZES.bigTablet}px) {
    padding: 10px 20px;
  }
`;

export const Title = styled.p`
  font: 18px/24px 'OpenSansBold';
  color: ${COLORS.blueResolution};
`;

export const Notification = styled.div`
  padding: 8px 5px;
  width: 100%;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: ${({ hasBorderBottom }: { hasBorderBottom?: boolean }) =>
    hasBorderBottom && `1px solid ${COLORS.grayMercury}`};
`;

export const NotificationTitle = styled.p`
  font: ${({ hasBiggerFont }: { hasBiggerFont?: boolean }) =>
    hasBiggerFont ? `16px/20px 'OpenSansSemiBold'` : `14px/20px 'OpenSansSemiBold'`};
  color: ${COLORS.riverBed};
  width: 75%;
  margin: 0;
  margin-bottom: 12px;
`;

export const DateText = styled.p`
  font: 11px/15px 'OpenSans';
  color: ${COLORS.graySantas};
  display: flex;
  justify-content: flex-end;
  text-align: right;
  align-items: center;
  flex: 1;
  margin: 0;
`;

export const NotificationText = styled.div`
  font: 14px/18px 'OpenSans';
  color: ${COLORS.riverBed};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  padding-top: ${({ hasPaddingTop }: { hasPaddingTop?: boolean }) => hasPaddingTop && `20px`};
`;

export const NotificationDetail = styled.div`
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  width: 90%;
  height: 100%;
  padding: 33px 30px;
`;

export const CustomParagraph = styled.p`
  color: ${COLORS.graySantas};
  font-size: 18px;
  margin: 0;
  margin-bottom: 5px;
`;
