import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from 'helpers/enums/colors';
import Button from 'components/commons/Button';

export const Form = styled.form`
  width: 100%;
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const ForgotPasswordLink = styled(Link)`
  color: ${COLORS.purple};
  font-family: OpenSans;
  font-size: 12px;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  margin: 30px 0 15px;
  display: flex;
  justify-content: center;
`;

export const FormButton = styled(Button)`
  padding: 10px 64px;
  background: ${COLORS.purple};

  :hover {
    background: ${COLORS.purpleHeart};
  }
`;

export const ModalText = styled.h1`
  margin-top: 20px;
  color: ${COLORS.blueRibbon};
`;

export const ModalButton = styled(Button)`
  && {
    height: 40px;
    color: ${COLORS.white};
    padding: 4px 20px;
    margin: 0 3px 25px 0;
    background: ${({ isDark }: { isDark?: boolean }) =>
      isDark ? `${COLORS.blueResolution}` : `${COLORS.bluePersian}`};

    .MuiButton-label {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      padding: 0;
      text-transform: initial;
    }

    :hover {
      color: ${COLORS.white};
      background: ${COLORS.purple};
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
