export const localitiesFilter = (localities: any): { [index: string]: string[] } =>
  localities
    .map((item: any) => ({
      province: item.provincia.nombre,
      locality: item.nombre,
    }))
    .reduce(
      (groups: { [index: string]: string[] }, item: { province: string; locality: string }) => {
        const group = groups[item.province] || [];
        group.push(item.locality);
        // eslint-disable-next-line no-param-reassign
        groups[item.province] = group.sort();
        return groups;
      },
      {},
    );

export const provincesFilter = (provinces: any): string[] =>
  provinces.map((province: any) => province.nombre).sort();
