import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

export const Link = styled.a`
  display: flex;
  justify-content: center;
  margin: 0;
`;

export const Container = styled.div`
  height: 320px;
  width: 226px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 10px 10px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #1f1f1f00 0%, #1f1f1f 62%, #1f1f1f 100%),
    url('${({ src }: { src: string; isGrid: boolean }) => src}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 20px 5px;

  ${({ isGrid }: { isGrid: boolean }) =>
    isGrid &&
    `
    margin: 0;
    width: 100%;
    transition: filter 0.2s;
    `}

  :hover {
    filter: drop-shadow(-5px 5px 5px #00000050);
  }
`;

export const Text = styled.div`
  display: flex;
  flex: 1;
  align-items: ${({ isTop }: { isTop: boolean }) => (isTop ? 'flex-end' : 'start')};
  color: ${COLORS.white};
  font-family: OpenSansBold;
  font-size: 16px;
  text-align: start;
  width: calc(100% - 40px);
  margin: ${({ isTop }: { isTop: boolean }) =>
    isTop ? '20px 20px 0px 20px' : '10px 20px 20px 20px'};
  padding-bottom: ${({ isTop }: { isTop: boolean }) => (isTop ? '5px' : '0')};
  border-bottom: ${({ isTop }: { isTop: boolean }) => (isTop ? '2px solid white' : '0')};
`;
