import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'PROGRAMS', {
  TITLE: 'PROGRAMA DE DESARROLLO',
  DESCRIPTION: 'Instancia de asesoramiento y capacitación, a través de un enfoque integral.',
  UCCELERATOR: 'Uccelerator',
  UCCELERATOR_DESCRIPTION:
    'Programa de desarrollo, con el principal objetivo de colaborar a la profesionalización y crecimiento de las PyMEs que accedan a esta instancia.',
  UCCELERATOR_CAPTION:
    '*Según el scoring adquirido durante el proceso podrán ser seleccionados para Uccelerator.',
  CAPSULES: 'Cápsulas de los programas',
  CAPSULE_TITLE_1: 'Sustentabilidad',
  CAPSULE_DESCRIPTION_1:
    'Desarrollo de estrategias sustentables, logrando procesos más eficientes para el cuidado del medio ambiente',
  CAPSULE_TITLE_2: 'Desarrollo de Negocio',
  CAPSULE_DESCRIPTION_2:
    'Potenciar y fortalecer la estructura interna del negocio a fin de maximizar oportunidades y reducir riesgos',
  CAPSULE_TITLE_3: 'Herramientas Financieras',
  CAPSULE_DESCRIPTION_3:
    'Conceptos y aspectos financieros orientados a potenciar la rentabilidad del negocio',
  CAPSULE_TITLE_4: 'Logística',
  CAPSULE_DESCRIPTION_4: 'Optimización y eficiencia de la cadena de abastecimiento y distribución',
  CAPSULE_TITLE_5: 'Cultura Organizacional',
  CAPSULE_DESCRIPTION_5:
    'Potenciar la cultura organizacional a través de herramientas y buenas prácticas organizacionales',
  CAPSULE_TITLE_6: 'Digital',
  CAPSULE_DESCRIPTION_6:
    'Potenciar la competitividad de las PyMEs mediante el uso de herramientas digitales',
  CAPSULE_TITLE_7: 'Cápsulas especiales',
  CAPSULE_DESCRIPTION_7:
    'Contamos con capacitaciones temporales de temáticas especificas de algún sector particular',
  JOIN: 'Sea parte del programa Unipyme',
  SCORING_TITLE: 'Sistema de scoring',
  SCORING_DESCRIPTION:
    'El compromiso de las PyMEs inscriptas en el programa se mide a través de su participación en las capacitaciones logrando:',
  SCORING_ITEM_1: 'Sumar puntos por capacitación',
  SCORING_ITEM_2: 'Sumar puntos extras por capacitación en vivo',
  SCORING_ITEM_3: 'Desbloquear niveles y obtener multiples beneficios',
});
