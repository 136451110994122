import React, { FunctionComponent } from 'react';

import LayoutContainer from 'containers/LayoutContainer';
import ConfirmRegisterView from 'views/ConfirmSignUpView';

const ConfirmSignupContainer: FunctionComponent = () => {
  return (
    <LayoutContainer isLanding>
      <ConfirmRegisterView />
    </LayoutContainer>
  );
};

export default ConfirmSignupContainer;
