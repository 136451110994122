import { COLORS } from 'helpers/enums/colors';
import styled, { css } from 'styled-components';

import { ReactComponent as Improve } from 'assets/imgs/improve.svg';
import { ReactComponent as Business } from 'assets/imgs/business.svg';
import { ReactComponent as Finance } from 'assets/imgs/finance.svg';
import { ReactComponent as Logistics } from 'assets/imgs/logistics.svg';
import { ReactComponent as Culture } from 'assets/imgs/culture.svg';
import { ReactComponent as Digital } from 'assets/imgs/digital.svg';
import { ReactComponent as Special } from 'assets/imgs/special.svg';
import { getIconBackgroundColor, getGradientBackground } from 'helpers/programs';
import { SIZES } from 'helpers/enums/sizes';

export const DefaulContent = styled.div`
  position: absolute;
  color: ${COLORS.white};
  text-align: center;
  opacity: 1;
  transition: 0.3s;
  top: 185px;
  padding: 0 15px;

  :hover {
    opacity: 0;
  }
`;

export const HoverContent = styled.div`
  color: ${COLORS.white};
  opacity: 0;
  transition: 0.3s;
  z-index: 1;
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const GradientBackground = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 182px;
  height: 167px;
  background: ${({ type }: { type: number }) => getGradientBackground(type)};
  transition: 0.3s;
  width: 321px;
`;

export const Container = styled.div`
  position: relative;
  width: 321px;
  border-radius: 8px;
  overflow: hidden;
  height: 350px;

  @media (max-width: ${SIZES.smallTablet}px) {
    margin-left: 0;
    margin-right: 0;
  }

  :hover {
    cursor: pointer;
    ${HoverContent} {
      opacity: 1;
    }
    ${DefaulContent} {
      opacity: 0;
    }
    ${GradientBackground} {
      top: 0px;
      height: 100%;
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: ${({ type }: { type: number }) => getIconBackgroundColor(type)};
`;

export const Title = styled.p`
  font-family: OpenSansBold;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-size: 16px;
  font-family: OpenSans;
  margin: 0;
`;

export const TextHover = styled(Text)`
  margin: ${({ isSpecial }: { isSpecial: boolean }) => (isSpecial ? '0 0 10px 0' : '0 0 20px 0')};
`;

export const InfoDetailText = styled.p`
  font-size: 16px;
  font-family: Unilever;
  font-weight: bold;
  margin: 0;
`;

export const CapsuleIcon = css`
  width: 25px;
  height: 25px;
`;

export const ImproveIcon = styled(Improve)`
  ${CapsuleIcon}
`;

export const BusinessIcon = styled(Business)`
  ${CapsuleIcon}
`;

export const FinanceIcon = styled(Finance)`
  ${CapsuleIcon}
`;

export const LogisticsIcon = styled(Logistics)`
  ${CapsuleIcon}
`;

export const CultureIcon = styled(Culture)`
  ${CapsuleIcon}
`;

export const DigitalIcon = styled(Digital)`
  ${CapsuleIcon}
`;

export const SpecialIcon = styled(Special)`
  ${CapsuleIcon}
`;

export const CapsuleImage = styled.img`
  height: 183px;
  opacity: 1;
  transition: 0.3s;
  position: relative;
  width: 321px;
`;

export const HoverTitle = styled.p`
  font-family: OpenSansBold;
  font-size: 20px;
  text-align: left;
  color: ${COLORS.white};
  max-width: 150px;
  margin: 0;
  align-self: center;
`;

export const HoverContentTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 55px;
  margin-top: 16px;
`;

export const HoverContentInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  width: 80%;
  margin-bottom: ${({ isSpecial }: { isSpecial: boolean }) => (isSpecial ? '10px' : '20px')};
`;

export const HoverContentSpecialTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 15px;
`;
