import React from 'react';

import { Container, FillContainer, ProgressContainer, Percentage, BenefitText } from './styles';
import { IProgressBarProps } from './types';

const ProgressBar = ({ completed, total, showPoints = false }: IProgressBarProps) => {
  const progress = (completed * 100) / total || 0;
  return (
    <ProgressContainer fromBenefits={showPoints}>
      <Container data-testid="progress-bar-component">
        <FillContainer progress={progress} />
      </Container>
      {showPoints ? (
        <BenefitText>{`${completed}/${total} pts`}</BenefitText>
      ) : (
        <Percentage>{Math.floor(progress)}%</Percentage>
      )}
    </ProgressContainer>
  );
};

export default ProgressBar;
