import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  HEADER_HEIGHT,
  FOOTER_HEIGHT,
  SIDE_MENU_WIDTH,
  COLLAPSED_SIDE_MENU_WIDTH,
} from 'helpers/dimensions';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';
import useTypedSelector from 'hooks/useTypedSelector';
import { IForm } from 'types/form.types';
import HeaderContainer from 'containers/HeaderContainer';
import Footer from 'components/main/Footer';
import { useDispatch, useSelector } from 'react-redux';

import countrySelector from 'redux/selectors/country.selector';
import PopupForm from 'components/commons/PopupForm';
import { Sections } from 'helpers/enums/sections';
import { getFormByType, splitFormQuestionsInPages } from 'helpers/forms';
import { MenuRoutes } from 'helpers/enums/links';

import {
  onCloseCompleteProfileModal,
  onInitializeSignUpView,
  onRedirectToProfile,
} from 'redux/actions/user.actions';
import pendingBenefitsSelector from 'redux/selectors/benefits.selector';
import CompleteProfileModal from 'components/commons/CompleteProfileModal';
import { isCompanyDataCompleted } from 'helpers/company';
import { Role } from 'helpers/enums/roles';
import DashboardHeaderContainer from './DashboardHeaderContainer';

interface IViewContainerProps {
  isMenuCollapsed: boolean;
  isLandingOrForm: boolean;
  isTopbarOverlay: boolean;
  showBenefitsBanner: boolean;
}
interface ILayoutContainerProps {
  children: ReactNode;
  isLanding?: boolean;
  isTopbarOverlay?: boolean;
  isHome?: boolean;
  isForm?: boolean;
  // eslint-disable-next-line react/require-default-props
  hasBanner?: boolean;
  // eslint-disable-next-line react/require-default-props
  forms?: IForm[];
  // eslint-disable-next-line react/require-default-props
  section?: Sections;
  // eslint-disable-next-line react/require-default-props
  completeForm?: (form: IForm, values: any) => void;
}

const GlobalContainer = styled.div`
  display: flex;
  flex-direction: ${({ isLandingOrForm }: { isLandingOrForm: boolean }) =>
    isLandingOrForm ? 'column' : 'row'};
`;

const ViewContainer = styled.div<IViewContainerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: ${(props) =>
    props.isMenuCollapsed && !props.isLandingOrForm
      ? `${COLLAPSED_SIDE_MENU_WIDTH}px`
      : !props.isMenuCollapsed && !props.isLandingOrForm && `${SIDE_MENU_WIDTH}px`};
  ${(props) =>
    `
      margin-top: ${
        // eslint-disable-next-line
        props.showBenefitsBanner
          ? SIZES.benefitsBanner + HEADER_HEIGHT
          : props.isTopbarOverlay
          ? 0
          : HEADER_HEIGHT
      }px;
    `}

  flex-direction: ${(props) => (props.isLandingOrForm ? 'column' : 'row')};
  min-height: ${(props) =>
    props.isLandingOrForm
      ? `calc(100vh - ${FOOTER_HEIGHT}px);`
      : `calc(100vh - ${HEADER_HEIGHT}px);`};
  background: ${(props) => (props.isLandingOrForm ? `${COLORS.white}` : `${COLORS.linkWater}`)};

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-bottom: ${(props) => !props.isLandingOrForm && `50px`};
    margin-left: 0;
  }
  @media (max-width: ${SIZES.mediumTablet}px) {
    ${(props) =>
      `
      margin-top: ${
        props.showBenefitsBanner ? SIZES.benefitsBanner * 2 + HEADER_HEIGHT : HEADER_HEIGHT
      }px;

      ${props.isTopbarOverlay && 'margin-top: 0;'}
    `}
  }
`;

const LayoutContainer: FunctionComponent<ILayoutContainerProps> = (
  props: ILayoutContainerProps,
) => {
  const {
    children,
    isForm = false,
    isLanding = false,
    hasBanner = false,
    isHome = false,
    forms,
    isTopbarOverlay = false,
    section,
    completeForm,
  } = props;
  const isLandingOrForm = isLanding || isForm;
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  const scores = useTypedSelector((state) => state.company?.scores);
  const isMenuCollapsed = useTypedSelector((state) => state.header.isMenuCollapsed);
  const user = useTypedSelector((state) => state.company?.user);
  const countries = useSelector(countrySelector());
  const pendingBenefits = useTypedSelector(pendingBenefitsSelector());
  const showBenefitsBanner =
    !!pendingBenefits.length &&
    user?.country.name !== 'Colombia' &&
    !isLanding &&
    pathname !== MenuRoutes.BENEFITS;
  const showCompleteProfileModal = useTypedSelector(
    (state) => state.company.showCompleteProfileModal,
  );
  const company = useTypedSelector((state) => state.company?.data);
  const isProfileComplete = company ? isCompanyDataCompleted(company) : false;

  const closeCompleteProfileModal = () => dispatch(onCloseCompleteProfileModal());
  const redirectToProfile = () => dispatch(onRedirectToProfile());

  const onInitialize = useCallback(() => {
    dispatch(onInitializeSignUpView());
  }, [dispatch]);
  useEffect(() => {
    onInitialize();
  }, [onInitialize]);

  const form = getFormByType(forms, section);

  const [formOpen, setFormOpen] = useState<boolean>(true);

  const toggleForm = () => {
    setFormOpen((prev) => !prev);
  };

  const submitForm = (values: any) => {
    if (completeForm && form) completeForm(form, values);
    toggleForm();
  };

  return (
    <GlobalContainer isLandingOrForm={isLandingOrForm}>
      {!isForm && <HeaderContainer isLanding={isLanding} isForm={isForm} />}
      {!isLanding && <DashboardHeaderContainer scores={scores} />}
      <ViewContainer
        isLandingOrForm={isLandingOrForm}
        isMenuCollapsed={isMenuCollapsed}
        isTopbarOverlay={isTopbarOverlay}
        showBenefitsBanner={showBenefitsBanner}
      >
        {form && (
          <PopupForm
            title={form.title}
            description={form.description}
            questionsPaginated={splitFormQuestionsInPages(form.question)}
            submitForm={submitForm}
            formOpen={formOpen}
            onCloseForm={toggleForm}
          />
        )}
        {children}
      </ViewContainer>
      {isLandingOrForm && (
        <Footer
          isLanding={isLanding}
          isHome={isHome}
          hasBanner={hasBanner}
          countries={countries}
          isLogged={!!user}
        />
      )}
      <CompleteProfileModal
        isOpen={
          // Hardcoding false for hiding the modal
          true &&
          user?.role === Role.COMPANY_OWNER &&
          !isLanding &&
          showCompleteProfileModal &&
          !isProfileComplete
        }
        onClickButton={redirectToProfile}
        onClose={closeCompleteProfileModal}
      />
    </GlobalContainer>
  );
};

LayoutContainer.defaultProps = {
  isLanding: false,
  isTopbarOverlay: false,
  isForm: false,
  isHome: false,
};

export default LayoutContainer;
