import React, { FunctionComponent } from 'react';

import LayoutContainer from 'containers/LayoutContainer';
import TermsAndConditionsView from 'views/TermsAndConditionsview';
import useQueryString from 'hooks/useQueryString';

const TermsAndConditionsContainer: FunctionComponent = () => {
  const { country }: any = useQueryString();
  return (
    <LayoutContainer isLanding>
      <TermsAndConditionsView country={country} />
    </LayoutContainer>
  );
};

export default TermsAndConditionsContainer;
