import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'FOOTER', {
  WHAT_IS: 'QUÉ ES UNIPYME',
  PROGRAMS: 'PROGRAMAS',
  NEWS: 'NOTICIAS',
  PRIVACY_HREF_ARGENTINA: 'https://www.unilevernotices.com/argentina/spanish/privacy-notice.html',
  PRIVACY_HREF_COLOMBIA: 'https://www.unilevernotices.com/privacy-notices/columbia-spanish.html',
  PRIVACY_TEXT: 'Aviso de privacidad',
});
