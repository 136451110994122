import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useTypedSelector from 'hooks/useTypedSelector';
import { SIZES } from 'helpers/enums/sizes';

import LayoutContainer from 'containers/LayoutContainer';
import NewsAndTestimonialsView from 'views/NewsAndTestimonialsView';
import { onCompleteForms, onGetForms } from 'redux/actions/user.actions';
import { Sections } from 'helpers/enums/sections';
import { getFormQuestionsAndAnswers } from 'helpers/forms';
import { IForm } from 'types/form.types';

const NewsAndTestimonialsContainer: FunctionComponent = () => {
  const news = useTypedSelector((state) => state.news.items);
  const testimonials = useTypedSelector((state) => state.testimonials.items);
  const forms = useTypedSelector((state) => state.company.user?.forms);

  const widthOfTablet = window.innerWidth < SIZES.bigTablet;
  const [isTablet, setIsTablet] = useState<boolean>(widthOfTablet);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!forms) dispatch(onGetForms());
    function handleResize(): void {
      if (window.innerWidth > SIZES.bigTablet) {
        setIsTablet(false);
      } else {
        setIsTablet(true);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const dashboardNews = news?.filter((newsItem) => newsItem.visibleInDash);
  const completeForm = (form: IForm, values: any) => {
    const completedFormData = {
      formId: form.id,
      formAnswers: getFormQuestionsAndAnswers(form, values),
    };
    dispatch(onCompleteForms(completedFormData));
  };

  return (
    <>
      <LayoutContainer
        forms={forms}
        section={Sections.NEWS_AND_TESTIMONIALS}
        completeForm={completeForm}
      >
        <NewsAndTestimonialsView
          isTablet={isTablet}
          news={dashboardNews}
          testimonials={testimonials}
        />
      </LayoutContainer>
    </>
  );
};

export default NewsAndTestimonialsContainer;
