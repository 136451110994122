import { CompanySectors } from './companySectors';

export enum CompanySubSectors {
  OTHER = 0,
  CIVIL_STRUCTURAL_INSTALLATION = 1,
  EQUIPMENT_MACHINERY = 2,
  PROCESSES = 3,
  TECHNICAL_ENGINEERING_SERVICES = 4,
  INDUSTRIAL_SUPPLIES = 5,
  REPLACEMENT_PARTS = 6,
  MAINTENANCE_SERVICES = 7,
  HARDWARE = 8,
  SOFTWARE = 9,
  DESIGN_AGENCY = 10,
  INFLUENCER_AGENCY = 11,
  SOCIAL_MEDIA_AGENCY = 12,
  DIGITAL_AGENCY = 13,
  PEOPLE_DATA_CENTER = 14,
  EVENTS_ACTIVATIONS_PROMOTIONS = 15,
  CONTENT_PRODUCTION = 16,
  DIGITAL_ADVERTISING = 17,
  CINE_ADVERTISING = 18,
  RADIO_ADVERTISING = 19,
  TELEVISION_ADVERTISING = 20,
  OUTDOOR_ADVERTISING = 21,
  PRINT_ADVERTISING = 22,
  HUMAN_RESOURCES_TRAINING = 23,
  TEMPORARY_WORK = 24,
  RECRUITMENT_SELECTION = 25,
  ENTERTAINMENT = 26,
  TRANSPORTATION = 27,
  TRAVEL_ACCOMMODATION = 28,
  REAL_ESTATE = 29,
  WORKSPACE_DESIGN = 30,
  FLEET_COMPANY_VEHICLES = 31,
  OFFICE_SUPPLIES_EQUIPMENT = 32,
  WORKWEAR_UNIFORMS = 33,
  SECURITY = 34,
  CATERING_VENDING_MACHINES = 35,
  BUILDING_MAINTENANCE_REPAIR_SERVICES = 36,
  OILS = 37,
  STARCHES_DERIVATIVES = 38,
  ORGANIC_MATERIALS = 39,
  MINERALS = 40,
  ANIMAL_PROTEINS = 41,
  SOFT_COMMODITIES = 42,
  CROPS = 43,
  SPICES_HERBS = 44,
  FUNCTIONAL_INGREDIENTS = 45,
  OLEOCHEMICALS = 46,
  PETROCHEMICALS = 47,
  SPECIALTY_CHEMICALS = 48,
  INORGANIC_CHEMICALS = 49,
  ORGANIC_CHEMICALS = 50,
  BOXES = 51,
  LAMINATES = 52,
  SERVICES = 53,
  TELECOMMUNICATIONS = 54,
  STORAGE_AND_PALLETS = 55,
  MATERIAL_HANDLING_EQUIPMENT = 56,
  TRANSPORTATION_AND_DISTRIBUTION_OF_GOODS = 57,
  CREATIVE_TRADITIONAL_AGENCY = 58,
  PUBLIC_RELATIONS_AGENCY = 59,
  MATERIALS_POINT_OF_SALE = 60,
}

export const companySubSectors = [
  {
    value: CompanySubSectors.DIGITAL_ADVERTISING,
    label: 'Publicidad Digital',
    type: [CompanySectors.MEDIA],
  },
  {
    value: CompanySubSectors.CINE_ADVERTISING,
    label: 'Publicidad en Cine',
    type: [CompanySectors.MEDIA],
  },
  {
    value: CompanySubSectors.RADIO_ADVERTISING,
    label: 'Publicidad en Radio',
    type: [CompanySectors.MEDIA],
  },
  {
    value: CompanySubSectors.TELEVISION_ADVERTISING,
    label: 'Publicidad en Televisión',
    type: [CompanySectors.MEDIA],
  },
  {
    value: CompanySubSectors.OUTDOOR_ADVERTISING,
    label: 'Publicidad Exterior',
    type: [CompanySectors.MEDIA],
  },
  {
    value: CompanySubSectors.PRINT_ADVERTISING,
    label: 'Publicidad Impresa (Revista / Periódico / Cupones)',
    type: [CompanySectors.MEDIA],
  },
  {
    value: CompanySubSectors.HARDWARE,
    label: 'Hardware',
    type: [
      CompanySectors.INNOVATION_AND_PROFESSIONAL_SERVICES,
      CompanySectors.IT_AND_TELECOMMUNICATIONS,
    ],
  },
  {
    value: CompanySubSectors.SOFTWARE,
    label: 'Software',
    type: [CompanySectors.IT_AND_TELECOMMUNICATIONS],
  },
  {
    value: CompanySubSectors.TELECOMMUNICATIONS,
    label: 'Telecomunicaciones',
    type: [CompanySectors.IT_AND_TELECOMMUNICATIONS],
  },
  {
    value: CompanySubSectors.CREATIVE_TRADITIONAL_AGENCY,
    label: 'Agencia Creativa/Tradicional',
    type: [CompanySectors.MARKETING],
  },
  {
    value: CompanySubSectors.DESIGN_AGENCY,
    label: 'Agencia de Diseño',
    type: [CompanySectors.MARKETING],
  },
  {
    value: CompanySubSectors.INFLUENCER_AGENCY,
    label: 'Agencia de influencers',
    type: [CompanySectors.MARKETING],
  },
  {
    value: CompanySubSectors.SOCIAL_MEDIA_AGENCY,
    label: 'Agencia de Redes Sociales',
    type: [CompanySectors.MARKETING],
  },
  {
    value: CompanySubSectors.PUBLIC_RELATIONS_AGENCY,
    label: 'Agencia de Relaciones Públicas (PR)',
    type: [CompanySectors.MARKETING],
  },
  {
    value: CompanySubSectors.DIGITAL_AGENCY,
    label: 'Agencia Digital',
    type: [CompanySectors.MARKETING],
  },
  {
    value: CompanySubSectors.PEOPLE_DATA_CENTER,
    label: 'Centro de Datos de Personas',
    type: [CompanySectors.MARKETING],
  },
  {
    value: CompanySubSectors.EVENTS_ACTIVATIONS_PROMOTIONS,
    label: 'Eventos, Activaciones y Promociones',
    type: [CompanySectors.MARKETING],
  },
  {
    value: CompanySubSectors.CONTENT_PRODUCTION,
    label: 'Producción de Contenidos',
    type: [CompanySectors.MARKETING],
  },
  {
    value: CompanySubSectors.REAL_ESTATE,
    label: 'Bienes Raíces',
    type: [CompanySectors.WORKPLACE],
  },
  {
    value: CompanySubSectors.WORKSPACE_DESIGN,
    label: 'Diseño de Espacios de Trabajo',
    type: [CompanySectors.WORKPLACE],
  },
  {
    value: CompanySubSectors.FLEET_COMPANY_VEHICLES,
    label: 'Flota y Vehículos de la Empresa',
    type: [CompanySectors.WORKPLACE],
  },
  {
    value: CompanySubSectors.OFFICE_SUPPLIES_EQUIPMENT,
    label: 'Suministros y Equipos de Oficina',
    type: [CompanySectors.WORKPLACE],
  },
  {
    value: CompanySubSectors.WORKWEAR_UNIFORMS,
    label: 'Ropa de Trabajo y Uniformes',
    type: [CompanySectors.WORKPLACE],
  },
  {
    value: CompanySubSectors.SECURITY,
    label: 'Seguridad',
    type: [CompanySectors.WORKPLACE],
  },
  {
    value: CompanySubSectors.SERVICES,
    label: 'Servicios',
    type: [
      CompanySectors.INNOVATION_AND_PROFESSIONAL_SERVICES,
      CompanySectors.IT_AND_TELECOMMUNICATIONS,
      CompanySectors.MARKETING,
      CompanySectors.MEDIA,
      CompanySectors.WORKPLACE,
    ],
  },
  {
    value: CompanySubSectors.CIVIL_STRUCTURAL_INSTALLATION,
    label: 'Civil, Estructural e Instalación',
    type: [CompanySectors.EQUIPMENT_AND_MACHINERY, CompanySectors.MAINTENANCE_SERVICES],
  },
  {
    value: CompanySubSectors.EQUIPMENT_MACHINERY,
    label: 'Equipos y maquinarias',
    type: [CompanySectors.EQUIPMENT_AND_MACHINERY],
  },
  {
    value: CompanySubSectors.PROCESSES,
    label: 'Procesos',
    type: [CompanySectors.EQUIPMENT_AND_MACHINERY],
  },
  {
    value: CompanySubSectors.TECHNICAL_ENGINEERING_SERVICES,
    label: 'Servicios Técnicos y de Ingeniería de Fábrica',
    type: [CompanySectors.EQUIPMENT_AND_MACHINERY],
  },
  {
    value: CompanySubSectors.INDUSTRIAL_SUPPLIES,
    label: 'Suministros Industriales',
    type: [CompanySectors.REPLACEMENT_PARTS_AND_COMPONENTS, CompanySectors.MAINTENANCE_SERVICES],
  },
  {
    value: CompanySubSectors.REPLACEMENT_PARTS,
    label: 'Repuestos',
    type: [CompanySectors.REPLACEMENT_PARTS_AND_COMPONENTS],
  },
  {
    value: CompanySubSectors.MAINTENANCE_SERVICES,
    label: 'Servicios de Mantenimiento y Reparación de Equipos',
    type: [CompanySectors.MAINTENANCE_SERVICES],
  },
  {
    value: CompanySubSectors.HUMAN_RESOURCES_TRAINING,
    label: 'Capacitación',
    type: [CompanySectors.RECRUITMENT_AND_HUMAN_RESOURCES],
  },
  {
    value: CompanySubSectors.TEMPORARY_WORK,
    label: 'Trabajo Temporal',
    type: [CompanySectors.RECRUITMENT_AND_HUMAN_RESOURCES],
  },
  {
    value: CompanySubSectors.RECRUITMENT_SELECTION,
    label: 'Reclutamiento y Selección de Personal',
    type: [CompanySectors.RECRUITMENT_AND_HUMAN_RESOURCES],
  },
  {
    value: CompanySubSectors.ENTERTAINMENT,
    label: 'Entretenimiento',
    type: [CompanySectors.TRAVEL],
  },
  {
    value: CompanySubSectors.TRANSPORTATION,
    label: 'Transporte',
    type: [CompanySectors.TRAVEL],
  },
  {
    value: CompanySubSectors.TRAVEL_ACCOMMODATION,
    label: 'Viajes y alojamiento',
    type: [CompanySectors.TRAVEL],
  },
  {
    value: CompanySubSectors.CATERING_VENDING_MACHINES,
    label: 'Servicios de Catering y Máquinas Expendedoras',
    type: [CompanySectors.WORKPLACE],
  },
  {
    value: CompanySubSectors.BUILDING_MAINTENANCE_REPAIR_SERVICES,
    label: 'Servicios de Mantenimiento y Reparación de Edificios',
    type: [CompanySectors.WORKPLACE],
  },
  {
    value: CompanySubSectors.OILS,
    label: 'Aceites',
    type: [CompanySectors.COMMODITIES],
  },
  {
    value: CompanySubSectors.STARCHES_DERIVATIVES,
    label: 'Almidones y derivados',
    type: [CompanySectors.COMMODITIES],
  },
  {
    value: CompanySubSectors.ORGANIC_MATERIALS,
    label: 'Materiales Orgánicos',
    type: [CompanySectors.COMMODITIES],
  },
  {
    value: CompanySubSectors.MINERALS,
    label: 'Minerales',
    type: [CompanySectors.COMMODITIES],
  },
  {
    value: CompanySubSectors.ANIMAL_PROTEINS,
    label: 'Proteínas animales',
    type: [CompanySectors.COMMODITIES],
  },
  {
    value: CompanySubSectors.SOFT_COMMODITIES,
    label: 'Soft commodities',
    type: [CompanySectors.COMMODITIES],
  },
  {
    value: CompanySubSectors.CROPS,
    label: 'Cultivos',
    type: [CompanySectors.INGREDIENTS],
  },
  {
    value: CompanySubSectors.SPICES_HERBS,
    label: 'Especias y Hierbas',
    type: [CompanySectors.INGREDIENTS],
  },
  {
    value: CompanySubSectors.FUNCTIONAL_INGREDIENTS,
    label: 'Funcionales',
    type: [CompanySectors.INGREDIENTS],
  },
  {
    value: CompanySubSectors.OLEOCHEMICALS,
    label: 'Oleoquímicos',
    type: [CompanySectors.CHEMICALS],
  },
  {
    value: CompanySubSectors.PETROCHEMICALS,
    label: 'Petroquímicos',
    type: [CompanySectors.CHEMICALS],
  },
  {
    value: CompanySubSectors.SPECIALTY_CHEMICALS,
    label: 'Químicos Especiales',
    type: [CompanySectors.CHEMICALS],
  },
  {
    value: CompanySubSectors.INORGANIC_CHEMICALS,
    label: 'Inorgánicos',
    type: [CompanySectors.CHEMICALS],
  },
  {
    value: CompanySubSectors.ORGANIC_CHEMICALS,
    label: 'Orgánicos',
    type: [CompanySectors.CHEMICALS],
  },
  {
    value: CompanySubSectors.BOXES,
    label: 'Cajas',
    type: [CompanySectors.PACKAGING],
  },
  {
    value: CompanySubSectors.LAMINATES,
    label: 'Laminados',
    type: [CompanySectors.PACKAGING],
  },
  {
    value: CompanySubSectors.STORAGE_AND_PALLETS,
    label: 'Almacenamiento y Pallets',
    type: [CompanySectors.LOGISTICS],
  },
  {
    value: CompanySubSectors.MATERIAL_HANDLING_EQUIPMENT,
    label: 'Equipos para Manejo de Materiales',
    type: [CompanySectors.LOGISTICS],
  },
  {
    value: CompanySubSectors.TRANSPORTATION_AND_DISTRIBUTION_OF_GOODS,
    label: 'Transporte y Distribución de Mercancías',
    type: [CompanySectors.LOGISTICS],
  },

  {
    value: CompanySubSectors.MATERIALS_POINT_OF_SALE,
    label: 'Materiales de punto de venta',
    type: [CompanySectors.MARKETING],
  },
  {
    value: CompanySubSectors.OTHER,
    label: 'Otros',
    type: Object.values(CompanySectors).filter(
      (value) => typeof value === 'number',
    ) as CompanySectors[],
  },
];
