import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'CAPSULE', {
  EDITIONS: 'Cantidad de ediciones',
  MODULES: 'Cantidad de módulos',
  HOURS: 'Cantidad de horas',
  DATE: 'Fecha de dictado',
  AGRO: 'Agro',
  AGRO_DATE: '10/08/22',
});
