import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${SIZES.smallDesktop}px;
  width: 100%;
  margin: 0 auto 50px;
`;

export const SignUpContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 80%;
  }
`;

export const Title = styled.h1`
  font-family: UnileverBold;
  font-size: 32px;
  color: ${COLORS.bluePersian};
  margin: 38px 0 38px 0;
`;

export const SignUpFormContainer = styled.div`
  margin-top: 0;
`;

export const LoginContainer = styled.div`
  width: 100%;
  font-family: OpenSans;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
`;

export const LoginLink = styled(Link)`
  color: ${COLORS.purple};
  margin-left: 5px;

  :hover {
    color: ${COLORS.gray};
  }
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
`;

export const TextContainer = styled.div`
  padding: 15px 25px;
  margin-top: 70px;
  justify-content: center;
  align-items: start;
  font-size: 11px;
  width: 70%;
  background-color: ${COLORS.grayAlabaster};
`;

export const HelpText = styled.p`
  font-family: OpenSans;
  font-size: 12px;
  margin: 0;
  margin-top: 10px;
  padding-left: 5px;
  color: ${COLORS.gray};
`;

export const MailLink = styled.a`
  color: ${COLORS.purple};
  font-size: 12px;
  padding-left: 4px;
  align-self: center;
  display: block;
  align-self: auto;
  margin: 0;
  display: inline;
`;
