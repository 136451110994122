import React, { FunctionComponent, useState } from 'react';
import { Field, InjectedFormProps, change, reduxForm } from 'redux-form';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';

import {
  requiredValidation,
  identifierLengthValidation,
  identifierTypeValidation,
} from 'helpers/validations';
import Select from 'components/inputs/Select';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import { CompanyRole } from 'views/SignUpView/types';
import RadioGroup from 'components/inputs/RadioGroup';
import RadioButton from 'components/inputs/RadioButton';
import { RadioButtonsContainer } from 'components/commons/PopupForm/components/PagePopupForm/styles';
import { CompanySubSectors, companySubSectors } from 'helpers/enums/companySubSector';
import { CompanySectors } from 'helpers/enums/companySectors';
import { IFieldOption } from 'views/ProfileView/components/ProfileForm/types';

import {
  Form,
  ButtonContainer,
  FormButton,
  Theme,
  RowDiv,
  RowDivInput,
  Title,
  RowDivWithPadding,
  CustomInput,
} from './styles';
import { ISignUpCompanyFormProps, ISignUpCompanyFormData } from './types';
import './i18n';

const SignUpCompanyForm: FunctionComponent<
  ISignUpCompanyFormProps & InjectedFormProps<ISignUpCompanyFormData, ISignUpCompanyFormProps>
> = (props) => {
  const {
    handleSubmit,
    onSubmit,
    submitting,
    invalid,
    country,
    onClickBack,
    companySectorOptions,
    numberOfCollaboratorsOptions,
    howDidYouHearAboutUsOptions,
  } = props;
  const dispatch = useDispatch();

  const [selectedSector, setSelectedSector] = useState<number | undefined>();
  const [selectedSubSector, setSelectedSubSector] = useState<number | undefined>();

  const extractLabels = (fieldOption: IFieldOption[]) => {
    return fieldOption.map((option) => option.label);
  };

  const sectorLabels = extractLabels(companySectorOptions);
  const collaboratorsLabels = extractLabels(numberOfCollaboratorsOptions);
  const hearAboutUsLabels = extractLabels(howDidYouHearAboutUsOptions);
  const subSectorLabels = extractLabels(
    companySubSectors.filter((companySubSector) =>
      companySubSector.type.includes(selectedSector as CompanySectors),
    ),
  );

  const onChangeSector = (sector: any) => {
    setSelectedSector(companySectorOptions.find((i) => i.label === sector)?.value);
    setSelectedSubSector(undefined);
    dispatch(change('signup', 'subSector', undefined));
    dispatch(change('signup', 'subSectorOtherText', undefined));
  };

  const onChangeSubSector = (subSector: any) => {
    setSelectedSubSector(
      companySubSectors.find((companySubSector) => companySubSector.label === subSector)?.value,
    );
    dispatch(change('signup', 'subSectorOtherText', undefined));
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} data-testid="signup-form">
        <Title>{i18next.t('SIGNUP_COMPANY_FORM:TITLE')}</Title>
        <RowDiv>
          <Field
            component={CustomInput}
            label={i18next.t('SIGNUP_COMPANY_FORM:COMPANY_NAME')}
            name="companyName"
            validate={[requiredValidation]}
          />
        </RowDiv>
        <MuiThemeProvider theme={Theme}>
          <Tooltip
            title={
              country.company.identifierTooltipText ? country.company.identifierTooltipText : ''
            }
            placement="top-start"
            arrow
          >
            <RowDivInput>
              <Field
                component={CustomInput}
                label={country.company.identifierLabel}
                name="cuit"
                validate={[
                  requiredValidation,
                  identifierLengthValidation,
                  identifierTypeValidation,
                ]}
                maxLength={country.company.identifierLength}
                type="string"
              />
            </RowDivInput>
          </Tooltip>
        </MuiThemeProvider>
        <RowDivWithPadding>
          <Field
            component={Select}
            name="sector"
            validate={[requiredValidation]}
            options={sectorLabels}
            label={i18next.t('SIGNUP_COMPANY_FORM:SELECT_SECTOR')}
            onChange={onChangeSector}
          />
        </RowDivWithPadding>
        {!!selectedSector && (
          <>
            <RowDivWithPadding>
              <Field
                component={Select}
                name="subSector"
                validate={[requiredValidation]}
                options={subSectorLabels}
                label={i18next.t('SIGNUP_COMPANY_FORM:SELECT_SUB_SECTOR')}
                onChange={onChangeSubSector}
              />
            </RowDivWithPadding>
            {selectedSubSector === CompanySubSectors.OTHER && (
              <RowDiv>
                <Field
                  component={CustomInput}
                  label={i18next.t('SIGNUP_COMPANY_FORM:OTHER')}
                  name="subSectorOtherText"
                  validate={[requiredValidation]}
                />
              </RowDiv>
            )}
          </>
        )}
        <RowDiv>
          <Field
            component={CustomInput}
            label={i18next.t('SIGNUP_COMPANY_FORM:COMPANY_SPECIALTY')}
            name="specialty"
            validate={[requiredValidation]}
            placeholder={i18next.t('SIGNUP_COMPANY_FORM:COMPANY_SPECIALTY_PLACEHOLDER')}
          />
        </RowDiv>
        {/* <RowDivWithPadding>
          {i18next.t('SIGNUP_COMPANY_FORM:IS_PROVIDER')}
          <Field component={CustomCheckbox} name="isProvider" />
        </RowDivWithPadding> */}
        <RowDivWithPadding>
          {i18next.t('SIGNUP_COMPANY_FORM:IS_PROVIDER_OR_CLIENT')}
          <Field
            component={RadioGroup}
            label="radio group"
            name="companyRole"
            validate={[requiredValidation]}
          >
            <RadioButtonsContainer>
              <RadioButton
                label={i18next.t('SIGNUP_COMPANY_FORM:PROVIDER')}
                value={String(CompanyRole.PROVIDER)}
              />
              <RadioButton
                label={i18next.t('SIGNUP_COMPANY_FORM:CLIENT')}
                value={String(CompanyRole.CLIENT)}
              />
              <RadioButton
                label={i18next.t('SIGNUP_COMPANY_FORM:OTHER')}
                value={String(CompanyRole.OTHER)}
              />
            </RadioButtonsContainer>
          </Field>
        </RowDivWithPadding>
        <RowDivWithPadding>
          <Field
            component={Select}
            name="collaborators"
            validate={[requiredValidation]}
            options={collaboratorsLabels}
            label={i18next.t('SIGNUP_COMPANY_FORM:SELECT_NUMBER_OF_COLLABORATORS')}
          />
        </RowDivWithPadding>
        <RowDiv>
          <Field
            component={Select}
            name="hearAboutUs"
            validate={[requiredValidation]}
            options={hearAboutUsLabels}
            label={i18next.t('SIGNUP_COMPANY_FORM:HOW_DID_YOU_HEAR_ABOUT_US')}
          />
        </RowDiv>
        <ButtonContainer>
          <FormButton type="button" onClick={onClickBack}>
            {i18next.t('SIGNUP_COMPANY_FORM:BACK_BUTTON')}
          </FormButton>
          <FormButton disabled={submitting || invalid} type="submit">
            {i18next.t('SIGNUP_COMPANY_FORM:NEXT_BUTTON')}
          </FormButton>
        </ButtonContainer>
      </Form>
    </>
  );
};

export default reduxForm<ISignUpCompanyFormData, ISignUpCompanyFormProps>({
  form: 'signup',
})(SignUpCompanyForm);
