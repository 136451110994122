import React, { FunctionComponent, useState } from 'react';
import i18next from 'i18next';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import 'moment/locale/es';

import ItemsListWithShowSection from 'components/commons/ItemsListWithShowSection';
import { INotifications } from 'types/notifications.types';

import { INotificationsViewProps } from './types';
import {
  NotificationsContainer,
  Title,
  Notification,
  RowDiv,
  NotificationTitle,
  DateText,
  NotificationText,
  NotificationDetail,
  CustomParagraph,
} from './styles';
import './i18n';

const NotificationsView: FunctionComponent<INotificationsViewProps> = (
  props: INotificationsViewProps,
) => {
  const { notifications } = props;
  const [selected, setSelected] = useState(notifications[0]);
  moment.locale('es');

  const renderItem = (item: INotifications): JSX.Element => {
    return (
      <Notification>
        <RowDiv>
          <NotificationTitle>{item.title}</NotificationTitle>
          <DateText>{moment(item.dateCreated).format('LLL')}</DateText>
        </RowDiv>
        <div>
          <NotificationText>{item.description}</NotificationText>
        </div>
      </Notification>
    );
  };

  const renderItemSelected = (item: INotifications): JSX.Element => {
    return (
      <NotificationDetail>
        <RowDiv hasBorderBottom>
          <NotificationTitle hasBiggerFont>{item.title}</NotificationTitle>
          <DateText>{moment(item.dateCreated).format('LLL')}</DateText>
        </RowDiv>
        <NotificationText hasPaddingTop>{item.description}</NotificationText>
      </NotificationDetail>
    );
  };

  const renderNotificationsText = () => (
    <CustomParagraph data-testid="custom-paragraph">
      {i18next.t('NOTIFICATIONS_VIEW:TITLE_BACK')}
    </CustomParagraph>
  );

  return (
    <NotificationsContainer data-testid="notifications-view">
      <Title>{i18next.t('NOTIFICATIONS_VIEW:TITLE')}</Title>
      <ItemsListWithShowSection
        items={notifications}
        renderItem={renderItem}
        renderItemSelected={renderItemSelected}
        selected={selected || notifications[0]}
        setSelected={setSelected}
        emptyListText={i18next.t('NOTIFICATIONS_VIEW:EMPTY_LIST')}
        renderGoToListText={renderNotificationsText}
      />
    </NotificationsContainer>
  );
};

export default NotificationsView;
