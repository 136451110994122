import { createSelector } from 'reselect';

import { ICountry } from 'types/country.types';

const getCountries = (state: any) => state.country.countries;

const countrySelector = () =>
  createSelector([getCountries], (countries: ICountry[]) =>
    countries.filter((country) => country.name !== 'Colombia'),
  );

export default countrySelector;
