import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import CapsuleIcon from 'components/commons/CapsuleIcon';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import { ICapsuleProps } from './types';
import {
  CapsuleContainer,
  Title,
  Text,
  RowDiv,
  Points,
  PointsContainer,
  TextDescription,
  TextContainer,
  Icon,
  ButtonContainer,
  ColumnDiv,
  Theme,
} from './styles';
import './i18n';

const Capsule: FunctionComponent<ICapsuleProps> = (props: ICapsuleProps) => {
  const {
    capsule: { id, primaryColor, trainings, title, description },
    user,
  } = props;
  let countCompleted = 0;
  let points = 0;
  const capsuleTrainings = trainings[new Date().getFullYear()];

  capsuleTrainings.forEach((training) => {
    if (training.completedTrainings.length) {
      training.completedTrainings.forEach((completedTraining) => {
        if (completedTraining.userId === user.id) {
          countCompleted += 1;
          points += completedTraining.points;
        }
      });
    }
  });

  const newTitle = title.replace('#Ucc', '');

  return (
    <CapsuleContainer data-testid="capsule-component" color={primaryColor}>
      <RowDiv>
        <MuiThemeProvider theme={Theme}>
          <Tooltip title={newTitle} placement="top-start" arrow>
            <Title>{newTitle}</Title>
          </Tooltip>
        </MuiThemeProvider>
        <Icon>
          <CapsuleIcon isWhite name={newTitle} />
        </Icon>
      </RowDiv>
      <TextDescription>{description}</TextDescription>
      {/* Para hacerlo sólo Beginner cambiar 'newTitle' a 'title' */}
      {/* Para hacerlo sólo Uccelerator cambiar 'newTitle' a 'title' y 'Herramientas Financieras' a 'Herramientas Financieras#Ucc' */}
      <PointsContainer>
        <RowDiv>
          <ColumnDiv>
            <TextContainer>
              <Text isBold>{countCompleted}</Text>
              <Text>
                {i18next.t('PROGRAMS_VIEW_CAPSULE:CANT_TRAININGS')} {capsuleTrainings.length}
              </Text>
            </TextContainer>
            <Points>
              {points} {i18next.t('PROGRAMS_VIEW_CAPSULE:POINTS')}
            </Points>
          </ColumnDiv>
          <ButtonContainer to={`/capsulas/${id}`}>
            {i18next.t('PROGRAMS_VIEW_CAPSULE:ENTER')}
          </ButtonContainer>
        </RowDiv>
      </PointsContainer>
    </CapsuleContainer>
  );
};

export default Capsule;
