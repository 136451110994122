import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

export const CustomButton = styled.button`
  border: 0;
  background: ${COLORS.black};
  color: ${COLORS.white};
  font-weight: bold;
  font-size: 14px;
  padding: 14px 30px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;

  :hover:not([disabled]) {
    background: ${COLORS.pigmentIndigo};
    color: ${COLORS.white};
  }

  :disabled {
    background: ${COLORS.gray};
    cursor: default;
    color: ${COLORS.white};
  }
`;
