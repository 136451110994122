import styled from 'styled-components';

import { SIZES } from 'helpers/enums/sizes';
import { COLORS } from 'helpers/enums/colors';

import { ReactComponent as ArrowSVG } from 'assets/imgs/arrow-back.svg';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 1024px;
  margin: 0px auto;
  padding: 20px 0;

  @media (max-width: ${SIZES.smallDesktop}px) {
    width: 90%;
  }
`;

export const Background = styled.div`
  background-color: ${COLORS.linkWater};
`;

export const Title = styled.p`
  font-family: UnileverMedium;
  font-size: 40px;
  color: ${COLORS.bluePersian};
  text-align: center;
  margin-bottom: 6px;
  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 36px;
  }
`;

export const Text = styled.span`
  font-family: OpenSans;
  font-size: 18px;
  color: ${COLORS.riverBed};
  @media (max-width: ${SIZES.smallDesktop}px) {
    font-size: 16px;
  }
`;

export const DescriptionText = styled(Text)`
  text-align: center;
`;

export const CapsulesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  justify-items: center;

  @media (max-width: ${SIZES.smallDesktop}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${SIZES.mediumTablet}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Capsules = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const SpecialCapsulesContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${SIZES.smallDesktop}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ScoringContainer = styled.div`
  background-color: ${COLORS.lightblueZumthor};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 633px;
  padding: 0 20px;
  @media (max-width: ${SIZES.smallDesktop}px) {
    width: 85%;
    margin-top: 20px;
  }
`;

export const ScoringTitle = styled.p`
  font-size: 24px;
  color: ${COLORS.bluePersian};
  font-family: OpenSansBold;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export const ListIcon = styled(ArrowSVG)`
  width: 5px;
  height: 10px;
  margin-right: 8px;
  fill: ${COLORS.bluePersian};
`;
