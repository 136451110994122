import React, { FunctionComponent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { SIZES } from 'helpers/enums/sizes';
import { GOOGLE_CAPTCHA } from 'configs/configs';

import { ICaptchaProps } from './types';
import Container from './styles';

const Captcha: FunctionComponent<ICaptchaProps> = (props: ICaptchaProps) => {
  const { input } = props;

  return (
    <Container data-testid="captcha-component">
      <ReCAPTCHA
        sitekey={GOOGLE_CAPTCHA}
        onChange={input.onChange}
        style={{ display: 'inline-block' }}
        size={window.innerWidth <= SIZES.smallPhone ? 'compact' : 'normal'}
      />
    </Container>
  );
};

export default Captcha;
