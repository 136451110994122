import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/testimonials.constants';
import * as appConstants from 'redux/constants/app.constants';
import * as companyConstants from 'redux/constants/company.constants';
import { getAllTestimonials } from 'services/testimonials.services';

export function* getTestimonials() {
  try {
    const data: unknown = yield call(getAllTestimonials);
    yield put({ type: constants.TESTIMONIALS_ON_GET_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.TESTIMONIALS_ON_GET_FAILED, error });
  }
}

export function* watchTestimonials() {
  yield all([
    takeLatest(
      [
        appConstants.APP_ON_INITIALIZE_REQUESTED,
        constants.TESTIMONIALS_ON_GET_REQUESTED,
        companyConstants.COMPANY_ON_SUBMIT_REGISTER_FORM_SUCCEEDED,
      ],
      getTestimonials,
    ),
  ]);
}
