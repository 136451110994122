import React, { FunctionComponent, useState } from 'react';

import Modal from 'components/commons/Modal';

import { IQuestion } from 'types/form.types';
import { IPopupFormProps } from './types';
import PagePopupForm from './components/PagePopupForm';

const PopupForm: FunctionComponent<IPopupFormProps> = (props: IPopupFormProps) => {
  const { title, description, questionsPaginated, submitForm, formOpen, onCloseForm } = props;
  const [page, setPage] = useState<number>(0);

  const scrollToTop = () => {
    const modalTitle = document.getElementById('modal-title');
    if (modalTitle) modalTitle.scrollIntoView();
  };

  const previousPage = () => {
    setPage(page - 1);
    scrollToTop();
  };
  const nextPage = () => {
    setPage(page + 1);
    scrollToTop();
  };

  return (
    <Modal isOpen={formOpen} title={title} onClose={onCloseForm}>
      {questionsPaginated.map(
        (questions: IQuestion[], index) =>
          index === page && (
            <PagePopupForm
              description={description}
              submitForm={submitForm}
              nextPage={nextPage}
              previousPage={previousPage}
              questions={questions}
              isFirstPage={index === 0}
              isLastPage={index === questionsPaginated.length - 1}
            />
          ),
      )}
    </Modal>
  );
};

export default PopupForm;
