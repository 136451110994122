import * as constants from 'redux/constants/user.constants';
import { ICompany, IUser } from 'types/company.types';

export function onConfirmAccount(token: string): { type: string; token: string } {
  return {
    type: constants.USER_ON_CONFIRM_ACCOUNT_REQUESTED,
    token,
  };
}

export function onAcceptAccount(token: string): { type: string; token: string } {
  return {
    type: constants.USER_ON_ACCEPT_ACCOUNT_REQUESTED,
    token,
  };
}

export function onForgotPassword(formData: any): { type: string; formData: any } {
  return {
    type: constants.USER_ON_FORGOT_PASSWORD_REQUESTED,
    formData,
  };
}

export function onLogin(
  formData: any,
  from: string,
): { type: string; formData: any; from: string } {
  return {
    type: constants.USER_ON_LOGIN_REQUESTED,
    formData,
    from,
  };
}

export function onInitializeEmployee(company: ICompany): { type: string; company: ICompany } {
  return {
    type: constants.USER_ON_INITIALIZE_EMPLOYEE_REQUESTED,
    company,
  };
}

export function onUserEnable(user: IUser): { type: string; user: IUser } {
  return {
    type: constants.USER_ENABLE_REQUESTED,
    user,
  };
}

export function onUserDisable(user: IUser): { type: string; user: IUser } {
  return {
    type: constants.USER_DISABLE_REQUESTED,
    user,
  };
}

export function onChangeUserToOwner(user: IUser): { type: string; user: IUser } {
  return {
    type: constants.USER_CHANGE_TO_OWNER_REQUESTED,
    user,
  };
}

export function onLogout(): { type: string } {
  return { type: constants.USER_ON_LOGOUT_REQUESTED };
}

export function onRegisterUser(formData: any): { type: string; formData: any } {
  return {
    type: constants.USER_ON_REGISTER_REQUESTED,
    formData,
  };
}

export function onRegisterCompany(formData: any): { type: string; formData: any } {
  return {
    type: constants.COMPANY_ON_REGISTER_REQUESTED,
    formData,
  };
}

export function onValidateIdentifier(formData: any): { type: string; formData: any } {
  return {
    type: constants.USER_ON_VALIDATE_IDENTIFIER_REQUESTED,
    formData,
  };
}

export function onRegisterFormBack(): { type: string } {
  return {
    type: constants.USER_ON_REGISTER_FORM_BACK,
  };
}

export function onResendEmailAccountConfirmation(formData: any): { type: string; formData: any } {
  return {
    type: constants.USER_ON_RESEND_EMAIL_ACCOUNT_CONFIRMATION_REQUESTED,
    formData,
  };
}

export function onResetPassword(formData: any): { type: string; formData: any } {
  return {
    type: constants.USER_ON_RESET_PASSWORD_REQUESTED,
    formData,
  };
}

export function onUpdate(formData: any): { type: string; formData: any } {
  return {
    type: constants.USER_ON_UPDATE_REQUESTED,
    formData,
  };
}

export function onContact(formData: any): { type: string; formData: any } {
  return {
    type: constants.USER_ON_CONTACT_REQUESTED,
    formData,
  };
}

export function onInitializeSignUpView(): { type: string } {
  return {
    type: constants.USER_ON_INITIALIZE_SIGN_UP_VIEW_REQUESTED,
  };
}

export function onInitializeSignUpViewSuceeded(formData: any): { type: string; formData: any } {
  return {
    type: constants.USER_ON_INITIALIZE_SIGN_UP_VIEW_SUCCEEDED,
    formData,
  };
}

export function onConfirmAssistance(
  userId: string,
  trainingId: string,
): { type: string; userId: string; trainingId: string } {
  return {
    type: constants.USER_ON_CONFIRM_ASSISTANCE_REQUESTED,
    userId,
    trainingId,
  };
}
export function onGetForms(): { type: string } {
  return {
    type: constants.USER_ON_GET_FORMS_REQUESTED,
  };
}

export function onCompleteForms(formData: any): { type: string; formData: any } {
  return {
    type: constants.USER_ON_COMPLETE_FORM_REQUESTED,
    formData,
  };
}

export function initializeCertificatesView(): { type: string } {
  return {
    type: constants.USER_ON_GET_CERTIFICATES_REQUESTED,
  };
}

export function onCloseNewLevelModal(): { type: string } {
  return {
    type: constants.USER_ON_CLOSE_NEW_LEVEL_MODAL,
  };
}

export function onRedirectToBenefits(): { type: string } {
  return {
    type: constants.USER_ON_REDIRECT_TO_BENEFITS,
  };
}

export function onCloseCompleteProfileModal(): { type: string } {
  return {
    type: constants.USER_ON_CLOSE_COMPLETE_PROFILE_MODAL,
  };
}

export function onRedirectToProfile(): { type: string } {
  return {
    type: constants.USER_ON_REDIRECT_TO_PROFILE,
  };
}
