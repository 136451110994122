import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import useTypedSelector from 'hooks/useTypedSelector';
import { CompanyStatus } from 'helpers/enums/status';

const PrivateRoute: FunctionComponent<RouteProps> = ({
  component: Component,
  ...rest
}: RouteProps) => {
  const company = useTypedSelector((state) => state.company.data);
  const accessPrivateRoute = useTypedSelector((state) => state.auth.accessPrivateRoutes);
  const [isLoading, setIsLoading] = useState(true);
  if (!Component) return null;

  const redirectCompany = (props: any) => {
    if (!company) return <Redirect to={props.location.pathname} />;
    // To enable form: Delete COMPLETE_FORM
    return company.status === CompanyStatus.ACTIVE ||
      company.status === CompanyStatus.COMPLETE_FORM ||
      props.location.pathname === '/dashboard' ? (
      <Component {...props} />
    ) : (
      <Redirect to="/dashboard" />
    );
  };
  const handleRender = (props: any) => {
    const redirect = redirectCompany(props);
    if (redirect) {
      return redirect;
    }
    return (
      <Redirect
        push={false}
        to={{
          pathname: '/login',
          state: {
            from: props.location.pathname,
            params: props.location.search,
          },
          search: props.location.search,
        }}
      />
    );
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (accessPrivateRoute) {
      setIsLoading(false);
    }
  }, [accessPrivateRoute]);

  return <Route {...rest} render={(props) => (isLoading ? '' : handleRender(props))} />;
};

export default PrivateRoute;
