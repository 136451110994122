import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { getCountryEmail } from 'helpers/countries';
import { companySectors } from 'helpers/enums/companySectors';
import { companyNumberOfCollaborators } from 'helpers/enums/companyNumberOfCollaborators';
import { companyHowDidYouHearAboutUs } from 'helpers/enums/companyHowDidYouHearAboutUs';
import { companySubSectors } from 'helpers/enums/companySubSector';

import { Role } from 'helpers/enums/roles';
import ProfileForm from './components/ProfileForm';

import { IProfileFormData, IProfileViewProps } from './types';
import {
  ProfileContainer,
  Title,
  Text,
  TextLink,
  FormContainer,
  TextContainer,
  SettingsContainer,
} from './styles';
import './i18n';
import { IFieldOption } from './components/ProfileForm/types';

const ProfileView: FunctionComponent<IProfileViewProps> = (props: IProfileViewProps) => {
  const { initialValues, onSubmit, onLogoutClick, country, user } = props;

  const handleSubmitProfile = (formData: IProfileFormData) => {
    const findValueByLabel = (options: IFieldOption[], label: string) =>
      options.find((option) => option.label === label)?.value.toString();

    const sector = findValueByLabel(companySectors, formData.sector);
    const collaborators = findValueByLabel(companyNumberOfCollaborators, formData.collaborators);
    const hearAboutUs = findValueByLabel(companyHowDidYouHearAboutUs, formData.hearAboutUs);
    const subSector = findValueByLabel(companySubSectors, formData.subSector);

    onSubmit({
      ...formData,
      sector: sector || '',
      collaborators: collaborators || '',
      hearAboutUs: hearAboutUs || '',
      subSector: subSector || '',
    });
  };

  return (
    <ProfileContainer data-testid="profile-view">
      <FormContainer>
        <Title>{i18next.t('PROFILE_VIEW:TITLE')}</Title>
        <ProfileForm
          isOwner={user.role === Role.COMPANY_OWNER}
          onSubmit={handleSubmitProfile}
          initialValues={initialValues}
          country={country.name}
        />
      </FormContainer>
      <Title hasLessMargin>{i18next.t('PROFILE_VIEW:SETTINGS')}</Title>
      <TextContainer>
        <Text>{i18next.t('PROFILE_VIEW:MODIFY_PASSWORD')}</Text>
        <TextLink target="_blank" href={`mailto:${getCountryEmail(country.name)}`}>
          {i18next.t('PROFILE_VIEW:MODIFY_PASSWORD_LINK')}
        </TextLink>
      </TextContainer>
      <SettingsContainer>
        <TextLink onClick={onLogoutClick}>{i18next.t('PROFILE_VIEW:LOG_OUT')}</TextLink>
      </SettingsContainer>
    </ProfileContainer>
  );
};

export default ProfileView;
