import styled from 'styled-components';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import { SIZES } from 'helpers/enums/sizes';

export const StyledCarousel = styled(Carousel)`
  text-align: center;
  display: flex;
  justify-content: flex-start;
  @media (max-width: ${SIZES.smallDesktop}px) {
    li {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: ${SIZES.mediumTablet}px) {
    li {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  margin-bottom: ${({ hasMarginBottom }: { hasMarginBottom?: boolean }) =>
    hasMarginBottom && '30px'};
`;
