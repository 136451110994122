import React, { FunctionComponent } from 'react';
import { NavHashLink } from 'react-router-hash-link';

import { WWW_URL } from 'configs/configs';
import { ILogoProps } from './types';
import { LogoContainer, LogoImgColor, LogoImgWhite, Line, UnileverLogo } from './styles';

const Logo: FunctionComponent<ILogoProps> = (props: ILogoProps) => {
  const { isDarkModeOn = false, isLanding, isWhiteLogo = false, showUnileverLogo = false } = props;

  return (
    <LogoContainer data-testid="logo" isDarkModeOn={isDarkModeOn}>
      {isLanding ? (
        <a href={`${WWW_URL}/#top`}>{isWhiteLogo ? <LogoImgWhite /> : <LogoImgColor />}</a>
      ) : (
        <NavHashLink smooth to="/dashboard">
          {isWhiteLogo ? <LogoImgWhite /> : <LogoImgColor />}
        </NavHashLink>
      )}
      {showUnileverLogo && (
        <>
          <Line />
          <UnileverLogo />
        </>
      )}
    </LogoContainer>
  );
};

export default Logo;
