import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { WWW_URL } from 'configs/configs';

import Logo from 'components/commons/Logo';
import imageNotFound from 'assets/imgs/img-404-min.png';

import { ErrorContainer, Top, ErrorBody, Text, StartButton, Link, Image } from './styles';
import './i18n';

const ErrorView: FunctionComponent = () => (
  <ErrorContainer data-testid="error-view">
    <Top>
      <Logo />
    </Top>
    <ErrorBody>
      <Image src={imageNotFound} />
      <Text>{i18next.t('ERROR_VIEW:HELPER_TEXT')}</Text>
      <Link data-testid="button-to-home" href={`${WWW_URL}`}>
        <StartButton>{i18next.t('ERROR_VIEW:TO_HOME')}</StartButton>
      </Link>
    </ErrorBody>
  </ErrorContainer>
);

export default ErrorView;
