import i18next from 'i18next';
import { locales } from 'configs/i18n';

i18next.addResources(locales.ES, 'TRAINING_CARD', {
  WATCH_TRAINING: 'Ver capacitación',
  SUBMIT_CODE: 'Cargar Código',
  POINTS: 'Sumó {{points}} puntos',
  CERT_MODAL_TITLE: '¡Felicidades!',
  CERT_MODAL_DESCRIPTION:
    'Completaste todas las capacitaciones pertenecientes a esta cápsula, en breve se genererará un certificado al que podrás acceder en la sección Certificados.',
  CERT_MODAL_BUTTON: 'Ir a Certificados',
  SOON: 'Próximamente',
  DETAIL: 'Ver detalles',
  DATE: 'Fecha',
  HOUR: 'Hora',
});
