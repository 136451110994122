import React from 'react';

import { WWW_URL } from 'configs/configs';
//  import { ReactComponent as NotificationsIcon } from '../../assets/imgs/icon-notifications.svg';
import { ReactComponent as DashboardIcon } from '../../assets/imgs/icon-dashboard.svg';
import { ReactComponent as BeneficiosIcon } from '../../assets/imgs/icon-benef.svg';
import { ReactComponent as InfoIcon } from '../../assets/imgs/InfoIcon.svg';
import { ReactComponent as CertIcon } from '../../assets/imgs/icon-certificate.svg';
import { ReactComponent as CollaboratorsIcon } from '../../assets/imgs/icon-colaboradores.svg';
import { Role } from './roles';

export const LandingLinks = [
  {
    id: 'nav-programs',
    text: 'Convocatorias 4.0',
    to: `${WWW_URL}/convocatorias`,
    external: true,
  },
  // {
  //   id: 'nav-programs',
  //   text: 'Programas',
  //   to: `${WWW_URL}#programs`,
  //   external: true,
  // },
  // {
  //   id: 'nav-news',
  //   text: 'Beneficios',
  //   to: '/home#benefits',
  // },
  {
    id: 'nav-what-is',
    text: 'Qué es UniPyME',
    to: `${WWW_URL}/#what-is`,
    external: true,
  },
];

export const DashboardLinks = [
  {
    id: 'nav-dashboard',
    text: 'Inicio',
    to: '/dashboard',
    target: '',
    icon: <DashboardIcon />,
  },
  {
    id: 'nav-beneficios',
    text: 'Beneficios',
    to: '/beneficios',
    target: '',
    icon: <BeneficiosIcon />,
  },
  /*
  {
    id: 'nav-notifications',
    text: 'Notificaciones',
    to: '/notificaciones',
    target: '',
    icon: <NotificationsIcon />,
  },
  */
  {
    id: 'nav-employees',
    text: 'Colaboradores',
    to: '/colaboradores',
    target: '',
    icon: <CollaboratorsIcon />,
  },
  {
    id: 'nav-certs',
    text: 'Certificados',
    to: '/certificados',
    target: '',
    icon: <CertIcon />,
  },
  {
    id: 'nav-info',
    text: '',
    to: '/terminos-y-condiciones',
    target: '_blank',
    icon: <InfoIcon />,
  },
];
export const LegalLinks = [
  {
    id: 'nav-aviso-cookies',
    href: 'https://www.unilevernotices.com/argentina/spanish/cookie-notice/notice.html',
    text: 'Aviso de Cookies',
  },
  {
    id: 'nav-aviso-legal',
    href: 'https://www.unilever-southlatam.com/legal.html',
    text: 'Aviso Legal',
  },
];

export enum MenuRoutes {
  DASHBOARD = '/dashboard',
  PROGRAMS = '/programas',
  NEWS_AND_TESTIMONIES = '/noticias',
  BENEFITS = '/beneficios',
  NOTIFICATIONS = '/notificaciones',
  EMPLOYEES = '/colaboradores',
  TERMS_AND_CONDITIONS = '/terminos-y-condiciones',
  CERTIFICATES = '/certificados',
  CAPSULE = '/capsulas',
  TRAININGS = '/capacitaciones',
}
export const userRoutesAuthorize = {
  [Role.COMPANY_OWNER]: [
    MenuRoutes.DASHBOARD,
    MenuRoutes.PROGRAMS,
    MenuRoutes.NEWS_AND_TESTIMONIES,
    MenuRoutes.BENEFITS,
    MenuRoutes.NOTIFICATIONS,
    MenuRoutes.EMPLOYEES,
    MenuRoutes.TERMS_AND_CONDITIONS,
    MenuRoutes.CERTIFICATES,
  ],
  [Role.COMPANY_USER]: [
    MenuRoutes.DASHBOARD,
    MenuRoutes.PROGRAMS,
    MenuRoutes.NEWS_AND_TESTIMONIES,
    MenuRoutes.BENEFITS,
    MenuRoutes.NOTIFICATIONS,
    MenuRoutes.TERMS_AND_CONDITIONS,
    MenuRoutes.CERTIFICATES,
  ],
  [Role.ADMIN]: [] as MenuRoutes[],
  [Role.SUPER_ADMIN]: [] as MenuRoutes[],
};
