import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { ICompleteProfileModalProps } from './types';
import Modal from '../Modal';
import { ModalButtonContainer, ModalContentContainer } from './styles';
import Button from '../Button';
import './i18n';

const CompleteProfileModal: FunctionComponent<ICompleteProfileModalProps> = (
  props: ICompleteProfileModalProps,
) => {
  const { isOpen, onClose, onClickButton } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isDark>
      <ModalContentContainer>
        <b>{i18next.t('COMPLETE_PROFILE_MODAL:TITLE')}</b>
        <br />
        {i18next.t('COMPLETE_PROFILE_MODAL:TEXT')}
        <ModalButtonContainer>
          <Button onClick={onClickButton}>{i18next.t('COMPLETE_PROFILE_MODAL:BUTTON_TEXT')}</Button>
        </ModalButtonContainer>
      </ModalContentContainer>
    </Modal>
  );
};

export default CompleteProfileModal;
