import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';

import useTypedSelector from 'hooks/useTypedSelector';
import { onContact } from 'redux/actions/user.actions';
import { IContactFormData } from 'views/HomeView/components/ContactForm/types';

import LayoutContainer from 'containers/LayoutContainer';
import HomeView from 'views/HomeView';
import Loading from 'components/commons/Loading';
import Modal from 'components/commons/Modal';
import countrySelector from 'redux/selectors/country.selector';
import * as userActions from 'redux/actions/user.actions';

const HomeContainer: FunctionComponent = () => {
  const news = useTypedSelector((state) => state.news.items);
  const loading = useTypedSelector((state) => state.company.loading);
  const dispatch = useDispatch();

  const countries = useSelector(countrySelector());
  const company = useTypedSelector((state) => state.company?.data);

  const onInitialize = useCallback(() => {
    dispatch(userActions.onInitializeSignUpView());
  }, [dispatch]);

  useEffect(() => {
    onInitialize();
  }, [onInitialize]);

  const onSubmit = (formData: IContactFormData) => {
    dispatch(onContact(formData));
    dispatch(reset('contactForm'));
  };

  const landingNews = news?.filter((newsItem) => newsItem.visibleInLand);

  return (
    <>
      {loading && (
        <Modal disabled isOpen>
          <Loading />
        </Modal>
      )}
      <LayoutContainer isLanding isHome hasBanner isTopbarOverlay>
        <HomeView
          onSubmitContact={onSubmit}
          news={landingNews}
          countries={countries}
          company={company}
        />
      </LayoutContainer>
    </>
  );
};

HomeContainer.defaultProps = {
  loading: false,
};

export default HomeContainer;
